import {
  DisplayRowProjectAuthentications,
  DisplayRowApiDefinition,
  DisplayRowProjectDictionary,
  DisplayRowProjectInfo,
  DisplayRowProjectInjectables,
  DisplayRowProjectMTLSCertificates,
  DisplayRowProjectPathExclusion,
  DisplayRowRunSchedule,
  Section,
  DisplayRowSpaceLink,
} from "@Components";
import { NewProjectContext, NewProjectExtrasContext } from "@Contexts";
import React, { useContext, useMemo } from "react";
import { Container } from "reactstrap";
import { SecurityChecksSection } from "./SecurityChecks";
import type {
  AuthenticationSettings,
  Injectable,
  MTLSCertificate,
} from "@Services";
import "./style.scss";

type FormModel = {
  authenticationSettings: AuthenticationSettings;
  injectables: Injectable[];
  mTLSCertificates: MTLSCertificate[];
};

export const Summary = React.memo(() => {
  const { project: p } = useContext(NewProjectContext);
  const { projectExtras: pe } = useContext(NewProjectExtrasContext);

  const authenticationSettings = useMemo(() => {
    const authenticationSettings = p?.authenticationSettings ?? [];
    const injectables = p?.injectables ?? [];
    const mtlsCertificates = p?.mTLSCertificates ?? [];

    const authentications: Array<FormModel> = [];

    authenticationSettings.forEach(authenticationSetting => {
      const thisInjectables = injectables.filter(
        i => i.associatedAuthSetting === authenticationSetting.name
      );
      const thisMtlsCertificates = mtlsCertificates.filter(
        m => m.associatedAuthSetting === authenticationSetting.name
      );

      authentications.push({
        authenticationSettings: authenticationSetting,
        injectables: thisInjectables,
        mTLSCertificates: thisMtlsCertificates,
      });
    });

    return authentications;
  }, [p?.authenticationSettings, p?.injectables, p?.mTLSCertificates]);

  return (
    <div id="summary">
      <Section>
        <Container fluid className="p-4">
          <DisplayRowProjectInfo project={p} />
          <DisplayRowApiDefinition
            fileName={{
              label: "common.source-file",
              value: pe.oasFile?.upload.name ?? "",
            }}
            endpoints={pe.oasFile?.endpoints || 0}
          />
          {!!p.spaceLinkID && (
            <DisplayRowSpaceLink spaceLinkID={p.spaceLinkID ?? ""} />
          )}
          <SecurityChecksSection />
          <DisplayRowRunSchedule runPolicy={p.runPolicy} />
          {p.authenticationSettings.length > 0 && (
            <DisplayRowProjectAuthentications
              authenticationSettings={authenticationSettings}
            />
          )}
          {p.dictionary.length > 0 && (
            <DisplayRowProjectDictionary dictionary={p.dictionary} />
          )}
          {p.pathExclusions && p.pathExclusions.length > 0 && (
            <DisplayRowProjectPathExclusion pathExclusions={p.pathExclusions} />
          )}
          {p.injectables && p.injectables.length > 0 && (
            <DisplayRowProjectInjectables injectableEntries={p.injectables} />
          )}
          {p.mTLSCertificates && p.mTLSCertificates.length > 0 && (
            <DisplayRowProjectMTLSCertificates
              mtlsCertificates={p.mTLSCertificates}
            />
          )}
        </Container>
      </Section>
    </div>
  );
});
