import { DisplayRow } from "../DisplayRow";
import { t } from "i18next";
import { useOrganizationContext, useSpaceLinkDetails } from "@Hooks";
import { useMemo } from "react";
import { Spinner } from "reactstrap";
import type { ButtonProps } from "@Components";

type DisplayRowSpaceLinkProps = {
  spaceLinkID: string;
  onClick?: VoidFunction;
};

const enterpriseOnlyTooltip = {
  content: t("common.enterprise-only"),
  place: "left",
} as const;

const labels = {
  noSpaceLink: t("project.settings.no-space-link"),
  connectSpaceLink: t("project.settings.connect-space-link"),
};

export const DisplayRowSpaceLink = ({
  onClick,
  spaceLinkID,
}: DisplayRowSpaceLinkProps) => {
  const { spaceLink, fetching } = useSpaceLinkDetails(spaceLinkID);

  const { license } = useOrganizationContext();
  const isEnterprise = license.name === "Enterprise";

  const buttonProps = useMemo<ButtonProps | undefined>(() => {
    if (!onClick) {
      return undefined;
    }
    const result: ButtonProps = {
      color: "primary",
      children: spaceLinkID ? "common.edit" : "common.connect",
      iconClass: spaceLinkID ? "pencil" : "plus-square-fill",
      onClick,
      data: { cy: "edit-space-links" },
    };

    if (!isEnterprise) {
      result.disabled = true;
      result.tooltip = enterpriseOnlyTooltip;
    }

    return result;
  }, [isEnterprise, onClick, spaceLinkID]);

  const displayRowProps = useMemo(() => {
    if (fetching) {
      return { children: <Spinner size="sm" /> };
    }

    if (spaceLink) {
      return {
        columns: [
          {
            label: "common.name",
            value: spaceLink.name,
            className: "mb-4 mb-xl-0",
            xs: 12,
          },
        ],
      };
    }

    return {
      children: (
        <>
          <div className="color-gray">
            {labels.noSpaceLink}
            {!buttonProps?.disabled && (
              <span className="d-block">{labels.connectSpaceLink}</span>
            )}
          </div>
        </>
      ),
    };
  }, [buttonProps?.disabled, fetching, spaceLink]);

  return (
    <DisplayRow
      title="common.space-link"
      buttonProps={onClick ? buttonProps : undefined}
      {...displayRowProps}
    />
  );
};
