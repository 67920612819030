import { memo, type FC } from "react";
import { FormInput, JSONViewer } from "@Components";
import type { AdvancedSettingsFormProps } from "./AdvancedSettingsForm.i";
import { useAdvancedSettingsForm } from "./useAdvancedSettingsForm";
import { FormGroup } from "reactstrap";
import "./style.scss";
import { equals } from "@Services";
import { numberInputRanges, providerAttackStrenghtOptions } from "./constansts";
import { useDebuggerMode } from "./useDebuggerMode";

export const AdvancedSettingsForm: FC<AdvancedSettingsFormProps> = memo(
  props => {
    const { values, onChange } = useAdvancedSettingsForm(props);
    const devMode = useDebuggerMode();
    return (
      <div id="advanced-settings-form">
        <div className="card p-3">
          <FormGroup>
            <FormInput
              type="number"
              id="clientTimeout"
              name="clientTimeout"
              label="clientTimeout (seconds)"
              min={numberInputRanges.clientTimeout.min}
              max={numberInputRanges.clientTimeout.max}
              value={values.clientTimeout}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup switch>
            <FormInput
              type="checkbox"
              id="disableCompression"
              name="disableCompression"
              label="disableCompression"
              checked={values.disableCompression}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup switch>
            <FormInput
              type="checkbox"
              id="disableKeepAlives"
              name="disableKeepAlives"
              label="disableKeepAlives"
              checked={values.disableKeepAlives}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup switch>
            <FormInput
              type="checkbox"
              id="enableDebugHeaders"
              name="enableDebugHeaders"
              label="enableDebugHeaders"
              checked={values.enableDebugHeaders}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup>
            <FormInput
              type="number"
              id="exitOnCoverage"
              name="exitOnCoverage"
              label="exitOnCoverage (%)"
              value={values.exitOnCoverage}
              min={numberInputRanges.exitOnCoverage.min}
              max={numberInputRanges.exitOnCoverage.max}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup switch>
            <FormInput
              type="checkbox"
              id="exitOnFullCoverage"
              name="exitOnFullCoverage"
              label="exitOnFullCoverage"
              checked={values.exitOnFullCoverage}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup>
            <FormInput
              type="number"
              id="exitOnReachability"
              name="exitOnReachability"
              label="exitOnReachability (%)"
              value={values.exitOnReachability}
              min={numberInputRanges.exitOnReachability.min}
              max={numberInputRanges.exitOnReachability.max}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup>
            <FormInput
              type="number"
              id="explorationPhaseTimeout"
              name="explorationPhaseTimeout"
              label="explorationPhaseTimeout (minutes)"
              value={values.explorationPhaseTimeout}
              min={numberInputRanges.explorationPhaseTimeout.min}
              max={numberInputRanges.explorationPhaseTimeout.max}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup>
            <FormInput
              type="number"
              id="exploitationPhaseTimeout"
              name="exploitationPhaseTimeout"
              label="exploitationPhaseTimeout (minutes)"
              value={values.exploitationPhaseTimeout}
              min={numberInputRanges.exploitationPhaseTimeout.min}
              max={numberInputRanges.exploitationPhaseTimeout.max}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup switch>
            <FormInput
              type="checkbox"
              id="followRedirect"
              name="followRedirect"
              label="followRedirect"
              checked={values.followRedirect}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup switch>
            <FormInput
              type="checkbox"
              id="insecureSkipVerify"
              name="insecureSkipVerify"
              label="insecureSkipVerify"
              checked={values.insecureSkipVerify}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup>
            <FormInput
              type="number"
              id="maxIteration"
              name="maxIteration"
              label="maxIteration"
              value={values.maxIteration}
              min={numberInputRanges.maxIteration.min}
              max={numberInputRanges.maxIteration.max}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup>
            <FormInput
              type="number"
              id="maxRenderAttempts"
              name="maxRenderAttempts"
              label="maxRenderAttempts"
              value={values.maxRenderAttempts}
              min={numberInputRanges.maxRenderAttempts.min}
              max={numberInputRanges.maxRenderAttempts.max}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup>
            <FormInput
              type="text"
              id="proxyUrl"
              name="proxyUrl"
              label="proxyUrl"
              value={values.proxyUrl}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup>
            <FormInput
              type="text"
              id="userAgent"
              name="userAgent"
              label="userAgent"
              value={values.userAgent}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup>
            <FormInput
              type="number"
              id="requestThrottle"
              name="requestThrottle"
              label="requestThrottle (seconds)"
              value={values.requestThrottle}
              min={numberInputRanges.requestThrottle.min}
              max={numberInputRanges.requestThrottle.max}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup>
            <FormInput
              type="number"
              id="requestJitterPercentage"
              name="requestJitterPercentage"
              label="requestJitterPercentage (%)"
              value={values.requestJitterPercentage}
              min={numberInputRanges.requestJitterPercentage.min}
              max={numberInputRanges.requestJitterPercentage.max}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup switch>
            <FormInput
              type="checkbox"
              id="anonymousExploration"
              name="anonymousExploration"
              label="anonymousExploration"
              checked={values.anonymousExploration}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup>
            <FormInput
              type="select"
              id="providerAttackStrength"
              name="providerAttackStrength"
              label="providerAttackStrength"
              value={values.providerAttackStrength}
              onChange={onChange}
              options={providerAttackStrenghtOptions}
            />
          </FormGroup>
          <FormGroup switch>
            <FormInput
              type="checkbox"
              id="exploitOnlyResolvedNodes"
              name="exploitOnlyResolvedNodes"
              label="exploitOnlyResolvedNodes"
              checked={values.exploitOnlyResolvedNodes}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup switch>
            <FormInput
              type="checkbox"
              id="includeAuthProfileOnlyForResolved"
              name="includeAuthProfileOnlyForResolved"
              label="includeAuthProfileOnlyForResolved"
              checked={values.includeAuthProfileOnlyForResolved}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup switch>
            <FormInput
              type="checkbox"
              id="includeParametersInHash"
              name="includeParametersInHash"
              label="includeParametersInHash"
              checked={values.includeParametersInHash}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup>
            <FormInput
              type="number"
              id="providerMaxScanDuration"
              name="providerMaxScanDuration"
              label="providerMaxScanDuration (minutes)"
              value={values.providerMaxScanDuration}
              min={numberInputRanges.providerMaxScanDuration.min}
              max={numberInputRanges.providerMaxScanDuration.max}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup noMargin>
            <FormInput
              type="number"
              id="authRefreshInterval"
              name="authRefreshInterval"
              label="authRefreshInterval (minutes)"
              value={values.authRefreshInterval}
              min={numberInputRanges.authRefreshInterval.min}
              max={numberInputRanges.authRefreshInterval.max}
              onChange={onChange}
            />
          </FormGroup>
          {devMode && <JSONViewer data={props.advancedSettings} />}
        </div>
      </div>
    );
  },
  equals
);
