import type { ComponentButtonProps } from "@Components";
import { useManageApiResponse, useUrlParams } from "@Hooks";
import { API } from "@Services";
import { useState } from "react";

export const useReinviteButton = () => {
  const [loading, setLoading] = useState(false);
  const [inviteSent, setInviteSent] = useState(false);
  const { userId, organizationId } = useUrlParams();
  const manageResponse = useManageApiResponse(setLoading);

  return {
    buttonProps: {
      color: "primary",
      id: "reinvite-button",
      children: "organization.re-invite",
      loading,
      disabled: inviteSent,
      data: { cy: "reinvite-button" },
      iconClass: "bi bi-person-fill-add",
      onClick: () => {
        if (!organizationId || !userId) return;
        manageResponse({
          promise: API.user().organizationAdminReInviteUser(
            organizationId,
            userId
          ),
          errorMessage: "invite-user",
          successMessage: "invite-user",
          onSuccess: () => {
            setInviteSent(true);
          },
        });
      },
    } as ComponentButtonProps,
  };
};
