import { memo, useMemo, type FC } from "react";
import isEqual from "react-fast-compare";
import { DangerZone, JSONViewer, Tabs } from "@Components";
import { SpaceLinkData } from "../SpaceLinkData";
import {
  useSpaceLinkDetailsContent,
  useSpaceLinkDetailsTab,
} from "../../Hooks";
import type { SpaceLinkDetailsContentProps } from "../../Hooks";
import { t } from "i18next";
import { SpaceLinkAssociatedProject } from "../SpaceLinkAssociatedProject";
import { SpaceLinkEdit } from "../SpaceLinkEdit";

const alerts = [
  {
    level: "error" as const,
    children: t("organization.delete-associated-space-link"),
  },
];

export const SpaceLinkDetailsContent: FC<SpaceLinkDetailsContentProps> = memo(
  props => {
    const {
      editEnabled,
      spaceLink,
      hasAssociatedProjects,
      onSpaceLinkDelete,
      refresh,
      spaceLinkDelete,
    } = useSpaceLinkDetailsContent(props);
    const { tabs, activeTab, setActiveTab } = useSpaceLinkDetailsTab();

    const additionalAlerts = hasAssociatedProjects ? alerts : undefined;

    const content = useMemo(() => {
      switch (activeTab) {
        case 1:
          return <SpaceLinkAssociatedProject />;
        default:
          return (
            <>
              <SpaceLinkData />
              <DangerZone
                className="mt-4"
                confirmationText={spaceLink.name}
                subject={spaceLink.name}
                promise={spaceLinkDelete}
                onSuccess={onSpaceLinkDelete}
                target="space-link"
                toastErrorMessage="delete-space-link"
                toastSuccessMessage="delete-space-link"
                additionalAlerts={additionalAlerts}
              />
              <JSONViewer data={spaceLink} devMode />
            </>
          );
      }
    }, [
      activeTab,
      additionalAlerts,
      onSpaceLinkDelete,
      spaceLink,
      spaceLinkDelete,
    ]);

    return (
      <div id="space-link-details">
        <Tabs
          activeTab={activeTab}
          tabs={tabs}
          setActiveTab={setActiveTab}
          updateUrl
        />
        <div id="space-link-details__content" className="card">
          {content}
        </div>
        {editEnabled && <SpaceLinkEdit refresh={refresh} />}
      </div>
    );
  },
  isEqual
);
