import { memo } from "react";
import { useAPIDefinitionDetailsStore } from "../../Stores";
import { DisplaySection } from "@Components";
import { AuthDisplaySection, BadgeContainer } from "./Components";
import { getInputIconClass } from "@Services";
import { t } from "i18next";
import { joinClasses } from "@Utils";

const dataFormatMapper = (s: string) => {
  return (
    <>
      <i className={joinClasses("me-2", getInputIconClass(s))} />
      {t([`compliance.${s}`, s])}
    </>
  );
};

export const DetailsSection = memo(() => {
  const { details } = useAPIDefinitionDetailsStore();

  if (!details) return null;

  return (
    <>
      <DisplaySection label="common.operation" value={details.name} />
      <div className="flex-container">
        <DisplaySection
          label="common.auth"
          value={<AuthDisplaySection auth={details.authenticationRequired} />}
        />
        <DisplaySection
          label="common.category"
          value={<BadgeContainer badges={details.category} />}
        />
        <DisplaySection
          label="common.tags"
          value={<BadgeContainer badges={details.tags} />}
        />
      </div>
      <DisplaySection
        label="common.inputs"
        value={
          <BadgeContainer
            badges={details.dataFormatInputs}
            mapper={dataFormatMapper}
          />
        }
      />
      <DisplaySection
        label="common.outputs"
        value={
          <BadgeContainer
            badges={details.dataFormatOutputs}
            mapper={dataFormatMapper}
          />
        }
      />
      {!!details.description && (
        <DisplaySection
          label="common.description"
          value={details.description}
        />
      )}
    </>
  );
});
