import { type FC } from "react";
import { BEM } from "@Utils";
import {
  LogsViewer,
  Paginator,
  AuditLogsFilters,
  SortInput,
} from "@Components";
import { useUserLogs } from "../../Hooks";

const headerItems = [
  { text: "common.created-at", sortByKey: "audit_log_created_at" },
];

export const UserLogs: FC = () => {
  const { loading, logs, totalItems, filterValues, refresh, setFilters } =
    useUserLogs();
  return (
    <div id={BEM("organization-user-details__content", "activity-logs")}>
      <AuditLogsFilters
        refresh={refresh}
        updateFilters={setFilters}
        filterValues={filterValues}
      />
      <LogsViewer loading={loading} logs={logs} section={false} />
      <div className="w-100 d-flex justify-content-between">
        <div className="pt-3">
          <SortInput
            headerItems={headerItems}
            updateFunction={setFilters}
            defaultSorts={{
              sortBy: filterValues.sortBy,
              sortMode: filterValues.sortMode,
            }}
          />
        </div>
        <Paginator
          filters={{ ...filterValues }}
          totalItems={totalItems}
          setFilters={setFilters}
        />
      </div>
    </div>
  );
};
