import { memo } from "react";
import { useHistoryStore } from "../../Store";
import { HistoryAttemptDetails } from "./Components/HistoryAttemptDetails/HistoryAttemptDetails";
import { HistoryTable } from "./Components/HistoryTable/HistoryTable";
import type { Props as HistoryTableProps } from "./Components/HistoryTable/HistoryTable.i";

export const HistoryTabContent = memo((props: HistoryTableProps) => {
  const { selectedListedAttempt } = useHistoryStore();
  const showDetails = !!selectedListedAttempt;

  return (
    <div className={"history-tab-content"}>
      <HistoryTable {...props} />
      {showDetails ? <HistoryAttemptDetails /> : null}
    </div>
  );
});
