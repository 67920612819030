import { Callout, Offcanvas, Section, SingleOptionSection } from "@Components";
import type { DangerZoneProps } from "./DangerZone.i";
import { useDangerZone } from "./useDangerZone";
import { Input } from "reactstrap";
import type { FC } from "react";
import { memo, useMemo } from "react";
import isEqual from "react-fast-compare";
import { joinClasses } from "@Utils";

export const DangerZone: FC<DangerZoneProps> = memo(props => {
  const {
    actions,
    alerts,
    deleteButtonProps,
    isCanvasOpen,
    labels,
    confirmation,
    isCanvasLoading,
    deletable,
  } = useDangerZone(props);

  const callouts = useMemo(() => {
    return (
      <>
        {alerts.map((alert, i) => (
          <Callout
            key={`${i}-${alert.children}`}
            className="mb-3"
            level={alert.level}
            children={alert.children}
          />
        ))}
      </>
    );
  }, [alerts]);

  return (
    <>
      <Section
        title="common.danger-zone"
        className={joinClasses("danger-zone", props.className)}
      >
        <SingleOptionSection
          title={labels.title}
          iconClass="bi bi-exclamation-square-fill"
          description={labels.info}
          buttonProps={[
            {
              color: "danger",
              children: labels.title,
              onClick: actions.openCanvas,
              data: {
                cy: "danger-zone-button",
              },
              iconClass: "bi bi-archive-fill",
            },
          ]}
        />
      </Section>
      <Offcanvas
        id="danger-zone-canvas"
        title={labels.title}
        isOpen={isCanvasOpen}
        toggle={actions.closeCanvas}
        buttons={deleteButtonProps}
      >
        <h4 className="mb-3 canvas-subject">{labels.subject}</h4>

        {callouts}

        <p className="mb-4 canvas-description">{labels.warning}</p>
        <p>{labels.deleteMsg}</p>
        <Input
          id="danger-zone-canvas-input"
          disabled={isCanvasLoading}
          value={confirmation}
          onChange={actions.handleInputValueChange}
          autoCorrect="off"
          autoComplete="off"
          valid={deletable}
          invalid={!deletable}
        />
      </Offcanvas>
    </>
  );
}, isEqual);
