import { useSpaceLinkDetails } from "@Hooks";
import { useScanSpaceLinkStore } from "../Store/ScanSpaceLink.store";
import { useEffect } from "react";

export const useFetchSpaceLink = () => {
  const { spaceLinkId, setSpaceLink, setLoading } = useScanSpaceLinkStore();

  const { spaceLink, fetching } = useSpaceLinkDetails(spaceLinkId);

  useEffect(() => {
    setLoading(fetching);
    setSpaceLink(spaceLink);
  }, [fetching, setLoading, setSpaceLink, spaceLink]);
};
