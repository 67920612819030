import type { Nullable } from "@Interfaces";

export const NANO_IN_SECOND = 1e9; // 1 second = 1,000,000,000 nanoseconds
export const NANO_IN_MINUTE = 60 * NANO_IN_SECOND; // 1 minute = 60 seconds
export const NANO_IN_HOUR = 60 * NANO_IN_MINUTE; // 1 hour = 60 minutes

export const THIRTY_DAYS_IN_MINUTES = 30 * 24 * 60;

export const getNanoInSecondsFormatted = (
  nanoValue: Nullable<number> | undefined
) => {
  const value = nanoValue ?? 0;
  if (!value) return "-";

  const outputInSeconds = Math.ceil(value / NANO_IN_SECOND);

  if (outputInSeconds < 60) {
    return `${outputInSeconds} seconds`;
  } else if (outputInSeconds < 3600) {
    const outputInMinutes = Math.ceil(outputInSeconds / 60);
    return `${outputInMinutes} minutes`;
  } else {
    const outputInHours = Math.ceil(outputInSeconds / 3600);
    return `${outputInHours} hours`;
  }
};
