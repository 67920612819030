import type { InventoryDetail } from "@Interfaces";
import { APP_ZOOM_PERCENTAGE } from "@Stores";
import type { Edge, EdgeMarker } from "@xyflow/react";
import { Position } from "@xyflow/react";
import type { ParameterNodeData } from "../AdditionalInfo.i";
import {
  INVENTORY_ITEM_NODE_TITLE_HEIGHT,
  INVENTORY_ITEM_NODE_WIDTH,
  LOCATION_NODE_TITLE_HEIGHT,
  LOCATION_NODE_WIDTH,
  PARAMETER_NODE_ITEM_HEIGHT,
  PARAMETER_NODE_TITLE_HEIGHT,
  PARAMETER_NODE_WIDTH,
  type AdditionalInfoNodeType,
} from "../AdditionalInfo.i";
import type { Parameter } from "@Services";
import { BASE_EDGE, nodesColors } from "@Constants";
import { hashString } from "@Utils";

// Main entry function to get API details nodes and edges
export const getApiDetailsNodesAndEdges = (details: InventoryDetail) => {
  const nodes: AdditionalInfoNodeType[] = [];
  const edges: Edge[] = [];

  const rootNode = createRootNode(details);
  nodes.push(rootNode);

  const parametersByUsage = groupParametersByUsage(details.parameters);

  parseLocationsAndParams(details, parametersByUsage.input, nodes, edges, true);
  parseLocationsAndParams(
    details,
    parametersByUsage.output,
    nodes,
    edges,
    false
  );

  return {
    nodes: sanitizeItems(nodes),
    edges: sanitizeItems(edges),
  };
};

// Create the root node for the inventory details
const createRootNode = (details: InventoryDetail): AdditionalInfoNodeType => ({
  id: hashString(`node-${details.id}`),
  type: "default",
  position: { x: 0, y: 0 },
  targetPosition: Position.Bottom,
  sourcePosition: Position.Top,
  data: { details, visible: true, edges: [] },
  width: INVENTORY_ITEM_NODE_WIDTH * APP_ZOOM_PERCENTAGE,
  height: INVENTORY_ITEM_NODE_TITLE_HEIGHT * APP_ZOOM_PERCENTAGE,
});

// Group parameters by usage (input/output)
const groupParametersByUsage = (parameters: Parameter[]) =>
  parameters.reduce<{ input: Parameter[]; output: Parameter[] }>(
    (acc, param) => {
      if (param.usage === "input") acc.input.push(param);
      else if (param.usage === "output") acc.output.push(param);
      return acc;
    },
    { input: [], output: [] }
  );

// Parse locations and connect parameters with nodes and edges
const parseLocationsAndParams = (
  details: InventoryDetail,
  parameters: Parameter[],
  nodes: AdditionalInfoNodeType[],
  edges: Edge[],
  input: boolean
) => {
  const parametersByLocation = groupParametersByLocation(parameters);
  const rootNodeId = hashString(`node-${details.id}`);

  Object.entries(parametersByLocation).forEach(([location, locationParams]) => {
    const locationNodeId = createLocationNodeId(location, input);
    const locationNode = createLocationNode(locationNodeId, location, input);
    const edge = createEdge(rootNodeId, locationNodeId, input);
    locationNode.data.edges = [edge];

    nodes.push(locationNode);
    edges.push(edge);

    parseParameters(locationNodeId, locationParams, nodes, edges, input);
  });
};

// Group parameters by their location
const groupParametersByLocation = (parameters: Parameter[]) =>
  parameters.reduce<Record<string, Parameter[]>>((acc, param) => {
    const location = param.in || "default";
    (acc[location] ??= []).push(param);
    return acc;
  }, {});

// Create a unique ID for location nodes
const createLocationNodeId = (location: string, input: boolean) =>
  hashString(`node-${input ? "input" : "output"}-${location}`);

// Create location node
const createLocationNode = (
  id: string,
  location: string,
  input: boolean
): AdditionalInfoNodeType => ({
  id,
  type: "location",
  position: { x: 0, y: input ? -100 : 100 },
  targetPosition: Position.Bottom,
  sourcePosition: Position.Top,
  data: { location, visible: true, edges: [] },
  width: LOCATION_NODE_WIDTH * APP_ZOOM_PERCENTAGE,
  height: LOCATION_NODE_TITLE_HEIGHT * APP_ZOOM_PERCENTAGE,
});

// Create edge between nodes
const createEdge = (
  sourceId: string,
  targetId: string,
  input: boolean,
  visible = true
): Edge => ({
  ...BASE_EDGE,
  id: `edge-${sourceId}-${targetId}`,
  source: input ? targetId : sourceId,
  target: input ? sourceId : targetId,
  sourceHandle: `handle-source-${input ? targetId : sourceId}`,
  targetHandle: `handle-target-${input ? sourceId : targetId}`,
  animated: true,
  data: { visible },
  markerEnd: {
    ...((BASE_EDGE.markerEnd as EdgeMarker) ?? {}),
    color: nodesColors.edge.marker.highlight,
  },
});

// Parse parameters and create corresponding nodes and edges
const parseParameters = (
  sourceNodeId: string,
  parameters: Parameter[],
  nodes: AdditionalInfoNodeType[],
  edges: Edge[],
  input: boolean
) => {
  const subParameters = groupSubParameters(parameters);

  parameters.forEach(parameter => {
    if (!isSubParameter(parameter)) {
      const paramNodeId = createParameterNodeId(
        sourceNodeId,
        `${parameter.name}-${parameter.usage}`
      );
      const paramNode = createParameterNode(paramNodeId, parameter, input);
      const paramEdge = createEdge(sourceNodeId, paramNodeId, input);
      paramNode.data.edges = [paramEdge];

      nodes.push(paramNode);
      edges.push(paramEdge);
    }
  });

  createSubParameterNodes(subParameters, nodes, edges, input);
};

// Group parameters that are part of a complex object (i.e., child of another parameter)
const groupSubParameters = (parameters: Parameter[]) =>
  parameters.reduce<Record<string, Parameter[]>>((acc, param) => {
    if (isSubParameter(param)) {
      const parentParam = param.pathName.split("/").slice(-2, -1)[0];
      (acc[parentParam] ??= []).push(param);
    }
    return acc;
  }, {});

// Check if a parameter is part of another parameter
const isSubParameter = (parameter: Parameter) =>
  parameter.pathName && parameter.pathName !== parameter.name;

// Create unique ID for parameter nodes
const createParameterNodeId = (sourceNodeId: string, paramName: string) =>
  hashString(`parameter-${sourceNodeId}-${paramName}`);

// Create parameter node
const createParameterNode = (
  id: string,
  parameter: Parameter,
  input: boolean,
  visible = true
): AdditionalInfoNodeType => ({
  id,
  type: "parameter",
  position: { x: 0, y: 0 },
  targetPosition: Position.Bottom,
  sourcePosition: Position.Top,
  data: { parameter, input, visible, edges: [] },
  width: PARAMETER_NODE_WIDTH * APP_ZOOM_PERCENTAGE,
  height:
    (PARAMETER_NODE_TITLE_HEIGHT + PARAMETER_NODE_ITEM_HEIGHT) *
    APP_ZOOM_PERCENTAGE,
});

// Create nodes for sub-parameters with parent-child relationships
const createSubParameterNodes = (
  subParameters: Record<string, Parameter[]>,
  nodes: AdditionalInfoNodeType[],
  edges: Edge[],
  input: boolean
) => {
  Object.entries(subParameters).forEach(([parentParam, childParams]) => {
    const parentNode = findNodeByName(
      nodes,
      parentParam,
      input ? "input" : "output"
    );
    if (parentNode) {
      const childNodes = createChildParameterNodes(
        childParams,
        parentNode,
        nodes,
        edges,
        input
      );
      (parentNode.data as ParameterNodeData).subNodes = childNodes;
    }
  });
};

// Create child parameter nodes and connect them to their immediate parent
const createChildParameterNodes = (
  childParams: Parameter[],
  parentNode: AdditionalInfoNodeType,
  nodes: AdditionalInfoNodeType[],
  edges: Edge[],
  input: boolean
) =>
  childParams.map(childParam => {
    const childNodeId = createParameterNodeId(
      parentNode.id,
      `${childParam.name}-${childParam.usage}`
    );
    const childNode = createParameterNode(
      childNodeId,
      childParam,
      input,
      false
    );

    childNode.data.parentId = parentNode.id;

    const edge = createEdge(parentNode.id, childNodeId, input, false);
    childNode.data.edges = [edge];

    nodes.push(childNode);
    edges.push(edge);

    return childNode;
  });

// Find node by parameter name
const findNodeByName = (
  nodes: AdditionalInfoNodeType[],
  name: string,
  usage: "input" | "output"
) =>
  nodes.find(n => {
    const { parameter } = n.data as ParameterNodeData;
    return parameter?.name === name && parameter?.usage === usage;
  });

// Remove duplicates from nodes or edges
const sanitizeItems = <T extends { id: string }>(items: T[]) => {
  const seen = new Map<string, T>();
  items.forEach(item => {
    if (!seen.has(item.id)) {
      seen.set(item.id, item);
    }
  });
  return Array.from(seen.values());
};

export const traverseGraphVisibleNodes = (
  nodes: Array<AdditionalInfoNodeType>,
  edges: Array<Edge>,
  selectedNodeId: string
): [Array<AdditionalInfoNodeType>, Array<Edge>] => {
  const makeNodeVisible = (node: AdditionalInfoNodeType) => {
    node.data.visible = true;
    node.data.edges.forEach(e => (e.data ? (e.data.visible = true) : false));
  };

  const makeNodesVisible = (subNodes: Array<AdditionalInfoNodeType>) => {
    subNodes.forEach(makeNodeVisible);
  };

  const traverseNode = (node: AdditionalInfoNodeType) => {
    const subNodes = (node.data as ParameterNodeData).subNodes;

    if (!subNodes) {
      return false;
    }

    if (node.id === selectedNodeId) {
      makeNodesVisible(subNodes);
      return true;
    }

    return subNodes.some(traverseNode);
  };

  nodes.forEach(node => {
    if (traverseNode(node)) {
      makeNodeVisible(node);
      const subNodes = (node.data as ParameterNodeData).subNodes;
      if (subNodes) {
        makeNodesVisible(subNodes);
      }
    }
  });

  return [nodes, edges];
};

export const sanitizeInventoryItemParameters = (details: InventoryDetail) => {
  const nextParameters: Parameter[] = [];

  for (
    let parameterIndex = 0;
    parameterIndex < details.parameters.length;
    ++parameterIndex
  ) {
    const parameter = details.parameters[parameterIndex];
    if (parameter.name.indexOf("array-primitive") > -1) {
      if (parameterIndex > 0) {
        const parentParameter = details.parameters[parameterIndex - 1];
        if (parentParameter) {
          if (parentParameter.type) {
            parentParameter.type = `array [${parameter.type}]`;
          }
          if (parentParameter.oracleType) {
            parentParameter.oracleType = `array [${parameter.oracleType}]`;
          }
        }
      }
      continue;
    }

    nextParameters.push(parameter);
  }

  details.parameters = nextParameters;
};
