import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import type { ComponentButtonProps } from "@Components";
import {
  useSpaceLinkListingStore,
  useSpaceLinkDetailsCanvasStore,
} from "../Stores";
import { useSpaceLinkDetailsStore } from "../Stores";
import { useUrlParams } from "@Hooks";
import { generateUrl } from "@Utils";
import { ORGANIZATION_PATH_SPACE_LINKS } from "@Constants";

export const useSpaceLinkDetailsCanvas = () => {
  const { setNeedRefresh } = useSpaceLinkListingStore();
  const { setEditCanvasOpen } = useSpaceLinkDetailsCanvasStore();
  const { spaceLinkDetails: spaceLink } = useSpaceLinkDetailsStore();
  const { organizationId } = useUrlParams();
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    navigate(
      generateUrl(ORGANIZATION_PATH_SPACE_LINKS, [organizationId ?? ""], true),
      {
        replace: false,
      }
    );
  }, [navigate, organizationId]);

  const handleDelete = useCallback(() => {
    setNeedRefresh(true);
    handleClose();
  }, [handleClose, setNeedRefresh]);

  const canvasButtons = useMemo(
    (): Array<ComponentButtonProps> => [
      {
        color: "primary",
        children: "common.edit",
        data: {
          cy: "edit-space-link-button",
        },
        onClick: () => setEditCanvasOpen(true),
        iconClass: "bi bi-pencil-fill",
        disabled: spaceLink?.expired,
      },
    ],
    [setEditCanvasOpen, spaceLink?.expired]
  );
  return {
    canvasButtons,
    handleClose,
    handleDelete,
  };
};
