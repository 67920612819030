import {
  DisplayRow,
  IssueExclusionCard,
  ProjectSettingsCardContainer,
} from "@Components";
import type { IssueExclusion } from "@Services";
import { joinClasses } from "@Utils";
import { memo, useCallback, useMemo, useState } from "react";

type DisplayRowProjectIssueExclusionProps = {
  issueExclusions: IssueExclusion[];
  deleteEntry?: (i: number) => Promise<void>;
  editEntry?: (i: number) => Promise<void>;
};

export const DisplayRowProjectIssueExclusion = memo(
  ({
    issueExclusions,
    deleteEntry,
    editEntry,
  }: DisplayRowProjectIssueExclusionProps) => {
    const [deletingIndex, setDeletingIndex] = useState<number>();

    const getOnDeleteFunction: (i: number) => VoidFunction | undefined =
      useCallback(
        (i: number) => {
          if (!deleteEntry) return undefined;
          return async () => {
            setDeletingIndex(i);
            await deleteEntry(i);
            setDeletingIndex(undefined);
          };
        },
        [deleteEntry]
      );

    const cardsContainer = useMemo(
      () => (
        <ProjectSettingsCardContainer
          cardType="issue-exclusion"
          emptyLabel="common.no-issue-exclusions"
        >
          {issueExclusions.map((e, i) => {
            const handleOnDelete = getOnDeleteFunction(i);
            return (
              <IssueExclusionCard
                {...e}
                key={`issue-exclusion-${i}`}
                disabled={deletingIndex !== undefined}
                loadingDelete={deletingIndex === i}
                deleteEntry={handleOnDelete}
                editEntry={editEntry ? () => editEntry(i) : undefined}
              />
            );
          })}
        </ProjectSettingsCardContainer>
      ),
      [deletingIndex, editEntry, getOnDeleteFunction, issueExclusions]
    );

    return (
      <DisplayRow
        title="common.issue-exclusion"
        className={joinClasses(
          "h-100",
          issueExclusions.length <= 0 && "no-data"
        )}
      >
        {cardsContainer}
      </DisplayRow>
    );
  }
);
