import { getPathnameParams } from "@Services";
import { useLocation } from "react-router-dom";

let cache = {
  pathname: "",
  search: "",
  params: getPathnameParams(""),
};

export const useUrlParams = () => {
  const { pathname, search } = useLocation(); // triggers the re-render at pathname change

  if (cache.pathname === pathname && cache.search === search) {
    // cache HIT
    return cache.params;
  }

  // cache MISS
  const params = getPathnameParams(`${pathname}${search}`);
  cache = { pathname, params, search };
  return params;
};
