import { pureComponent } from "@Utils";
import { memo } from "react";
import { Offcanvas } from "@Components";
import { useSpaceLinkDetailsCanvas } from "../../Hooks";
import { SpaceLinkDetails } from "./Components";

export const SpaceLinkDetailsCanvas = memo(() => {
  const { canvasButtons, handleClose, handleDelete } =
    useSpaceLinkDetailsCanvas();
  return (
    <Offcanvas
      className="full-height unstiled"
      isOpen={true}
      title="common.space-link-details"
      toggle={handleClose}
      children={<SpaceLinkDetails onDelete={handleDelete} />}
      size="md"
      exitLabel="common.close"
      buttons={canvasButtons}
    />
  );
}, pureComponent);
