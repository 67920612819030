import { ItemNotFound, LoadingScreen } from "@Components";
import { useCallback, type FC } from "react";
import { ORGANIZATION_PATH_API_KEYS } from "@Constants";
import { generateUrl } from "@Utils";
import { useNavigate } from "react-router-dom";
import { useUrlParams } from "@Hooks";

export const ErrorPage: FC = () => {
  const navigate = useNavigate();
  const { organizationId } = useUrlParams();
  const handleNavigateToList = useCallback(() => {
    navigate(
      generateUrl(ORGANIZATION_PATH_API_KEYS, [organizationId ?? ""], true),
      { replace: false }
    );
  }, [navigate, organizationId]);

  return (
    <ItemNotFound
      message="errors.no-api-key"
      navigateToList={handleNavigateToList}
    />
  );
};

export const LoadingPage: FC = () => {
  return <LoadingScreen />;
};
