import { FormInput, GridContainer } from "@Components";
import { getFilterAllowedOptions } from "@Services";
import { NANO_IN_MINUTE, THIRTY_DAYS_IN_MINUTES } from "@Utils";
import { t } from "i18next";
import { type ChangeEvent, memo, useMemo } from "react";
import ReactDOMServer from "react-dom/server";
import isEqual from "react-fast-compare";
import { FormGroup } from "reactstrap";
import { useRefreshDefinitionForm } from "../Hooks/useRefreshDefinitionForm";
import { RefreshDefinitionOperation } from "./RefreshDefinitionOperation";

type RefreshDefinitionFormProps = {
  validities: { [key: string]: boolean };
  enabledDomains: string[] | undefined;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const typeOptions = getFilterAllowedOptions("refresh_definition_type");

export const RefreshDefinitionForm = memo(
  ({
    validities,
    enabledDomains,
    handleChange,
  }: RefreshDefinitionFormProps) => {
    const { authenticationSettings, type, loading } =
      useRefreshDefinitionForm();
    const enabledDomainsTooltip = useMemo(
      () => (
        <p className="out-of-scope-message">
          <span
            className="color-severity-medium font-size-caption"
            data-tooltip-id="tooltip"
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
              <>
                <b className="pb-1">{t("common.enabled-domains")}</b>
                {enabledDomains?.map(d => <div key={d}>{d}</div>)}
              </>
            )}
          >
            {t("common.domain-not-enabled")}
            <i className="ms-2 bi bi-info-circle"></i>
          </span>
        </p>
      ),
      [enabledDomains]
    );

    const refreshIntervalValue =
      +`${authenticationSettings.refreshDefinition?.refreshInterval}`;

    return (
      <FormGroup noMargin>
        <FormInput
          type="select"
          label="common.type"
          name="refreshDefinition.type"
          id="authentication-setting-refresh-type"
          value={authenticationSettings.refreshDefinition?.type}
          onChange={handleChange}
          options={typeOptions}
          bsSize="sm"
          disabled={loading}
          data-cy="authentication-settings-refresh-type"
        />
        {type === "internal" && (
          <>
            <RefreshDefinitionOperation
              loading={loading}
              validities={validities}
              handleChange={handleChange}
            />
            <FormInput
              className="mt-3"
              type="text"
              label="project.authentication-and-dictionary.token-output-key"
              name="refreshDefinition.tokenOutputKey"
              id="authentication-setting-rd-token-output-key"
              value={
                authenticationSettings.refreshDefinition?.tokenOutputKey ?? ""
              }
              onChange={handleChange}
              bsSize="sm"
              disabled={loading}
              data-cy="authentication-settings-rd-token-output-key"
              autoTrim
            />
          </>
        )}

        {type === "external" && (
          <>
            <FormInput
              id="authentication-settings-token-endpoint"
              type="text"
              label="common.token-endpoint"
              name="refreshDefinition.oAuthData.tokenEndpoint"
              className="mt-3"
              value={
                authenticationSettings.refreshDefinition?.oAuthData
                  ?.tokenEndpoint ?? ""
              }
              data-cy={"authentication-settings-token-endpoint"}
              onChange={handleChange}
              bsSize="sm"
              valid={validities.tokenEndpoint}
              invalid={!validities.tokenEndpoint}
              placeholder="https://www.example.com/page"
              disabled={loading}
              required
            />

            {!!authenticationSettings.refreshDefinition?.oAuthData
              ?.tokenEndpoint &&
              !validities.tokenEndpoint &&
              enabledDomainsTooltip}

            <FormInput
              className="mt-3"
              type="textarea"
              rows={3}
              label="project.authentication-and-dictionary.refresh-token"
              name="refreshDefinition.oAuthData.refreshToken"
              id="authentication-setting-rd-refresh-token"
              value={
                authenticationSettings.refreshDefinition?.oAuthData
                  ?.refreshToken ?? ""
              }
              onChange={handleChange}
              bsSize="sm"
              valid={validities.rDRefreshToken}
              invalid={!validities.rDRefreshToken}
              disabled={loading}
              data-cy="authentication-settings-rd-refresh-token"
              autoTrim
              required
            />

            <GridContainer columns={2} className="mt-3">
              <FormInput
                id="authentication-settings-rd-client-id"
                type="text"
                label="common.client-id"
                name="refreshDefinition.oAuthData.clientID"
                value={
                  authenticationSettings.refreshDefinition?.oAuthData
                    ?.clientID ?? ""
                }
                data-cy={"authentication-settings-client-id"}
                onChange={handleChange}
                bsSize="sm"
                disabled={loading}
              />

              <FormInput
                id="authentication-settings-rd-client-secret"
                type="text"
                label="common.client-secret"
                name="refreshDefinition.oAuthData.clientSecret"
                value={
                  authenticationSettings.refreshDefinition?.oAuthData
                    ?.clientSecret ?? ""
                }
                data-cy={"authentication-settings-client-secret"}
                onChange={handleChange}
                bsSize="sm"
                disabled={loading}
              />
            </GridContainer>
            <FormInput
              className="mt-3"
              id="authentication-settings-rd-scope"
              type="text"
              label="common.scope"
              name="refreshDefinition.oAuthData.scope"
              value={
                authenticationSettings.refreshDefinition?.oAuthData?.scope ?? ""
              }
              data-cy={"authentication-settings-scope"}
              placeholder={t("common.scope-placeholder")}
              onChange={handleChange}
              bsSize="sm"
              disabled={loading}
            />
          </>
        )}
        {!!type && (
          <FormInput
            className="mt-3"
            type="number"
            label="project.authentication-and-dictionary.refresh-interval"
            name="refreshDefinition.refreshInterval"
            id="authentication-setting-rd-refresh-interval"
            value={
              refreshIntervalValue > 0
                ? refreshIntervalValue / NANO_IN_MINUTE
                : ""
            }
            onChange={handleChange}
            bsSize="sm"
            disabled={loading}
            data-cy="authentication-settings-rd-refresh-interval"
            invalid={!validities.rdRefreshInterval}
            min={0}
            max={THIRTY_DAYS_IN_MINUTES}
          />
        )}
      </FormGroup>
    );
  },
  isEqual
);
