import { useAuthenticationSettingFormStore } from "@Stores";
import { useCallback } from "react";

export const useAdditionalDataTable = () => {
  const {
    authenticationSettings: { oAuthData },
    loading,
    setAuthenticationSettings,
  } = useAuthenticationSettingFormStore();

  const deleteEntry = useCallback(
    (key: string) => {
      setAuthenticationSettings(prev => {
        if (!prev.oAuthData?.additionalData) return prev;
        const additionalData = structuredClone(prev.oAuthData.additionalData);
        delete additionalData[key];
        prev.oAuthData.additionalData = additionalData;
        return { ...prev };
      });
    },
    [setAuthenticationSettings]
  );

  return { additionalData: oAuthData?.additionalData, deleteEntry, loading };
};
