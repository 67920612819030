import { memo, useCallback, useMemo, type FC } from "react";
import {
  FormInput,
  Button,
  DisplayTable,
  TrashButton,
  FormMessage,
} from "@Components";
import { t } from "i18next";
import { Form, FormGroup } from "reactstrap";
import { useAuthenticationSettingFormStore } from "@Stores";
import { useCredentialInputsTab } from "./useCredentialInputsTab";
import { preventDefault } from "@Services";

const emptyStringLabel = t("common.empty-string").toLowerCase();

/**
 * Credential Inputs form component. It update authenticationSettings.credentialInputs on submit
 */
const CredentialInputsForm: FC = () => {
  const {
    duplicateKey,
    entry,
    loading,
    valid,
    validities,
    nameInputRef,
    credentialInputFormSubmit,
    updateEntry,
  } = useCredentialInputsTab();

  return (
    <>
      <Form id="credential-input-form" onSubmit={preventDefault}>
        <FormInput
          id="credential-key"
          data-cy="authentication-settings-input-credential-key"
          label="project.authentication-and-dictionary.credential-key"
          type="text"
          name="key"
          bsSize="sm"
          value={entry.key}
          valid={validities.key}
          invalid={!validities.key}
          onChange={updateEntry}
          disabled={loading}
          innerRef={nameInputRef}
          autoTrim
        />
        <FormInput
          id="credential-value"
          data-cy="authentication-settings-input-credential-value"
          label="project.authentication-and-dictionary.credential-value"
          type="text"
          name="value"
          bsSize="sm"
          value={entry.value}
          valid={validities.value}
          invalid={!validities.value}
          placeholder={emptyStringLabel}
          onChange={updateEntry}
          disabled={loading}
          autoTrim
        />
        <Button
          color="primary"
          size="sm"
          data-cy="authentication-settings-add-credential-button"
          onClick={credentialInputFormSubmit}
          children="common.add"
          iconClass="bi bi-plus-square-fill"
          disabled={!valid || loading}
          type="submit"
        />
      </Form>
      {duplicateKey && (
        <FormMessage
          color="danger"
          message={t("validation.x-already-in-use", { x: t("common.key") })}
        />
      )}
    </>
  );
};

/**
 * Credential Inputs component. Contains Form and Showcase
 */
export const CredentialInputsTab: FC = memo(() => {
  const {
    authenticationSettings: { credentialInputs },
    setAuthenticationSettings,
    loading,
  } = useAuthenticationSettingFormStore();

  const length = Object.entries(credentialInputs ?? []).length;

  const deleteEntry = useCallback(
    (key: string) => {
      setAuthenticationSettings(prev => {
        const credentialInputs = structuredClone(prev.credentialInputs);
        if (!credentialInputs) {
          return prev;
        }
        delete credentialInputs[key];
        return { ...prev, credentialInputs };
      });
    },
    [setAuthenticationSettings]
  );

  const table = useMemo(() => {
    if (!length) return null;
    return (
      <DisplayTable>
        <thead>
          <tr>
            <th className="text-end">{t("common.key")}</th>
            <th>{t("common.value")}</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(credentialInputs ?? {}).map(([key, value], i) => {
            return (
              <tr key={`${i}-${key}-${value}`}>
                <td>{key}</td>
                <td>
                  <div className="d-flex justify-content-between align-items-center">
                    {value ? (
                      <span>{value}</span>
                    ) : (
                      <i className="opacity-75">{emptyStringLabel}</i>
                    )}
                    <TrashButton
                      onClick={() => deleteEntry(key)}
                      disabled={loading}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </DisplayTable>
    );
  }, [credentialInputs, deleteEntry, length, loading]);

  return (
    <>
      <FormGroup noMargin={length === 0}>
        <CredentialInputsForm />
      </FormGroup>
      {table}
    </>
  );
});
