import {
  DisplaySection,
  DoughnutChart,
  DoughnutSeverity,
  Switch,
} from "@Components";
import {
  dateTimeFormat,
  getDoughnutChartData,
  scanStatusMapper,
  showScanVulnerabilites,
} from "@Services";
import { t } from "i18next";
import { memo } from "react";
import { Col, Row } from "reactstrap";
import {
  EMPTY_SCAN_DETAILS,
  useScanDetailsStore,
} from "../../Store/ScanDetails.store";
import { useScanSpaceLinkStore } from "../../Store/ScanSpaceLink.store";
const showEndDateStatus = ["completed", "failed", "stopped"];

export const General = memo(() => {
  const { details, loading } = useScanDetailsStore();
  const {
    spaceLink,
    spaceLinkId,
    loading: loadinSpaceLink,
  } = useScanSpaceLinkStore();
  const { scan } = details ?? EMPTY_SCAN_DETAILS;
  const { severityCount } = scan;
  const total = severityCount.high + severityCount.medium + severityCount.low;

  return (
    <div id="scan-detail-general" className="p-4">
      <Row className="flex-column-reverse flex-xl-row pt-2 ps-2">
        <Col xs={12} xl={6}>
          <Row>
            <Col xs={12} sm={6}>
              <DisplaySection
                value={scan.id}
                label="common.scan-id"
                loading={loading}
              />
            </Col>
            <Col xs={12} sm={6}>
              <DisplaySection
                value={
                  <div className="d-flex align-items-center">
                    <div className="d-inline-flex align-items-center status-circle me-1">
                      <i
                        className={`circle ${scanStatusMapper[scan.status].color}`}
                      />
                    </div>
                    <span className="status-label">
                      {t(scanStatusMapper[scan.status].label)}
                    </span>
                  </div>
                }
                label={t("common.status")}
                loading={loading}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12} sm={6}>
              <DisplaySection
                value={dateTimeFormat(scan.startedAt)}
                label={"common.startedAt"}
                loading={loading}
              />
            </Col>
            <Col xs={12} sm={6}>
              <DisplaySection
                value={
                  showEndDateStatus.includes(scan.status)
                    ? dateTimeFormat(scan.completedAt)
                    : "-"
                }
                label="common.completedAt"
                loading={loading}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12} sm={6}>
              <DisplaySection
                value={
                  <div>
                    <i
                      className={
                        (scan.triggeredBy === "API"
                          ? "bi-upload"
                          : "bi-calendar-check") + " me-2"
                      }
                    />
                    {scan.triggeredBy === "API"
                      ? "API"
                      : t("scans.triggered-by-scheduler")}
                  </div>
                }
                label="scans.triggered-by"
                loading={loading}
              />
            </Col>
            <Col xs={12} sm={6}>
              <DisplaySection
                value={
                  <Switch
                    title=""
                    showLabel={false}
                    id="scan-exploitation"
                    checked={scan.exploitation}
                    disabled
                  />
                }
                label="scans.exploitation"
                loading={loading}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12} sm={6}>
              <DisplaySection
                value={scan.baseURL}
                label="project.base-url"
                loading={loading}
              />
            </Col>
            <Col xs={12} sm={6}>
              <DisplaySection
                value={
                  <Switch
                    showLabel={false}
                    id="scan-production-url"
                    checked={scan.isProductionURL}
                    disabled
                  />
                }
                label="project.production-url"
                loading={loading}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12} sm={6}>
              <DisplaySection
                value={scan.lastError || "-"}
                label="common.last-error"
                loading={loading}
              />
            </Col>
            {spaceLinkId && (
              <Col xs={12} sm={6}>
                <DisplaySection
                  value={spaceLink?.name || t("common.error")}
                  label="common.space-link"
                  loading={loadinSpaceLink}
                />
              </Col>
            )}
          </Row>
        </Col>
        <Col xs={12} xl={6}>
          {showScanVulnerabilites(scan) && (
            <div className="flex-center flex-grow-1" id="scan-vulnerabilites">
              <div className="me-5">
                <h5 className="color-pink mb-3 text-center">
                  {t("common.issues")}
                </h5>
                <DoughnutSeverity severityCount={scan.severityCount} />
              </div>
              <div
                id="doughnut-chart-container"
                className="d-flex flex-grow-1 align-items-center"
              >
                {total ? (
                  <DoughnutChart
                    data={getDoughnutChartData(scan.severityCount)}
                  />
                ) : (
                  <div className="w-100 no-issues d-flex justify-content-center align-items-center text-center">
                    <div>
                      <i className="bi-shield fs-2" />
                      <div>{t("common.no-issues")}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
});
