import { GridContainer, JSONViewer } from "@Components";
import { DeleteUser } from "../DeleteUser";
import { useUserSectionDetails } from "../../Hooks";
import { ReinviteBox } from "../ReinviteBox";
import { UserData } from "../UserData";
import { BEM, limitString, pureComponent } from "@Utils";
import { t } from "i18next";
import { memo } from "react";

export const UserSectionDetails = memo(() => {
  const { user, editingItself } = useUserSectionDetails();

  if (!user) {
    return null;
  }
  return (
    <>
      <GridContainer
        className="section-grid-container"
        id={BEM("organization-user-details__content", "main-info-container")}
      >
        <div>
          <div
            id={BEM(
              "organization-user-details__content__main-info-container",
              "title"
            )}
          >
            <h1 className="user-name">
              {limitString(`${user.name} ${user.surname}`, 80)}
            </h1>
            <h6 className="color-gray" data-cy="user-role">
              {t(`roles.${user.role}`)}
            </h6>
          </div>
        </div>
        <>
          <UserData user={user} editingItself={editingItself} />
        </>
        <>
          {!editingItself && (
            <>
              <ReinviteBox hasLastLogin={!!user.lastLogin} />
              <DeleteUser subject={`${user.name} ${user.surname}`} />
            </>
          )}
        </>
      </GridContainer>
      <JSONViewer data={user ?? {}} devMode />
    </>
  );
}, pureComponent);
