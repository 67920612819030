import type { Dispatch, FC } from "react";
import { useAddDictionaryEntry } from "./useAddDictionaryEntry";
import { Button, DictionaryForm } from "@Components";

type AddDictionaryEntryProps = {
  comboBoxOptions?: string[];
  consumeOption: Dispatch<string>;
};

export const AddDictionaryEntry: FC<AddDictionaryEntryProps> = ({
  consumeOption,
  comboBoxOptions,
}) => {
  const { addEntryToProject, entry, formValid, setEntry, setFormValid } =
    useAddDictionaryEntry(consumeOption);
  return (
    <div>
      <DictionaryForm
        entry={entry}
        setEntry={setEntry}
        keywords={comboBoxOptions}
        setFormValid={setFormValid}
      />
      <div className="text-end mt-3">
        <Button
          form="dictionary-form"
          color="primary"
          iconClass="plus-square-fill"
          children="common.add"
          type="submit"
          disabled={!formValid}
          onClick={addEntryToProject}
          data={{ cy: "add-dictionary-button" }}
        />
      </div>
    </div>
  );
};
