import type { ButtonProps } from "@Components";
import { Button } from "@Components";
import { equals } from "@Services";
import { joinClasses } from "@Utils";
import { t } from "i18next";
import type { FC } from "react";
import { memo, useMemo } from "react";
import "./SingleOptionSection.style.scss";

type SingleOptionSectionProps = {
  title: string;
  iconClass: string;
  description: string;
  buttonProps?: Array<ButtonProps>;
  className?: string;
};

export const SingleOptionSection: FC<SingleOptionSectionProps> = memo(
  ({ title, iconClass, description, buttonProps, className }) => {
    const buttons = useMemo(
      () =>
        buttonProps?.map((props, index) => (
          <Button {...props} key={`button.${index}`} />
        )),
      [buttonProps]
    );

    return (
      <div className={joinClasses(className, "single-option-section card")}>
        <i className={`bi ${iconClass} font-size-h4`} />
        <div>
          <h6 className="single-option-section__title">{t(title)}</h6>
          <span className="font-size-caption single-option-section__description">
            {t(description)}
          </span>
        </div>
        <div className="d-flex justify-content-end">{buttons}</div>
      </div>
    );
  },
  equals
);
