import { useApiKeyDetailsCanvasStore } from "../../../../../Stores";
import { getApiKeyExtraData } from "@Services";

export const useSectionDetails = () => {
  const { apiKey } = useApiKeyDetailsCanvasStore();
  const { isExpired, remainingDays, status } = getApiKeyExtraData(apiKey);

  return {
    apiKey,
    isExpired,
    remainingDays,
    status,
  };
};
