import { Button, Offcanvas } from "@Components";
import { BEM, joinClasses } from "@Utils";
import { useMemo } from "react";
import { ActivityView } from "../ActivityView/ActivityView";
import { ClipboardBadge } from "../Badge";
import type { Props } from "./HTTPViewer.i";
import { getCodeMirrorTheme } from "@Interfaces";
import { useHTTPViewer } from "./Hooks";
import "./HTTPViewer.style.scss";
import ReactCodeMirror from "@uiw/react-codemirror";
import { parsifyHttpRequestResponse } from "./functions";

export const HTTPViewer = ({
  value,
  showTitleBar = true,
  title,
  className,
}: Props) => {
  const { codeTheme, extensions, canvasOpen, handleToggleCanvas } =
    useHTTPViewer();
  const localizedTitle = typeof title === "string" ? title : "common.http-code";

  const parsedString = useMemo(
    () => parsifyHttpRequestResponse(value),
    [value]
  );

  const codeMirror = useMemo(
    () => (
      <ReactCodeMirror
        value={parsedString}
        height="100%"
        extensions={extensions}
        theme={getCodeMirrorTheme(codeTheme)}
        readOnly
      />
    ),
    [codeTheme, extensions, parsedString]
  );

  return (
    <>
      <div className={joinClasses("http-code", className)}>
        <ActivityView
          bodyContent={codeMirror}
          showTitleBar={showTitleBar}
          titleContent={
            <div className={BEM("http-code", "title-bar")}>
              <span>{title}</span>
              <div>
                <Button
                  iconClass="bi bi-arrows-fullscreen"
                  color={"primary"}
                  onClick={handleToggleCanvas}
                />
                <ClipboardBadge value={value} />
              </div>
            </div>
          }
        />
      </div>
      <Offcanvas
        className="full-height"
        isOpen={canvasOpen}
        title={localizedTitle}
        toggle={handleToggleCanvas}
        exitLabel="common.close"
        noPadding
        children={
          <div className="http-code on-canvas">
            {codeMirror}
            <ClipboardBadge value={value} />
          </div>
        }
      />
    </>
  );
};
