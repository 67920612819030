import { Button, ComboBox, FormInput, RefreshListButton } from "@Components";
import type { Nullable } from "@Interfaces";
import { getFilterAllowedOptions, type AuthenticationProfile } from "@Services";
import { t } from "i18next";
import type { Dispatch, FC } from "react";
import { memo, useCallback, useEffect, useMemo } from "react";
import { Label } from "reactstrap";
import type { PageFiltersProps } from "../PageFilters.i";
import { PageFiltersWrapper } from "../PageFiltersWrapper";
import { usePageFilters } from "../usePageFilters";
import { STATUS_CODE_OPTIONS } from "@Utils";

type FilterControlsObject = {
  caller: {
    visible: boolean;
  };
};

type HistoryFiltersProps = PageFiltersProps & {
  authenticationProfiles: Nullable<Array<AuthenticationProfile>>;
  clear: VoidFunction;
  filterControls: FilterControlsObject;
};

const getAuthenticationProfilesSelectOptions = (
  authenticationProfiles: Nullable<Array<AuthenticationProfile>>
) => {
  if (!authenticationProfiles) {
    return [{ label: t("common.loading"), value: "" }];
  }
  const result = authenticationProfiles.map(a => ({
    label: a.name,
    value: a.id,
  }));

  return [
    {
      label: t("common.all"),
      value: "",
    },
    ...result,
  ];
};

const checkFilterValue = (
  authenticationProfiles: Nullable<AuthenticationProfile[]>,
  getFilterValueFn: (k: string) => string,
  updateFiltersFn: Dispatch<Record<string, string>>
) => {
  let actualFilterValue = getFilterValueFn("attempt_sequence_auth_profile_id");

  if (!actualFilterValue) {
    return;
  }

  actualFilterValue = actualFilterValue.toLocaleLowerCase();

  if (!authenticationProfiles || !authenticationProfiles.length) {
    return;
  }

  const profileExists = authenticationProfiles?.find(
    authProfile =>
      authProfile.name.toLocaleLowerCase() === actualFilterValue ||
      authProfile.id === actualFilterValue
  );

  if (profileExists) {
    return;
  }

  // the actual filter value does not exists within the profile array
  // that means that the user moved into a new scan with different profiles
  // reset the filter value
  updateFiltersFn({
    attempt_sequence_auth_profile_id: "",
  });
};

export const HistoryFilters: FC<HistoryFiltersProps> = memo(
  ({
    updateFilters,
    refresh,
    clear,
    authenticationProfiles,
    filterControls,
  }) => {
    const { getValueOf, handleOnChange } = usePageFilters(updateFilters);

    const handleStatusChange = useCallback(
      (statusCode: string) => {
        updateFilters({ attempt_status_code: statusCode });
      },
      [updateFilters]
    );

    useEffect(() => {
      checkFilterValue(authenticationProfiles, getValueOf, updateFilters);
    }, [authenticationProfiles, getValueOf, updateFilters]);

    const staticFilters = useMemo(
      () => (
        <>
          <FormInput
            id="scan-history-attempt-method-select"
            type="select"
            options={getFilterAllowedOptions("verb")}
            label="common.method"
            name="attempt_method"
            value={getValueOf("attempt_method")}
            onChange={handleOnChange}
          />
          <FormInput
            id="scan-history-attempt-path-input"
            type="text"
            label="scans.history.filters.attempt-path"
            name="attempt_path"
            value={getValueOf("attempt_path")}
            onChange={handleOnChange}
          />
          <div>
            <Label for="scan-history-attempt-status-input">
              {t("common.status-code")}
            </Label>
            <ComboBox
              id="scan-history-attempt-status-input"
              bsSize="sm"
              value={getValueOf("attempt_status_code")}
              data={STATUS_CODE_OPTIONS}
              role="form-control"
              onChange={handleStatusChange}
            />
          </div>
          <FormInput
            id="scan-history-attempt-resolve-select"
            type="select"
            label="common.resolved"
            options={getFilterAllowedOptions("resolved")}
            name="attempt_resolved"
            value={getValueOf("attempt_resolved")}
            onChange={handleOnChange}
          />
          <FormInput
            id="scan-history-attempt-scope-select"
            type="select"
            options={getFilterAllowedOptions("scope")}
            label="common.scope"
            name="attempt_scope"
            value={getValueOf("attempt_scope")}
            onChange={handleOnChange}
          />
          {filterControls.caller.visible && (
            <FormInput
              id="scan-history-attempt-caller-select"
              type="select"
              options={getFilterAllowedOptions("caller")}
              label="common.caller"
              name="attempt_caller"
              value={getValueOf("attempt_caller")}
              data-cy="http-history-input-caller"
              onChange={handleOnChange}
            />
          )}
          <RefreshListButton onClick={refresh} />
          <Button
            id="filters-clear-btn"
            type="button"
            iconClass="bi bi-eraser-fill"
            size="md"
            onClick={clear}
            color="danger"
            title={t("common.clear-filters")}
          />
        </>
      ),
      [
        clear,
        filterControls.caller.visible,
        getValueOf,
        handleOnChange,
        handleStatusChange,
        refresh,
      ]
    );

    return (
      <PageFiltersWrapper>
        <FormInput
          type="select"
          id="input-authentication-profile-name"
          label="scans.history.filters.attempt_sequence_auth_profile_id"
          name="attempt_sequence_auth_profile_id"
          value={getValueOf("attempt_sequence_auth_profile_id")}
          className="w-100"
          disabled={!authenticationProfiles}
          onChange={handleOnChange}
          options={getAuthenticationProfilesSelectOptions(
            authenticationProfiles
          )}
        />
        {staticFilters}
      </PageFiltersWrapper>
    );
  }
);
