import type { ApiKey, Nullable } from "@Interfaces";
import { clamp } from "@Services";
import { getDaysBetween } from "@Utils";

export const getApiKeyExtraData = (apiKey: Nullable<ApiKey>) => {
  const daysBetween = apiKey ? getDaysBetween(apiKey.expiresAt) : 0;

  let remainingDays = Infinity;
  if (Number.isFinite(daysBetween)) {
    remainingDays = clamp(daysBetween, 0, Number.MAX_SAFE_INTEGER);
  }

  const isExpired = remainingDays <= 0;

  const status = apiKey?.active ? "enabled" : "disabled";

  return {
    status,
    isExpired,
    remainingDays,
  };
};
