import { usePreference, useUpdateCacheFilters } from "@Hooks";
import { useMemo } from "react";
import { getDefaultFilters, mapHeaderItemsWithSorts } from "@Services";
import { useLocation } from "react-router-dom";
import { getHeaders } from "./functions";
import type { OrganizationUserListTableProps } from "../OrganizationUserList.i";
import { useTableConfig } from "./useTableConfig";

export const useOrganizationUserListTable = ({
  users,
  loading = false,
  refreshUsers,
}: OrganizationUserListTableProps) => {
  const [preference, setPreference] = usePreference(
    "users-card-version",
    "true" // cards as default
  );

  const { search } = useLocation();
  const updateFunction = useUpdateCacheFilters("organization.users");
  const defaultFilters = useMemo(() => getDefaultFilters("users"), []);

  const headers = useMemo(() => {
    return mapHeaderItemsWithSorts({
      items: getHeaders(),
      search,
      updateFunction,
      defaults: defaultFilters,
    });
  }, [defaultFilters, search, updateFunction]);

  return {
    loading,
    headers,
    cardVersion: preference === "true",
    setCardVersion: (cardVersion: boolean) => setPreference(`${cardVersion}`),
    config: useTableConfig(users ?? [], refreshUsers),
    defaultSorts: defaultFilters,
    setSorts: updateFunction,
  };
};
