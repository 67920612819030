import { t } from "i18next";
import { CurtesyPage } from "../CurtesyPage";

export const NoParameters = () => {
  return (
    <CurtesyPage>
      <i className="bi bi-boxes font-size-h1 color-gray"></i>
      <h3 className="mb-3">{t("common.no-parameters")}</h3>
    </CurtesyPage>
  );
};
