import { Offcanvas, PathExclusionForm } from "@Components";
import type { Method } from "@Interfaces";
import { useFormCanvas } from "./Hooks";
import type { PathExclusionFormCanvasProps } from "./PathExclusion.i";

export const PathExclusionFormCanvas = (
  props: PathExclusionFormCanvasProps
) => {
  const {
    canvasButtons,
    close,
    entry,
    isOpen,
    updateEntryMethods,
    updateEntryPath,
    validities,
    loading,
    calloutMessage,
    pathMatchLoading,
  } = useFormCanvas(props);

  return (
    <Offcanvas
      id="path-exclusion-form-canvas"
      isOpen={isOpen}
      toggle={close}
      title="common.path-exclusion"
      buttons={canvasButtons}
      children={
        <PathExclusionForm
          path={entry.path}
          activeMethods={entry.methods as Method[]}
          setPath={updateEntryPath}
          setActiveMethods={updateEntryMethods}
          validities={validities}
          gridColumns={1}
          disabled={loading}
          calloutMessage={calloutMessage}
          pathLoading={pathMatchLoading}
        />
      }
    />
  );
};
