import { memo } from "react";
import { Col, Row } from "reactstrap";
import type { ProjectCardProps } from "./ListItems.i";
import { DisplaySection } from "../DisplaySections";

export const ListItemCard = memo(
  ({ tr, headers, fullRowIndexes }: ProjectCardProps) => {
    const getLabel = (index: number) => {
      const header = headers[index];
      return typeof header === "string" ? header : header.text;
    };

    const getTooltip = (index: number) => {
      const header = headers[index];
      return typeof header === "string" ? undefined : header?.tooltip;
    };

    return (
      <div className="card p-3 flex-grow-1">
        <Row>
          {tr.items
            .filter(td => td?.removeFromCard !== true)
            .map((td, i) => (
              <Col
                md={fullRowIndexes && fullRowIndexes.includes(i) ? 12 : 6}
                className="single-item item-col"
                key={i}
              >
                <DisplaySection
                  {...(td?.props || {})}
                  label={getLabel(i)}
                  value={td?.hide ? "-" : td.jsx}
                  tooltip={getTooltip(i)}
                />
              </Col>
            ))}
        </Row>
      </div>
    );
  }
);
