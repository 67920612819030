import { useUIStore } from "@Stores";
import { langs } from "@uiw/codemirror-extensions-langs";
import { EditorView } from "@uiw/react-codemirror";
import { useCallback, useMemo, useState } from "react";

export const useHTTPViewer = () => {
  const { codeTheme } = useUIStore();
  const [canvasOpen, setCanvasOpen] = useState(false);

  const extensions = useMemo(() => [langs.http(), EditorView.lineWrapping], []);
  const handleToggleCanvas = useCallback(() => {
    setCanvasOpen(co => !co);
  }, []);

  return {
    codeTheme,
    extensions,
    canvasOpen,
    handleToggleCanvas,
  };
};
