import { useTimedState, useToast } from "@Hooks";
import { useIssueListStore } from "@Stores";
import { useMemo } from "react";
import { useIssueDetailsCanvasIssueIds } from "./useIssueDetailsCanvasIssueIds";
import { PROJECT_DETAILS_SCAN_URL } from "@Constants";
import { generateUrl } from "@Utils";

export const useIssueDetailsCanvasShareButton = () => {
  const { organizationId, projectId, scanId } = useIssueDetailsCanvasIssueIds();
  const { openIssueId } = useIssueListStore();
  const [shared, setShared] = useTimedState<boolean>(false);
  const toast = useToast();

  const shareableUrl = useMemo(() => {
    if (!organizationId || !projectId || !scanId) {
      return undefined;
    }

    return `${window.location.origin}${generateUrl(PROJECT_DETAILS_SCAN_URL, [
      organizationId,
      projectId,
      scanId,
    ])}?isp_issueId=${openIssueId}#issues`;
  }, [openIssueId, organizationId, projectId, scanId]);

  const shareButton = useMemo(
    () => ({
      color: "pink",
      children: "common.share",
      iconClass: shared ? "bi bi-check" : "bi bi-share-fill",
      disabled: !shareableUrl,
      onClick: () => {
        if (!shareableUrl) {
          return;
        }
        navigator.clipboard.writeText(shareableUrl);
        setShared(true);
        toast({
          message: "common.element-copied-clipboard",
          type: "success",
        });
      },
      data: {
        cy: "issue-share-button",
      },
    }),
    [setShared, shareableUrl, shared, toast]
  );

  return {
    shareButton,
  };
};
