import { useManageApiResponse, useUrlParams } from "@Hooks";
import { API } from "@Services";
import { useCallback, useEffect } from "react";
import { useScanDetailsStore } from "../Store/ScanDetails.store";
import { isStatusCodeSuccess } from "@Utils";
import { useScanSpaceLinkStore } from "../Store/ScanSpaceLink.store";

export const useFetchDetails = () => {
  const { organizationId, projectId, scanId } = useUrlParams();

  const manageResponse = useManageApiResponse();

  const {
    setScanDetails,
    setNotFound,
    setLoading: setScanLoading,
  } = useScanDetailsStore();

  const { setSpaceLinkId } = useScanSpaceLinkStore();

  const fetchScanDetails = useCallback(async () => {
    if (!organizationId || !projectId || !scanId) return;

    setScanLoading(true);

    try {
      const promise = API.scan().scanGet(organizationId, projectId, scanId);
      const { data, status } = await manageResponse({ promise });
      if (!data || !isStatusCodeSuccess(status)) throw new Error("not-found");
      setScanDetails(data);
      setSpaceLinkId(data.scan.spaceLinkID);
    } catch (e) {
      setNotFound(true);
    } finally {
      setScanLoading(false);
    }
  }, [
    organizationId,
    projectId,
    scanId,
    setScanLoading,
    manageResponse,
    setScanDetails,
    setSpaceLinkId,
    setNotFound,
  ]);

  //

  useEffect(() => {
    fetchScanDetails();
  }, [fetchScanDetails]);
};
