import type { LiveLog } from "@Services";
import type { Dispatch } from "react";
import { createStore } from "src/Hooks/store/useStore";

export type LiveLogsStoreProperties = {
  loading: boolean;
  liveLogs: Array<LiveLog>;
};

export type LiveLogsStore = LiveLogsStoreProperties & {
  setLoading: Dispatch<boolean>;
  setLiveLogs: Dispatch<Array<LiveLog>>;
  reset: VoidFunction;
};

const initialState: LiveLogsStoreProperties = {
  loading: false,
  liveLogs: [],
};

export const useLiveLogsStore = createStore<LiveLogsStore>(
  (get, set) => ({
    setLoading: loading => set(store => ({ ...store, loading })),
    setLiveLogs: liveLogs => set(store => ({ ...store, liveLogs })),
    reset: () => set(store => ({ ...store, ...initialState })),
  }),
  initialState
);
