import { DisplayRow, GridContainer } from "@Components";
import { dateTimeFormat } from "@Services";
import { t } from "i18next";
import { memo } from "react";
import isEqual from "react-fast-compare";
import { BEM, getDaysBetween, joinClasses, limitString } from "@Utils";
import { useSpaceLinkDetailsStore } from "../../../../../Stores";

const trimTime = true;

const labels = {
  enabled: t("common.enabled"),
  disabled: t("common.disabled"),
  connected: t("common.connected"),
  disconnected: t("common.disconnected"),
};

export const SpaceLinkData = memo(() => {
  const { spaceLinkDetails: spaceLink } = useSpaceLinkDetailsStore();
  if (!spaceLink) {
    return null;
  }
  const { name, createdAt, active, expiresAt, connected } = spaceLink;
  const daysRemaining = getDaysBetween(expiresAt);
  const expired = daysRemaining <= 0;
  const hasExpiration = expired || Number.isFinite(daysRemaining);

  return (
    <>
      <GridContainer
        data-cy="api-key-data"
        className="section-grid-container"
        id={BEM("space-link-details__content", "main-info-container")}
      >
        <div
          id={BEM("space-link-details__content__main-info-container", "title")}
        >
          <h1 className="space-link-name">{limitString(name, 80)}</h1>
          <h6 data-cy="space-link-status" className="color-gray">
            <i
              className={joinClasses(
                "bi",
                `bi-wifi${!connected ? "-off" : ""}`,
                `color-${connected ? "success" : "severity-high"}`,
                "me-2"
              )}
            />
            {connected ? labels.connected : labels.disconnected}
          </h6>
        </div>
        <DisplayRow
          noHeader
          className="m-0 mt-4"
          columns={[
            {
              label: "common.name",
              value: name,
              xs: 12,
              xl: 4,
              xxl: 3,
            },
            {
              label: "common.created-at",
              value: dateTimeFormat(createdAt, { trimTime }),
              xs: 12,
              xl: 4,
              xxl: 3,
            },
            {
              label: "common.status",
              value: expired
                ? t("common.expired")
                : active
                  ? labels.enabled
                  : labels.disabled,
              xs: 12,
              xl: 4,
              xxl: 3,
            },
            hasExpiration && {
              label: "common.expires-at",
              value: dateTimeFormat(expiresAt, { trimTime }),
              xs: 12,
              xl: 4,
              xxl: 3,
            },
            daysRemaining > 0 && {
              label: "common.remaining-days",
              value: daysRemaining,
              xs: 12,
              xl: 4,
              xxl: 3,
            },
          ].filter(e => !!e)}
        />
      </GridContainer>
    </>
  );
}, isEqual);
