import { AdvancedSettingsForm, Button, SettingsContainer } from "@Components";
import { t } from "i18next";
import useAdvancedSettings from "./useAdvancedSettings";

import "./style.scss";

export const AdvancedSettings = () => {
  const { buttons, displayable, form, hasError, loading } =
    useAdvancedSettings();

  return (
    <div id="project-advanced-settings">
      {displayable && (
        <SettingsContainer
          loading={loading}
          section="settings"
          hasError={hasError}
          children={
            <>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h6
                  className="color-pink m-0"
                  children={t("common.advanced-settings")}
                />
                <div className="buttons-container">
                  {buttons.map((props, i) => (
                    <Button {...props} key={i} />
                  ))}
                </div>
              </div>
              <div>
                {form && (
                  <AdvancedSettingsForm
                    advancedSettings={form.model}
                    setAdvancedSettings={form.setModel}
                  />
                )}
              </div>
            </>
          }
        />
      )}
    </div>
  );
};
