import type { PageFiltersProps } from "../PageFilters.i";
import { getFilterAllowedOptions } from "@Services";
import { FormInput, RefreshListButton } from "@Components";
import { usePageFilters } from "../usePageFilters";
import { PageFiltersWrapper } from "../PageFiltersWrapper";

const userRoleOptions = getFilterAllowedOptions("enabled");

export const OrganizationUsersFilters = ({
  updateFilters,
  refresh,
}: PageFiltersProps) => {
  const { getValueOf, handleOnChange } = usePageFilters(updateFilters);
  return (
    <PageFiltersWrapper>
      <FormInput
        type="text"
        id="user-surname-name-input"
        label="common.name"
        name="user_surname_name"
        value={getValueOf("user_surname_name")}
        onChange={handleOnChange}
      />
      <FormInput
        type="text"
        id="user-email-input"
        label="common.email"
        name="user_email"
        value={getValueOf("user_email")}
        onChange={handleOnChange}
      />
      <FormInput
        type="select"
        id="user-role-active-input"
        label="common.active"
        name="user_role_active"
        value={getValueOf("user_role_active")}
        options={userRoleOptions}
        onChange={handleOnChange}
      />
      <RefreshListButton onClick={refresh} />
    </PageFiltersWrapper>
  );
};
