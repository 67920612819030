import type { ButtonProps } from "@Components";
import { Button } from "@Components";
import { useMemo } from "react";
import "./style.scss";

export type ComponentButtonProps = ButtonProps & {
  show?: boolean;
};

interface Props {
  buttons: ComponentButtonProps[];
  stick?: "bottom";
  className?: string;
}

export const ButtonBar = ({ buttons, stick, className }: Props) => {
  const classNames = ["button-bar"];

  stick && classNames.push(`sticked-${stick}`);
  className && classNames.push(className);

  const buttonElements = useMemo(
    () =>
      buttons
        .filter(btn => btn.show !== false)
        .map((btn, i) => {
          delete btn.show;
          return <Button {...btn} key={i} />;
        }),
    [buttons]
  );

  return <div className={classNames.join(" ")}>{buttonElements}</div>;
};

export { type Props as ButtonBarProps };
