import { t } from "i18next";
import { DisplayRow } from "../DisplayRow";
import type { DisplayRowProps as MainProps } from "../DisplayRow.i";
import { dateTimeFormat, type RunPolicy } from "@Services";

type DisplayRowRunScheduleProps = {
  runPolicy: RunPolicy;
} & Pick<MainProps, "buttonProps">;

const formatDate = (date: string) => {
  return dateTimeFormat(date, {
    checkMaxEndDate: true,
    trimTime: true,
  });
};

export const DisplayRowRunSchedule = ({
  runPolicy,
  buttonProps,
}: DisplayRowRunScheduleProps) => {
  const isRecurrenceNone = runPolicy.recurrence === "none";
  const isRecurrenceOneShot = runPolicy.recurrence === "one_shot";

  const startDateValue = isRecurrenceNone
    ? "-"
    : formatDate(runPolicy.startDate);

  const endDateValue =
    isRecurrenceNone || isRecurrenceOneShot
      ? "-"
      : formatDate(runPolicy.endDate);

  return (
    <DisplayRow
      title="common.schedule"
      columns={[
        {
          label: "common.start-date",
          value: startDateValue,
          className: "mb-4 mb-xl-0",
          xs: 12,
          xl: 4,
        },
        {
          label: "common.end-date",
          value: endDateValue,
          className: "mb-4 mb-xl-0",
          xs: 12,
          xl: 4,
        },
        {
          label: "common.recurrence",
          value: t(`recurrences.${runPolicy.recurrence}`),
          xs: 12,
          xl: 4,
        },
      ]}
      buttonProps={buttonProps}
    />
  );
};
