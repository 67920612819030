import type { InventoryDetail } from "@Interfaces";
import type { Parameter } from "@Services";
import type { Edge, Node } from "@xyflow/react";

export const INVENTORY_ITEM_NODE_WIDTH = 240; // 19.75rem
export const INVENTORY_ITEM_NODE_TITLE_HEIGHT = 40;
export const INVENTORY_ITEM_NODE_ITEM_HEIGHT = 34;

export const LOCATION_NODE_WIDTH = 160; // 10rem
export const LOCATION_NODE_TITLE_HEIGHT = 40;
export const LOCATION_NODE_ITEM_HEIGHT = 34;

export const PARAMETER_NODE_WIDTH = 240; // 19.75rem
export const PARAMETER_NODE_TITLE_HEIGHT = 32 + 2; // 2px for border
export const PARAMETER_NODE_ITEM_HEIGHT = 55 + 2;

type SharedData = {
  visible: boolean;
  edges: Array<Edge>;
  parentId?: string;
};

export type InventoryNodeData = SharedData & {
  details: InventoryDetail;
};
export type InventoryNodeProps = {
  id: string;
  data: InventoryNodeData;
};

export type LocationNodeData = SharedData & {
  location: string;
};
export type LocationNodeProps = {
  id: string;
  data: LocationNodeData;
};

export type ParameterNodeData = SharedData & {
  parameter: Parameter;
  input: boolean;
  subNodes?: Array<AdditionalInfoNodeType>;
};
export type ParameterNodeProps = {
  id: string;
  data: ParameterNodeData;
};

export type AdditionalInfoNodeType = Node<
  InventoryNodeData | LocationNodeData | ParameterNodeData
>;
