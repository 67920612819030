import type { LiveLog } from "@Services";

export const ORGANIZATION_LIVE_LOGS: Array<LiveLog> = [
  {
    message:
      "Stats - coverage: 0.00%, reached: 40.00%, attempted: 40.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:26:11.819003515Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 60.00%, attempted: 60.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:26:19.741424225Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "2",
      scan_id: "1-100003",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 60.00%, attempted: 60.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:26:23.767555884Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 70.00%, attempted: 70.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:26:28.347828168Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "3",
      scan_id: "1-100005",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 70.00%, attempted: 70.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:26:37.03414261Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 80.00%, attempted: 80.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:26:39.633774924Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100004",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 80.00%, attempted: 80.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:26:44.669198843Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "2",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 80.00%, attempted: 80.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:26:47.520776025Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 80.00%, attempted: 80.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:26:53.39484233Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "5",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 80.00%, attempted: 80.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:26:55.358941017Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "2",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 90.00%, attempted: 90.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:26:57.002006416Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 90.00%, attempted: 90.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:27:00.126334857Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 90.00%, attempted: 90.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:27:02.246885178Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 90.00%, attempted: 90.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:27:04.144150032Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 90.00%, attempted: 90.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:27:09.779777766Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 90.00%, attempted: 90.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:27:11.352785246Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 90.00%, attempted: 90.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:27:16.137672426Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 90.00%, attempted: 90.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:27:22.394874126Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 100.00%, attempted: 100.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:27:37.028971473Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "====================== EXPLORE RESULT FOR PROFILE <nil> ======================",
    timestamp: "2024-08-19T14:27:56.971940933Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "[33m❆\tv1-health - GET /v1/health\tNode ID: 235109d9-9a9b-4277-8dc9-eee4eb5d63fc, Node Name: v1-health, Node Full URI: GET /v1/health[0m",
    timestamp: "2024-08-19T14:27:56.971986236Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "[33m❆\tv1-login - POST /v1/login\tNode ID: 596f6f55-c7d4-44c2-8277-7ea0479c2eba, Node Name: v1-login, Node Full URI: POST /v1/login[0m",
    timestamp: "2024-08-19T14:27:56.972009647Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "[33m❆\tv1-user-get-info - GET /v1/users\tNode ID: 39ebe631-d658-421f-9c69-0fba2459e0a1, Node Name: v1-user-get-info, Node Full URI: GET /v1/users[0m",
    timestamp: "2024-08-19T14:27:56.972032144Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "[33m❆\tv1-user-get-last-login - GET /v1/logs/login\tNode ID: dc026596-fe87-4b0f-82d9-5400f333f350, Node Name: v1-user-get-last-login, Node Full URI: GET /v1/logs/login[0m",
    timestamp: "2024-08-19T14:27:56.972050994Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "[33m❆\tv1-user-get-organization - GET /v1/organizations/{organization_id}\tNode ID: 5f112e72-9d01-4d8d-9c2b-f24d80573a4a, Node Name: v1-user-get-organization, Node Full URI: GET /v1/organizations/{organization_id}[0m",
    timestamp: "2024-08-19T14:27:56.972078194Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "[33m❆\tv1-user-get-organizations-related-to-requester - GET /v1/organizations\tNode ID: 13e32c29-db4c-49fd-9679-623d8676f7d4, Node Name: v1-user-get-organizations-related-to-requester, Node Full URI: GET /v1/organizations[0m",
    timestamp: "2024-08-19T14:27:56.972103415Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "[33m❆\tv1-user-get-roles - GET /v1/roles\tNode ID: d183736c-a173-47f5-85a2-c5cce54f96e4, Node Name: v1-user-get-roles, Node Full URI: GET /v1/roles[0m",
    timestamp: "2024-08-19T14:27:56.972139114Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "[33m❆\tv1-user-get-user-settings - GET /v1/users/settings\tNode ID: d02d58b4-c02a-412f-bb71-7fc8be320baa, Node Name: v1-user-get-user-settings, Node Full URI: GET /v1/users/settings[0m",
    timestamp: "2024-08-19T14:27:56.972241687Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "[33m❆\tv1-user-logout - GET /v1/users/logout\tNode ID: 921a8699-95c1-4c22-97d8-ead85b8cdc7b, Node Name: v1-user-logout, Node Full URI: GET /v1/users/logout[0m",
    timestamp: "2024-08-19T14:27:56.972294643Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "[33m❆\tv1-user-refresh-token - GET /v1/users/refresh-token\tNode ID: 5b8719be-00a3-44b3-a84d-8bd37554bf07, Node Name: v1-user-refresh-token, Node Full URI: GET /v1/users/refresh-token[0m",
    timestamp: "2024-08-19T14:27:56.972323446Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 100.00%, attempted: 100.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:27:56.972356935Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "====================== COMMON EXPLORE RESULT ======================",
    timestamp: "2024-08-19T14:27:56.972540789Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "[33m❆\tv1-health - GET /v1/health\tNode ID: 235109d9-9a9b-4277-8dc9-eee4eb5d63fc, Node Name: v1-health, Node Full URI: GET /v1/health[0m",
    timestamp: "2024-08-19T14:27:56.972575449Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "[33m❆\tv1-login - POST /v1/login\tNode ID: 596f6f55-c7d4-44c2-8277-7ea0479c2eba, Node Name: v1-login, Node Full URI: POST /v1/login[0m",
    timestamp: "2024-08-19T14:27:56.972595681Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "[33m❆\tv1-user-get-info - GET /v1/users\tNode ID: 39ebe631-d658-421f-9c69-0fba2459e0a1, Node Name: v1-user-get-info, Node Full URI: GET /v1/users[0m",
    timestamp: "2024-08-19T14:27:56.972618393Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "[33m❆\tv1-user-get-last-login - GET /v1/logs/login\tNode ID: dc026596-fe87-4b0f-82d9-5400f333f350, Node Name: v1-user-get-last-login, Node Full URI: GET /v1/logs/login[0m",
    timestamp: "2024-08-19T14:27:56.972645947Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "[33m❆\tv1-user-get-organization - GET /v1/organizations/{organization_id}\tNode ID: 5f112e72-9d01-4d8d-9c2b-f24d80573a4a, Node Name: v1-user-get-organization, Node Full URI: GET /v1/organizations/{organization_id}[0m",
    timestamp: "2024-08-19T14:27:56.972669852Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "[33m❆\tv1-user-get-organizations-related-to-requester - GET /v1/organizations\tNode ID: 13e32c29-db4c-49fd-9679-623d8676f7d4, Node Name: v1-user-get-organizations-related-to-requester, Node Full URI: GET /v1/organizations[0m",
    timestamp: "2024-08-19T14:27:56.972720659Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "[33m❆\tv1-user-get-roles - GET /v1/roles\tNode ID: d183736c-a173-47f5-85a2-c5cce54f96e4, Node Name: v1-user-get-roles, Node Full URI: GET /v1/roles[0m",
    timestamp: "2024-08-19T14:27:56.972780375Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "[33m❆\tv1-user-get-user-settings - GET /v1/users/settings\tNode ID: d02d58b4-c02a-412f-bb71-7fc8be320baa, Node Name: v1-user-get-user-settings, Node Full URI: GET /v1/users/settings[0m",
    timestamp: "2024-08-19T14:27:56.972810166Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "[33m❆\tv1-user-logout - GET /v1/users/logout\tNode ID: 921a8699-95c1-4c22-97d8-ead85b8cdc7b, Node Name: v1-user-logout, Node Full URI: GET /v1/users/logout[0m",
    timestamp: "2024-08-19T14:27:56.972840584Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "[33m❆\tv1-user-refresh-token - GET /v1/users/refresh-token\tNode ID: 5b8719be-00a3-44b3-a84d-8bd37554bf07, Node Name: v1-user-refresh-token, Node Full URI: GET /v1/users/refresh-token[0m",
    timestamp: "2024-08-19T14:27:56.972867516Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 100.00%, attempted: 100.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:27:56.97288781Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message: "[no authentication profiles used]",
    timestamp: "2024-08-19T14:27:56.972909515Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 40.00%, attempted: 40.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:26:11.819003515Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 60.00%, attempted: 60.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:26:19.741424225Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 60.00%, attempted: 60.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:26:23.767555884Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 70.00%, attempted: 70.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:26:28.347828168Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 70.00%, attempted: 70.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:26:37.03414261Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 80.00%, attempted: 80.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:26:39.633774924Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 80.00%, attempted: 80.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:26:44.669198843Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 80.00%, attempted: 80.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:26:47.520776025Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 80.00%, attempted: 80.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:26:53.39484233Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 80.00%, attempted: 80.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:26:55.358941017Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 90.00%, attempted: 90.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:26:57.002006416Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 90.00%, attempted: 90.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:27:00.126334857Z",
    level: "error",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 90.00%, attempted: 90.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:27:02.246885178Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 90.00%, attempted: 90.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:27:04.144150032Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 90.00%, attempted: 90.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:27:09.779777766Z",
    level: "debug",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 90.00%, attempted: 90.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:27:11.352785246Z",
    level: "trace",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 90.00%, attempted: 90.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:27:16.137672426Z",
    level: "fatal",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 90.00%, attempted: 90.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:27:22.394874126Z",
    level: "fatal",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
  {
    message:
      "Stats - coverage: 0.00%, reached: 100.00%, attempted: 100.00%\n------------------------------------------------------------",
    timestamp: "2024-08-19T14:27:37.028971473Z",
    level: "info",
    fields: {
      organization_id: "7badfea7-346e-48f5-8e93-55cc617b8ca5",
      project_id: "1",
      scan_id: "1-100002",
    },
  },
];
