import type { ValidityState } from "@Interfaces";
import { isFilledString, type PathExclusionEntry } from "@Services";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getAddButtonProps } from "../../Functions";
import type { PathExclusionFormCanvasProps } from "../PathExclusion.i";
import type { ButtonProps, CalloutProps } from "@Components";
import { useExclusionMatches, useProject } from "@Hooks";
import { t } from "i18next";

const pathNotFound = t("project.settings.unrelated-project-path");

const defaultEntry: PathExclusionEntry = {
  path: "",
  methods: [],
} as const;

export const useFormCanvas = ({
  isOpen,
  close,
  addEntry,
}: PathExclusionFormCanvasProps) => {
  const [entry, setEntry] = useState({ ...defaultEntry });
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const updateEntryPath = useCallback((path: string) => {
    setEntry(e => ({ ...e, path }));
  }, []);

  const updateEntryMethods = useCallback((methods: string[]) => {
    setEntry(e => ({ ...e, methods }));
  }, []);

  const closeCanvas = useCallback(() => {
    close();
    setEntry({ ...defaultEntry });
  }, [close]);

  const submitEntry = useCallback(() => {
    setLoading(true);
    addEntry({ ...entry })
      .then(success => success && closeCanvas())
      .finally(() => setLoading(false));
  }, [addEntry, closeCanvas, entry]);

  const canvasButtons = useMemo<ButtonProps[]>(() => {
    return [getAddButtonProps({ disabled, loading, onClick: submitEntry })];
  }, [disabled, loading, submitEntry]);

  const validities = useMemo<ValidityState<PathExclusionEntry>>(
    () => ({
      methods: !!entry.methods.length,
      path: isFilledString(entry.path),
    }),
    [entry.methods.length, entry.path]
  );

  useEffect(() => {
    const formValid = Object.values(validities).every(Boolean);
    setDisabled(!formValid);
  }, [validities]);

  const {
    isMatch: pathMatch,
    loading: pathMatchLoading,
    lastTestedPath,
  } = useExclusionMatches(entry.path, entry.methods);

  const baseUrl = useProject().project?.baseURL;
  const calloutMessage = useMemo<CalloutProps | undefined>(() => {
    if (pathMatch === false && baseUrl) {
      return {
        children: (
          <div>
            {pathNotFound}:
            <b className="d-block">
              {`${baseUrl}/${lastTestedPath}`.replaceAll(/\/+/g, "/")}
            </b>
          </div>
        ),
        level: "info",
      };
    }
  }, [baseUrl, lastTestedPath, pathMatch]);

  return {
    isOpen,
    entry,
    close: closeCanvas,
    updateEntryPath,
    updateEntryMethods,
    canvasButtons,
    loading,
    validities,
    pathMatch,
    pathMatchLoading,
    calloutMessage,
  };
};
