import { getStartOf, type ListAuditLogsResponse } from "@Services";
import { containsLikeQuery } from "@Utils";
import moment from "moment";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, userAuditLogs } from "src/Mock";

export const getUserLogs = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { searchParams: sP } = req.url;

  const page = +(sP.get("page") || 1);
  const size = +(sP.get("size") || 10);

  const { userId } = req.params;
  const collection = userAuditLogs.get(`${userId}`) ?? [];

  const criterias = sP.getAll("filter_criteria");
  const values = sP.getAll("filter_values");

  const filters: {
    [key: string]: string;
  } = {};

  for (const i in criterias) {
    filters[criterias[i]] = values[i];
  }

  const logs = collection.filter(a => {
    let bConditionMet = true;

    if (filters?.audit_log_path) {
      bConditionMet &&= containsLikeQuery(a.path, filters.audit_log_path);
    }

    if (filters?.audit_log_status) {
      bConditionMet &&= a.status === +filters?.audit_log_status;
    }

    if (filters?.audit_log_method) {
      bConditionMet &&= `${a.method}` === filters?.audit_log_method;
    }

    if (filters?.period) {
      const periodIndex = criterias.indexOf("period");
      const start = getStartOf(values[periodIndex]);
      bConditionMet &&= moment(a.createdAt).isSameOrAfter(start);
    }

    return bConditionMet;
  });

  const offset = (page - 1) * size;
  const limit = offset + size;

  const sortBy = sP.get("sort_by");
  const sortMode = sP.get("sort_mode");

  if (sortBy) {
    switch (sortBy) {
      case "audit_log_created_at": {
        logs.sort((a, b) => {
          const modifier = sortMode === "asc" ? 1 : -1;
          return (
            (moment(a.createdAt).isSameOrBefore(moment(b.createdAt)) ? -1 : 1) *
            modifier
          );
        });
      }
    }
  }

  const data = logs.slice(offset, limit);

  const response: ListAuditLogsResponse = {
    data,
    page,
    size,
    totalItems: logs.length,
    totalPages: Math.ceil(logs.length / size),
  };

  return res(ctx.json(response), ctx.status(200), ctx.status(delay));
};
