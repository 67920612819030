import type { Options } from "@Interfaces";
import { t } from "i18next";

const filterValues = {
  enabled: [
    { label: "common.all", value: "" },
    { label: "common.enabled", value: "true" },
    { label: "common.disabled", value: "false" },
  ],
  active: [
    { label: "common.all", value: "" },
    { label: "common.active", value: "true" },
    { label: "common.not-active", value: "false" },
  ],
  expired: [
    { label: "common.all", value: "" },
    { label: "common.expired", value: "true" },
    { label: "common.not-expired", value: "false" },
  ],
  period: [
    { label: "components.date-filter.last_7_days", value: "last_7_days" },
    {
      label: "components.date-filter.last_30_days",
      value: "last_30_days",
      default: true,
    },
    { label: "components.date-filter.last_90_days", value: "last_90_days" },
    { label: "components.date-filter.last_180_days", value: "last_180_days" },
    { label: "components.date-filter.in_the_last_year", value: "last_year" },
    { label: "components.date-filter.current_month", value: "current_month" },
    {
      label: "components.date-filter.current_quarter",
      value: "current_quarter",
    },
    { label: "components.date-filter.current_year", value: "current_year" },
  ],
  auth_matrix_verb: [
    { label: "common.all", value: "" },
    { label: "GET", value: "GET" },
    { label: "POST", value: "POST" },
    { label: "PUT", value: "PUT" },
    { label: "DELETE", value: "DELETE" },
    { label: "PATCH", value: "PATCH" },
    { label: "HEAD", value: "HEAD" },
  ],
  severity: [
    { label: "common.all", value: "" },
    { label: "common.high", value: "high" },
    { label: "common.medium", value: "medium" },
    { label: "common.low", value: "low" },
    { label: "common.information", value: "information" },
  ],
  scan_status: [
    { label: "common.all", value: "" },
    { label: "scans.completed", value: "completed" },
    { label: "scans.failed", value: "failed" },
    { label: "scans.exploration", value: "run_exploration_phase" },
    { label: "scans.exploitation", value: "run_exploitation_phase" },
    { label: "scans.stopped", value: "stopped" },
    { label: "scans.queued", value: "queued" },
    { label: "scans.purged", value: "purged" },
    { label: "scans.pending", value: "pending" },
  ],
  user_role: [
    { label: "common.all", value: "" },
    { label: "roles.organization_admin", value: "organization_admin" },
    { label: "roles.user", value: "user" },
    { label: "roles.security_reader", value: "security_reader" },
  ],
  api_key_role: [
    { label: "common.all", value: "" },
    { label: "roles.api_organization_admin", value: "api_organization_admin" },
    { label: "roles.api_user", value: "api_user" },
    { label: "roles.api_security_reader", value: "api_security_reader" },
  ],
  verb: [
    { label: "common.all", value: "" },
    { label: "GET", value: "GET" },
    { label: "POST", value: "POST" },
    { label: "PUT", value: "PUT" },
    { label: "PATCH", value: "PATCH" },
    { label: "DELETE", value: "DELETE" },
    { label: "HEAD", value: "HEAD" },
    { label: "OPTIONS", value: "OPTIONS" },
  ],
  scope: [
    {
      label: "common.all",
      value: "",
    },
    { label: "common.exploration", value: "exploration" },
    { label: "common.exploitation", value: "exploitation" },
  ],
  caller: [
    { label: "common.all", value: "" },
    { label: "common.render", value: "render" },
    { label: "common.auth-detector", value: "auth_detector" },
    { label: "common.auth-validation", value: "auth_validation" },
    { label: "common.auth-refresh", value: "auth_refresh" },
    { label: "common.auth-logout", value: "auth_logout" },
    { label: "common.extension", value: "extension" },
    { label: `common.p-extension`, value: "p_extension" },
    { label: "common.param-evaluation", value: "param_evaluation" },
    { label: "common.exploitation", value: "exploitation" },
  ],
  inventory_item_authentication_expected: [
    { label: "common.all", value: "" },
    { label: "common.authenticated", value: "true" },
    { label: "common.not-authenticated", value: "false" },
  ],
  origin: [
    { label: "common.all", value: "" },
    {
      label: "origin.end_user_input_credentials",
      value: "end_user_input_credentials",
    },
    { label: "origin.end_user_output_token", value: "end_user_output_token" },
    { label: "origin.autogenerated", value: "autogenerated" },
  ],
  resolved: [
    { label: "common.all", value: "" },
    { label: "common.resolved", value: "true" },
    { label: "common.not-resolved", value: "false" },
  ],
  connection: [
    { label: "common.all", value: "" },
    { label: "common.connected", value: "true" },
    { label: "common.disconnected", value: "false" },
  ],
  refresh_definition_type: [
    { label: "common.please-select", value: "" },
    { label: "common.internal-application-endpoint", value: "internal" },
    { label: "common.oauth-refresh-token", value: "external" },
  ],
  live_log_level: [
    { label: "common.please-select", value: "" },
    { label: "Panic", value: "panic" },
    { label: "Fatal", value: "fatal" },
    { label: "Error", value: "error" },
    { label: "Warning", value: "warning" },
    { label: "Info", value: "info" },
    { label: "Debug", value: "debug" },
    { label: "Trace", value: "trace" },
  ],
  inventory_item_category: [
    { label: "common.all", value: "" },
    { label: "common.read", value: "read" },
    { label: "common.create", value: "create" },
    { label: "common.updated", value: "updated" },
    { label: "common.other", value: "other" },
    { label: "common.login", value: "login" },
    { label: "common.register", value: "register" },
    { label: "common.logout", value: "logout" },
    { label: "common.reset", value: "reset" },
    { label: "common.refresh", value: "refresh" },
  ],
};

export type SelectFiltersValueKey = keyof typeof filterValues;

export const getFilterAllowedValues = (
  key: SelectFiltersValueKey
): string[] => {
  return filterValues[key].map(({ value }) => {
    return value;
  });
};

export const getFilterAllowedOptions = (
  key: SelectFiltersValueKey
): Options<string> => {
  return filterValues[key].map(option => {
    const hasDefault = "default" in option;
    return {
      label: t(option.label),
      value: option.value,
      default: hasDefault ? option.default : false,
    };
  });
};
