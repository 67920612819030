import type { Issue } from "@Interfaces";
import { API } from "@Services";
import { useIssueListStore } from "@Stores";
import { useEffect, useState } from "react";
import { useIssueDetailsCanvasStore } from "../../../IssueDetailsCanvas.store";

export const useIssueDetails = () => {
  const {
    openIssueId: issueId,
    organizationId,
    projectId,
    scanId,
  } = useIssueListStore();

  const { issueDetails, setIssueDetails } = useIssueDetailsCanvasStore();
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const getData = async () => {
      if (organizationId && projectId && scanId && issueId) {
        await API.issue()
          .issueGet(organizationId, projectId, scanId, issueId)
          .then(data => {
            setIssueDetails(data.data as Issue);
          })
          .catch(() => {
            setNotFound(true);
          });
      } else {
        setNotFound(true);
      }
    };
    getData();
  }, [organizationId, projectId, scanId, issueId, setIssueDetails]);

  useEffect(() => {
    return () => setIssueDetails(null);
  }, [setIssueDetails]);

  return { details: issueDetails, notFound };
};
