import { Button } from "@Components";
import { preventDefault } from "@Services";
import { t } from "i18next";
import { type FC } from "react";
import { FormInput } from "src/Components/Form/FormInput";
import { FormMessage } from "src/Components/Form/FormMessage";
import { Form } from "reactstrap";
import { EMPTY_STRING_LABEL } from "../shared";
import { useAdditionalDataForm } from "./useAdditionalDataForm";

/**
 * Credential Inputs form component. It update authenticationSettings.credentialInputs on submit
 */
export const AdditionalDataForm: FC = () => {
  const {
    entry,
    keyInputRef,
    loading,
    updateEntry,
    validities,
    duplicateKey,
    handleSubmit,
    valid,
  } = useAdditionalDataForm();
  return (
    <>
      <Form id="additional-data-input-form" onSubmit={preventDefault}>
        <FormInput
          id="additional-data-key"
          data-cy="authentication-settings-input-additional-data-key"
          label="project.authentication-and-dictionary.additional-data-key"
          type="text"
          name="key"
          bsSize="sm"
          value={entry.key}
          valid={validities.key}
          invalid={!validities.key}
          onChange={updateEntry}
          disabled={loading}
          innerRef={keyInputRef}
          required
          autoTrim
        />
        <FormInput
          id="additional-data-value"
          data-cy="authentication-settings-input-additional-data-value"
          label="project.authentication-and-dictionary.additional-data-value"
          type="text"
          name="value"
          bsSize="sm"
          value={entry.value}
          valid={validities.value}
          invalid={!validities.value}
          placeholder={EMPTY_STRING_LABEL}
          onChange={updateEntry}
          disabled={loading}
          autoTrim
        />
        <Button
          color="primary"
          size="sm"
          data-cy="authentication-settings-add-additional-data-button"
          onClick={handleSubmit}
          children="common.add"
          iconClass="bi bi-plus-square-fill"
          disabled={!valid || loading}
          type="submit"
        />
      </Form>
      {duplicateKey && (
        <FormMessage
          color="danger"
          message={t("validation.x-already-in-use", { x: t("common.key") })}
        />
      )}
    </>
  );
};
