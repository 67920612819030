import { InfoBadge } from "@Components";
import { PROJECT_DETAILS_URL } from "@Constants";
import { useProject, useUrlParams } from "@Hooks";
import { generateUrl } from "@Utils";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";

export const ProjectBreadcrumb = () => {
  const { project } = useProject();
  const { organizationId } = useUrlParams();
  if (!project || !project.name) {
    return <Spinner size="sm" />;
  }
  return (
    <InfoBadge
      bgColor="pink"
      className="me-2 text-truncate"
      id="project-breadcrumb"
      title={project.name}
    >
      <Link
        key="project-link"
        to={generateUrl(PROJECT_DETAILS_URL, [
          organizationId ?? "",
          project.id ?? "",
        ])}
      >
        {project.name}
      </Link>
    </InfoBadge>
  );
};
