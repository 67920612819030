import {
  isFilledString,
  type OrganizationAdminCreateSpaceLinkRequest,
  type OrganizationAdminCreateSpaceLinkResponse,
} from "@Services";
import moment from "moment-timezone";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, generateRandomToken, spaceLinks } from "src/Mock";

export const createSpaceLink = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { organizationId } = req.params as Record<string, string>;

  if (!organizationId) return res(ctx.status(400), ctx.delay(delay));

  const payload = (await req.json()) as OrganizationAdminCreateSpaceLinkRequest;

  if (!validatePayLoad) return res(ctx.status(400), ctx.delay(delay));

  const spaceLinksArray = spaceLinks.get(organizationId);
  const organizationSpaceLinks = spaceLinksArray ? [...spaceLinksArray] : [];

  const id = `${Array.from(spaceLinks.values()).reduce(
    (sum, organizationSpaceLinks) => (sum += organizationSpaceLinks.length),
    1 // id is simply the position inside the array containing every spaceLink + 1
  )}`;

  organizationSpaceLinks.push({
    id,
    connected: false,
    createdAt: moment().toISOString(),
    ...payload,
  });

  spaceLinks.set(organizationId, organizationSpaceLinks);

  const response: OrganizationAdminCreateSpaceLinkResponse = {
    token: generateRandomToken(),
    id,
  };

  return res(ctx.status(201), ctx.delay(delay), ctx.json(response));
};

function validatePayLoad({
  active,
  expiresAt,
  name,
}: OrganizationAdminCreateSpaceLinkRequest): boolean {
  if (!isFilledString(name)) return false;
  const mExpiresAt = moment(expiresAt);
  if (!mExpiresAt.isValid()) return false;
  if (moment().isSameOrBefore(mExpiresAt, "date")) return false;
  if (!active && active !== false) return false;
  return true;
}
