import type { ListItemConfigElement } from "@Components";
import type { SpaceLink } from "@Services";
import { dateTimeFormat } from "@Services";
import { BEM, getDaysBetween, joinClasses } from "@Utils";
import { t } from "i18next";
import { clamp } from "lodash";
import type { Dispatch } from "react";
import { FormGroup, Input, Label } from "reactstrap";

const dateTimeOpt = {
  trimTime: true,
  checkMaxEndDate: true,
};

type Args = {
  spaceLinks: Array<SpaceLink>;
  navigateToId: Dispatch<SpaceLink["id"]>;
  updatingInlineStatus: boolean;
  updateInlineStatus: Dispatch<SpaceLink["id"]>;
};

type PickedUpdateProps = "updateInlineStatus" | "updatingInlineStatus";
type StatusItemProps = { spaceLink: SpaceLink } & Pick<Args, PickedUpdateProps>;

export const getSpaceLinkTableConfig = ({
  spaceLinks,
  navigateToId,
  updatingInlineStatus,
  updateInlineStatus,
}: Args) => {
  let result: ListItemConfigElement[] = [];

  result = spaceLinks.map(sl => {
    const statusProps: StatusItemProps = {
      spaceLink: sl,
      updateInlineStatus,
      updatingInlineStatus,
    };
    return {
      props: {
        className: "active",
        onClick: () => navigateToId(sl.id),
      },
      items: [
        { jsx: <>{sl.name}</> },
        { jsx: <>{dateTimeFormat(sl.createdAt, dateTimeOpt)}</> },
        { jsx: <>{dateTimeFormat(sl.expiresAt, dateTimeOpt)}</> },
        { jsx: <>{getRemainingDays(sl.expiresAt)}</> },
        { jsx: <>{getConnectedLabel(sl.connected)}</> },
        { jsx: <>{getActiveLabel(statusProps)}</> },
      ],
    };
  });
  return result;
};

const labels = {
  connected: t("common.connected"),
  disconnected: t("common.disconnected"),
  enabled: t("common.enabled"),
  disabled: t("common.disabled"),
  expired: t("common.expired"),
};

const connectedValueOutcome = {
  connected: (
    <>
      <i className={`bi bi-wifi color-success`} />
      <span>{labels.connected}</span>
    </>
  ),
  disconnected: (
    <>
      <i className={`bi bi-wifi-off color-severity-high`} />
      <span>{labels.disconnected}</span>
    </>
  ),
};

const getConnectedLabel = (connected: boolean) => {
  return connectedValueOutcome[connected ? `connected` : `disconnected`];
};

const icons = {
  enabled: <i className="bi bi-check2 color-success" />,
  disabled: <i className="bi bi-x color-severity-low" />,
  expired: <i className="bi bi-exclamation-triangle color-severity-high" />,
};

const getActiveLabel = ({
  spaceLink: { id, active, expiresAt },
  updateInlineStatus,
  updatingInlineStatus,
}: StatusItemProps) => {
  const isExpired = getDaysBetween(expiresAt) <= 0;

  if (isExpired) {
    return (
      <>
        {icons.expired}
        <span>{labels.expired}</span>
      </>
    );
  }

  const label = active ? labels.enabled : labels.disabled;
  const inputId = `inline-status-toggle-${id}`;

  const className = BEM(
    "status-form-group",
    null,
    updatingInlineStatus ? "disabled" : null
  );

  return (
    <FormGroup switch className={className} onClick={e => e.stopPropagation()}>
      <Input
        id={inputId}
        disabled={updatingInlineStatus}
        type="checkbox"
        checked={active}
        onChange={() => updateInlineStatus(id)}
      />
      <Label htmlFor={inputId} children={label} />
    </FormGroup>
  );
};

const getRemainingDays = (expiresAt: string) => {
  const diff = getDaysBetween(expiresAt);

  const remainingDays = Number.isFinite(diff)
    ? clamp(diff, 0, Number.MAX_SAFE_INTEGER)
    : "-";

  return (
    <span
      className={joinClasses(remainingDays === 0 && "color-severity-high")}
      children={remainingDays}
    />
  );
};
