import { createStore } from "@Hooks";
import type { ApiKeyResponse } from "@Services";
import type { Dispatch } from "react";

export type ApiKeyListStoreProperties = {
  apiKeys: Array<ApiKeyResponse>;
  totalItems: number;
  loading: boolean;
  refreshData: boolean;
};

export type ApiKeyListStore = ApiKeyListStoreProperties & {
  setApiKeys: (apiKeys: Array<ApiKeyResponse>, totalItems: number) => void;
  setNeedRefresh: Dispatch<boolean>;
  setLoading: Dispatch<boolean>;
};

const initialState: ApiKeyListStoreProperties = {
  apiKeys: [],
  refreshData: false,
  totalItems: 0,
  loading: false,
};

export const useApiKeyListStore = createStore<ApiKeyListStore>(
  (get, set) => ({
    setApiKeys: (apiKeys: Array<ApiKeyResponse>, totalItems: number) =>
      set(state => ({ ...state, apiKeys, totalItems })),
    setNeedRefresh: (refreshData = true) =>
      set(state => ({ ...state, refreshData })),
    setLoading: loading => set(state => ({ ...state, loading })),
  }),
  initialState
);
