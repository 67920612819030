import { useIsReader, useKeyboardEvent } from "@Hooks";
import { t } from "i18next";
import { Spinner } from "reactstrap";
import type { ButtonProps } from "./Button.i";
import { useButtonComponent } from "./useButtonComponent";
import { useCallback, useMemo, useRef } from "react";
import ReactDomServer from "react-dom/server";
import { joinClasses } from "@Utils";
import "./Button.style.scss";

export const Button = ({
  className: inputClasses,
  hiddenForReader,
  keyAction,
  tooltip,
  ...props
}: ButtonProps) => {
  const {
    loading,
    iconClass,
    children: propsChildren,
    data,
    className,
    disabled,
    ...otherProps
  } = useButtonComponent(props);

  const { onMouseDown } = props;

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useKeyboardEvent({
    action: keyAction,
    onKeyDown: () => {
      if (buttonRef.current) {
        const event = new MouseEvent("mousedown", {
          bubbles: true,
          cancelable: true,
          view: window,
        });
        buttonRef.current.dispatchEvent(event);
      }
    },
  });

  const handleMouseDown = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!onMouseDown) {
        return;
      }

      const { button } = event;
      if (button === 0) {
        // left mouse click, avoid mouse down for other mouse buttons
        onMouseDown(event);
      }
    },
    [onMouseDown]
  );

  const tooltipProps = useMemo(() => {
    return tooltip
      ? {
          "data-tooltip-id": "tooltip",
          "data-tooltip-place": tooltip.place,
          "data-tooltip-html": ReactDomServer.renderToStaticMarkup(
            <>{tooltip.content}</>
          ),
        }
      : null;
  }, [tooltip]);

  const children = useMemo(() => {
    let result: JSX.Element | null = null;
    if (typeof propsChildren === "string") {
      result = <span>{t(propsChildren)}</span>;
    } else if (propsChildren) {
      result = <>{propsChildren}</>;
    }
    if (result && disabled && !!tooltip) {
      result = (
        <>
          {result}
          <i className="ms-2 bi bi-info-circle" />
        </>
      );
    }
    return result;
  }, [disabled, propsChildren, tooltip]);

  if (useIsReader() && hiddenForReader) {
    return null;
  }

  return (
    <button
      {...data}
      {...otherProps}
      {...tooltipProps}
      ref={buttonRef}
      onMouseDown={disabled ? undefined : handleMouseDown}
      title={
        otherProps.title || (keyAction ? t(keyAction.getTitle()) : undefined)
      }
      className={joinClasses(inputClasses, className)}
      disabled={disabled}
    >
      <span
        title={props.title}
        className={joinClasses(
          loading && "invisible",
          "text-nowrap d-flex align-items-center justify-content-center"
        )}
      >
        {!!iconClass && (
          <i className={joinClasses(iconClass, !!children && "me-2")} />
        )}
        {children}
      </span>

      {loading && <Spinner size="sm" className="loading-spinner" />}
    </button>
  );
};
