import { isFilledString, type AuthenticationSettings } from "@Services";
import { useAuthenticationSettingFormStore } from "@Stores";
import {
  useCallback,
  useMemo,
  useRef,
  useState,
  type ChangeEvent,
} from "react";
import type { Nullable } from "@Interfaces";

type AdditionalDataModel = {
  key: string;
  value: string;
};

const DEFAULT_ENTRY: AdditionalDataModel = {
  key: "",
  value: "",
};

/**
 * Credential Inputs form component. It update credentialInputs on submit
 */
export const useAdditionalDataForm = () => {
  const {
    authenticationSettings: { oAuthData },
    setAuthenticationSettings,
    loading,
  } = useAuthenticationSettingFormStore();

  const [entry, setEntry] = useState(DEFAULT_ENTRY);

  const keyInputRef = useRef<Nullable<HTMLInputElement>>(null);

  const updateEntry = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = target;
      setEntry(prev => ({ ...prev, [name]: value }));
    },
    []
  );

  const { validities, duplicateKey, valid } = useMemo(() => {
    const duplicateKey = Object.keys(oAuthData?.additionalData ?? {}).includes(
      entry.key
    );
    const validities = {
      key: !duplicateKey && isFilledString(entry.key),
      value: true,
    };
    return {
      validities,
      duplicateKey,
      valid: Object.values(validities).every(Boolean),
    };
  }, [oAuthData?.additionalData, entry.key]);

  const handleSubmit = useCallback(() => {
    setAuthenticationSettings(prev => {
      const additionalData =
        structuredClone(prev.oAuthData?.additionalData) ?? {};

      additionalData[entry.key] = entry.value;
      const sortedEntries = Object.entries(additionalData).sort(
        (entryA, entryB) => entryA[0].localeCompare(entryB[0])
      );
      return {
        ...prev,
        oAuthData: {
          ...prev.oAuthData,
          additionalData: Object.fromEntries(sortedEntries),
        },
      } as AuthenticationSettings;
    });
    setEntry(DEFAULT_ENTRY);
    keyInputRef.current?.focus();
  }, [entry, setAuthenticationSettings]);

  return {
    entry,
    updateEntry,
    validities,
    loading,
    keyInputRef,
    handleSubmit,
    valid,
    duplicateKey,
  };
};
