import { dateTimeFormat, getLicenseIconAndColor } from "@Services";
import type { DisplaySectionProps } from "@Components";
import type { License } from "@Interfaces";
import { hasCriticalValue } from "./hasCriticalValue";

type Output = Array<DisplaySectionProps>;

const orders = {
  row: [1, 4, 3, 2, 5, 6],
  column: [1, 2, 3, 4, 5, 6],
};

const critialClass = " critical-value";

export const getLabels = (license: License, column: boolean): Output => {
  const { quantity, size, startDate, endDate, name, residual } = license;
  const { color, icon } = getLicenseIconAndColor(name);
  const isMssp = name === "MSSP";
  const total = isMssp ? quantity : quantity * size;
  const order = orders[column ? "column" : "row"];

  const critical = {
    endDate: hasCriticalValue("end-date", endDate),
    available: hasCriticalValue("available-endpoints", residual),
  };

  const elements: Output = [
    {
      label: "common.start-date",
      value: dateTimeFormat(startDate, { trimTime: true }),
      className: `order-${order[0]}`,
    },
    {
      label: "common.end-date",
      value: dateTimeFormat(endDate, { trimTime: true }),
      className: `order-${order[1]}${critical.endDate ? critialClass : ""}`,
    },
  ];

  if (!isMssp) {
    elements.push({
      label: "organization.subscriptions-count",
      value: `${quantity}`,
      className: `order-${order[2]}`,
    });
  }

  return elements.concat([
    {
      label: isMssp
        ? "organization.total-tokens"
        : "organization.total-endpoints",
      value: `${total}`,
      className: `order-${order[3]}`,
    },
    {
      label: isMssp
        ? "organization.available-tokens"
        : "organization.available-endpoints",
      value: `${residual}`,
      className: `order-${order[4]}${critical.available ? critialClass : ""}`,
    },
    {
      label: "common.subscription-type",
      value: (
        <div className="d-flex align-items-center" style={{ color }}>
          <i className={`bi bi-${icon} me-2 fs-5`} />
          <span data-cy="subscription-type" className="fw-bold">
            {name}
          </span>
        </div>
      ),
      className: `order-${order[5]}`,
    },
  ]);
};
