import { useCallback, useEffect, useMemo, useState } from "react";
import { useUrlParams } from "../../../Hooks/other";
import { useManageApiResponse } from "../../../Hooks/http";
import type {
  Level as LiveLogFilterLevelValue,
  LiveLog,
  OrganizationAdminGetLiveLogsInOrganizationResponse,
  OrganizationAdminGetLiveLogsInProjectResponse,
  OrganizationAdminGetLiveLogsInScanResponse,
} from "@Services";
import { API } from "@Services";
import type { AxiosResponse } from "axios";
import { useLiveLogsStore } from "@Stores";
import { debounce } from "lodash";

type LiveLogsResponse =
  | OrganizationAdminGetLiveLogsInScanResponse
  | OrganizationAdminGetLiveLogsInProjectResponse
  | OrganizationAdminGetLiveLogsInOrganizationResponse;

type LiveLogFiltersCriterias = "live_log_level"[];
type LiveLogFiltersOperators = ("EQ" | "NEQ")[];
type LiveLogFilterLevelValues = LiveLogFilterLevelValue[];

const NO_LOGS: LiveLog = {
  level: "info",
  message: "No logs available...",
  timestamp: "",
  fields: {},
} as const;

export const useLiveLogs = (doPolling: boolean) => {
  const { loading, liveLogs, setLoading, setLiveLogs, reset } =
    useLiveLogsStore();
  const { organizationId, projectId, scanId } = useUrlParams();
  const [liveLogLevelFilter, setLiveLogLevelFilter] = useState<string>("");
  const manageResponse = useManageApiResponse(setLoading);

  const updateLiveLogs = useCallback(
    (response: AxiosResponse<LiveLogsResponse>) => {
      const { data } = response;
      if (!data.data || !data.data.length) {
        setLiveLogs([NO_LOGS]);
        return;
      }
      setLiveLogs(data.data);
    },
    [setLiveLogs]
  );

  const debouncedRefresh = useMemo(
    () =>
      debounce(async () => {
        if (!organizationId) {
          throw new Error("organization-id-empty");
        }

        const filter_criterias = liveLogLevelFilter ? ["live_log_level"] : [];
        const filter_operators = liveLogLevelFilter ? ["EQ"] : [];
        const filter_values = liveLogLevelFilter ? [liveLogLevelFilter] : [];

        if (organizationId && projectId && scanId) {
          await manageResponse({
            promise: API.auditLog().organizationAdminGetLiveLogsInScan(
              organizationId,
              projectId,
              scanId,
              filter_criterias as LiveLogFiltersCriterias,
              filter_operators as LiveLogFiltersOperators,
              filter_values as LiveLogFilterLevelValues
            ),
            onSuccess: updateLiveLogs,
          });
        } else if (organizationId && projectId) {
          await manageResponse({
            promise: API.auditLog().organizationAdminGetLiveLogsInProject(
              organizationId,
              projectId,
              filter_criterias as LiveLogFiltersCriterias,
              filter_operators as LiveLogFiltersOperators,
              filter_values as LiveLogFilterLevelValues
            ),
            onSuccess: updateLiveLogs,
          });
        } else {
          await manageResponse({
            promise: API.auditLog().organizationAdminGetLiveLogsInOrganization(
              organizationId,
              filter_criterias as LiveLogFiltersCriterias,
              filter_operators as LiveLogFiltersOperators,
              filter_values as LiveLogFilterLevelValues
            ),
            onSuccess: updateLiveLogs,
          });
        }
      }, 500),
    [
      liveLogLevelFilter,
      manageResponse,
      organizationId,
      projectId,
      scanId,
      updateLiveLogs,
    ]
  );

  const refresh = useCallback(() => {
    debouncedRefresh();
  }, [debouncedRefresh]);

  useEffect(() => {
    if (!doPolling) {
      return;
    }

    const interval = setInterval(refresh, 5_000);
    refresh();

    return () => clearInterval(interval);
  }, [doPolling, refresh, liveLogLevelFilter, reset]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    loading,
    logs: liveLogs,
    liveLogLevelFilter,
    refresh,
    setLiveLogLevelFilter,
  };
};
