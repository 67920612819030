import { createStore } from "@Hooks";
import type { SpaceLink } from "@Services";
import type { Dispatch } from "react";

export type SpaceLinkListingStoreProperties = {
  spaceLinks: Array<SpaceLink>;
  totalItems: number;
  loading: boolean;
  refreshData: boolean;
};

export type SpaceLinkListingStore = SpaceLinkListingStoreProperties & {
  setSpaceLinks: (spaceLinks: Array<SpaceLink>, totalItems: number) => void;
  setNeedRefresh: Dispatch<boolean>;
  setLoading: Dispatch<boolean>;
};

const initialState: SpaceLinkListingStoreProperties = {
  spaceLinks: [],
  refreshData: false,
  totalItems: 0,
  loading: false,
};

export const useSpaceLinkListingStore = createStore<SpaceLinkListingStore>(
  (get, set) => ({
    setSpaceLinks: (spaceLinks: Array<SpaceLink>, totalItems: number) =>
      set(state => ({ ...state, spaceLinks, totalItems })),
    setNeedRefresh: (refreshData = true) =>
      set(state => ({ ...state, refreshData })),
    setLoading: loading => set(state => ({ ...state, loading })),
  }),
  initialState
);
