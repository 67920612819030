import { JSONViewer, LoadingScreen } from "@Components";
import { memo } from "react";
import { APIDefinitionDetailsNotFound } from "../Components/APIDefinitionDetailsNotFound";
import { APIDefinitionDetailsSectionDetails } from "../Components/APIDefinitionDetailsSectionDetails";
import { useAPIDefinitionDetailsStore } from "../Stores/APIDefinitionDetails.store";
import { equals } from "@Services";
import "./APIDefinitionGeneralTab.styles.scss";

export const APIDefinitionGeneralTab = memo(() => {
  const { notFound, loading, details } = useAPIDefinitionDetailsStore();
  if (notFound) {
    return <APIDefinitionDetailsNotFound />;
  }

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div id="api-definition-general-tab">
      <APIDefinitionDetailsSectionDetails />
      <JSONViewer data={details ?? {}} devMode />
    </div>
  );
}, equals);
