import { joinClasses } from "@Utils";
import { memo, useCallback } from "react";
import { HistoryFiltersContainer, HistoryTabContent } from "./Components";
import { useDynamicFiltersControl, useGetHistory } from "./Hooks";
import { useHistoryStore } from "./Store";
import "./style.scss";

export const HistoryList = memo(() => {
  const { setFilters, fetch, defaultFilters } = useGetHistory();
  const { selectedListedAttempt } = useHistoryStore();
  const showDetails = !!selectedListedAttempt;

  useDynamicFiltersControl();

  const handleClear = useCallback(() => {
    setFilters({
      attempt_sequence_auth_profile_id: "",
      attempt_profile: "",
      attempt_method: "",
      attempt_path: "",
      attempt_scope: "",
      attempt_caller: "",
      attempt_status_code: "",
      attempt_resolved: "",
    });
  }, [setFilters]);

  return (
    <div
      id="scan-histories"
      className={joinClasses(showDetails && "details-opened")}
    >
      <HistoryFiltersContainer
        setFilters={setFilters}
        clear={handleClear}
        refresh={fetch}
      />
      <HistoryTabContent
        defaultFilters={defaultFilters}
        setFilters={setFilters}
      />
    </div>
  );
});
