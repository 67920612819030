import { type TabProps } from "@Components";
import { useState } from "react";

const TABS: Array<TabProps> = [
  {
    label: "common.details",
    iconClass: "bi bi-info-square-fill",
  },
  {
    label: "common.activity-logs",
    iconClass: "bi bi-activity",
  },
];

export const useApiKeyDetailsTab = () => {
  const [activeTab, setActiveTab] = useState<number>(0);

  return {
    tabs: TABS,
    activeTab,
    setActiveTab,
  };
};
