import { Button } from "@Components";
import { pureComponent } from "@Utils";
import { t } from "i18next";
import { memo, type FC } from "react";
import { Link } from "react-router-dom";

export const PasswordCreated: FC = memo(() => {
  return (
    <div id="password-created-view">
      <h4 className="m-0 text-center">{t("login.setup-password.success")}</h4>
      <div className="mt-5">
        <Link to="/login" className="d-grid gap-2 no-underline">
          <Button
            color="primary"
            children="login.back"
            iconClass="bi bi-arrow-left-square-fill"
            data-cy="back-to-login-button"
          />
        </Link>
      </div>
    </div>
  );
}, pureComponent);
