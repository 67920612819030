import { usePaginatedResult, useUpdateCacheFilters } from "@Hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { API, getDefaultFilters } from "@Services";
import type { ApiArgsType } from "../OrganizationUserList.i";
import { useOrganizationUserListStore } from "../Stores";

export const useOrganizationUserList = () => {
  const { users, refreshData, setUsers, setNeedRefresh } =
    useOrganizationUserListStore();
  const { response, loading, fetch } = usePaginatedResult({
    defaultFilters: useMemo(() => getDefaultFilters("users"), []),
    getResponse: useCallback(args => {
      return API.user().organizationAdminListUsersByOrganizationId(
        args.organizationId,
        args.page,
        args.pageSize,
        args.sortBy as ApiArgsType["sortBy"],
        args.sortMode,
        args.criteria as ApiArgsType["criteria"],
        args.operators as ApiArgsType["operators"],
        args.values
      );
    }, []),
  });

  // Invite user
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const updateFilters = useUpdateCacheFilters("organization.users");

  useEffect(() => {
    setUsers(response?.data ?? []);
  }, [response, setUsers]);

  useEffect(() => {
    if (refreshData) {
      fetch();
      setNeedRefresh(false);
    }
  }, [fetch, refreshData, setNeedRefresh]);

  return {
    users,
    totalItems: response?.totalItems ?? 0,
    loading,
    fetch,
    sidebarOpen,
    updateFilters,
    setSidebarOpen,
  };
};
