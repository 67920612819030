import { ComboBox, FormInput } from "@Components";
import { t } from "i18next";
import { Col, Form, Label, Row } from "reactstrap";
import type { DictionaryFormProps } from "./DictionaryForm.i";
import { useDictionaryForm } from "./useDictionaryForm";
import type { FC } from "react";
import { preventDefault } from "@Services";

const emptyStringLabel = t("common.empty-string").toLowerCase();

export const DictionaryForm: FC<DictionaryFormProps> = props => {
  const {
    entry,
    validities,
    comboBoxOptions,
    disabled,
    updateKey,
    updateValue,
    handleKeyInputBlur,
  } = useDictionaryForm(props);
  return (
    <Form id="dictionary-form" onSubmit={preventDefault}>
      <Row>
        <Col>
          <Label for="dictionary-key" children={t("common.key")} />
          <ComboBox
            id="dictionary-key"
            value={entry.key}
            bsSize="sm"
            data={comboBoxOptions}
            onChange={updateKey}
            onBlur={handleKeyInputBlur}
            valid={validities.key}
            invalid={!validities.key}
            disabled={disabled}
          />
        </Col>
        <Col>
          <FormInput
            type="text"
            label="common.value"
            id="dictionary-value"
            bsSize="sm"
            value={entry.value}
            onChange={updateValue}
            placeholder={emptyStringLabel}
            disabled={disabled}
            valid={validities.value}
            invalid={!validities.value}
            autoTrim
          />
        </Col>
      </Row>
    </Form>
  );
};
