import { BEM, joinClasses } from "@Utils";
import { Handle, Position, useUpdateNodeInternals } from "@xyflow/react";
import type { FC } from "react";
import { memo, useEffect } from "react";
import type { LocationNodeProps } from "../../AdditionalInfo.i";
import { equals } from "@Services";
import "./LocationNode.styles.scss";
// CustomNode component with types
export const LocationNode: FC<LocationNodeProps> = memo(
  ({ id: nodeId, data }) => {
    const updateNodeInternals = useUpdateNodeInternals();
    const { location } = data;

    useEffect(() => {
      updateNodeInternals(nodeId);
    }, [data, nodeId, updateNodeInternals]);

    return (
      <div id={nodeId} className={joinClasses("location-node")}>
        <Handle
          type="target"
          position={Position.Left}
          id={`handle-target-${nodeId}`}
          isConnectable={false}
        >
          <i className="bi bi-arrow-right-square-fill" />
        </Handle>
        <div className={BEM("location-node", "title")}>
          <span>{location}</span>
        </div>
        <Handle
          type="source"
          position={Position.Right}
          id={`handle-source-${nodeId}`}
          isConnectable={false}
        >
          <i className="bi bi-arrow-right-square-fill" />
        </Handle>
      </div>
    );
  },
  equals
);
