import {
  DeleteUserParameterLinkEdge,
  LoadingScreen,
  NoParameterLinks,
  type ParameterLinkEdgeData,
  ParameterLinkNode,
} from "@Components";
import { ThemeContext } from "@Contexts";
import {
  Background,
  BackgroundVariant,
  Controls,
  type Edge,
  MiniMap,
  ReactFlow,
  SelectionMode,
} from "@xyflow/react";
import { memo, useContext } from "react";
import isEqual from "react-fast-compare";
import { useParameterLinksGraph } from "../Hooks/useUserParameterLinksGraph";

const parameterLinkNodeTypes = {
  default: ParameterLinkNode,
};

const parameterLinkEdgeTypes = {
  deleteUserParameterLink: DeleteUserParameterLinkEdge,
};

export const APIDefinitionParameterLinksTab = memo(() => {
  const {
    nodes,
    edges,
    loading,
    selectedItemId,
    onNodesChange,
    onEdgesChange,
    handleNodeClick,
    handleOutsideClick,
    handleEdgeClick,
  } = useParameterLinksGraph();

  const { theme } = useContext(ThemeContext);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div
      id="api-definition-details-parameter-links-tab"
      className={selectedItemId ? "with-node-selected" : ""}
    >
      {nodes.length && edges.length ? (
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onNodeClick={handleNodeClick}
          onPaneClick={handleOutsideClick}
          onEdgeClick={(e, edge) =>
            handleEdgeClick(e, edge as Edge<ParameterLinkEdgeData>)
          }
          selectionMode={SelectionMode.Partial}
          proOptions={{ hideAttribution: true }}
          nodeTypes={parameterLinkNodeTypes}
          edgeTypes={parameterLinkEdgeTypes}
          colorMode={theme}
          panOnDrag={[0]}
          fitView
          fitViewOptions={{
            maxZoom: 1,
          }}
          snapToGrid
        >
          <MiniMap zoomStep={1} zoomable pannable />
          <Controls orientation="horizontal" />
          <Background variant={BackgroundVariant.Cross} />
        </ReactFlow>
      ) : (
        <NoParameterLinks />
      )}
    </div>
  );
}, isEqual);
