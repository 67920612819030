import type { ChangeEvent } from "react";
import { useCallback, useMemo, useRef, useState } from "react";
import type { Method } from "@Interfaces";
import { isFilledString } from "@Services";
import { useAuthenticationSettingFormStore } from "@Stores";

export const useInputOperationsTab = () => {
  const {
    authenticationSettings: { inputOperations },
    setAuthenticationSettings,
    loading,
  } = useAuthenticationSettingFormStore();

  const [path, setPath] = useState("");
  const [methods, setMethods] = useState<Method[]>([]);
  const pathInputRef = useRef<HTMLInputElement | null>(null);

  const { duplicatePath, valid, validitied } = useMemo(() => {
    const duplicatePath = inputOperations?.find(e => e.path === path);
    const validitied = {
      path: !duplicatePath && isFilledString(path),
      methods: methods.length > 0,
    };
    const valid = Object.values(validitied).every(Boolean);
    return { duplicatePath, validitied, valid };
  }, [inputOperations, methods.length, path]);

  const handlePathChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setPath(value);
    },
    []
  );

  const handleSubmit = useCallback(() => {
    setAuthenticationSettings(prev => {
      const inputOperations = structuredClone(prev.inputOperations) ?? [];
      inputOperations.push({
        methods,
        path,
      });
      setPath("");
      pathInputRef.current?.focus();
      return { ...prev, inputOperations };
    });
  }, [methods, path, setAuthenticationSettings]);

  return {
    loading,
    path,
    setPath: handlePathChange,
    handleSubmit,
    methods,
    setMethods,
    duplicatePath,
    valid,
    validitied,
    pathInputRef,
  };
};
