import { IssuesPageLayout } from "../IssuesPageLayout";
import { useOpenIssues } from "./Hooks";

export const OpenIssues = () => {
  const { fetch, loading, response, setFilters } = useOpenIssues();
  return (
    <IssuesPageLayout
      fetch={fetch}
      loading={loading}
      response={response}
      setFilters={setFilters}
      issueListingType="open"
    />
  );
};
