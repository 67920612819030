import { isFilledString } from "@Services";
import { useAuthenticationSettingFormStore } from "@Stores";
import type { ChangeEvent } from "react";
import { useCallback, useMemo, useRef, useState } from "react";

type CredentialInputModel = {
  key: string;
  value: string;
};

const DEFAULT_ENTRY: CredentialInputModel = {
  key: "",
  value: "",
};

export const useCredentialInputsTab = () => {
  const {
    authenticationSettings: { credentialInputs },
    setAuthenticationSettings,
    loading,
  } = useAuthenticationSettingFormStore();

  const [entry, setEntry] = useState(DEFAULT_ENTRY);
  const nameInputRef = useRef<HTMLInputElement | null>(null);

  const updateEntry = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      setEntry(prev => ({ ...prev, [name]: value }));
    },
    []
  );

  const updateAuthenticationSettings = useCallback(() => {
    setAuthenticationSettings(prev => {
      const credentialInputs = structuredClone(prev.credentialInputs) ?? {};
      credentialInputs[entry.key] = entry.value;
      const sortedEntries = Object.entries(credentialInputs).sort(
        (entryA, entryB) => entryA[0].localeCompare(entryB[0])
      );
      return {
        ...prev,
        credentialInputs: Object.fromEntries(sortedEntries),
      };
    });
  }, [entry, setAuthenticationSettings]);

  const { validities, duplicateKey, valid } = useMemo(() => {
    const duplicateKey = Object.keys(credentialInputs ?? []).includes(
      entry.key
    );
    const validities = {
      key: !duplicateKey && isFilledString(entry.key),
      value: true,
    };
    const valid = Object.values(validities).every(Boolean);
    return { validities, valid, duplicateKey };
  }, [credentialInputs, entry.key]);

  const credentialInputFormSubmit = useCallback(() => {
    if (!valid) return;
    updateAuthenticationSettings();
    setEntry({ ...DEFAULT_ENTRY });
    nameInputRef.current?.focus();
  }, [updateAuthenticationSettings, valid]);

  return {
    entry,
    loading,
    validities,
    valid,
    duplicateKey,
    credentialInputFormSubmit,
    updateEntry,
    nameInputRef,
  };
};
