import { memo, useCallback, useMemo, type FC } from "react";
import {
  Button,
  DisplayTable,
  FormInput,
  FormMessage,
  MethodBadge,
  MethodsComboBox,
  TrashButton,
} from "@Components";
import type { Method } from "@Interfaces";
import { t } from "i18next";
import { Form, FormGroup } from "reactstrap";
import { useAuthenticationSettingFormStore } from "@Stores";
import { useInputOperationsTab } from "./useInputOperationsTab";
import { preventDefault } from "@Services";

const ALLOWED_METHODS: Method[] = ["GET", "POST", "PUT"];

const InputOperationsForm: FC = memo(() => {
  const {
    duplicatePath,
    loading,
    methods,
    path,
    valid,
    validitied,
    pathInputRef,
    handleSubmit,
    setMethods,
    setPath,
  } = useInputOperationsTab();

  return (
    <>
      <Form id="input-operations-form" onSubmit={preventDefault}>
        <FormInput
          id="operation-path"
          data-cy="authentication-settings-input-operation-path"
          label="common.path"
          type="text"
          bsSize="sm"
          value={path}
          valid={validitied.path}
          invalid={!validitied.path}
          onChange={setPath}
          disabled={loading}
          innerRef={pathInputRef}
          autoTrim
        />
        <div>
          <span className="input-label">{t("common.methods")}</span>
          <MethodsComboBox
            activeMethods={methods}
            setActiveMethods={setMethods}
            methods={ALLOWED_METHODS}
            addAll={false}
            disabled={loading}
          />
        </div>
        <Button
          color="primary"
          size="sm"
          data-cy="authentication-settings-input-operations-button"
          onClick={handleSubmit}
          children="common.add"
          iconClass="bi bi-plus-square-fill"
          disabled={!valid || loading}
          type="submit"
        />
      </Form>
      {duplicatePath && (
        <FormMessage
          color="danger"
          message={t("validation.x-already-in-use", { x: t("common.path") })}
        />
      )}
    </>
  );
});

/**
 * Input Operations component. Contains Form and Showcase
 */
export const InputOperationsTab: FC = memo(() => {
  const {
    authenticationSettings: { inputOperations },
    setAuthenticationSettings,
    loading,
  } = useAuthenticationSettingFormStore();

  const length = (inputOperations ?? []).length;

  const deleteEntry = useCallback(
    (i: number) => {
      setAuthenticationSettings(prev => {
        const newInputOperations = [...(prev.inputOperations ?? [])];
        newInputOperations.splice(i, 1);
        return { ...prev, inputOperations: newInputOperations };
      });
    },
    [setAuthenticationSettings]
  );

  const table = useMemo(() => {
    if (!length) return null;
    return (
      <DisplayTable>
        <thead>
          <tr>
            <th></th>
            <th>{t("common.path")}</th>
            <th>{t("common.methods")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {inputOperations?.map(({ path, methods }, i) => {
            return (
              <tr key={`${i}-${path}`}>
                <td>{i + 1}.</td>
                <td>{path}</td>
                <td>
                  <div>
                    {(methods as Method[]).map(m => (
                      <MethodBadge type={m} className="ms-1" key={m} />
                    ))}
                  </div>
                </td>
                <td className="text-end">
                  <TrashButton
                    onClick={() => deleteEntry(i)}
                    disabled={loading}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </DisplayTable>
    );
  }, [deleteEntry, inputOperations, length, loading]);

  return (
    <>
      <FormGroup noMargin={length === 0}>
        <InputOperationsForm />
      </FormGroup>
      {table}
    </>
  );
});
