export const findIdFromRestUrl = (pathname: string, needle: string) => {
  const splitted = pathname.split("/");
  const index = splitted.indexOf(needle);

  return index < 0 ? undefined : splitted[index + 1]?.split("?")[0];
};

export const findIdFromRestParameters = (pathname: string, needle: string) => {
  const queryStringIndex = pathname.indexOf("?");
  if (queryStringIndex >= 0) {
    const queryString = pathname.substring(queryStringIndex);
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get(needle);
    return id || undefined;
  }
  return undefined;
};

export const findOrganizationIdFromUrl = (pathname: string) => {
  return findIdFromRestUrl(pathname, "organizations");
};

export const findUserIdFromUrl = (pathname: string) => {
  return (
    findIdFromRestUrl(pathname, "users") ??
    findIdFromRestParameters(pathname, "isp_userId")
  );
};

export const findProjectIdFromUrl = (pathname: string) => {
  return (
    findIdFromRestUrl(pathname, "projects") ??
    findIdFromRestUrl(pathname, "project")
  );
};

export const findIssueIdFromUrl = (pathname: string) => {
  return (
    findIdFromRestUrl(pathname, "issues") ??
    findIdFromRestParameters(pathname, "isp_issueId")
  );
};

export const findHistoryIdFromUrl = (pathname: string) => {
  return findIdFromRestUrl(pathname, "history");
};

export const findApiDefinitionFromUrl = (pathname: string) => {
  return findIdFromRestUrl(pathname, "api-definitions");
};

export const findScanIdFromUrl = (pathname: string) => {
  return findIdFromRestUrl(pathname, "scans");
};

export const findApiKeyIdFromUrl = (pathname: string) => {
  return (
    findIdFromRestUrl(pathname, "apikeys") ??
    findIdFromRestParameters(pathname, "isp_apiKeyId")
  );
};

export const findInventoryFromUrl = (pathname: string) => {
  return findIdFromRestUrl(pathname, "inventory");
};

export const findSpaceLinkFromUrl = (pathname: string) => {
  return (
    findIdFromRestUrl(pathname, "spacelinks") ??
    findIdFromRestParameters(pathname, "isp_spaceLinkId")
  );
};
