import { Tabs, Offcanvas } from "@Components";
import { memo, useMemo } from "react";
import { Card } from "reactstrap";
import { OffCanvasAddParameterLink } from "./Components/OffCanvasAddParameterLink/OffCanvasAddParameterLink";
import { useAPIDefinitionDetails } from "./Hooks/useAPIDefinitionDetails";
import { useAPIDefinitionDetailsTabs } from "./Hooks/useAPIDefinitionDetailsTabs";
import "./APIDefinitionDetails.style.scss";
import {
  APIDefinitionGeneralTab,
  APIDefinitionParameterLinksTab,
  APIDefinitionParametersTab,
} from "./Tabs";
import { useAPIDefinitionDetailsCanvasButtons } from "./Hooks";

export const APIDefinitionDetails = memo(() => {
  const { activeTab, setActiveTab, tabs } = useAPIDefinitionDetailsTabs();
  const { handleClose } = useAPIDefinitionDetails();
  const {
    canvasButtons,
    openAddParameterLink,
    handleAddParametersLinkCanvasClose,
  } = useAPIDefinitionDetailsCanvasButtons();

  const content = useMemo(() => {
    switch (activeTab) {
      case 1:
        return <APIDefinitionParametersTab />;
      case 2:
        return <APIDefinitionParameterLinksTab />;
      default:
        return <APIDefinitionGeneralTab />;
    }
  }, [activeTab]);

  return (
    <Offcanvas
      id="api-definition-details-canvas"
      data-cy="api-definition-details-canvas"
      isOpen={true}
      title="common.details"
      className="full-height unstiled"
      toggle={handleClose}
      size="lg"
      buttons={canvasButtons}
      noPadding
    >
      <>
        <div id="api-definition-details">
          <Tabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabs={tabs}
            updateUrl={true}
          />
          <Card className="border-tl-0 w-100 h-100">{content}</Card>
        </div>

        {openAddParameterLink && (
          <OffCanvasAddParameterLink
            onClose={handleAddParametersLinkCanvasClose}
          />
        )}
      </>
    </Offcanvas>
  );
});
