import type { ChangeEvent } from "react";
import { useCallback } from "react";
import type { AdvancedSettingsFormProps } from "./AdvancedSettingsForm.i";
import { type AdvancedSettings } from "@Services";
import { NANO_IN_MINUTE, NANO_IN_SECOND } from "@Utils";
import { getParsedInputValue } from "./functions";

type KeyOfAdvancedSettings = keyof AdvancedSettings;

export const useAdvancedSettingsForm = ({
  advancedSettings,
  setAdvancedSettings,
}: AdvancedSettingsFormProps) => {
  const updateModel = useCallback(
    ({ target: { name, value, checked } }: ChangeEvent<HTMLInputElement>) => {
      switch (name as KeyOfAdvancedSettings) {
        // timeouts (seconds)
        case "clientTimeout":
        case "requestThrottle":
          return setAdvancedSettings?.({
            ...advancedSettings,
            [name]: parseInt(value) * NANO_IN_SECOND,
          });
        // timeouts (minutes)
        case "explorationPhaseTimeout":
        case "exploitationPhaseTimeout":
        case "providerMaxScanDuration":
        case "authRefreshInterval":
          return setAdvancedSettings?.({
            ...advancedSettings,
            [name]: parseInt(value) * NANO_IN_MINUTE,
          });
        // plain numbers
        case "exitOnCoverage":
        case "exitOnReachability":
        case "maxIteration":
        case "maxRenderAttempts":
        case "requestJitterPercentage":
          return setAdvancedSettings?.({
            ...advancedSettings,
            [name]: parseInt(value),
          });
        // plain text
        case "proxyUrl":
        case "userAgent":
        case "providerAttackStrength":
          return setAdvancedSettings?.({
            ...advancedSettings,
            [name]: value,
          });
        // checkboxes
        case "disableCompression":
        case "disableKeepAlives":
        case "enableDebugHeaders":
        case "exitOnFullCoverage":
        case "followRedirect":
        case "insecureSkipVerify":
        case "anonymousExploration":
        case "exploitOnlyResolvedNodes":
        case "includeAuthProfileOnlyForResolved":
        case "includeParametersInHash":
          return setAdvancedSettings?.({
            ...advancedSettings,
            [name]: checked,
          });
        default:
          return alert(`Unknown key ${name}`);
      }
    },
    [advancedSettings, setAdvancedSettings]
  );

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();

      try {
        const boundedValue = getParsedInputValue(e);
        e.target.value = boundedValue;
        updateModel(e);
      } catch (e) {
        // do nothings
      }
    },
    [updateModel]
  );

  const {
    clientTimeout,
    exploitationPhaseTimeout,
    explorationPhaseTimeout,
    requestThrottle,
    providerMaxScanDuration,
    authRefreshInterval,
  } = advancedSettings;

  const values: typeof advancedSettings = {
    ...advancedSettings,
    explorationPhaseTimeout: explorationPhaseTimeout / NANO_IN_MINUTE,
    exploitationPhaseTimeout: exploitationPhaseTimeout / NANO_IN_MINUTE,
    providerMaxScanDuration: providerMaxScanDuration / NANO_IN_MINUTE,
    authRefreshInterval: authRefreshInterval / NANO_IN_MINUTE,
    requestThrottle: requestThrottle / NANO_IN_SECOND,
    clientTimeout: clientTimeout / NANO_IN_SECOND,
  };

  return { values, onChange: handleOnChange };
};
