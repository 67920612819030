const defaultValues: Record<string, string> = {
  period: "last_30_days",
  pageSize: "12",
};

const defaultGroups = {
  scans: {
    sortBy: "scan_started_at",
    sortMode: "desc",
  },
  projects: {
    sortBy: "project_name",
    sortMode: "asc",
  },
  users: {
    sortBy: "user_surname_name",
    sortMode: "asc",
  },
  authMatrix: {
    sortBy: "auth_matrix_path",
    sortMode: "desc",
  },
  apiDefinitions: {
    sortBy: "inventory_item_path",
    sortMode: "asc",
  },
  issues: {
    sortBy: "issue_path",
    sortMode: "asc",
  },
  apikeys: {
    sortBy: "api_key_created_at",
    sortMode: "desc",
  },
  apidefinitions: {
    sortBy: "api_definition_created_at",
    sortMode: "desc",
  },
  history: {
    sortBy: "attempt_index",
    sortMode: "asc",
  },
  nodes: {
    sortBy: "node_path",
    sortMode: "desc",
  },
  "profile-roles": {
    sortBy: "role_value",
    sortMode: "desc",
  },
  "organization-user-audit-logs": {
    sortBy: "audit_log_created_at",
    sortMode: "desc",
  },
  "organization-apikeys-audit-logs": {
    sortBy: "audit_log_created_at",
    sortMode: "desc",
  },
};

export const getDefaultFilters = (k: keyof typeof defaultGroups) => {
  return defaultGroups[k];
};

export const getDefaultFilterValue = (k: keyof typeof defaultValues) => {
  return defaultValues[k] ?? "";
};
