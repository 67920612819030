import { t } from "i18next";
import { Spinner } from "reactstrap";

export const DashboardLoader = () => {
  return (
    <section className="flex-center w-100 h-100">
      <Spinner />
      <h1 className="my-0 ms-3">{t("common.loading-dashboard-content")}</h1>
    </section>
  );
};
