import type { UpdateProjectData } from "@Interfaces";
import type { PathExclusionEntry, Project } from "@Services";
import { projectDataToUpdateProjectData } from "@Services";

type Args = {
  project: Project;
  update: (project: UpdateProjectData) => Promise<boolean>;
  action: "add" | "remove";
} & (
  | {
      action: "add";
      entry: PathExclusionEntry;
    }
  | {
      action: "remove";
      index: number;
    }
);

const updateEntries = (args: Args) => {
  const { project, update, action } = args;
  const pathExclusions = project.pathExclusions.slice();

  if (action === "add") {
    const { entry } = args;
    pathExclusions.unshift({ ...entry, isMatch: false });
  }

  if (action === "remove") {
    const { index } = args;
    pathExclusions.splice(index, 1);
  }

  const payload = projectDataToUpdateProjectData({
    ...project,
    pathExclusions,
  });

  return update(payload);
};

export default updateEntries;
