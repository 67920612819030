import { CYPRESS_RUNNING } from "@Constants";
import { getCodeMirrorTheme } from "@Interfaces";
import { equals, isLocalHost } from "@Services";
import { useUIStore } from "@Stores";
import { langs } from "@uiw/codemirror-extensions-langs";
import type { ViewUpdate } from "@uiw/react-codemirror";
import ReactCodeMirror, { EditorView } from "@uiw/react-codemirror";
import { debounce } from "lodash";
import type { Dispatch, FC } from "react";
import { memo, useCallback, useMemo } from "react";

type JSONViewerProps = {
  data: object | undefined;
  devMode?: boolean;
  onChange?: Dispatch<object>;
};
export const JSONViewer: FC<JSONViewerProps> = memo(
  ({ data, devMode, onChange }) => {
    const hidden = !data || (devMode && (!isLocalHost() || CYPRESS_RUNNING));
    const { codeTheme } = useUIStore();

    const delayedHandleChange = useMemo(
      () =>
        debounce((value: string, view: ViewUpdate) => {
          try {
            const parsedValue = JSON.parse(value);
            onChange?.(parsedValue);
          } catch (ex) {}
        }, 1_000),
      [onChange]
    );
    const handleChange = useCallback(
      (value: string, view: ViewUpdate) => {
        delayedHandleChange(value, view);
      },
      [delayedHandleChange]
    );

    if (hidden) {
      return null;
    }

    return (
      <ReactCodeMirror
        height="600px"
        className="mt-4"
        value={JSON.stringify(data, null, 2)}
        extensions={[langs.json(), EditorView.lineWrapping]}
        theme={getCodeMirrorTheme(codeTheme)}
        onChange={handleChange}
      />
    );
  },
  equals
);
