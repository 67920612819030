import { BEM, joinClasses } from "@Utils";
import type { GridItemProps } from "../../GridContainer.i";
import type { CSSProperties } from "react";

export const GridItem = ({
  className: propsClassName,
  children,
  columnEnd: gridColumnEnd,
  columnStart: gridColumnStart,
  rowEnd: gridRowEnd,
  rowStart: gridRowStart,
}: GridItemProps) => {
  const className = joinClasses(propsClassName, BEM("grid-container", "item"));

  const style: CSSProperties = {
    gridColumnStart,
    gridColumnEnd,
    gridRowStart,
    gridRowEnd,
  };

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};
