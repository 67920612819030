import { AuthContext } from "@Contexts";
import { useOrganizationContext } from "@Hooks";
import { checkRBACCondition, parseCondition } from "@Utils";
import { useContext, useMemo } from "react";

export const useRbac = (condition: string | undefined) => {
  const { role } = useContext(AuthContext);
  const { license } = useOrganizationContext();
  const { advancedSettings } = useOrganizationContext();

  const canAccess = useMemo(
    () =>
      condition
        ? checkRBACCondition(
            parseCondition(condition),
            role ? [role.role] : [],
            license.name,
            advancedSettings.enabled
          )
        : true,
    [advancedSettings.enabled, condition, license.name, role]
  );

  if (license.loading) {
    return { canAccess: false, loading: true };
  }

  return { canAccess, loading: false };
};
