import { useManageApiResponse, useUrlParams } from "@Hooks";
import type { InventoryDetail } from "@Interfaces";
import { API } from "@Services";
import { useCallback, useEffect } from "react";
import { decodeIntentoryDetailItem } from "../functions";
import { useAPIDefinitionDetailsStore } from "../Stores/APIDefinitionDetails.store";
import { useNavigate } from "react-router-dom";
import { generateUrl } from "@Utils";
import {
  PROJECT_DETAILS_API_DEFINITIONS_INVENTORY_PATH,
  PROJECT_DETAILS_SCAN_URL,
} from "@Constants";

export const useAPIDefinitionDetails = () => {
  const {
    projectId,
    scanId,
    inventoryItemId,
    organizationId,
    apiDefinitionId,
  } = useUrlParams();
  const {
    details,
    notFound,
    reset: resetAPIDefinitionDetailsStore,
    setDetails,
    setLoading,
    setIsLatestAPIDefinition,
  } = useAPIDefinitionDetailsStore();
  const navigate = useNavigate();

  const manageResponse = useManageApiResponse();

  const fetchLatestInventoryItem = useCallback(async () => {
    if (!organizationId || !projectId) {
      return;
    }
    await manageResponse({
      promise: API.apiDefinitions().apiDefinitionListInProject(
        organizationId,
        projectId,
        1,
        1,
        "api_definition_created_at",
        "desc",
        undefined,
        [],
        []
      ),
      onSuccess: response => {
        const content = response.data;
        setIsLatestAPIDefinition(content.data[0]?.id === apiDefinitionId);
      },
      onError: () => {
        setIsLatestAPIDefinition(false);
      },
    });
  }, [
    apiDefinitionId,
    manageResponse,
    organizationId,
    projectId,
    setIsLatestAPIDefinition,
  ]);

  const fetchInventoryItem = useCallback(async () => {
    if (!organizationId || !projectId || !inventoryItemId) {
      return;
    }
    await manageResponse({
      promise: API.inventory().inventoryGet(
        organizationId,
        projectId,
        inventoryItemId
      ),
      onSuccess: response => {
        const content = response.data as InventoryDetail;
        const decodedContent = decodeIntentoryDetailItem(content);
        setDetails(decodedContent);
      },
      onError: () => {
        setDetails(null);
      },
    });
  }, [inventoryItemId, manageResponse, organizationId, projectId, setDetails]);

  const fetchAll = useCallback(async () => {
    try {
      setLoading(true);
      await Promise.all([fetchLatestInventoryItem(), fetchInventoryItem()]);
    } finally {
      setLoading(false);
    }
  }, [fetchInventoryItem, fetchLatestInventoryItem, setLoading]);

  const handleClose = useCallback(() => {
    const url = scanId
      ? generateUrl(
          PROJECT_DETAILS_SCAN_URL,
          [organizationId ?? "", projectId ?? "", scanId ?? ""],
          true
        )
      : generateUrl(
          PROJECT_DETAILS_API_DEFINITIONS_INVENTORY_PATH,
          [organizationId ?? "", projectId ?? "", apiDefinitionId ?? ""],
          true
        );
    navigate(url);
  }, [apiDefinitionId, navigate, organizationId, projectId, scanId]);

  useEffect(() => {
    return () => resetAPIDefinitionDetailsStore();
  }, [resetAPIDefinitionDetailsStore]);

  useEffect(() => {
    fetchAll();
  }, [fetchAll]);

  return { details, notFound, handleClose };
};
