import { useRbac } from "@Hooks";
import { type ComponentType } from "react";

export function withRbac<WrappedComponentProps extends object>(
  condition: string,
  WrappedComponent: ComponentType<WrappedComponentProps>
): ComponentType<WrappedComponentProps> {
  return (props: WrappedComponentProps) => {
    const { canAccess } = useRbac(condition);
    return canAccess ? <WrappedComponent {...props} /> : null;
  };
}
