import { joinClasses } from "@Utils";
import type { FC } from "react";
import "./PageFiltersWrapper.style.scss";
import { preventDefault } from "@Services";
import type { InjectableContent } from "@Interfaces";

interface PageFilterWrapper {
  children: InjectableContent;
  className?: string;
}

export const PageFiltersWrapper: FC<PageFilterWrapper> = ({
  children,
  className,
}) => (
  <form
    className={joinClasses("filters-form", className)}
    onSubmit={preventDefault}
    children={children}
  />
);
