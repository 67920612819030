import { useHasAnyItemsGuard, usePaginatedResult, useUrlParams } from "@Hooks";
import { API, getDefaultFilters } from "@Services";
import { useCallback, useEffect, useMemo } from "react";
import type { Criteria, Operators, SortBy } from "../ApiKeyListing.i";
import { useApiKeyListStore } from "../Stores";

export const useApiKeyListing = () => {
  const { organizationId } = useUrlParams();
  const { refreshData, totalItems, setApiKeys, setNeedRefresh, setLoading } =
    useApiKeyListStore();

  const defaultFilters = useMemo(() => getDefaultFilters("apikeys"), []);

  const { response, loading, fetch } = usePaginatedResult({
    defaultFilters,
    getResponse: useCallback(args => {
      return API.apiKeys().organizationAdminListApiKeysByOrganizationId(
        args.organizationId,
        args.page,
        args.pageSize,
        args.sortBy as SortBy,
        args.sortMode,
        args.criteria as Criteria,
        args.operators as Operators,
        args.values
      );
    }, []),
  });

  const { fetchingHasAnyItem, hasAnyItem } = useHasAnyItemsGuard({
    fetchCondition: Boolean(response && response.totalItems === 0),
    getResponse: useCallback(() => {
      if (!organizationId) throw new Error("Missing Organization ID");
      return API.apiKeys().organizationAdminListApiKeysByOrganizationId(
        organizationId,
        1,
        1
      );
    }, [organizationId]),
  });

  useEffect(() => {
    setApiKeys(response?.data ?? [], response?.totalItems ?? 0);
  }, [response, setApiKeys]);

  useEffect(() => {
    if (refreshData) {
      fetch();
      setNeedRefresh(false);
    }
  }, [fetch, refreshData, setNeedRefresh]);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return {
    response,
    showCurtesyPage: !totalItems && hasAnyItem === false,
    loading: loading || fetchingHasAnyItem,
    fetch,
  };
};
