export const parsifyHttpRequestResponse = (value: string) => {
  const splittedByLines = value.split("\r\n\r\n");
  const jsonParsedStrings = splittedByLines.map(str => {
    try {
      const asJson = JSON.parse(str);
      const asJsonString = JSON.stringify(asJson, null, 2);
      return asJsonString;
    } catch (e) {
      return str;
    }
  });
  return jsonParsedStrings.join("\r\n\r\n");
};
