import { useValidDomain } from "@Hooks";
import type { Options } from "@Interfaces";
import { clamp, isFilledString } from "@Services";
import { useAuthenticationSettingFormStore } from "@Stores";
import { NANO_IN_MINUTE, NANO_IN_SECOND, THIRTY_DAYS_IN_MINUTES } from "@Utils";
import { set } from "lodash";
import type { ChangeEvent } from "react";
import { useCallback, useEffect, useMemo } from "react";
import { schemes } from "../../../../constants";

const schemeOptions: Options = schemes.map(scheme => ({
  label: scheme.toUpperCase(),
  value: scheme,
}));

const getFormattedRefreshInterval = (value: string) => {
  if (!value || isNaN(+value) || +value <= 0) return undefined;
  const min = NANO_IN_MINUTE;
  const max = THIRTY_DAYS_IN_MINUTES * NANO_IN_MINUTE;
  return clamp(+value * NANO_IN_MINUTE, min, max);
};

export const useStaticTokenForm = () => {
  const {
    loading,
    authenticationSettings,
    setSubFormValidity,
    setAuthenticationSettings,
  } = useAuthenticationSettingFormStore();

  const { isDomainEnabled, enabledDomains } = useValidDomain();

  const handleChange = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      const nextValue = name.endsWith("refreshInterval")
        ? getFormattedRefreshInterval(value)
        : value;

      setAuthenticationSettings(prev => set(prev, name, nextValue));
    },
    [setAuthenticationSettings]
  );

  const { validities, valid, refreshDefinitionValid } = useMemo(() => {
    const userAuthTokens =
      (authenticationSettings.userAuthTokens ?? []).length > 0;

    let refreshDefinitionValid = true;
    let refreshDefinitionValidities: { [key: string]: boolean } = {};

    if (authenticationSettings.refreshDefinition?.type) {
      refreshDefinitionValidities = {
        rdRefreshInterval:
          !authenticationSettings.refreshDefinition.refreshInterval ||
          authenticationSettings.refreshDefinition.refreshInterval >=
            NANO_IN_SECOND,
      };

      if (authenticationSettings.refreshDefinition.type === "internal") {
        refreshDefinitionValidities = {
          ...refreshDefinitionValidities,
          rDOperationPath: isFilledString(
            authenticationSettings.refreshDefinition?.operation?.path
          ),
          rDOperationMethods:
            !!authenticationSettings.refreshDefinition?.operation?.methods
              ?.length,
        };
      } else {
        refreshDefinitionValidities = {
          ...refreshDefinitionValidities,
          tokenEndpoint:
            !!authenticationSettings.refreshDefinition?.oAuthData
              ?.tokenEndpoint &&
            isDomainEnabled(
              authenticationSettings.refreshDefinition?.oAuthData?.tokenEndpoint
            ),
          rDRefreshToken: isFilledString(
            authenticationSettings.refreshDefinition?.oAuthData?.refreshToken
          ),
        };
      }

      refreshDefinitionValid = Object.values(refreshDefinitionValidities).every(
        Boolean
      );
    }

    const combinedValidities = {
      userAuthTokens,
      ...refreshDefinitionValidities,
    };

    const valid = Object.values(combinedValidities).every(Boolean);

    return { validities: combinedValidities, valid, refreshDefinitionValid };
  }, [authenticationSettings, isDomainEnabled]);

  useEffect(() => {
    setSubFormValidity("flow", valid);
  }, [setSubFormValidity, valid]);

  return {
    authenticationSettings,
    refreshDefinitionValid,
    enabledDomains,
    schemeOptions,
    validities,
    loading,
    handleChange,
  };
};
