import { useToast, useUrlParams } from "@Hooks";
import type { OrganizationId, ProjectId, ScanId } from "@Interfaces";
import type {
  IssueExclusion,
  AuthenticationSettings,
  DictionaryEntry,
  Injectable,
  MTLSCertificate,
  PathExclusionEntry,
  Category,
} from "@Services";
import { API } from "@Services";
import { useCallback, useEffect, useState } from "react";
import { useSettingsStore } from "../Store/SettingsStore";

export const useScanDetailsSettings = () => {
  const toast = useToast();
  const [error, setError] = useState(false);
  const { projectId, scanId, organizationId } = useUrlParams();
  const {
    dataLoaded,
    setDataLoaded,
    setLoading,
    setUriParameters,
    setAuthenticationSettings,
    setDictionarySettings,
    setInjectableParametersSettings,
    setIssueExclusionSettings,
    setPathExclusionSettings,
    setMTSLCertificateSettings,
    setExtensionSettings,
  } = useSettingsStore();

  useEffect(
    () => setUriParameters(organizationId, projectId, scanId),
    [organizationId, projectId, scanId, setUriParameters]
  );

  const fetch = useCallback(async () => {
    setError(false);
    if (!organizationId || !projectId || !scanId) {
      return;
    }
    setLoading(true);
    const responses = await fetchSettings(organizationId, projectId, scanId);
    setLoading(false);

    if (responses.some(r => r.status !== "fulfilled")) {
      toast({ message: "errors.retrieving-data" });
      setError(true);
      return;
    }

    // handle all successfull responses
    setDataLoaded(true);

    setDictionarySettings(
      (responses[1] as PromiseFulfilledResult<DictionaryEntry[]>).value
    );
    setInjectableParametersSettings(
      (responses[2] as PromiseFulfilledResult<Injectable[]>).value
    );
    setIssueExclusionSettings(
      (responses[3] as PromiseFulfilledResult<IssueExclusion[]>).value
    );
    setPathExclusionSettings(
      (responses[4] as PromiseFulfilledResult<PathExclusionEntry[]>).value
    );
    setMTSLCertificateSettings(
      (responses[5] as PromiseFulfilledResult<MTLSCertificate[]>).value
    );
    setExtensionSettings(
      (responses[6] as PromiseFulfilledResult<Category[]>).value
    );

    // place auth settings at last due to the fact that we need to have injectables and mtls
    // saved in the store
    setAuthenticationSettings(
      (responses[0] as PromiseFulfilledResult<AuthenticationSettings[]>).value
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, projectId, scanId]);

  useEffect(() => {
    if (!dataLoaded) {
      fetch();
    }
  }, [dataLoaded, fetch]);

  return { error };
};

function fetchSettings(
  organizationId: OrganizationId,
  projectId: ProjectId,
  scanId: ScanId
) {
  const args: [string, string, string] = [organizationId, projectId, scanId];
  const api = API.scanSettings();
  return Promise.allSettled([
    api.listScanAuthenticationSettings(...args).then(r => r.data.data),
    api.listScanDictionary(...args).then(r => r.data.data),
    api.listScanInjectables(...args).then(r => r.data.data),
    api.listScanIssueExclusions(...args).then(r => r.data.data),
    api.listScanPathExclusions(...args).then(r => r.data.data),
    api.listScanMtlsCertificates(...args).then(r => r.data.data),
    api.getScanExtensionSettings(...args).then(r => {
      return r.data.extensionSettings.enabledCategories;
    }),
  ]);
}
