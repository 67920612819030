import { ProjectScanDetailsBreadcrumb } from "@Components";
import { APIDefinitionDetails, ScanDetails } from "@Pages";
import { ProjectScans } from "src/Pages/Project/Components";

export const ProjectScanRoutes = [
  {
    path: "", // scans
    element: <ProjectScans />,
  },
  {
    path: ":scanId",
    element: <ScanDetails />,
    handle: {
      crumb: <ProjectScanDetailsBreadcrumb disabled />,
    },
    children: [
      {
        path: "inventory/:inventoryId",
        element: <APIDefinitionDetails />,
      },
    ],
  },
];
