import { ThemeContext } from "@Contexts";
import { t } from "i18next";
import { useCallback, useContext, useEffect } from "react";
import { Slide, toast } from "react-toastify";

type ToastType = "error" | "success";

type CustomToastArguments = {
  type?: ToastType;
  message: string;
};

export const useToast = () => {
  const { theme } = useContext(ThemeContext);

  const customToastFunction = useCallback(
    (args: CustomToastArguments) => {
      const type = getType(args);
      toast(t(args.message), {
        type,
        theme,
        autoClose: type === "success" ? 2000 : 5000,
        position: "bottom-center",
        transition: Slide,
        closeOnClick: true,
        draggable: true,
      });
    },
    [theme]
  );

  useEffect(() => {
    const toasts = document.querySelectorAll(".Toastify .Toastify__toast");
    toasts.forEach(({ classList }) => {
      const oppositeTheme = `Toastify__toast-theme--${theme === "dark" ? "light" : "dark"}`;
      const actualTheme = `Toastify__toast-theme--${theme}`;

      if (classList.contains(oppositeTheme)) {
        classList.remove(oppositeTheme);
      }
      if (!classList.contains(actualTheme)) {
        classList.add(actualTheme);
      }
    });
  }, [theme]);

  return customToastFunction;
};

function getType({ message, type }: CustomToastArguments): ToastType {
  if (type) return type;
  return message?.startsWith("successes.") ? "success" : "error";
}
