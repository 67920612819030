import { useOrganizationApiKey } from "@Hooks";
import type { ApiKey } from "@Interfaces";
import { useCallback, useEffect } from "react";
import { useApiKeyDetailsCanvasStore } from "../../../../../Stores";
import { getApiKeyExtraData } from "@Services";

export const useApiKeyDetail = () => {
  const {
    editCanvasOpen,
    apiKey: storeApiKey,
    setApiKey,
    setEditCanvasOpen,
  } = useApiKeyDetailsCanvasStore();
  const { isExpired } = getApiKeyExtraData(storeApiKey);

  const {
    apiKey,
    loading: loadingApiKey,
    actions: { update },
    notFound,
  } = useOrganizationApiKey();

  const handleUpdate = useCallback(
    async (apiKey: ApiKey) => {
      const success = await update({
        active: apiKey.active,
        role: apiKey.role,
      });

      if (success) {
        setApiKey({ ...apiKey });
        setEditCanvasOpen(false);
      }

      return success;
    },
    [setApiKey, setEditCanvasOpen, update]
  );

  useEffect(() => {
    setApiKey(apiKey as ApiKey);
  }, [apiKey, setApiKey]);

  return {
    notFound,
    isExpired,
    editCanvasOpen,
    apiKey: storeApiKey,
    apiKeyLoading: loadingApiKey,
    handleUpdate,
    setEditCanvasOpen,
  };
};
