import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import type { ComponentButtonProps } from "@Components";
import { useOrganizationUserDetailsCanvasStore } from "../Stores";
import { generateUrl } from "@Utils";
import { ORGANIZATION_PATH_USERS } from "@Constants";
import { useUrlParams } from "@Hooks";

export const useOrganizationUserDetailsCanvas = () => {
  const { setOpen: setOrganizationUserDetailsCanvasOpen } =
    useOrganizationUserDetailsCanvasStore();
  const { organizationId } = useUrlParams();
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    if (!organizationId) {
      return;
    }
    navigate(generateUrl(ORGANIZATION_PATH_USERS, [organizationId], true));
  }, [navigate, organizationId]);

  const canvasButtons = useMemo(
    (): Array<ComponentButtonProps> => [
      {
        color: "primary",
        children: "common.edit",
        data: {
          cy: "edit-user-button",
        },
        onClick: () => setOrganizationUserDetailsCanvasOpen(true),
        iconClass: "bi bi-pencil-fill",
      },
    ],
    [setOrganizationUserDetailsCanvasOpen]
  );
  return {
    canvasButtons,
    handleClose,
  };
};
