import { NewProjectContext } from "@Contexts";
import type { Dispatch } from "react";
import { useCallback, useContext } from "react";

export const useViewDictionary = (restoreOption: Dispatch<string>) => {
  const { project, setProject } = useContext(NewProjectContext);

  const removeEntry = useCallback(
    (index: number) => {
      const dictionary = structuredClone(project.dictionary);
      const [removed] = dictionary.splice(index, 1);
      setProject({ ...project, dictionary });
      restoreOption(removed.key);
    },
    [project, restoreOption, setProject]
  );

  return {
    dictionary: project.dictionary,
    removeEntry,
  };
};
