import type { Dispatch } from "react";
import { createStore } from "@Hooks";
import type { Nullable } from "@Interfaces";
import type { OrganizationAdminGetUserResponse } from "@Services";

export type OrganizationUserDetailsCanvasStoreProperties = {
  open: boolean;
  userDetails: Nullable<OrganizationAdminGetUserResponse>;
};

export type OrganizationUserDetailsCanvasStore =
  OrganizationUserDetailsCanvasStoreProperties & {
    setOpen: Dispatch<boolean>;
    setUserDetails: Dispatch<Nullable<OrganizationAdminGetUserResponse>>;
  };

const initialState: OrganizationUserDetailsCanvasStoreProperties = {
  open: false,
  userDetails: null,
};

export const useOrganizationUserDetailsCanvasStore =
  createStore<OrganizationUserDetailsCanvasStore>(
    (get, set) => ({
      setOpen: open => set(store => ({ ...store, open })),
      setUserDetails: userDetails => set(state => ({ ...state, userDetails })),
    }),
    initialState
  );
