import { Col, Row } from "reactstrap";
import type { DisplayRowProps } from "./DisplayRow.i";
import { DisplaySection, Button } from "@Components";
import { t } from "i18next";
import "./DisplayRow.style.scss";

export const DisplayRow = ({
  title,
  buttonProps = [],
  className = "",
  noHeader,
  ...props
}: DisplayRowProps) => {
  const arrayButtonProps = Array.isArray(buttonProps)
    ? buttonProps
    : [buttonProps];

  return (
    <div className={`display-row ${className}`.trimEnd()}>
      {noHeader ? null : (
        <Row className="display-row-header">
          <Col>
            {title && <h6 className="title">{t(title)}</h6>}
            {arrayButtonProps.length > 0 && (
              <div className="buttons-container">
                {arrayButtonProps.map((btnProps, i) => (
                  <Button {...btnProps} size="sm" key={i} />
                ))}
              </div>
            )}
          </Col>
        </Row>
      )}
      {"columns" in props && props.columns.length > 0 ? (
        <Row className="display-row-body">
          {props.columns.map(({ label, value, ...colProps }, i) => (
            <Col {...colProps} key={`${i}|${title}`}>
              <DisplaySection label={label} value={value} />
            </Col>
          ))}
        </Row>
      ) : "children" in props && !!props.children ? (
        <div className="display-row-body">{props.children}</div>
      ) : (
        <></>
      )}
    </div>
  );
};
