import type { Method } from "@Interfaces";
import type { IssueExclusion } from "@Services";
import { t } from "i18next";
import type { Dispatch, FC } from "react";
import { FormGroup } from "reactstrap";
import type { CalloutProps } from "@Components";
import { FormInput, Switch, MethodsComboBox, Callout } from "@Components";

const methods: Method[] = ["GET", "POST", "PATCH", "PUT", "DELETE"];

type IssueExclusionFormProps = {
  issueExclusion: IssueExclusion;
  setIssueExclusion: Dispatch<IssueExclusion>;
  calloutMessage?: CalloutProps;
  pathLoading?: boolean;
};

export const IssueExclusionForm: FC<IssueExclusionFormProps> = ({
  issueExclusion,
  setIssueExclusion,
  calloutMessage,
  pathLoading,
}) => (
  <div id="issue-exclusion-form">
    <FormGroup>
      <FormInput
        label="common.extension-name"
        type="text"
        id="issue-exclusion-extensionName"
        value={issueExclusion.extensionName}
        bsSize="sm"
        loading={pathLoading}
        readOnly
      />
    </FormGroup>
    <hr></hr>
    <FormGroup>
      <FormInput
        autoTrim
        label="common.path"
        type="text"
        id="issue-exclusion-name"
        value={issueExclusion.path}
        bsSize="sm"
        onChange={e =>
          setIssueExclusion({
            ...issueExclusion,
            path: e.target.value,
          })
        }
      />
    </FormGroup>
    <FormGroup>
      <span className="form-label">{t("common.methods")}</span>
      <MethodsComboBox
        methods={methods}
        activeMethods={issueExclusion.methods as Method[]}
        setActiveMethods={methods =>
          setIssueExclusion({
            ...issueExclusion,
            methods: methods as Method[],
          })
        }
        addAll
        minSelected={1}
      />
    </FormGroup>
    <FormGroup noMargin>
      <Switch
        title="common.false-positive"
        id="issue-exclusion-false-positive"
        checked={issueExclusion.falsePositive}
        showLabel={false}
        onChange={() =>
          setIssueExclusion({
            ...issueExclusion,
            falsePositive: !issueExclusion.falsePositive,
          })
        }
      />
    </FormGroup>
    {!!calloutMessage && (
      <div className="mt-3">
        <Callout {...calloutMessage} />
      </div>
    )}
  </div>
);
