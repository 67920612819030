import { dateTimeFormat } from "@Services";
import { t } from "i18next";

export const getNextRun = (nextRun: string): string => {
  if (!nextRun) {
    return "-";
  }
  switch (nextRun) {
    case "error":
      return t(`common.next-run-error`);
    case "in progress":
      return t(`common.next-run-in-progress`);
    case "queued":
      return t(`common.next-run-queued`);
    case "no more next run":
      return t(`common.next-run-policy-ended`);
    case "scan already executed":
      return t(`common.next-run-already-done`);
    default:
      return dateTimeFormat(nextRun, { trimTime: true });
  }
};
