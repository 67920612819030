import { PROJECT_MAX_END_DATE } from "@Constants";
import type { InviteOrEditUserModel } from "@Interfaces";

const model: InviteOrEditUserModel = {
  active: true,
  email: "",
  name: "",
  surname: "",
  role: "security_reader",
  expiresAt: PROJECT_MAX_END_DATE,
};

export const getFormInitialState = () => {
  return Object.assign({}, model);
};
