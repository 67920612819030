export const fieldsWithLikeAvailable = [
  "organization_name",
  "project_name",
  "user_name",
  "user_surname",
  "auth_matrix_path",
  "user_surname_name",
  "user_email",
  "inventory_item_path",
  "api_key_name",
  "authentication_profile_name",
  "attempt_path",
  "space_link_name",
];
