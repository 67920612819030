import * as themes from "@uiw/codemirror-themes-all";

const CODE_THEMES = [
  "dark",
  "light",
  "abcdef",
  "abyss",
  "androidstudio",
  "atomone",
  "aura",
  "bbedit",
  "bespin",
  "darcula",
  "dracula",
  "duotoneDark",
  "duotoneLight",
  "eclipse",
  "githubDark",
  "githubLight",
  "gruvboxDark",
  "gruvboxLight",
  "kimbie",
  "material",
  "materialDark",
  "materialLight",
  "monokai",
  "monokaiDimmed",
  "noctisLilac",
  "nord",
  "okaidia",
  "quietlight",
  "red",
  "solarizedDark",
  "solarizedLight",
  "sublime",
  "tokyoNight",
  "tokyoNightDay",
  "tokyoNightStorm",
  "tomorrowNightBlue",
  "vscodeDark",
  "xcodeDark",
  "xcodeLight",
] as const;

export type CodeTheme = (typeof CODE_THEMES)[number];

// Map over the array to create label-value pairs
export const CodeThemeOptions = CODE_THEMES.map(theme => ({
  label: theme,
  value: theme,
}));

export const DEFAULT_CODE_THEME: CodeTheme = "aura";

export const getCodeMirrorTheme = (codeTheme: CodeTheme) => {
  switch (codeTheme) {
    case "light":
      return "light";
    case "abcdef":
      return themes.abcdef;
    case "abyss":
      return themes.abyss;
    case "androidstudio":
      return themes.androidstudio;
    case "atomone":
      return themes.atomone;
    case "aura":
      return themes.aura;
    case "bbedit":
      return themes.bbedit;
    case "bespin":
      return themes.bespin;
    case "darcula":
      return themes.darcula;
    case "dracula":
      return themes.dracula;
    case "duotoneDark":
      return themes.duotoneDark;
    case "duotoneLight":
      return themes.duotoneLight;
    case "eclipse":
      return themes.eclipse;
    case "githubDark":
      return themes.githubDark;
    case "githubLight":
      return themes.githubLight;
    case "gruvboxDark":
      return themes.gruvboxDark;
    case "gruvboxLight":
      return themes.gruvboxLight;
    case "kimbie":
      return themes.kimbie;
    case "material":
      return themes.material;
    case "materialDark":
      return themes.materialDark;
    case "materialLight":
      return themes.materialLight;
    case "monokai":
      return themes.monokai;
    case "monokaiDimmed":
      return themes.monokaiDimmed;
    case "noctisLilac":
      return themes.noctisLilac;
    case "nord":
      return themes.nord;
    case "okaidia":
      return themes.okaidia;
    case "quietlight":
      return themes.quietlight;
    case "red":
      return themes.red;
    case "solarizedDark":
      return themes.solarizedDark;
    case "solarizedLight":
      return themes.solarizedLight;
    case "sublime":
      return themes.sublime;
    case "tokyoNight":
      return themes.tokyoNight;
    case "tokyoNightDay":
      return themes.tokyoNightDay;
    case "tokyoNightStorm":
      return themes.tokyoNightStorm;
    case "tomorrowNightBlue":
      return themes.tomorrowNightBlue;
    case "vscodeDark":
      return themes.vscodeDark;
    case "xcodeDark":
      return themes.xcodeDark;
    case "xcodeLight":
      return themes.xcodeLight;
    default:
      return themes.aura;
  }
};
