import { memo, type FC } from "react";
import type { ResetPasswordPageProps } from "./ResetPasswordPage.i";
import { FormGroup, Input, Label } from "reactstrap";
import { t } from "i18next";
import { Button, PasswordInput } from "@Components";
import { useResetPasswordPage } from "./useResetPasswordPage";
import { equals } from "@Services";

export const ResetPasswordPage: FC<ResetPasswordPageProps> = memo(props => {
  const {
    password,
    repeatPassword,
    submittable,
    loading,
    newPasswordValidities,
    updatePassword,
    updatePasswordRepeat,
    setValidPassword,
    submitFunction,
  } = useResetPasswordPage(props);

  return (
    <>
      <FormGroup>
        <Label for="password">{t("common.password")}</Label>
        <PasswordInput
          id="password"
          password={password}
          setPassword={updatePassword}
          setValidPassword={setValidPassword}
          autoComplete="password"
          bsSize="sm"
        />
      </FormGroup>
      <FormGroup>
        <Label for="repeatPassword">
          {t("login.setup-password.repeat-password")}
        </Label>
        <Input
          type="password"
          id="repeatPassword"
          bsSize="sm"
          value={repeatPassword}
          onChange={updatePasswordRepeat}
          valid={newPasswordValidities.valid}
          invalid={newPasswordValidities.invalid}
        />
      </FormGroup>

      <div className="mt-3 d-grid gap-2">
        <Button
          color="primary"
          onClick={submitFunction}
          disabled={!submittable}
          loading={loading}
          children="login.setup-password.submit"
          iconClass="bi bi-floppy"
          data-cy="submit-password-button"
        />
      </div>
    </>
  );
}, equals);
