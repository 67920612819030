import { useUrlParams } from "@Hooks";
import { API } from "@Services";
import { useCallback } from "react";
import { useOrganizationUserListStore } from "../../../../../Stores";
import { useNavigate } from "react-router-dom";
import { generateUrl } from "@Utils";
import { ORGANIZATION_PATH_USERS } from "@Constants";

export const useDeleteUser = () => {
  const { organizationId, userId } = useUrlParams();
  const { setNeedRefresh } = useOrganizationUserListStore();
  const navigate = useNavigate();

  const handleDelete = useCallback(() => {
    if (!userId || !organizationId) {
      throw new Error("Missing parameters");
    }
    return API.role().organizationAdminDeleteRole(userId, organizationId);
  }, [organizationId, userId]);

  const handleOnSuccess = useCallback(() => {
    setNeedRefresh(true);

    navigate(
      generateUrl(ORGANIZATION_PATH_USERS, [organizationId ?? ""], true)
    );
  }, [navigate, organizationId, setNeedRefresh]);

  return {
    handleDelete,
    handleOnSuccess,
  };
};
