import {
  GridContainer,
  LongTextOffcanvas,
  MethodBadge,
  NotificationBadge,
} from "@Components";
import { memo, useState } from "react";
import { useAPIDefinitionDetailsStore } from "../Stores";
import type { Method } from "@Interfaces";
import { DetailsSection } from "./DetailsSection";

export const APIDefinitionDetailsSectionDetails = memo(() => {
  const { details } = useAPIDefinitionDetailsStore();
  const [showLongTextModal, setShowLongTextModal] = useState<false | string>(
    false
  );

  if (!details) {
    return null;
  }

  return (
    <>
      <GridContainer className="section-grid-container">
        <div>
          <h1>{details.path}</h1>
          <div className="d-flex justify-content-start">
            <MethodBadge type={details.verb as Method} />
            {details.createdFrom === "FROM_EXTENSION" && (
              <div className="ms-2 shadow-badge">
                <NotificationBadge display="badge" type="shadow" />
              </div>
            )}
          </div>
        </div>
        <DetailsSection />
      </GridContainer>
      <LongTextOffcanvas
        showLongTextModal={showLongTextModal}
        setShowLongTextModal={setShowLongTextModal}
        text={details.description}
      />
    </>
  );
});
