export const generateUrl = (
  template: string,
  values: string[],
  keepSearch?: boolean
): string => {
  const url = template;
  let valueIndex = 0;

  // Split the URL into the main part and the fragment part
  const [mainPart, fragmentPart] = url.split("#");

  // Replace only in the main part of the URL
  const processedMainPart = mainPart.replace(/:([^/]+)/g, () => {
    if (valueIndex >= values.length) {
      throw new Error(`Not enough values provided for parameters.`);
    }
    return values[valueIndex++];
  });

  // Check if there are extra values after replacements
  if (valueIndex < values.length) {
    throw new Error(
      `Too many values provided for parameters, ${template} ${JSON.stringify(values)}`
    );
  }

  // Reconstruct the full URL
  const reconstructedUrl = fragmentPart
    ? `${processedMainPart}#${fragmentPart}`
    : processedMainPart;

  if (keepSearch) {
    const { search } = window.location;
    return `${reconstructedUrl}${search}`;
  }

  return reconstructedUrl;
};
