import { memo } from "react";
import {
  Offcanvas as BootstrapOffcanvas,
  Card,
  OffcanvasBody,
} from "reactstrap";
import type { OffCanvasProps } from "./OffCanvas.i";
import { OffcanvasHeader } from "./Components";
import useOffcanvas from "./useOffcanvas";
import "./OffCanvas.style.scss";

export const Offcanvas = memo((props: OffCanvasProps) => {
  const {
    children,
    close,
    isOpen,
    buttons,
    id,
    title: canvasTitle,
    className,
    noPadding,
  } = useOffcanvas(props);

  return (
    <BootstrapOffcanvas
      id={id}
      isOpen={isOpen}
      toggle={close}
      direction="end"
      className={className}
      title={undefined}
    >
      <OffcanvasHeader title={canvasTitle} buttons={buttons} />
      <OffcanvasBody>
        <Card className={noPadding ? "" : "p-4"}>{children}</Card>
      </OffcanvasBody>
    </BootstrapOffcanvas>
  );
});
