import { ORGANIZATION_PATH_API_KEYS } from "@Constants";
import { useUrlParams } from "@Hooks";
import { API } from "@Services";
import { generateUrl } from "@Utils";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useApiKeyListStore } from "../../../../../Stores";
import type { ApiKey } from "@Interfaces";

export const useDeleteApiKey = (apiKey: ApiKey) => {
  const { organizationId } = useUrlParams();
  const { setNeedRefresh } = useApiKeyListStore();
  const navigate = useNavigate();

  const deletePromise = useCallback(() => {
    if (!organizationId || !apiKey.id) {
      throw new Error("Invalid url params");
    }
    return API.apiKeys().organizationAdminApiKeysDelete(
      organizationId,
      apiKey.id
    );
  }, [apiKey.id, organizationId]);

  const handleOnSuccess = useCallback(() => {
    setNeedRefresh(true);
    navigate(
      generateUrl(ORGANIZATION_PATH_API_KEYS, [organizationId ?? ""], true),
      { replace: false }
    );
  }, [navigate, organizationId, setNeedRefresh]);

  return {
    deletePromise,
    handleOnSuccess,
  };
};
