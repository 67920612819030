import { Offcanvas } from "@Components";
import { useIssueExclusionCanvas } from "./Hooks";
import { pureComponent } from "@Utils";
import { memo } from "react";
import { IssueExclusionForm } from "./Components";

export const IssueExclusionEditCanvas = memo(() => {
  const {
    issueExclusionForm,
    open,
    close,
    setIssueExclusionForm,
    loading,
    saveButton,
    calloutMessage,
    exclusionLoading,
  } = useIssueExclusionCanvas();

  if (!issueExclusionForm) {
    return null;
  }

  return (
    <Offcanvas
      id="issue-exclusion-edit-canvas"
      isOpen={open}
      toggle={close}
      title="common.issue-exclusion"
      buttons={saveButton}
      className={loading ? "loading" : undefined}
      children={
        <IssueExclusionForm
          issueExclusion={issueExclusionForm}
          setIssueExclusion={setIssueExclusionForm}
          calloutMessage={calloutMessage}
          pathLoading={exclusionLoading}
        />
      }
    />
  );
}, pureComponent);
