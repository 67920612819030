import type { Edge } from "@xyflow/react";
import { useEdgesState, useNodesState } from "@xyflow/react";
import { useCallback, useEffect, useState } from "react";
import { useAPIDefinitionDetailsStore } from "../Stores";
import type { ParameterNodeData } from "../AdditionalInfo.i";
import { type AdditionalInfoNodeType } from "../AdditionalInfo.i";
import {
  getApiDetailsNodesAndEdges,
  sanitizeInventoryItemParameters,
  traverseGraphVisibleNodes,
} from "./functions";
import { getDagreLayoutedElements } from "@Utils";
import type { Nullable } from "@Interfaces";

export const useAdditionalInfo = () => {
  const { details } = useAPIDefinitionDetailsStore();
  const [, setNodes, onNodesChange] = useNodesState<AdditionalInfoNodeType>([]);
  const [, setEdges, onEdgesChange] = useEdgesState<Edge>([]);
  const [selectedNodeId, setSelectedNodeId] = useState<string>("");

  const [visibleNodes, setVisibleNodes] =
    useState<Nullable<Array<AdditionalInfoNodeType>>>(null);
  const [visibleEdges, setVisibleEdges] = useState<Nullable<Array<Edge>>>(null);

  // Memoize generated layout details to avoid unnecessary recalculations
  const generateLayout = useCallback(() => {
    if (!details) return;

    sanitizeInventoryItemParameters(details);

    const { nodes: generatedNodes, edges: generatedEdges } =
      getApiDetailsNodesAndEdges(details);

    const [traversedNodes, traversedEdges] = traverseGraphVisibleNodes(
      generatedNodes,
      generatedEdges,
      selectedNodeId
    );

    const visibleNodes = traversedNodes.filter(n => n.data.visible);
    const visibleEdges = traversedEdges.filter(e => e.data?.visible);

    const { nodes, edges } = getDagreLayoutedElements(
      visibleNodes,
      visibleEdges,
      { rankdir: "LR", ranksep: 100 }
    );

    setVisibleNodes(nodes);
    setVisibleEdges(edges);

    setNodes(generatedNodes);
    setEdges(generatedEdges);
  }, [details, selectedNodeId, setEdges, setNodes]);

  // Clean up handleNodeClick by moving selection logic to a separate function
  const handleNodeSelection = useCallback((node: AdditionalInfoNodeType) => {
    setSelectedNodeId(previousSelectedNodeId => {
      return previousSelectedNodeId === node.id
        ? node.data.parentId ?? ""
        : node.id;
    });
  }, []);

  // Handle node click and prevent unnecessary re-renders
  const handleNodeClick = useCallback(
    (
      event: Nullable<React.MouseEvent<Element>>,
      node: AdditionalInfoNodeType
    ) => {
      const subNodes = (node.data as ParameterNodeData).subNodes;
      if (!subNodes) return;

      event?.preventDefault();
      event?.stopPropagation();
      handleNodeSelection(node);
    },
    [handleNodeSelection]
  );

  // Effect to recalculate the layout whenever selection or details change
  useEffect(() => {
    generateLayout();
  }, [generateLayout]);

  return {
    details,
    nodes: visibleNodes,
    edges: visibleEdges,
    selectedNodeId,
    setNodes,
    setEdges,
    onNodesChange,
    onEdgesChange,
    handleNodeClick,
  };
};
