import { dateTimeFormat, equals } from "@Services";
import { memo, type FC } from "react";
import type { UserDataProps } from "../../Hooks";
import { useUserData } from "../../Hooks";
import { DisplayRow } from "@Components";
import { joinClasses } from "@Utils";
import { t } from "i18next";

export const UserData: FC<UserDataProps> = memo(({ user, editingItself }) => {
  const { email, expiresAt, name, surname, createdAt, roleActive, lastLogin } =
    useUserData(user);
  return (
    <DisplayRow
      noHeader
      className="m-0 mt-4"
      columns={[
        {
          label: "common.name",
          value: name,
          xs: 12,
          xl: 3,
        },
        {
          label: "common.surname",
          value: surname,
          xs: 12,
          xl: 3,
        },
        {
          label: "common.email",
          value: email,
          xs: 12,
          xl: 6,
        },
        expiresAt && {
          label: "common.expires-at",
          value: expiresAt,
          xs: 12,
          xl: 3,
        },
        {
          label: "common.last-login",
          value: dateTimeFormat(lastLogin, { trimTime: true }),
          xs: 12,
          xl: 3,
        },
        {
          label: "common.created-at",
          value: dateTimeFormat(createdAt, { trimTime: true }),
          xs: 12,
          xl: 3,
        },
        !editingItself && {
          label: "common.status",
          value: (
            <div className="d-flex">
              <i
                className={joinClasses(
                  "me-2",
                  roleActive ? "color-success" : "color-gray",
                  "bi",
                  roleActive ? "bi-check-circle" : "bi-plus-circle"
                )}
              />
              <span>
                {roleActive ? t("common.active") : t("common.disabled")}
              </span>
            </div>
          ),
          xs: 12,
          xl: 3,
        },
      ].filter(e => !!e)}
    />
  );
}, equals);
