import {
  DangerZone,
  Section,
  SkeletonBox,
  Switch,
  TimeZone,
} from "@Components";
import { useIsAdmin, useOrganizationSettings } from "@Hooks";
import { BEM, joinClasses } from "@Utils";
import { memo } from "react";
import { Col, Row } from "reactstrap";
import { EnabledDomains } from "../Components/EnabledDomains";
import isEqual from "react-fast-compare";

export const Loading = () => {
  return (
    <SkeletonBox width="100%" height="580">
      <rect x="0" y="0" rx="3" ry="3" width="49.5%" height={150} />
      <rect x="50.5%" y="0" rx="3" ry="3" width="49.5%" height={150} />

      <rect x="0" y="166" rx="3" ry="3" width="49.5%" height={250} />
      <rect x="50.5%" y="166" rx="3" ry="3" width="49.5%" height={250} />

      <rect x="0" y="432" rx="3" ry="3" width="100%" height={150} />
    </SkeletonBox>
  );
};

export const OrganizationGeneralPageSettingsTab = memo(() => {
  const {
    organization,
    organizationSettings,
    loading,
    loadingUpdate,
    dangerZone,
    updateOrganizationSettings,
  } = useOrganizationSettings();

  const isAdmin = useIsAdmin();

  return (
    <div
      className={joinClasses(BEM("organization-details", "container"), "card")}
      id="organization-details-settings-tab"
    >
      {loading ? (
        <Loading />
      ) : organizationSettings ? (
        <>
          <Row>
            <Col md={6}>
              <Section title="common.organization-settings">
                <Switch
                  title="common.advanced-settings"
                  id="advanced-settings"
                  checked={organizationSettings.advancedSettings}
                  disabled
                />
              </Section>
            </Col>
            <Col md={6}>
              <Section title="common.security">
                <Switch
                  title="common.2fa"
                  id="mandatory-2fa"
                  disabled={!isAdmin || loadingUpdate}
                  checked={
                    organizationSettings.mandatoryTwoFactorAuthentication
                  }
                  onChange={
                    isAdmin
                      ? () =>
                          updateOrganizationSettings({
                            mandatoryTwoFactorAuthentication:
                              !organizationSettings.mandatoryTwoFactorAuthentication,
                          })
                      : undefined
                  }
                />
              </Section>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <Section title="common.date-settings" className="h-100">
                <TimeZone value={organizationSettings.timezone} disabled />
              </Section>
            </Col>
            <Col md={6}>
              <EnabledDomains scope={organizationSettings.scope} />
            </Col>
          </Row>

          {isAdmin && organization && dangerZone && (
            <section className="mt-3">
              <DangerZone
                target="organization"
                confirmationText={organization.name}
                subject={organization.name}
                onSuccess={dangerZone.onSuccess}
                toastErrorMessage="delete-organization"
                promise={dangerZone.promise}
              />
            </section>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}, isEqual);
