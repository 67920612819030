import { AdvancedSettingsForm } from "@Components";
import { NewProjectContext } from "@Contexts";
import { useContext } from "react";
import { Card } from "reactstrap";

export const NewProjectAdvancedSettings = () => {
  const { project, setProject } = useContext(NewProjectContext);
  return (
    <div id="advanced-settings">
      {project.advancedSettings && (
        <Card className="p-4 border-tl-0">
          <AdvancedSettingsForm
            advancedSettings={project.advancedSettings}
            setAdvancedSettings={advancedSettings =>
              setProject({ ...project, advancedSettings })
            }
          />
        </Card>
      )}
    </div>
  );
};
