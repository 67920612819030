import { createStore } from "@Hooks";
import type { Nullable } from "@Interfaces";
import type { SpaceLink } from "@Services";
import type { Dispatch } from "react";

type ScanSpaceLinkStoreProperties = {
  spaceLinkId: Nullable<SpaceLink["id"]>;
  spaceLink: Nullable<SpaceLink>;
  loading: boolean;
};

type ScanSpaceLinkStore = ScanSpaceLinkStoreProperties & {
  setSpaceLink: Dispatch<Nullable<SpaceLink>>;
  setSpaceLinkId: Dispatch<Nullable<SpaceLink["id"]>>;
  setLoading: Dispatch<boolean>;
  reset: VoidFunction;
};

const initialState: ScanSpaceLinkStoreProperties = {
  spaceLinkId: null,
  spaceLink: null,
  loading: true,
};

export const useScanSpaceLinkStore = createStore<ScanSpaceLinkStore>(
  (get, set) => ({
    ...initialState,
    setLoading: loading => set(state => ({ ...state, loading })),
    setSpaceLink: spaceLink => set(state => ({ ...state, spaceLink })),
    setSpaceLinkId: spaceLinkId => set(state => ({ ...state, spaceLinkId })),
    reset: () => set(state => ({ ...state, ...initialState })),
  })
);
