import type { InventoryDetail } from "@Interfaces";
import type { Parameter } from "@Services";

export const decodeIntentoryDetailItem = (detail: InventoryDetail) => {
  const { headers, parameters } = detail;

  const parameterMapper = (parameter: Parameter) => {
    const result = { ...parameter };
    if (result.pathName) {
      result.pathName = decodeURIComponent(result.pathName);
    }
    return result;
  };

  return {
    ...detail,
    headers: headers.map(parameterMapper),
    parameters: parameters.map(parameterMapper),
  };
};
