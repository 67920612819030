import type { PathExclusion, PathExclusionEntry } from "@Services";
import { generateRandomUUID } from "../other";

const pathExclusions: PathExclusion[] = [
  {
    path: `services/bola/preview/${generateRandomUUID()}`,
    methods: ["GET"],
    isMatch: false,
  },
  {
    path: `services/bola/preview/${generateRandomUUID()}/download`,
    methods: ["GET", "POST", "PUT"],
    isMatch: true,
  },

  {
    path: `services/login`,
    methods: ["POST"],
    isMatch: true,
  },
  {
    path: `api/login/attempts`,
    methods: ["GET"],
    isMatch: true,
  },
  {
    path: `api/v2/login/attempts`,
    methods: ["GET"],
    isMatch: true,
  },
  {
    path: `services/img/convert`,
    methods: ["PUT"],
    isMatch: false,
  },
  {
    path: `docs/${generateRandomUUID()}`,
    methods: ["GET"],
    isMatch: true,
  },
  {
    path: `api/users/payments`,
    methods: ["POST"],
    isMatch: true,
  },
  {
    path: "/users",
    methods: ["POST", "PATCH"],
    isMatch: true,
  },
];

export const generateRandomPathExclusions = () => {
  return structuredClone(pathExclusions);
};

export const generateRandomPathExclusionEntries = (): PathExclusionEntry[] => {
  return pathExclusions.map<PathExclusionEntry>(e => ({
    methods: e.methods,
    path: e.path,
  }));
};
