import { memo, useMemo } from "react";
import {
  Button,
  OrganizationUsersFilters,
  Paginator,
  Section,
} from "@Components";
import { SidebarNewUser, OrganizationUserListTable } from "./Components";
import { useOrganizationUserList } from "./Hooks";
import { Outlet } from "react-router-dom";

export const OrganizationUsersList = memo(() => {
  const {
    fetch,
    loading,
    users,
    totalItems,
    sidebarOpen,
    setSidebarOpen,
    updateFilters,
  } = useOrganizationUserList();

  const table = useMemo(
    () => (
      <OrganizationUserListTable
        users={users}
        loading={loading}
        refreshUsers={fetch}
      />
    ),
    [fetch, loading, users]
  );

  const paginator = useMemo(
    () => (
      <Paginator
        setFilters={updateFilters}
        totalItems={totalItems}
        showItemsPerPage
      />
    ),
    [totalItems, updateFilters]
  );

  const sidebar = useMemo(
    () => (
      <SidebarNewUser
        isOpen={sidebarOpen}
        toggle={setSidebarOpen}
        onUserInvited={fetch}
      />
    ),
    [fetch, setSidebarOpen, sidebarOpen]
  );

  return (
    <div id="organization-users">
      <Section title="organization.users">
        <div className="d-flex justify-content-between align-items-end">
          <OrganizationUsersFilters
            updateFilters={updateFilters}
            refresh={fetch}
          />
          <Button
            color="primary"
            onClick={() => setSidebarOpen(true)}
            children="organization.invite-user"
            data-cy="invite-user-button"
            iconClass="bi bi-person-fill-add"
            className="ms-3"
          />
        </div>
      </Section>
      {table}
      {paginator}
      {sidebar}
      <Outlet />
    </div>
  );
});
