import type { ListedProject, Project } from "@Services";
import moment from "moment-timezone";
import { ParameterLinks } from "./parameter-links";
import { getRandomBetweenMinMax } from "@Utils";
import {
  generateRandomAuthenticationProfiles,
  generateRandomDictionaryEntries,
  generateRandomInjectables,
  generateRandomMTLSCertificates,
  generateRandomRunPolicy,
  generateRandomUUID,
  generateRandomPathExclusions,
  generateRandomAdvancedSettings,
} from "../utils";

// --------------------------
// - PROJECTS
// --------------------------

const baseURLs = [
  "https://app.equixly.com",
  "https://api.equixly.com",
  "https://api.equixly.com/*",
  "https://pwnpoint.equixly.com",
  "https://pwnpoint.equixly.com/*",
];

export const projects: ListedProject[] = [
  {
    id: "1",
    name: "Project alpha",
    description:
      "Project Alpha is an innovative and cutting-edge initiative that aims to revolutionize the digital landscape.",
    latestScan: moment().subtract(1, "day").toISOString(),
    latestScanSeverityCount: {
      high: 2,
      information: 0,
      low: 4,
      medium: 3,
    },
    status: "ready",
    nextRun: moment().add(7, "day").toISOString(),
    endpoints: getRandomBetweenMinMax(30, 70),
    archivedAt: "",
    createdAt: moment().subtract("360", "day").format("YYYY-MM-DD"),
    baseURL: baseURLs[getRandomBetweenMinMax(0, baseURLs.length)],
    lastError: "",
  },
  {
    id: "2",
    name: "Project gamma",
    description:
      "Project Gamma is a groundbreaking venture dedicated to exploring the frontiers of sustainable technologies and eco-friendly solutions",
    nextRun: "in progress",
    latestScan: moment().subtract(2, "day").toISOString(),
    latestScanSeverityCount: {
      high: 3,
      information: 4,
      low: 3,
      medium: 2,
    },
    endpoints: getRandomBetweenMinMax(30, 70),
    status: "ready",
    archivedAt: "",
    createdAt: moment().subtract("300", "day").format("YYYY-MM-DD"),
    baseURL: baseURLs[getRandomBetweenMinMax(0, baseURLs.length)],
    lastError: "",
  },
  {
    id: "3",
    name: "Project omega",
    description:
      "Project Omega is a groundbreaking venture dedicated to exploring the frontiers of sustainable technologies and eco-friendly solutions",
    nextRun: "",
    latestScan: "",
    latestScanSeverityCount: {
      high: 0,
      information: 0,
      low: 0,
      medium: 0,
    },
    status: "pending",
    endpoints: getRandomBetweenMinMax(30, 70),
    archivedAt: "",
    createdAt: moment().subtract("240", "day").format("YYYY-MM-DD"),
    baseURL: baseURLs[getRandomBetweenMinMax(0, baseURLs.length)],
    lastError: "",
  },
  {
    id: "4",
    name: "Project beta",
    description: "Project beta is a test project",
    nextRun: "",
    latestScan: "",
    latestScanSeverityCount: {
      high: 0,
      information: 0,
      low: 0,
      medium: 0,
    },
    status: "error",
    endpoints: getRandomBetweenMinMax(30, 70),
    archivedAt: "",
    createdAt: moment().subtract("180", "day").format("YYYY-MM-DD"),
    baseURL: baseURLs[getRandomBetweenMinMax(0, baseURLs.length)],
    lastError: "",
  },
  {
    id: "5",
    name: "Project epsilon",
    description: "Project epsilon is ad archived project",
    latestScan: moment().subtract(1, "day").toISOString(),
    latestScanSeverityCount: {
      high: 0,
      information: 1,
      low: 0,
      medium: 0,
    },
    status: "ready",
    nextRun: moment().add(7, "day").toISOString(),
    endpoints: getRandomBetweenMinMax(30, 70),
    archivedAt: moment().subtract(5, "day").toISOString(),
    createdAt: moment().subtract("120", "day").format("YYYY-MM-DD"),
    baseURL: baseURLs[getRandomBetweenMinMax(0, baseURLs.length)],
    lastError: "",
  },
  {
    id: "6",
    name: "Project theta",
    description: "Project theta is a new project",
    latestScan: moment().subtract(1, "day").toISOString(),
    latestScanSeverityCount: {
      high: 0,
      information: 1,
      low: 0,
      medium: 0,
    },
    status: "ready",
    nextRun: moment().add(7, "day").toISOString(),
    endpoints: getRandomBetweenMinMax(30, 70),
    archivedAt: "",
    createdAt: moment().subtract("120", "day").format("YYYY-MM-DD"),
    baseURL: baseURLs[getRandomBetweenMinMax(0, baseURLs.length)],
    lastError: "",
  },
  {
    id: "7",
    name: "Project lima oscar lima",
    description:
      "Project lima oscar lima is an innovative and cutting-edge initiative that aims to revolutionize the digital landscape.",
    latestScan: moment().subtract(1, "day").toISOString(),
    latestScanSeverityCount: {
      high: 2,
      information: 0,
      low: 4,
      medium: 3,
    },
    status: "ready",
    nextRun: moment().add(7, "day").toISOString(),
    endpoints: getRandomBetweenMinMax(30, 70),
    archivedAt: "",
    createdAt: moment().subtract("360", "day").format("YYYY-MM-DD"),
    baseURL: baseURLs[getRandomBetweenMinMax(0, baseURLs.length)],
    lastError: "",
  },
  {
    id: "8",
    name: "Project Django",
    description:
      "Project Django is an innovative and cutting-edge initiative that aims to revolutionize the digital landscape.",
    latestScan: moment().subtract(1, "day").toISOString(),
    latestScanSeverityCount: {
      high: 2,
      information: 0,
      low: 4,
      medium: 3,
    },
    status: "ready",
    nextRun: moment().add(7, "day").toISOString(),
    endpoints: getRandomBetweenMinMax(30, 70),
    archivedAt: "",
    createdAt: moment().subtract("360", "day").format("YYYY-MM-DD"),
    baseURL: baseURLs[getRandomBetweenMinMax(0, baseURLs.length)],
    lastError: "",
  },
];

export const projectInfos: Project[] = projects.map(p => {
  const isProjectAlpha = p.id === "1";
  return {
    baseURL: p.baseURL,
    description: p.description,
    advancedSettings: generateRandomAdvancedSettings(),
    authenticationSettings: isProjectAlpha
      ? generateRandomAuthenticationProfiles()
      : [],
    dictionary: isProjectAlpha ? generateRandomDictionaryEntries() : [],
    injectables: isProjectAlpha ? generateRandomInjectables() : [],
    mTLSCertificates: isProjectAlpha ? generateRandomMTLSCertificates() : [],
    id: p.id,
    isProductionURL: Math.random() > 0.5,
    name: p.name,
    runPolicy: generateRandomRunPolicy(),
    endpoints: p.endpoints,
    status: p.status,
    upload: {
      checksum: "checksum",
      fileName: generateRandomUUID(),
      validated: true,
    },
    pathExclusions: generateRandomPathExclusions(),
    archivedAt: p.archivedAt,
    parameterLinks: [...(ParameterLinks[p.id] ?? [])],
    lastError: p.lastError,
    spaceLinkID: "",
  };
});
