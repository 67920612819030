import { t } from "i18next";
import { ButtonBar } from "@Components";
import type { OffCanvasProps } from "../../OffCanvas.i";

type Props = Required<Pick<OffCanvasProps, "title" | "buttons">>;

export const OffcanvasHeader = ({ title: canvasTitle, buttons }: Props) => {
  return (
    <div className="offcanvas-header">
      <div className="w-100 d-flex align-items-center">
        <div className="flex-grow-1">
          <h6 className="offcanvas-title">{t(canvasTitle)}</h6>
        </div>
        <ButtonBar buttons={buttons} />
      </div>
    </div>
  );
};
