import { delay } from "@Mocks";
import type {
  UserExclusionMatchesRequest,
  UserExclusionMatchesResponse,
} from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";

export const exclusionMatches = async (
  req: RestRequest<DefaultBodyType, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { path } = (await req.json()) as UserExclusionMatchesRequest;

  const isMatch = path.length > 5 && path.length < 20;

  const response: UserExclusionMatchesResponse = {
    isMatch,
  };

  return res(ctx.delay(delay * 2), ctx.status(200), ctx.json(response));
};
