import { memo } from "react";
import { Card } from "reactstrap";
import { AddDictionaryEntry, ViewDictionary } from "./Components";
import { pureComponent } from "@Utils";
import { useDictionary } from "./useDictionary";

export const NewProjectDictionarySettings = memo(() => {
  const { comboBoxOptions, consumeOption, restoreOption } = useDictionary();
  return (
    <section id="dictionary">
      <Card className="border-tl-0 p-4">
        <AddDictionaryEntry
          comboBoxOptions={comboBoxOptions}
          consumeOption={consumeOption}
        />
        <ViewDictionary restoreOption={restoreOption} />
      </Card>
    </section>
  );
}, pureComponent);
