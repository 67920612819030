import { DisplayTable, TrashButton } from "@Components";
import { t } from "i18next";
import { memo, useMemo } from "react";
import { EMPTY_STRING_LABEL } from "../shared";
import { useAdditionalDataTable } from "./useAdditionalDataTable";

export const AdditionalDataTable = memo(() => {
  const { additionalData, deleteEntry, loading } = useAdditionalDataTable();

  const tableRows = useMemo(() => {
    return Object.entries(additionalData ?? {}).map(([key, value], i) => {
      return (
        <tr key={`${i}-${key}-${value}`}>
          <td>{key}</td>
          <td>
            <div className="d-flex justify-content-between align-items-center">
              {value ? (
                <span>{value}</span>
              ) : (
                <i className="opacity-75">{EMPTY_STRING_LABEL}</i>
              )}
              <TrashButton
                onClick={() => deleteEntry(key)}
                disabled={loading}
              />
            </div>
          </td>
        </tr>
      );
    });
  }, [additionalData, deleteEntry, loading]);

  if (!tableRows.length) {
    return null;
  }

  return (
    <DisplayTable>
      <thead>
        <tr>
          <th className="text-end">{t("common.key")}</th>
          <th>{t("common.value")}</th>
        </tr>
      </thead>
      <tbody>{tableRows}</tbody>
    </DisplayTable>
  );
});
