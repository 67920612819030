import { AdvancedSettingsProviderAttackStrength } from "@Services";
import { THIRTY_DAYS_IN_MINUTES } from "@Utils";

export const TWO_HOURS_IN_SECONDS = 2 * 60 * 60;

/**
 * Some advanced settings fields must respect a value boundary.
 * The following maps contains the min-max couples for each needed field
 */
export const numberInputRanges = {
  // minutes
  explorationPhaseTimeout: { min: 1, max: THIRTY_DAYS_IN_MINUTES },
  exploitationPhaseTimeout: { min: 1, max: THIRTY_DAYS_IN_MINUTES },
  providerMaxScanDuration: { min: 1, max: THIRTY_DAYS_IN_MINUTES },
  authRefreshInterval: { min: 1, max: THIRTY_DAYS_IN_MINUTES },
  // seconds
  requestThrottle: { min: 0, max: TWO_HOURS_IN_SECONDS },
  clientTimeout: { min: 1, max: TWO_HOURS_IN_SECONDS },
  // percentages
  exitOnCoverage: { min: 0, max: 100 },
  exitOnReachability: { min: 0, max: 100 },
  requestJitterPercentage: { min: 0, max: 100 },
  // iterations
  maxIteration: { min: 0, max: 1e9 },
  maxRenderAttempts: { min: 0, max: 1e9 },
} as const;

/**
 * Options for input#providerAttackStrength
 */
export const providerAttackStrenghtOptions = Object.values(
  AdvancedSettingsProviderAttackStrength
).map(v => ({
  label: `common.${v}`,
  value: v,
}));
