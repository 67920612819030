import { FormInput } from "../../Form";
import { getFilterAllowedOptions } from "@Services";
import type { PageFiltersProps } from "../PageFilters.i";
import { RefreshListButton } from "../../Buttons";
import { usePageFilters } from "../usePageFilters";
import { PageFiltersWrapper } from "../PageFiltersWrapper";

const verbOptions = getFilterAllowedOptions("verb");
const categoryOptions = getFilterAllowedOptions("inventory_item_category");
const authExpectedOptions = getFilterAllowedOptions(
  "inventory_item_authentication_expected"
);

export const InventoryFilters = ({
  updateFilters,
  refresh,
}: PageFiltersProps) => {
  const { getValueOf, handleOnChange } = usePageFilters(updateFilters);
  return (
    <PageFiltersWrapper className="inventory-filters">
      <FormInput
        id="inventory-item-verb-select"
        type="select"
        options={verbOptions}
        label="common.method"
        name="inventory_item_verb"
        value={getValueOf("inventory_item_verb")}
        onChange={handleOnChange}
      />
      <FormInput
        id="inventory-item-path-input"
        type="text"
        label="common.endpoint"
        name="inventory_item_path"
        value={getValueOf("inventory_item_path")}
        onChange={handleOnChange}
      />
      <FormInput
        id="inventory-item-category-input"
        type="select"
        options={categoryOptions}
        label="common.category"
        name="inventory_item_category"
        value={getValueOf("inventory_item_category")}
        onChange={handleOnChange}
      />
      <FormInput
        id="inventory-item-authentication-expected-select"
        type="select"
        options={authExpectedOptions}
        label="common.authentication"
        name="inventory_item_authentication_expected"
        value={getValueOf("inventory_item_authentication_expected")}
        onChange={handleOnChange}
      />
      <RefreshListButton onClick={refresh} />
    </PageFiltersWrapper>
  );
};
