import { FormInput } from "../../Form";
import type { PageFiltersProps } from "../PageFilters.i";
import { RefreshListButton } from "../../Buttons";
import { usePageFilters } from "../usePageFilters";
import { getFilterAllowedOptions } from "@Services";
import { PageFiltersWrapper } from "../PageFiltersWrapper";

const roleOptions = getFilterAllowedOptions("api_key_role");
const enabledOptions = getFilterAllowedOptions("enabled");

export const ApiKeyFilters = ({ updateFilters, refresh }: PageFiltersProps) => {
  const { getValueOf, handleOnChange } = usePageFilters(updateFilters);
  return (
    <PageFiltersWrapper>
      <FormInput
        id="api-key-name"
        label="common.name"
        type="text"
        name="api_key_name"
        value={getValueOf("api_key_name")}
        onChange={handleOnChange}
      />
      <FormInput
        id="api_key_role_value"
        label="common.role"
        type="select"
        name="api_key_role_value"
        value={getValueOf("api_key_role_value")}
        options={roleOptions}
        onChange={handleOnChange}
      />
      <FormInput
        id="api_key_role_status"
        label="common.status"
        type="select"
        name="api_key_role_status"
        value={getValueOf("api_key_role_status")}
        options={enabledOptions}
        onChange={handleOnChange}
      />
      <RefreshListButton onClick={refresh} />
    </PageFiltersWrapper>
  );
};
