import { API } from "@Services";
import { useState, useEffect, useCallback } from "react";
import { useUrlParams } from "@Hooks";
import { useOrganizationUserDetailsCanvasStore } from "../Stores";
import { useEditingItself } from "./useEditingItself";

export const useOrganizationUserDetails = () => {
  const { userDetails, setUserDetails } =
    useOrganizationUserDetailsCanvasStore();
  const [notFound, setNotFound] = useState(false);
  const { organizationId, userId } = useUrlParams();
  const editingItself = useEditingItself();

  const getData = useCallback(async () => {
    if (userId && organizationId) {
      await API.user()
        .organizationAdminGetUser(userId, organizationId)
        .then(({ data }) => {
          setUserDetails(data);
        })
        .catch(() => {
          setNotFound(true);
        });
    } else {
      setNotFound(true);
    }
  }, [userId, organizationId, setUserDetails]);

  useEffect(() => {
    getData();

    return () => {
      setUserDetails(null);
      setNotFound(false);
    };
  }, [getData, setUserDetails]);

  return {
    editingItself,
    details: userDetails,
    notFound,
    refresh: getData,
  };
};
