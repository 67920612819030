import { createStore } from "@Hooks";
import type { Nullable, NullableArray } from "@Interfaces";
import type { ListedProject, SpaceLink } from "@Services";
import { getDaysBetween } from "@Utils";
import type { Dispatch } from "react";

type SpaceLinkWithExpiration = SpaceLink & {
  expired: boolean;
};

type SpaceLinkDetailsProperties = {
  loading: boolean;
  notFound: boolean;
  spaceLinkDetails: Nullable<SpaceLinkWithExpiration>;
  associatedProjects: NullableArray<ListedProject>;
};

type SpaceLinkDetailsStore = SpaceLinkDetailsProperties & {
  setSpaceLinkDetails: Dispatch<Nullable<SpaceLink>>;
  setAssociatedProjects: Dispatch<NullableArray<ListedProject>>;
  setLoading: Dispatch<boolean>;
  setNotFound: Dispatch<boolean>;
  reset: VoidFunction;
};

const INITIAL_STATE: SpaceLinkDetailsProperties = {
  loading: true,
  associatedProjects: null,
  spaceLinkDetails: null,
  notFound: false,
};

export const useSpaceLinkDetailsStore = createStore<SpaceLinkDetailsStore>(
  (get, set) => ({
    ...INITIAL_STATE,
    setSpaceLinkDetails: spaceLinkDetails => {
      if (spaceLinkDetails === null) {
        set(state => ({ ...state, spaceLinkDetails: null, expired: null }));
        return;
      }
      const remainingDays = getDaysBetween(spaceLinkDetails.expiresAt);
      const expired = Number.isFinite(remainingDays) && remainingDays <= 0;
      const newSpaceLinkDetails: SpaceLinkWithExpiration = {
        ...spaceLinkDetails,
        expired,
      };
      set(state => ({ ...state, spaceLinkDetails: newSpaceLinkDetails }));
    },
    setAssociatedProjects: associatedProjects => {
      set(state => ({ ...state, associatedProjects }));
    },
    setLoading: loading => {
      set(state => ({ ...state, loading }));
    },
    setNotFound: notFound => {
      set(state => ({ ...state, notFound }));
    },
    reset: () => {
      set(state => ({ ...state, ...INITIAL_STATE }));
    },
  })
);
