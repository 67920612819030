import type { ButtonProps } from "@Components";
import { EditOrganizationUserForm, Offcanvas } from "@Components";
import { useMemo, type FC } from "react";
import type { EditUserCanvasProps } from "../../Hooks";
import { useEditUserCanvas } from "../../Hooks";

export const EditUserCanvas: FC<EditUserCanvasProps> = props => {
  const {
    close,
    isOpen,
    selfEditing,
    setUser,
    user,
    formDirty,
    formValid,
    setFormValid,
    canvasLoading,
    handleSubmit,
  } = useEditUserCanvas(props);

  const buttons = useMemo<ButtonProps[]>(() => {
    return [
      {
        color: "primary",
        disabled: !formDirty || !formValid,
        loading: canvasLoading,
        children: "common.save",
        iconClass: "bi bi-floppy",
        data: { cy: "submit-user-canvas" },
        onClick: handleSubmit,
      },
    ];
  }, [canvasLoading, formDirty, formValid, handleSubmit]);

  return (
    <Offcanvas
      isOpen={isOpen}
      title="common.edit-user"
      toggle={close}
      buttons={buttons}
      id="edit-user-canvas"
    >
      <EditOrganizationUserForm
        selfEditing={selfEditing}
        user={user}
        setUser={setUser}
        setFormValid={setFormValid}
        disabled={canvasLoading}
      />
    </Offcanvas>
  );
};
