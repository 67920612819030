import { CubePink } from "@Assets";
import { InfoBadge } from "@Components";
import { PROJECT_DETAILS_SETTINGS_URL } from "@Constants";
import { useUrlParams } from "@Hooks";
import type { ListedProject } from "@Services";
import { BEM, generateUrl } from "@Utils";
import { t } from "i18next";
import { useCallback, type FC } from "react";
import { useNavigate } from "react-router-dom";

type ProjectListItemProps = {
  listedProject: ListedProject;
};

const c = "project-list-item";
const style = { backgroundImage: `url("${CubePink}")` };
const projectLabel = t("common.project");

export const ProjectListItem: FC<ProjectListItemProps> = ({
  listedProject: { id: projectId, name, status, baseURL },
}) => {
  const { organizationId } = useUrlParams();

  const navigate = useNavigate();

  const onClick = useCallback(() => {
    if (!organizationId) return;
    const to = generateUrl(PROJECT_DETAILS_SETTINGS_URL, [
      organizationId,
      projectId,
    ]);
    navigate(to);
  }, [navigate, organizationId, projectId]);

  return (
    <div className={BEM(c)}>
      <div className={BEM(c, "icon")} style={style} />
      <div className={BEM(c, "content")}>
        <div className={BEM(c, "header")}>
          <InfoBadge children={projectLabel} bgColor="success" />
          <div>
            <span className={BEM(c, "project-name")} onClick={onClick}>
              {name}
            </span>
          </div>
        </div>
        <div className={BEM(c, "body")}>
          <span className={BEM(c, "base-url")}>{baseURL}</span>
        </div>
      </div>
    </div>
  );
};
