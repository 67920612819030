import type { Issue, Nullable, NullableArray } from "@Interfaces";
import type { ListedIssue } from "@Services";
import type { Dispatch } from "react";
import { createStore } from "src/Hooks/store/useStore";

export type IssueListStoreProperties = {
  openIssueId: Nullable<string>;
  issues: NullableArray<ListedIssue>;
  issueDetails: Nullable<Issue>;
  scanId: Nullable<string>;
  projectId: Nullable<string>;
  organizationId: Nullable<string>;
};

export type IssueListStore = IssueListStoreProperties & {
  setOpenIssueId: (
    openIssueId: string,
    scanId: string,
    projectId: string,
    organizationId: string
  ) => void;
  clearOpenIssueId: VoidFunction;
  setIssueDetails: Dispatch<Nullable<Issue>>;
  setIssues: Dispatch<NullableArray<ListedIssue>>;
  reset: VoidFunction;
};

const initialState: IssueListStoreProperties = {
  openIssueId: null,
  issueDetails: null,
  issues: null,
  scanId: null,
  projectId: null,
  organizationId: null,
};
export const useIssueListStore = createStore<IssueListStore>((get, set) => ({
  ...initialState,
  setOpenIssueId: (
    openIssueId: string,
    scanId: string,
    projectId: string,
    organizationId: string
  ) =>
    set(state => ({
      ...state,
      openIssueId,
      scanId,
      projectId,
      organizationId,
    })),
  clearOpenIssueId: () =>
    set(state => ({
      ...state,
      openIssueId: null,
      scanId: null,
      projectId: null,
      organizationId: null,
    })),
  setIssues: issues => set(state => ({ ...state, issues })),
  setIssueDetails: issueDetails => set(state => ({ ...state, issueDetails })),
  reset: () => set(state => ({ ...state, ...initialState })),
}));
