import { memo } from "react";
import { PasswordCreated, ResetPasswordPage } from "../../Components";
import { useNewPasswordPage } from "./useNewPasswordPage";
import { t } from "i18next";
import { Callout } from "@Components";

export const NewPassword = memo(() => {
  const { showEmptyPage, submitFunction, submitted, error } =
    useNewPasswordPage();

  if (showEmptyPage) {
    return null;
  }

  if (submitted) {
    return <PasswordCreated />;
  }

  return (
    <>
      <h4 className="mb-3">{t("login.setup-password.new-password")}</h4>
      <ResetPasswordPage submitFunction={submitFunction} />
      {error && (
        <div className="mt-3">
          <Callout
            level="error"
            className="white-space-prewrap"
            children={error}
          />
        </div>
      )}
    </>
  );
});
