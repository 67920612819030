import type { Nullable } from "@Interfaces";
import { useEffect, useState } from "react";
import isEqual from "react-fast-compare";

const createEmitter = () => {
  const subscriptions = new Map();
  return {
    emit: (v: any) => subscriptions.forEach(fn => fn(v)),
    subscribe: (fn: any) => {
      const key = Symbol();
      subscriptions.set(key, fn);
      return () => {
        subscriptions.delete(key);
        return;
      };
    },
    subscriptionsCount: () => subscriptions.size,
  };
};

export type StoreGetFunction<T> = () => Nullable<T>;
export type StoreSetFunction<T> = (
  op: (store: T) => T,
  forceEmit?: boolean
) => void;

export function createStore<TypeOfTheStore>(
  init: (
    get: StoreGetFunction<TypeOfTheStore>,
    set: StoreSetFunction<TypeOfTheStore>
  ) => Partial<TypeOfTheStore>,
  initialState?: Partial<TypeOfTheStore>
) {
  // create an emitter
  const emitter = createEmitter();

  let store: TypeOfTheStore;

  const get = () => store;
  const set = (
    op: (store: TypeOfTheStore) => TypeOfTheStore,
    forceEmit?: boolean
  ) => {
    const prevStore = store;
    store = op(store);

    // Notify only if specific properties have changed
    if (!forceEmit && isEqual(store, prevStore)) {
      return;
    }

    emitter.emit(store);
  };
  store = { ...init(get, set), ...(initialState ?? {}) } as TypeOfTheStore;

  const useStore = () => {
    const [localStore, setLocalStore] = useState(get());

    useEffect(() => {
      const unsubscribe = emitter.subscribe(setLocalStore);
      return () => {
        unsubscribe();
        if (emitter.subscriptionsCount() === 0 && initialState) {
          // reset to initial state if no one is listening
          set(store => ({ ...store, ...initialState }));
        }
      };
    }, []);

    return localStore;
  };
  return useStore;
}
