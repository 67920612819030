import { IssuesFilters, IssuesTable, Paginator, Section } from "@Components";
import type { Props } from "./IssuesPageLayout.i";
import { useIssueListStore } from "@Stores";
import { useEffect } from "react";

export const IssuesPageLayout = ({
  response,
  fetch,
  loading,
  issueListingType,
  setFilters,
}: Props) => {
  const { setIssues } = useIssueListStore();
  useEffect(() => {
    setIssues(response?.data ?? null);
  }, [response?.data, setIssues]);
  return (
    <>
      <Section removeLowerRadius removeTopLeftRadius>
        <IssuesFilters
          refresh={fetch}
          updateFilters={setFilters}
          issueListingType={issueListingType}
        />
      </Section>
      <IssuesTable
        issues={response?.data}
        loading={loading}
        setFilters={setFilters}
      />
      <Paginator totalItems={response?.totalItems} setFilters={setFilters} />
    </>
  );
};
