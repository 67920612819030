import type { Setter } from "@Interfaces";
import type { AxiosPromise, AxiosResponse } from "axios";
import { AxiosError } from "axios";
import { t } from "i18next";
import { useCallback } from "react";
import { useToast } from "src/Hooks/other/useToast";

interface Args<T> {
  promise: AxiosPromise<T> | (() => AxiosPromise<T>);
  errorMessage?: string;
  successMessage?: string;
  statusErrorMessage?: { [key: string]: string };
  onSuccess?: (response: AxiosResponse<T>) => void;
  onError?: (response?: AxiosResponse<T>) => void;
}

interface Output<T> {
  data: T | undefined;
  status: number | undefined;
}

export const useManageApiResponse = (setLoading?: Setter<boolean>) => {
  const toast = useToast();

  return useCallback(
    async <T>({
      promise,
      errorMessage,
      successMessage,
      statusErrorMessage,
      onSuccess = () => true,
      onError = () => true,
    }: Args<T>): Promise<Output<T>> => {
      const updateLoadingStatus = setLoading ?? (() => undefined);
      let result: AxiosResponse<T> | undefined;
      let apiSuccess = false;
      let timeout = false;

      updateLoadingStatus(true);

      // requested api call
      try {
        result = await (typeof promise === "function" ? promise() : promise);
        apiSuccess = true;
      } catch (e) {
        if (e instanceof AxiosError) {
          if (e.code === "ECONNABORTED") timeout = true;
          if (e.response) result = e.response;
        }
      } finally {
        // evaluate handler
        if (apiSuccess && result) onSuccess(result);
        if (!apiSuccess) onError(result);
        updateLoadingStatus(false);
      }

      // show toast
      if (apiSuccess && successMessage) {
        toast({ message: `successes.${successMessage}`, type: "success" });
      } else if (!apiSuccess && timeout) {
        toast({ message: "errors.timeout" });
      } else if (!apiSuccess && (errorMessage || statusErrorMessage)) {
        const msg = statusErrorMessage?.[`${result?.status}`] ?? errorMessage;
        if (msg) toast({ message: t([`errors.${msg}`, msg]) });
      }

      return {
        data: result?.data,
        status: result?.status,
      };
    },
    [setLoading, toast]
  );
};
