import { memo, useMemo, type FC } from "react";
import type { LogViewerProps } from "./LogViewer.i";
import {
  ClipboardBadge,
  MethodBadge,
  Section,
  SkeletonBox,
  StatusCodeBadge,
} from "@Components";
import { dateTimeFormat } from "@Services";
import { CubePink } from "@Assets";
import { t } from "i18next";
import type { Method } from "@Interfaces";
import { pureComponent } from "@Utils";

export const LogsViewer: FC<LogViewerProps> = ({
  loading,
  logs,
  section = true /* todo remove me */,
}) => {
  const content = useMemo(() => {
    if (loading) {
      return <Loading />;
    }
    if (!logs?.length) {
      return <NoLogs />;
    }
    return logs?.map((log, i) => (
      <div className="log-info" key={`${i}-${log.createdAt}`}>
        <div
          className="log-info__bg"
          style={{ backgroundImage: `url("${CubePink}")` }}
        />
        <div className="log-info__content">
          <div className="log-info__header">
            <span className="log-time">{dateTimeFormat(log.createdAt)}</span>
          </div>
          <div>
            <div className="log-info__info grid">
              <StatusCodeBadge code={log.status} />
              <MethodBadge type={log.method as Method} />
              <span className="text-truncate" title={log.path}>
                {log.path}
              </span>
              <ClipboardBadge value={`${log.method} ${log.path}`} />
            </div>
            <div className="log-info__info">{log.userAgent}</div>
          </div>
        </div>
      </div>
    ));
  }, [loading, logs]);

  if (!section) {
    return <div className="logs-viewer">{content}</div>;
  }

  return (
    <Section title="common.actions" className="logs-viewer">
      {content}
    </Section>
  );
};

const Loading = memo(
  () => (
    <SkeletonBox height="100%" width="100%">
      {Array.from({ length: 12 }).map((_, i) => {
        const y = `${i * 6}rem`;
        return (
          <rect x="0" y={y} rx="3" ry="3" width="1029" height="5rem" key={i} />
        );
      })}
    </SkeletonBox>
  ),
  pureComponent
);

const NoLogs = memo(
  () => (
    <div className="text-center p-4">
      <h5>{t("common.no-logs")}</h5>
    </div>
  ),
  pureComponent
);
