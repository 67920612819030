import { PROJECT_MAX_END_DATE } from "@Constants";
import type { OrganizationId } from "@Interfaces";
import { type SpaceLink } from "@Services";
import moment from "moment-timezone";

const spaceLinksMap = new Map<OrganizationId, SpaceLink[]>();

spaceLinksMap.set("1", [
  {
    active: true,
    connected: true,
    createdAt: moment().subtract(60, "days").format("YYYY-MM-DD"),
    expiresAt: moment().add(30, "days").format("YYYY-MM-DD"),
    id: "1",
    name: "Active Space Link",
  },
  {
    active: false,
    connected: false,
    createdAt: moment().subtract(15, "days").format("YYYY-MM-DD"),
    expiresAt: moment().add(75, "days").format("YYYY-MM-DD"),
    id: "2",
    name: "Disconnected Space Link",
  },
  {
    active: true,
    connected: true,
    createdAt: moment().subtract(15, "days").format("YYYY-MM-DD"),
    expiresAt: PROJECT_MAX_END_DATE,
    id: "3",
    name: "Infinity Space Link",
  },
  {
    active: true,
    connected: true,
    createdAt: moment().subtract(105, "days").format("YYYY-MM-DD"),
    expiresAt: moment().subtract(15, "days").format("YYYY-MM-DD"),
    id: "4",
    name: "Expired Space Link",
  },
  {
    active: true,
    connected: true,
    createdAt: moment().format("YYYY-MM-DD"),
    expiresAt: PROJECT_MAX_END_DATE,
    id: "5",
    name: "Undeletable Space Link",
  },
]);

export const spaceLinks = spaceLinksMap;
