import {
  ConfirmDialog,
  DisplaySection,
  MethodBadge,
  TrashButton,
} from "@Components";
import type { Method } from "@Interfaces";
import type { Props } from "./PathExclusionCard.i";
import { useMemo, useState } from "react";
import { t } from "i18next";

const unrelatedPathLabel = t("project.settings.unrelated-project-path");

export const PathExclusionCard = ({
  disabled,
  loading,
  methods,
  path,
  deleteEntry,
  isMatch,
}: Props) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const methodsJsx = useMemo(() => {
    return methods.map(m => (
      <MethodBadge type={m as Method} key={m} className="me-1 mb-1" />
    ));
  }, [methods]);

  const pathTooltip = isMatch === false ? unrelatedPathLabel : undefined;

  return (
    <div className="path-exclusion-card editable-card">
      <DisplaySection
        className="overflow-wrap-anywhere"
        label={"common.path"}
        value={path}
        tooltip={pathTooltip}
      />
      <div className="mt-auto">
        <DisplaySection
          label="common.methods"
          className="mt-3 display-methods"
          value={methodsJsx}
        />
      </div>
      <div className="card-actions">
        {!!deleteEntry && (
          <TrashButton
            onClick={() => setShowConfirmDialog(true)}
            loading={loading}
            disabled={disabled}
          />
        )}
      </div>
      {deleteEntry && showConfirmDialog && (
        <ConfirmDialog
          title="confirmation-dialog.title.delete-configuration"
          description="confirmation-dialog.description.delete-configuration"
          cancelLabel="confirmation-dialog.cancel.delete-configuration"
          confirmLabel="confirmation-dialog.confirm.delete-configuration"
          onCancel={() => setShowConfirmDialog(false)}
          onConfirm={() => {
            setShowConfirmDialog(false);
            deleteEntry();
          }}
        />
      )}
    </div>
  );
};

export default PathExclusionCard;
