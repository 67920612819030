import {
  ProjectAPIDefinitionsBreadcrumb,
  ProjectAPIDefinitionsInventoryBreadcrumb,
} from "@Components";
import { APIDefinition, APIDefinitionDetails, APIDefinitions } from "@Pages";

export const ProjectApiDefinitionsRoutes = [
  {
    path: "",
    handle: {
      crumb: <ProjectAPIDefinitionsBreadcrumb />,
    },
    children: [
      {
        path: "",
        element: <APIDefinitions />,
      },
      {
        path: ":apiDefinition",
        children: [
          {
            path: "inventory",
            element: <APIDefinition />,
            handle: {
              crumb: <ProjectAPIDefinitionsInventoryBreadcrumb disabled />,
            },
            children: [
              {
                path: ":inventoryId",
                element: <APIDefinitionDetails />,
              },
            ],
          },
        ],
      },
    ],
  },
];
