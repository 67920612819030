import type { ChangeEvent } from "react";
import { useCallback, useEffect, useMemo } from "react";
import type { DictionaryFormProps } from "./DictionaryForm.i";
import { isFilledString, type DictionaryEntry } from "@Services";
import type { Options, ValidityState } from "@Interfaces";

export const useDictionaryForm = ({
  entry,
  setEntry,
  keywords,
  setFormValid,
  disabled,
}: DictionaryFormProps) => {
  const updateKey = useCallback(
    (key: string) => {
      setEntry(prev => ({ ...prev, key }));
    },
    [setEntry]
  );

  const handleKeyInputBlur = useCallback(() => {
    setEntry(prev => {
      const key = prev.key.trim();
      if (key === prev.key) return prev;
      return { ...prev, key };
    });
  }, [setEntry]);

  const updateValue = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setEntry(prev => ({ ...prev, value }));
    },
    [setEntry]
  );

  const { validities, formValid } = useMemo(() => {
    const validities: ValidityState<DictionaryEntry> = {
      key: isFilledString(entry.key),
      value: entry.value === "" || isFilledString(entry.value),
    };
    return { validities, formValid: Object.values(validities).every(Boolean) };
  }, [entry.key, entry.value]);

  useEffect(() => {
    setFormValid?.(formValid);
  }, [formValid, setFormValid]);

  const comboBoxOptions = useMemo<Options>(
    () =>
      (keywords ?? []).map(s => ({
        label: s,
        value: s,
      })),
    [keywords]
  );

  return {
    entry,
    validities,
    comboBoxOptions,
    disabled,
    updateKey,
    updateValue,
    handleKeyInputBlur,
  };
};
