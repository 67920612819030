import type { OrganizationId } from "@Interfaces";
import type { SpaceLink } from "../client";
import { API } from "../api";

export const fetchSpaceLinkAssociatedProjects = (
  organizationId: OrganizationId,
  spaceLinkId: SpaceLink["id"]
) => {
  return API.project().projectList(
    organizationId,
    1,
    undefined,
    "project_name",
    "asc",
    ["project_space_link_id", "project_archived"],
    ["EQ", "EQ"],
    [spaceLinkId, "false"]
  );
};
