import type { ChangeEvent } from "react";
import { useCallback, useState } from "react";
import type { ResetPasswordPageProps } from "./ResetPasswordPage.i";

export const useResetPasswordPage = (props: ResetPasswordPageProps) => {
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const [validPassword, setValidPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const samePassword = password === repeatPassword;
  const submittable = validPassword && samePassword;

  const updatePassword = useCallback((e: string) => {
    setPassword(e);
  }, []);

  const updatePasswordRepeat = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setRepeatPassword(e.target.value);
    },
    []
  );

  const submitFunction = useCallback(() => {
    if (!submittable) return;
    setLoading(true);
    props.submitFunction(password).finally(() => setLoading(false));
  }, [password, props, submittable]);

  const newPasswordValidities = {
    valid: !!repeatPassword && samePassword,
    invalid: !!repeatPassword && !samePassword,
  };

  return {
    ...props,
    password,
    repeatPassword,
    submittable,
    loading,
    newPasswordValidities,
    updatePassword,
    updatePasswordRepeat,
    setValidPassword,
    submitFunction,
  };
};
