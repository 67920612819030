import type {
  PathExclusion,
  UserListScanPathExclusionsResponse,
} from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay } from "src/Mock";

const responseData: PathExclusion[] = [
  {
    methods: ["GET", "POST"],
    path: "/api/v1/users",
    isMatch: true,
  },
  {
    methods: ["PUT", "DELETE"],
    path: "/api/v1/users/{id}",
    isMatch: false,
  },
  {
    methods: ["GET"],
    path: "/api/v2/products",
    isMatch: true,
  },
];

export const scanPathExclusions = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { searchParams: sP } = req.url;

  const page = Number(sP.get("page")) || 1;
  const size = Number(sP.get("size")) || 12;

  const { organizationId, projectId, scanId } = req.params as Record<
    string,
    string
  >;

  if (!organizationId || !projectId || !scanId) {
    return res(ctx.delay(delay), ctx.status(400));
  }

  const response: UserListScanPathExclusionsResponse = {
    data: responseData,
    page,
    size,
    totalItems: responseData.length,
    totalPages: Math.ceil(responseData.length / size),
  };

  return res(ctx.delay(delay), ctx.status(200), ctx.json(response));
};
