import type { ParameterLinksEntry } from "@Services";

export const ParameterLinks: Record<string, Array<ParameterLinksEntry>> = {
  "1": [
    {
      consumingOperationURI: "/foo",
      consumingOperationVerb: "DELETE",
      consumingParameterLocation: "header",
      consumingParameterPath: "/consuming-parameter",
      producingOperationURI: "/boo",
      producingOperationVerb: "GET",
      producingParameterLocation: "body",
      producingParameterPath: "/header-parameter",
    },
    {
      consumingOperationURI: "/doo",
      consumingOperationVerb: "PATCH",
      consumingParameterLocation: "cookie",
      consumingParameterPath: "/consuming-parameter",
      producingOperationURI: "/boo",
      producingOperationVerb: "GET",
      producingParameterLocation: "header",
      producingParameterPath: "/header-parameter",
    },
    {
      consumingOperationURI: "/noo",
      consumingOperationVerb: "PATCH",
      consumingParameterLocation: "body",
      consumingParameterPath: "/producing-parameter",
      producingOperationURI: "/doo",
      producingOperationVerb: "PATCH",
      producingParameterLocation: "cookie",
      producingParameterPath: "/header-parameter",
    },
    {
      producingOperationURI: "/users",
      producingOperationVerb: "POST",
      producingParameterLocation: "header",
      producingParameterPath: "/auth",
      consumingOperationURI: "/image-gallery",
      consumingOperationVerb: "PUT",
      consumingParameterLocation: "header",
      consumingParameterPath: "/api/access",
    },
    {
      producingOperationURI: "/analytics",
      producingOperationVerb: "PUT",
      producingParameterLocation: "header",
      producingParameterPath: "/auth",
      consumingOperationURI: "/documents",
      consumingOperationVerb: "POST",
      consumingParameterLocation: "body",
      consumingParameterPath: "/timezone",
    },
  ],
};
