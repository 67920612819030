import { InfoBadge } from "@Components";
import type { FC } from "react";

export const BadgeContainer: FC<{
  badges: string | string[];
  mapper?: (key: string) => JSX.Element;
}> = ({ badges, mapper }) => {
  const source = [badges].flat();

  if (!source.length) {
    return "-";
  }

  return (
    <div className="badge-container">
      {source.map((badge, i) => {
        const children = mapper?.(badge) || badge;
        return <InfoBadge key={badge + i} children={children} />;
      })}
    </div>
  );
};
