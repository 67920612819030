import type { InventoryItemParameter } from "@Interfaces";

export const parameters: { [key: string]: InventoryItemParameter[] } = {
  "2929": [
    {
      description: "Filter criteria for custom queries",
      statusCode: "200",
      format: "string",
      in: "query",
      name: "filter",
      not: "Optional",
      oracleFormat: "String",
      oracleType: "Query",
      pathName: "/filter",
      required: false,
      schemaComposition: "FilterSchema",
      schemaReference:
        'e.g., "user-name", "user-surname", "user-email", "user-role", "user-created-at"',
      type: "string",
      usage: "Specify filter criteria for custom queries.",
      evaluation: "????",
    },
    {
      description: "Version of the API to use",
      statusCode: "200",
      format: "string",
      in: "query",
      name: "apiVersion",
      not: "",
      oracleFormat: "String",
      oracleType: "Query",
      pathName: "/version",
      required: false,
      schemaComposition: "VersionSchema",
      schemaReference: 'e.g., "v1"',
      type: "string",
      usage: "Specify the version of the API you want to use.",
      evaluation: "????",
    },
    {
      description: "Page number for result pagination",
      statusCode: "200",
      format: "integer",
      in: "path",
      name: "page",
      not: "",
      oracleFormat: "Integer",
      oracleType: "Query",
      pathName: "/",
      required: false,
      schemaComposition: "",
      schemaReference: "e.g., 1, 2, 3, ...",
      type: "number",
      usage: "Enter the page number for paginating results.",
      evaluation: "????",
    },
    {
      description: "Sorting order for the result set",
      statusCode: "200",
      format: "string",
      in: "cookie",
      name: "sort",
      not: "",
      oracleFormat: "String",
      oracleType: "Query",
      pathName: "/user",
      required: false,
      schemaComposition: "UserDetailsSchema",
      schemaReference: 'e.g., "asc" (ascending) or "desc" (descending)',
      type: "string",
      usage: "Specify the sorting order for the returned results.",
      evaluation: "????",
    },
    {
      description: "Data of the object to create or update",
      statusCode: "200",
      format: "JSON",
      in: "body",
      name: "data",
      not: "",
      oracleFormat: "Object",
      oracleType: "Data",
      pathName: "%3Croot-param%3E",
      required: true,
      schemaComposition: "ObjectSchema",
      schemaReference: 'e.g., { "key": "value" }',
      type: "object",
      usage:
        "Provide the data of the object to create or update in the request body.",
      evaluation: "????",
    },
    {
      description: "Unique identifier for the resource",
      statusCode: "200",
      format: "UUID",
      in: "path",
      name: "id",
      not: "",
      oracleFormat: "String",
      oracleType: "Identifier",
      pathName: "/resource/{id}",
      required: true,
      schemaComposition: "",
      schemaReference: 'e.g., "123e4567-e89b-12d3-a456-426614174000"',
      type: "string",
      usage: "Specify the unique identifier for the resource.",
      evaluation: "????",
    },
    {
      description: "Locale setting for the request",
      statusCode: "200",
      format: "string",
      in: "query",
      name: "locale",
      not: "",
      oracleFormat: "String",
      oracleType: "Setting",
      pathName: "/settings",
      required: false,
      schemaComposition: "LocaleSchema",
      schemaReference: 'e.g., "en-US", "fr-FR"',
      type: "string",
      usage: "Specify the locale setting for the request.",
      evaluation: "????",
    },
    {
      description: "Date and time of the request",
      statusCode: "200",
      format: "ISO 8601",
      in: "query",
      name: "timestamp",
      not: "",
      oracleFormat: "String",
      oracleType: "DateTime",
      pathName: "/time",
      required: false,
      schemaComposition: "",
      schemaReference: 'e.g., "2021-12-31T23:59:59Z"',
      type: "string",
      usage: "Specify the date and time of the request.",
      evaluation: "????",
    },
    {
      description: "Maximum number of results to return",
      statusCode: "200",
      format: "integer",
      in: "query",
      name: "limit",
      not: "",
      oracleFormat: "Integer",
      oracleType: "Query",
      pathName: "/results",
      required: false,
      schemaComposition: "",
      schemaReference: "e.g., 10, 20, 50",
      type: "number",
      usage: "Specify the maximum number of results to return.",
      evaluation: "????",
    },
    {
      description: "Session ID for user session tracking",
      statusCode: "200",
      format: "UUID",
      in: "cookie",
      name: "sessionId",
      not: "",
      oracleFormat: "String",
      oracleType: "Session",
      pathName: "/session",
      required: true,
      schemaComposition: "",
      schemaReference: 'e.g., "abc123"',
      type: "string",
      usage: "Provide the session ID for user session tracking.",
      evaluation: "????",
    },
    {
      description: "E-mail address for notifications",
      statusCode: "200",
      format: "email",
      in: "query",
      name: "email",
      not: "",
      oracleFormat: "String",
      oracleType: "Contact",
      pathName: "/notifications",
      required: true,
      schemaComposition: "",
      schemaReference: 'e.g., "user@example.com"',
      type: "string",
      usage: "Specify the e-mail address for receiving notifications.",
      evaluation: "????",
    },
    {
      description: "OAuth 2.0 Authorization code",
      statusCode: "200",
      format: "string",
      in: "query",
      name: "code",
      not: "",
      oracleFormat: "String",
      oracleType: "Authentication",
      pathName: "/oauth2/callback",
      required: true,
      schemaComposition: "",
      schemaReference: 'e.g., "abc123"',
      type: "string",
      usage: "Provide the OAuth 2.0 authorization code.",
      evaluation: "????",
    },
    {
      description: "Timestamp for the event",
      statusCode: "200",
      format: "ISO 8601",
      in: "query",
      name: "eventTime",
      not: "",
      oracleFormat: "String",
      oracleType: "DateTime",
      pathName: "/event",
      required: false,
      schemaComposition: "",
      schemaReference: 'e.g., "2021-12-31T23:59:59Z"',
      type: "string",
      usage: "Specify the timestamp for the event.",
      evaluation: "????",
    },
    {
      description: "List of IDs for bulk operations",
      statusCode: "200",
      format: "Array of UUIDs",
      in: "body",
      name: "ids",
      not: "",
      oracleFormat: "Array",
      oracleType: "BulkOperation",
      pathName: "/bulk",
      required: true,
      schemaComposition: "ArraySchema",
      schemaReference: 'e.g., ["123e4567-e89b-12d3-a456-426614174000"]',
      type: "array",
      usage: "Provide a list of IDs for bulk operations.",
      evaluation: "????",
    },
    {
      description: "Callback URL for asynchronous operations",
      statusCode: "200",
      format: "URL",
      in: "query",
      name: "callbackUrl",
      not: "",
      oracleFormat: "String",
      oracleType: "URL",
      pathName: "/callback",
      required: false,
      schemaComposition: "",
      schemaReference: 'e.g., "https://example.com/callback"',
      type: "string",
      usage: "Specify the callback URL for asynchronous operations.",
      evaluation: "????",
    },
    {
      description: "Access control level for the resource",
      statusCode: "200",
      format: "string",
      in: "query",
      name: "acl",
      not: "",
      oracleFormat: "String",
      oracleType: "Control",
      pathName: "/acl",
      required: false,
      schemaComposition: "",
      schemaReference: 'e.g., "read", "write", "admin"',
      type: "string",
      usage: "Specify the access control level for the resource.",
      evaluation: "????",
    },
    {
      description: "Refresh token for obtaining new access tokens",
      statusCode: "200",
      format: "JWT",
      in: "body",
      name: "refreshToken",
      not: "",
      oracleFormat: "String",
      oracleType: "Authentication",
      pathName: "/token/refresh",
      required: true,
      schemaComposition: "",
      schemaReference: "Bearer <refresh-token>",
      type: "string",
      usage: "Provide the refresh token to obtain a new access token.",
      evaluation: "????",
    },
    {
      description: "Flag to indicate a dry run",
      statusCode: "200",
      format: "boolean",
      in: "query",
      name: "dryRun",
      not: "",
      oracleFormat: "Boolean",
      oracleType: "Operation",
      pathName: "/operation",
      required: false,
      schemaComposition: "",
      schemaReference: "e.g., true or false",
      type: "boolean",
      usage: "Specify whether to perform a dry run of the operation.",
      evaluation: "????",
    },
    {
      description: "Currency code for transactions",
      statusCode: "200",
      format: "ISO 4217",
      in: "body",
      name: "currency",
      not: "",
      oracleFormat: "String",
      oracleType: "Finance",
      pathName: "/transactions",
      required: false,
      schemaComposition: "",
      schemaReference: 'e.g., "USD", "EUR"',
      type: "string",
      usage: "Specify the currency code for the transactions.",
      evaluation: "???",
    },
    {
      description: "Time zone for the request",
      statusCode: "200",
      format: "string",
      in: "body",
      name: "timeZone",
      not: "",
      oracleFormat: "String",
      oracleType: "Setting",
      pathName: "/timezone",
      required: false,
      schemaComposition: "",
      schemaReference: 'e.g., "UTC", "America/New_York"',
      type: "string",
      usage: "Specify the time zone for the request.",
      evaluation: "???",
    },
    {
      description: "Debug mode flag",
      statusCode: "200",
      format: "boolean",
      in: "query",
      name: "debug",
      not: "",
      oracleFormat: "Boolean",
      oracleType: "Setting",
      pathName: "/debug",
      required: false,
      schemaComposition: "",
      schemaReference: "e.g., true or false",
      type: "boolean",
      usage: "Specify whether to enable debug mode.",
      evaluation: "???",
    },
    {
      description: "Customer ID for the request",
      statusCode: "200",
      format: "UUID",
      in: "query",
      name: "customerId",
      not: "",
      oracleFormat: "String",
      oracleType: "Identifier",
      pathName: "/customer",
      required: true,
      schemaComposition: "",
      schemaReference: 'e.g., "123e4567-e89b-12d3-a456-426614174000"',
      type: "string",
      usage: "Specify the customer ID for the request.",
      evaluation: "???",
    },
    {
      description: "Webhook event type",
      statusCode: "200",
      format: "string",
      in: "query",
      name: "eventType",
      not: "",
      oracleFormat: "String",
      oracleType: "Event",
      pathName: "/webhook",
      required: true,
      schemaComposition: "",
      schemaReference: 'e.g., "order.created", "payment.failed"',
      type: "string",
      usage: "Specify the type of webhook event.",
      evaluation: "???",
    },
    {
      description: "User's language preference",
      statusCode: "200",
      format: "string",
      in: "query",
      name: "language",
      not: "",
      oracleFormat: "String",
      oracleType: "Setting",
      pathName: "/language",
      required: false,
      schemaComposition: "",
      schemaReference: 'e.g., "en", "fr", "es"',
      type: "string",
      usage: "Specify the user's language preference.",
      evaluation: "???",
    },
    {
      description: "Country code for the request",
      statusCode: "200",
      format: "ISO 3166-1 alpha-2",
      in: "query",
      name: "country",
      not: "",
      oracleFormat: "String",
      oracleType: "Location",
      pathName: "/country",
      required: false,
      schemaComposition: "",
      schemaReference: 'e.g., "US", "FR", "ES"',
      type: "string",
      usage: "Specify the country code for the request.",
      evaluation: "???",
    },
    {
      description: "Timezone offset for the request",
      statusCode: "200",
      format: "integer",
      in: "query",
      name: "tzOffset",
      not: "",
      oracleFormat: "Integer",
      oracleType: "Setting",
      pathName: "/timezone",
      required: false,
      schemaComposition: "",
      schemaReference: "e.g., -5, 0, 3",
      type: "number",
      usage: "Specify the timezone offset for the request.",
      evaluation: "???",
    },
    {
      description: "Search query string",
      statusCode: "200",
      format: "string",
      in: "query",
      name: "search",
      not: "",
      oracleFormat: "String",
      oracleType: "Query",
      pathName: "/search",
      required: false,
      schemaComposition: "",
      schemaReference: 'e.g., "keyword1, keyword2"',
      type: "string",
      usage: "Provide the search query string.",
      evaluation: "???",
    },
    {
      description: "Customer's date of birth",
      statusCode: "200",
      format: "ISO 8601",
      in: "query",
      name: "dob",
      not: "",
      oracleFormat: "String",
      oracleType: "PersonalInfo",
      pathName: "/customer",
      required: false,
      schemaComposition: "",
      schemaReference: 'e.g., "1990-01-01"',
      type: "string",
      usage: "Specify the customer's date of birth.",
      evaluation: "???",
    },
    {
      description: "Metadata for the request",
      statusCode: "200",
      format: "JSON",
      in: "body",
      name: "metadata",
      not: "",
      oracleFormat: "Object",
      oracleType: "Metadata",
      pathName: "/metadata",
      required: false,
      schemaComposition: "",
      schemaReference: 'e.g., { "key": "value" }',
      type: "object",
      usage: "Provide additional metadata for the request.",
      evaluation: "???",
    },
    {
      description: "Flag to indicate if the response should be compressed",
      statusCode: "200",
      format: "boolean",
      in: "body",
      name: "compress",
      not: "",
      oracleFormat: "Boolean",
      oracleType: "Setting",
      pathName: "/response",
      required: false,
      schemaComposition: "",
      schemaReference: "e.g., true or false",
      type: "boolean",
      usage: "Specify whether the response should be compressed.",
      evaluation: "???",
    },
    {
      description: "ID of the item to delete",
      statusCode: "200",
      format: "UUID",
      in: "body",
      name: "itemId",
      not: "",
      oracleFormat: "String",
      oracleType: "Identifier",
      pathName: "/item/{itemId}",
      required: true,
      schemaComposition: "",
      schemaReference: 'e.g., "123e4567-e89b-12d3-a456-426614174000"',
      type: "string",
      usage: "Specify the ID of the item to delete.",
      evaluation: "???",
    },
  ],
  "6789": [
    {
      in: "query",
      name: "itemsPerPage",
      usage: "input",
      type: "integer",
      description: "The number of items to display on each page",
      required: false,
      format: "int32",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "pagination",
      pathName: "itemsPerPage",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "query",
      name: "page",
      usage: "input",
      type: "integer",
      description: "The page of output to display",
      required: false,
      format: "int32",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "pagination",
      pathName: "page",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "query",
      name: "sortField",
      usage: "input",
      type: "string",
      description: "The field on which to sort the output",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "sortField",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "query",
      name: "sortOrder",
      usage: "input",
      type: "boolean",
      description: "True to sort ascending, False to sort descending",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "boolean",
      oracleFormat: "boolean",
      pathName: "sortOrder",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "query",
      name: "includeVersions",
      usage: "input",
      type: "boolean",
      description: "True to get all component versions with the components",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "boolean",
      oracleFormat: "boolean",
      pathName: "includeVersions",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "AutomotiveSoftwareFilterPost",
      usage: "input",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareFilterPost",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareFilterPost",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "componentFilterStatus",
      usage: "input",
      type: "array",
      description: "List of software statuses",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareFilterPost",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareFilterPost/componentFilterStatus",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<array-primitive-parameter>",
      usage: "input",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareFilterPost",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName:
        "AutomotiveSoftwareFilterPost/componentFilterStatus/%3Carray-primitive-parameter%3E",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "componentIds",
      usage: "input",
      type: "array",
      description: "List of software IDs",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareFilterPost",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareFilterPost/componentIds",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<array-primitive-parameter>",
      usage: "input",
      type: "integer",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareFilterPost",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName:
        "AutomotiveSoftwareFilterPost/componentIds/%3Carray-primitive-parameter%3E",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "componentName",
      usage: "input",
      type: "string",
      description: "Software name",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareFilterPost",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "AutomotiveSoftwareFilterPost/componentName",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "creationTime",
      usage: "input",
      type: "integer",
      description: "Component creation time. Format: POSIX time in ms",
      required: false,
      format: "int64",
      schemaReference: "#/components/schemas/AutomotiveSoftwareFilterPost",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "datetime",
      pathName: "AutomotiveSoftwareFilterPost/creationTime",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "domainIds",
      usage: "input",
      type: "array",
      description: "List of domain IDs",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareFilterPost",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareFilterPost/domainIds",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<array-primitive-parameter>",
      usage: "input",
      type: "integer",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareFilterPost",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName:
        "AutomotiveSoftwareFilterPost/domainIds/%3Carray-primitive-parameter%3E",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "ecuModelIds",
      usage: "input",
      type: "array",
      description: "A list of ECU model IDs",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareFilterPost",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareFilterPost/ecuModelIds",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<array-primitive-parameter>",
      usage: "input",
      type: "integer",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareFilterPost",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName:
        "AutomotiveSoftwareFilterPost/ecuModelIds/%3Carray-primitive-parameter%3E",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "installerTypes",
      usage: "input",
      type: "array",
      description: "List of installer type IDs",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareFilterPost",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareFilterPost/installerTypes",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "demo",
      usage: "input",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareFilterPost",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "AutomotiveSoftwareFilterPost/installerTypes/demo",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<array-primitive-parameter>",
      usage: "input",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareFilterPost",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName:
        "AutomotiveSoftwareFilterPost/installerTypes/demo/%3Carray-primitive-parameter%3E",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "onlyWithZeroVersion",
      usage: "input",
      type: "boolean",
      description: "True to get components only with zero version",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareFilterPost",
      schemaComposition: "",
      not: "",
      oracleType: "boolean",
      oracleFormat: "boolean",
      pathName: "AutomotiveSoftwareFilterPost/onlyWithZeroVersion",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "platformIds",
      usage: "input",
      type: "array",
      description: "List of platform IDs",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareFilterPost",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareFilterPost/platformIds",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<array-primitive-parameter>",
      usage: "input",
      type: "integer",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareFilterPost",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName:
        "AutomotiveSoftwareFilterPost/platformIds/%3Carray-primitive-parameter%3E",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "vehicleModelIds",
      usage: "input",
      type: "array",
      description: "A list of vehicle model IDs",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareFilterPost",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareFilterPost/vehicleModelIds",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<array-primitive-parameter>",
      usage: "input",
      type: "integer",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareFilterPost",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName:
        "AutomotiveSoftwareFilterPost/vehicleModelIds/%3Carray-primitive-parameter%3E",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "ComponentRepresentationList",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ComponentRepresentationList",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "ComponentRepresentationList",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "totalCount",
      usage: "output",
      type: "integer",
      description: "",
      required: false,
      format: "int64",
      schemaReference: "#/components/schemas/ComponentRepresentationList",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "pagination",
      pathName: "ComponentRepresentationList/totalCount",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "totalCountIncludingSubDomains",
      usage: "output",
      type: "integer",
      description: "",
      required: false,
      format: "int64",
      schemaReference: "#/components/schemas/ComponentRepresentationList",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "ComponentRepresentationList/totalCountIncludingSubDomains",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<root-param>",
      usage: "output",
      type: "object",
      description: "",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "%3Croot-param%3E",
      statusCode: "400",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "httpError",
      usage: "output",
      type: "string",
      description: "",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "%3Croot-param%3E/httpError",
      statusCode: "400",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "key",
      usage: "output",
      type: "string",
      description: "",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "%3Croot-param%3E/key",
      statusCode: "400",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizedMessage",
      usage: "output",
      type: "string",
      description: "",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "%3Croot-param%3E/localizedMessage",
      statusCode: "400",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<root-param>",
      usage: "output",
      type: "object",
      description: "",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "%3Croot-param%3E",
      statusCode: "404",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "key",
      usage: "output",
      type: "string",
      description: "",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "%3Croot-param%3E/key",
      statusCode: "404",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizedMessage",
      usage: "output",
      type: "string",
      description: "",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "%3Croot-param%3E/localizedMessage",
      statusCode: "404",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "httpError",
      usage: "output",
      type: "string",
      description: "",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "%3Croot-param%3E/httpError",
      statusCode: "404",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<root-param>",
      usage: "output",
      type: "object",
      description: "",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "%3Croot-param%3E",
      statusCode: "401",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "httpError",
      usage: "output",
      type: "string",
      description: "",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "%3Croot-param%3E/httpError",
      statusCode: "401",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "key",
      usage: "output",
      type: "string",
      description: "",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "%3Croot-param%3E/key",
      statusCode: "401",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizedMessage",
      usage: "output",
      type: "string",
      description: "",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "%3Croot-param%3E/localizedMessage",
      statusCode: "401",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<root-param>",
      usage: "output",
      type: "object",
      description: "",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "%3Croot-param%3E",
      statusCode: "405",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "key",
      usage: "output",
      type: "string",
      description: "",
      required: false,
      format: "other",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "%3Croot-param%3E/key",
      statusCode: "405",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizedMessage",
      usage: "output",
      type: "string",
      description: "",
      required: false,
      format: "other",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "%3Croot-param%3E/localizedMessage",
      statusCode: "405",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "httpError",
      usage: "output",
      type: "string",
      description: "",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "%3Croot-param%3E/httpError",
      statusCode: "405",
      evaluation: "uncertain",
    },
  ],
  "12929": [
    {
      in: "path",
      name: "componentId",
      usage: "input",
      type: "integer",
      description: "The software ID",
      required: true,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "",
      pathName: "componentId",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "query",
      name: "inRootDomain",
      usage: "input",
      type: "boolean",
      description:
        "If true, look for the software in the current domain and its sub-domains. If false, look for the software only in the current domain",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "boolean",
      oracleFormat: "",
      pathName: "inRootDomain",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "AutomotiveSoftwareRepresentation",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareRepresentation",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "actionResult",
      usage: "output",
      type: "array",
      description: "Result and reason of an action. Contains error details.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareRepresentation/actionResult",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "ActionResultRepresentation",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionResultRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/actionResult/ActionResultRepresentation",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "code",
      usage: "output",
      type: "integer",
      description: "Result code",
      required: true,
      format: "int32",
      schemaReference: "#/components/schemas/ActionResultRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "int32",
      pathName:
        "AutomotiveSoftwareRepresentation/actionResult/ActionResultRepresentation/code",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "priority",
      usage: "output",
      type: "integer",
      description: "Result priority",
      required: true,
      format: "int32",
      schemaReference: "#/components/schemas/ActionResultRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "int32",
      pathName:
        "AutomotiveSoftwareRepresentation/actionResult/ActionResultRepresentation/priority",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "reasonMessage",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/actionResult/ActionResultRepresentation/reasonMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizationMessageId",
      usage: "output",
      type: "string",
      description:
        'Message localization key.\n\n This is the key in the localization file, and is the "return code" of the action. If there is a problem substituting the parameters in the message (because the message is formatted badly with the wrong parameters, etc), 1016 is returned, indicating an unexpected exception',
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/actionResult/ActionResultRepresentation/reasonMessage/localizationMessageId",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizedMessage",
      usage: "output",
      type: "string",
      description:
        "The formatted localized message. If there is a problem substituting the parameters in the message, the message is returned unformatted.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/actionResult/ActionResultRepresentation/reasonMessage/localizedMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "parameters",
      usage: "output",
      type: "array",
      description:
        "Message parameters. These parameters have already been substituted into localizedMessage.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/actionResult/ActionResultRepresentation/reasonMessage/parameters",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<array-primitive-parameter>",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/actionResult/ActionResultRepresentation/reasonMessage/parameters/%3Carray-primitive-parameter%3E",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "resultMessage",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/actionResult/ActionResultRepresentation/resultMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizationMessageId",
      usage: "output",
      type: "string",
      description:
        'Message localization key.\n\n This is the key in the localization file, and is the "return code" of the action. If there is a problem substituting the parameters in the message (because the message is formatted badly with the wrong parameters, etc), 1016 is returned, indicating an unexpected exception',
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/actionResult/ActionResultRepresentation/resultMessage/localizationMessageId",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizedMessage",
      usage: "output",
      type: "string",
      description:
        "The formatted localized message. If there is a problem substituting the parameters in the message, the message is returned unformatted.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/actionResult/ActionResultRepresentation/resultMessage/localizedMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "parameters",
      usage: "output",
      type: "array",
      description:
        "Message parameters. These parameters have already been substituted into localizedMessage.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/actionResult/ActionResultRepresentation/resultMessage/parameters",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<array-primitive-parameter>",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/actionResult/ActionResultRepresentation/resultMessage/parameters/%3Carray-primitive-parameter%3E",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "applicableForRollback",
      usage: "output",
      type: "boolean",
      description: "Whether rollback is applicable or not.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "boolean",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareRepresentation/applicableForRollback",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "availableStatusesForNewVersion",
      usage: "output",
      type: "array",
      description:
        "A comma-separated list of available statuses for new versions.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/availableStatusesForNewVersion",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<array-primitive-parameter>",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/availableStatusesForNewVersion/%3Carray-primitive-parameter%3E",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "componentVersionsRep",
      usage: "output",
      type: "array",
      description:
        "A list of associated versions. List of ComponentVersionRepresentation.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareRepresentation/componentVersionsRep",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "ComponentVersionRepresentation",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "actionResult",
      usage: "output",
      type: "array",
      description: "Result and reason of an action. Contains error details.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/actionResult",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "ActionResultRepresentation",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionResultRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/actionResult/ActionResultRepresentation",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "code",
      usage: "output",
      type: "integer",
      description: "Result code",
      required: true,
      format: "int32",
      schemaReference: "#/components/schemas/ActionResultRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "int32",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/actionResult/ActionResultRepresentation/code",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "priority",
      usage: "output",
      type: "integer",
      description: "Result priority",
      required: true,
      format: "int32",
      schemaReference: "#/components/schemas/ActionResultRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "int32",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/actionResult/ActionResultRepresentation/priority",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "reasonMessage",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/actionResult/ActionResultRepresentation/reasonMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizationMessageId",
      usage: "output",
      type: "string",
      description:
        'Message localization key.\n\n This is the key in the localization file, and is the "return code" of the action. If there is a problem substituting the parameters in the message (because the message is formatted badly with the wrong parameters, etc), 1016 is returned, indicating an unexpected exception',
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/actionResult/ActionResultRepresentation/reasonMessage/localizationMessageId",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizedMessage",
      usage: "output",
      type: "string",
      description:
        "The formatted localized message. If there is a problem substituting the parameters in the message, the message is returned unformatted.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/actionResult/ActionResultRepresentation/reasonMessage/localizedMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "parameters",
      usage: "output",
      type: "array",
      description:
        "Message parameters. These parameters have already been substituted into localizedMessage.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/actionResult/ActionResultRepresentation/reasonMessage/parameters",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<array-primitive-parameter>",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/actionResult/ActionResultRepresentation/reasonMessage/parameters/%3Carray-primitive-parameter%3E",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "resultMessage",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/actionResult/ActionResultRepresentation/resultMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizationMessageId",
      usage: "output",
      type: "string",
      description:
        'Message localization key.\n\n This is the key in the localization file, and is the "return code" of the action. If there is a problem substituting the parameters in the message (because the message is formatted badly with the wrong parameters, etc), 1016 is returned, indicating an unexpected exception',
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/actionResult/ActionResultRepresentation/resultMessage/localizationMessageId",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizedMessage",
      usage: "output",
      type: "string",
      description:
        "The formatted localized message. If there is a problem substituting the parameters in the message, the message is returned unformatted.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/actionResult/ActionResultRepresentation/resultMessage/localizedMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "parameters",
      usage: "output",
      type: "array",
      description:
        "Message parameters. These parameters have already been substituted into localizedMessage.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/actionResult/ActionResultRepresentation/resultMessage/parameters",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<array-primitive-parameter>",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/actionResult/ActionResultRepresentation/resultMessage/parameters/%3Carray-primitive-parameter%3E",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "applicableForRollback",
      usage: "output",
      type: "boolean",
      description: "Whether rollback is applicable or not.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "boolean",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/applicableForRollback",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "availableStatuses",
      usage: "output",
      type: "array",
      description: "A list of available statuses.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/availableStatuses",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<array-primitive-parameter>",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/availableStatuses/%3Carray-primitive-parameter%3E",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "componentId",
      usage: "output",
      type: "string",
      description: "The ID of the software instance.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/componentId",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "componentInstallerType",
      usage: "output",
      type: "string",
      description: "The software instance installer type.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/componentInstallerType",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "componentName",
      usage: "output",
      type: "string",
      description: "The software instance name.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/componentName",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "componentVariant",
      usage: "output",
      type: "string",
      description: "The software instance variant.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/componentVariant",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "componentVendor",
      usage: "output",
      type: "string",
      description: "The software instance vendor.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/componentVendor",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "deprecationStatus",
      usage: "output",
      type: "string",
      description:
        "Deprecation status. Describes whether the endpoint is deprecated or not. Will contain information about version which the deprecation started in and suggestion of the endpoint to use instead.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/deprecationStatus",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "description",
      usage: "output",
      type: "string",
      description: "The software description.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/description",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "estimatedUpdateTime",
      usage: "output",
      type: "integer",
      description:
        "The estimated time to update the software version, in minutes.",
      required: false,
      format: "int32",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "int32",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/estimatedUpdateTime",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "icon",
      usage: "output",
      type: "string",
      description: "The software icon.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/icon",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "id",
      usage: "output",
      type: "integer",
      description: "Representation ID",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/id",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "isFirmware",
      usage: "output",
      type: "boolean",
      description: "Whether the software instance is of type firmware.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "boolean",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/isFirmware",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "latestComponentVersionId",
      usage: "output",
      type: "integer",
      description: "The latest software version ID.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/latestComponentVersionId",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "longDescription",
      usage: "output",
      type: "string",
      description: "The software long description.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/longDescription",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "md5",
      usage: "output",
      type: "string",
      description: "The generated MD5 hash for the app file.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/md5",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "name",
      usage: "output",
      type: "string",
      description: "The software name.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/name",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "originalFileName",
      usage: "output",
      type: "string",
      description: "The file name of the associated binary.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/originalFileName",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "platformTypeByFileExtension",
      usage: "output",
      type: "string",
      description: "The platform type by uploaded file extension.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/platformTypeByFileExtension",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "readerMaxDeltaFormat",
      usage: "output",
      type: "integer",
      description:
        "The maximum Delta format that this version can read. Relevant for reader components.",
      required: false,
      format: "int32",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "int32",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/readerMaxDeltaFormat",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "readerMinDeltaFormat",
      usage: "output",
      type: "integer",
      description:
        "The minimum Delta format that this version can read. Relevant for reader components.",
      required: false,
      format: "int32",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "int32",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/readerMinDeltaFormat",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "releaseNotes",
      usage: "output",
      type: "string",
      description: "Release notes",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/releaseNotes",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "scomoId",
      usage: "output",
      type: "string",
      description:
        "The software instance SCOMO ID. Name of the software (often fully qualified).",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/scomoId",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "status",
      usage: "output",
      type: "string",
      description: "The version status",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/status",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "systemPermissions",
      usage: "output",
      type: "boolean",
      description:
        "Whether to install with full permissions. Relevant for Android system apps on KitKat or later.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "boolean",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/systemPermissions",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "tempFileName",
      usage: "output",
      type: "string",
      description:
        "Temporary file name for uploaded binary. This field is returned after you upload the version binary; it is used when adding the version to the server. The file name expires after a short time.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/tempFileName",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "versionNumber",
      usage: "output",
      type: "integer",
      description: "The software version number.",
      required: true,
      format: "int64",
      schemaReference: "#/components/schemas/ComponentVersionRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "int64",
      pathName:
        "AutomotiveSoftwareRepresentation/componentVersionsRep/ComponentVersionRepresentation/versionNumber",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "creationUserName",
      usage: "output",
      type: "string",
      description: "The username of the creator of the software.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareRepresentation/creationUserName",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "vendor",
      usage: "output",
      type: "string",
      description: "The software vendor",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareRepresentation/vendor",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "defaultStatusForNewVersion",
      usage: "output",
      type: "string",
      description:
        "The default status for new versions. Relevant only for firmware.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareRepresentation/defaultStatusForNewVersion",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "deprecationStatus",
      usage: "output",
      type: "string",
      description:
        "Deprecation status. Describes whether the endpoint is deprecated or not. Will contain information about version which the deprecation started in and suggestion of the endpoint to use instead.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareRepresentation/deprecationStatus",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "description",
      usage: "output",
      type: "string",
      description: "The software description.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareRepresentation/description",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "domains",
      usage: "output",
      type: "array",
      description:
        "A list of associated domains. List of DomainRepresentation. Relevant only if isConnectedToAllDomainTreeis false.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareRepresentation/domains",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "DomainRepresentation",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareRepresentation/domains/DomainRepresentation",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "actionResult",
      usage: "output",
      type: "array",
      description: "Result and reason of an action. Contains error details.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/actionResult",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "ActionResultRepresentation",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionResultRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/actionResult/ActionResultRepresentation",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "code",
      usage: "output",
      type: "integer",
      description: "Result code",
      required: true,
      format: "int32",
      schemaReference: "#/components/schemas/ActionResultRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "int32",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/actionResult/ActionResultRepresentation/code",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "priority",
      usage: "output",
      type: "integer",
      description: "Result priority",
      required: true,
      format: "int32",
      schemaReference: "#/components/schemas/ActionResultRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "int32",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/actionResult/ActionResultRepresentation/priority",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "reasonMessage",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/actionResult/ActionResultRepresentation/reasonMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizationMessageId",
      usage: "output",
      type: "string",
      description:
        'Message localization key.\n\n This is the key in the localization file, and is the "return code" of the action. If there is a problem substituting the parameters in the message (because the message is formatted badly with the wrong parameters, etc), 1016 is returned, indicating an unexpected exception',
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/actionResult/ActionResultRepresentation/reasonMessage/localizationMessageId",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizedMessage",
      usage: "output",
      type: "string",
      description:
        "The formatted localized message. If there is a problem substituting the parameters in the message, the message is returned unformatted.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/actionResult/ActionResultRepresentation/reasonMessage/localizedMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "parameters",
      usage: "output",
      type: "array",
      description:
        "Message parameters. These parameters have already been substituted into localizedMessage.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/actionResult/ActionResultRepresentation/reasonMessage/parameters",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<array-primitive-parameter>",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/actionResult/ActionResultRepresentation/reasonMessage/parameters/%3Carray-primitive-parameter%3E",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "resultMessage",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/actionResult/ActionResultRepresentation/resultMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizationMessageId",
      usage: "output",
      type: "string",
      description:
        'Message localization key.\n\n This is the key in the localization file, and is the "return code" of the action. If there is a problem substituting the parameters in the message (because the message is formatted badly with the wrong parameters, etc), 1016 is returned, indicating an unexpected exception',
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/actionResult/ActionResultRepresentation/resultMessage/localizationMessageId",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizedMessage",
      usage: "output",
      type: "string",
      description:
        "The formatted localized message. If there is a problem substituting the parameters in the message, the message is returned unformatted.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/actionResult/ActionResultRepresentation/resultMessage/localizedMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "parameters",
      usage: "output",
      type: "array",
      description:
        "Message parameters. These parameters have already been substituted into localizedMessage.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/actionResult/ActionResultRepresentation/resultMessage/parameters",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<array-primitive-parameter>",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/actionResult/ActionResultRepresentation/resultMessage/parameters/%3Carray-primitive-parameter%3E",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "allowedRoles",
      usage: "output",
      type: "array",
      description:
        "Roles available to users in this domain. For the list of values, see RoleRepresentation",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/allowedRoles",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<array-primitive-parameter>",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/allowedRoles/%3Carray-primitive-parameter%3E",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "businessKey",
      usage: "output",
      type: "string",
      description: "Domain business key (same as the name)",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/businessKey",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "capabilities",
      usage: "output",
      type: "object",
      description:
        'List of capabilities for this root domain (according to the server configuration)\nConfiguration values list, that can be updated:\nSettings:\nkey: "device.setting.enabled" , possible values:  0(not supported)/1(supported)',
      required: false,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/capabilities",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "deprecationStatus",
      usage: "output",
      type: "string",
      description:
        "Deprecation status. Describes whether the endpoint is deprecated or not. Will contain information about version which the deprecation started in and suggestion of the endpoint to use instead.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/deprecationStatus",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "directChildrenDomains",
      usage: "output",
      type: "array",
      description: "List of immediate subdomains",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/directChildrenDomains",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "DomainRepresentation",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/directChildrenDomains/DomainRepresentation",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "id",
      usage: "output",
      type: "integer",
      description: "Representation ID",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/id",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "isLdap",
      usage: "output",
      type: "boolean",
      description: "Whether the domain hierarchy uses LDAP",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "boolean",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/isLdap",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "name",
      usage: "output",
      type: "string",
      description: "Domain name",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/name",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "pin",
      usage: "output",
      type: "string",
      description:
        "Domain PIN number. This is generated by the server when the domain is created",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/pin",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "serviceCampaigns",
      usage: "output",
      type: "boolean",
      description:
        "Whether a user in the SERVICE domain can run campaigns in this domain hierarchy",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "boolean",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/serviceCampaigns",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "status",
      usage: "output",
      type: "string",
      description: "Domain status",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/status",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "type",
      usage: "output",
      type: "string",
      description: "Domain type. Available types: REGULAR, AUTOMOTIVE",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/domains/DomainRepresentation/type",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "ecuModelRepresentations",
      usage: "output",
      type: "array",
      description: "A list of EcuModelRepresentation",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareRepresentation/ecuModelRepresentations",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "ModelRepresentation",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ModelRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "actionResult",
      usage: "output",
      type: "array",
      description: "Result and reason of an action. Contains error details.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ModelRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/actionResult",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "ActionResultRepresentation",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionResultRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/actionResult/ActionResultRepresentation",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "code",
      usage: "output",
      type: "integer",
      description: "Result code",
      required: true,
      format: "int32",
      schemaReference: "#/components/schemas/ActionResultRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "int32",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/actionResult/ActionResultRepresentation/code",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "priority",
      usage: "output",
      type: "integer",
      description: "Result priority",
      required: true,
      format: "int32",
      schemaReference: "#/components/schemas/ActionResultRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "int32",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/actionResult/ActionResultRepresentation/priority",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "reasonMessage",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/actionResult/ActionResultRepresentation/reasonMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizationMessageId",
      usage: "output",
      type: "string",
      description:
        'Message localization key.\n\n This is the key in the localization file, and is the "return code" of the action. If there is a problem substituting the parameters in the message (because the message is formatted badly with the wrong parameters, etc), 1016 is returned, indicating an unexpected exception',
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/actionResult/ActionResultRepresentation/reasonMessage/localizationMessageId",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizedMessage",
      usage: "output",
      type: "string",
      description:
        "The formatted localized message. If there is a problem substituting the parameters in the message, the message is returned unformatted.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/actionResult/ActionResultRepresentation/reasonMessage/localizedMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "parameters",
      usage: "output",
      type: "array",
      description:
        "Message parameters. These parameters have already been substituted into localizedMessage.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/actionResult/ActionResultRepresentation/reasonMessage/parameters",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<array-primitive-parameter>",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/actionResult/ActionResultRepresentation/reasonMessage/parameters/%3Carray-primitive-parameter%3E",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "resultMessage",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/actionResult/ActionResultRepresentation/resultMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizationMessageId",
      usage: "output",
      type: "string",
      description:
        'Message localization key.\n\n This is the key in the localization file, and is the "return code" of the action. If there is a problem substituting the parameters in the message (because the message is formatted badly with the wrong parameters, etc), 1016 is returned, indicating an unexpected exception',
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/actionResult/ActionResultRepresentation/resultMessage/localizationMessageId",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizedMessage",
      usage: "output",
      type: "string",
      description:
        "The formatted localized message. If there is a problem substituting the parameters in the message, the message is returned unformatted.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/actionResult/ActionResultRepresentation/resultMessage/localizedMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "parameters",
      usage: "output",
      type: "array",
      description:
        "Message parameters. These parameters have already been substituted into localizedMessage.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/actionResult/ActionResultRepresentation/resultMessage/parameters",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<array-primitive-parameter>",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/actionResult/ActionResultRepresentation/resultMessage/parameters/%3Carray-primitive-parameter%3E",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "deprecationStatus",
      usage: "output",
      type: "string",
      description:
        "Deprecation status. Describes whether the endpoint is deprecated or not. Will contain information about version which the deprecation started in and suggestion of the endpoint to use instead.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ModelRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/deprecationStatus",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "domains",
      usage: "output",
      type: "array",
      description: "List of domains associated with this model",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ModelRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "DomainRepresentation",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "actionResult",
      usage: "output",
      type: "array",
      description: "Result and reason of an action. Contains error details.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/actionResult",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "ActionResultRepresentation",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionResultRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/actionResult/ActionResultRepresentation",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "code",
      usage: "output",
      type: "integer",
      description: "Result code",
      required: true,
      format: "int32",
      schemaReference: "#/components/schemas/ActionResultRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "int32",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/actionResult/ActionResultRepresentation/code",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "priority",
      usage: "output",
      type: "integer",
      description: "Result priority",
      required: true,
      format: "int32",
      schemaReference: "#/components/schemas/ActionResultRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "int32",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/actionResult/ActionResultRepresentation/priority",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "reasonMessage",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/actionResult/ActionResultRepresentation/reasonMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizationMessageId",
      usage: "output",
      type: "string",
      description:
        'Message localization key.\n\n This is the key in the localization file, and is the "return code" of the action. If there is a problem substituting the parameters in the message (because the message is formatted badly with the wrong parameters, etc), 1016 is returned, indicating an unexpected exception',
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/actionResult/ActionResultRepresentation/reasonMessage/localizationMessageId",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizedMessage",
      usage: "output",
      type: "string",
      description:
        "The formatted localized message. If there is a problem substituting the parameters in the message, the message is returned unformatted.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/actionResult/ActionResultRepresentation/reasonMessage/localizedMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "parameters",
      usage: "output",
      type: "array",
      description:
        "Message parameters. These parameters have already been substituted into localizedMessage.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/actionResult/ActionResultRepresentation/reasonMessage/parameters",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<array-primitive-parameter>",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/actionResult/ActionResultRepresentation/reasonMessage/parameters/%3Carray-primitive-parameter%3E",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "resultMessage",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/actionResult/ActionResultRepresentation/resultMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizationMessageId",
      usage: "output",
      type: "string",
      description:
        'Message localization key.\n\n This is the key in the localization file, and is the "return code" of the action. If there is a problem substituting the parameters in the message (because the message is formatted badly with the wrong parameters, etc), 1016 is returned, indicating an unexpected exception',
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/actionResult/ActionResultRepresentation/resultMessage/localizationMessageId",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizedMessage",
      usage: "output",
      type: "string",
      description:
        "The formatted localized message. If there is a problem substituting the parameters in the message, the message is returned unformatted.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/actionResult/ActionResultRepresentation/resultMessage/localizedMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "parameters",
      usage: "output",
      type: "array",
      description:
        "Message parameters. These parameters have already been substituted into localizedMessage.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/actionResult/ActionResultRepresentation/resultMessage/parameters",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<array-primitive-parameter>",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/actionResult/ActionResultRepresentation/resultMessage/parameters/%3Carray-primitive-parameter%3E",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "allowedRoles",
      usage: "output",
      type: "array",
      description:
        "Roles available to users in this domain. For the list of values, see RoleRepresentation",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/allowedRoles",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<array-primitive-parameter>",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/allowedRoles/%3Carray-primitive-parameter%3E",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "businessKey",
      usage: "output",
      type: "string",
      description: "Domain business key (same as the name)",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/businessKey",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "capabilities",
      usage: "output",
      type: "object",
      description:
        'List of capabilities for this root domain (according to the server configuration)\nConfiguration values list, that can be updated:\nSettings:\nkey: "device.setting.enabled" , possible values:  0(not supported)/1(supported)',
      required: false,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/capabilities",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "deprecationStatus",
      usage: "output",
      type: "string",
      description:
        "Deprecation status. Describes whether the endpoint is deprecated or not. Will contain information about version which the deprecation started in and suggestion of the endpoint to use instead.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/deprecationStatus",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "directChildrenDomains",
      usage: "output",
      type: "array",
      description: "List of immediate subdomains",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/directChildrenDomains",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "DomainRepresentation",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/directChildrenDomains/DomainRepresentation",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "id",
      usage: "output",
      type: "integer",
      description: "Representation ID",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/id",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "isLdap",
      usage: "output",
      type: "boolean",
      description: "Whether the domain hierarchy uses LDAP",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "boolean",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/isLdap",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "name",
      usage: "output",
      type: "string",
      description: "Domain name",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/name",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "pin",
      usage: "output",
      type: "string",
      description:
        "Domain PIN number. This is generated by the server when the domain is created",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/pin",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "serviceCampaigns",
      usage: "output",
      type: "boolean",
      description:
        "Whether a user in the SERVICE domain can run campaigns in this domain hierarchy",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "boolean",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/serviceCampaigns",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "status",
      usage: "output",
      type: "string",
      description: "Domain status",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/status",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "type",
      usage: "output",
      type: "string",
      description: "Domain type. Available types: REGULAR, AUTOMOTIVE",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/DomainRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/domains/DomainRepresentation/type",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "platformRepresentation",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/PlatformRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/platformRepresentation",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "actionResult",
      usage: "output",
      type: "array",
      description: "Result and reason of an action. Contains error details.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/PlatformRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/platformRepresentation/actionResult",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "ActionResultRepresentation",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionResultRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/platformRepresentation/actionResult/ActionResultRepresentation",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "code",
      usage: "output",
      type: "integer",
      description: "Result code",
      required: true,
      format: "int32",
      schemaReference: "#/components/schemas/ActionResultRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "int32",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/platformRepresentation/actionResult/ActionResultRepresentation/code",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "priority",
      usage: "output",
      type: "integer",
      description: "Result priority",
      required: true,
      format: "int32",
      schemaReference: "#/components/schemas/ActionResultRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "int32",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/platformRepresentation/actionResult/ActionResultRepresentation/priority",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "reasonMessage",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/platformRepresentation/actionResult/ActionResultRepresentation/reasonMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizationMessageId",
      usage: "output",
      type: "string",
      description:
        'Message localization key.\n\n This is the key in the localization file, and is the "return code" of the action. If there is a problem substituting the parameters in the message (because the message is formatted badly with the wrong parameters, etc), 1016 is returned, indicating an unexpected exception',
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/platformRepresentation/actionResult/ActionResultRepresentation/reasonMessage/localizationMessageId",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizedMessage",
      usage: "output",
      type: "string",
      description:
        "The formatted localized message. If there is a problem substituting the parameters in the message, the message is returned unformatted.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/platformRepresentation/actionResult/ActionResultRepresentation/reasonMessage/localizedMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "parameters",
      usage: "output",
      type: "array",
      description:
        "Message parameters. These parameters have already been substituted into localizedMessage.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/platformRepresentation/actionResult/ActionResultRepresentation/reasonMessage/parameters",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<array-primitive-parameter>",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/platformRepresentation/actionResult/ActionResultRepresentation/reasonMessage/parameters/%3Carray-primitive-parameter%3E",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "resultMessage",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/platformRepresentation/actionResult/ActionResultRepresentation/resultMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizationMessageId",
      usage: "output",
      type: "string",
      description:
        'Message localization key.\n\n This is the key in the localization file, and is the "return code" of the action. If there is a problem substituting the parameters in the message (because the message is formatted badly with the wrong parameters, etc), 1016 is returned, indicating an unexpected exception',
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/platformRepresentation/actionResult/ActionResultRepresentation/resultMessage/localizationMessageId",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizedMessage",
      usage: "output",
      type: "string",
      description:
        "The formatted localized message. If there is a problem substituting the parameters in the message, the message is returned unformatted.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/platformRepresentation/actionResult/ActionResultRepresentation/resultMessage/localizedMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "parameters",
      usage: "output",
      type: "array",
      description:
        "Message parameters. These parameters have already been substituted into localizedMessage.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/platformRepresentation/actionResult/ActionResultRepresentation/resultMessage/parameters",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<array-primitive-parameter>",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/platformRepresentation/actionResult/ActionResultRepresentation/resultMessage/parameters/%3Carray-primitive-parameter%3E",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "deprecationStatus",
      usage: "output",
      type: "string",
      description:
        "Deprecation status. Describes whether the endpoint is deprecated or not. Will contain information about version which the deprecation started in and suggestion of the endpoint to use instead.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/PlatformRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/platformRepresentation/deprecationStatus",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "displayValue",
      usage: "output",
      type: "string",
      description: "Platform display name",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/PlatformRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/platformRepresentation/displayValue",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "id",
      usage: "output",
      type: "integer",
      description: "Representation ID",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/PlatformRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/platformRepresentation/id",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "installerTypes",
      usage: "output",
      type: "array",
      description:
        "Available installer type localization keys\n\n. The installer types indicate which types of software can be installed on the device, for example firmware or Android user app. \n\n The localization keys uniquely identify the\ninstaller type, and are also used to find the UI display string of each installer type in the localization files",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/PlatformRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/platformRepresentation/installerTypes",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<array-primitive-parameter>",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/PlatformRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/platformRepresentation/installerTypes/%3Carray-primitive-parameter%3E",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "name",
      usage: "output",
      type: "string",
      description: "Platform name",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/PlatformRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/platformRepresentation/name",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "vendor",
      usage: "output",
      type: "string",
      description: "Platform vendor",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/PlatformRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/platformRepresentation/vendor",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "id",
      usage: "output",
      type: "integer",
      description: "Representation ID",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ModelRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/id",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "name",
      usage: "output",
      type: "string",
      description: "Device model name",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ModelRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/name",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "tools",
      usage: "output",
      type: "array",
      description: "List of tools associated with this model",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ModelRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/tools",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "ToolRepresentation",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ToolRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/tools/ToolRepresentation",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "actionResult",
      usage: "output",
      type: "array",
      description: "Result and reason of an action. Contains error details.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ToolRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/tools/ToolRepresentation/actionResult",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "ActionResultRepresentation",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionResultRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/tools/ToolRepresentation/actionResult/ActionResultRepresentation",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "code",
      usage: "output",
      type: "integer",
      description: "Result code",
      required: true,
      format: "int32",
      schemaReference: "#/components/schemas/ActionResultRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "int32",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/tools/ToolRepresentation/actionResult/ActionResultRepresentation/code",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "priority",
      usage: "output",
      type: "integer",
      description: "Result priority",
      required: true,
      format: "int32",
      schemaReference: "#/components/schemas/ActionResultRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "int32",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/tools/ToolRepresentation/actionResult/ActionResultRepresentation/priority",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "reasonMessage",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/tools/ToolRepresentation/actionResult/ActionResultRepresentation/reasonMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizationMessageId",
      usage: "output",
      type: "string",
      description:
        'Message localization key.\n\n This is the key in the localization file, and is the "return code" of the action. If there is a problem substituting the parameters in the message (because the message is formatted badly with the wrong parameters, etc), 1016 is returned, indicating an unexpected exception',
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/tools/ToolRepresentation/actionResult/ActionResultRepresentation/reasonMessage/localizationMessageId",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizedMessage",
      usage: "output",
      type: "string",
      description:
        "The formatted localized message. If there is a problem substituting the parameters in the message, the message is returned unformatted.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/tools/ToolRepresentation/actionResult/ActionResultRepresentation/reasonMessage/localizedMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "parameters",
      usage: "output",
      type: "array",
      description:
        "Message parameters. These parameters have already been substituted into localizedMessage.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/tools/ToolRepresentation/actionResult/ActionResultRepresentation/reasonMessage/parameters",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<array-primitive-parameter>",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/tools/ToolRepresentation/actionResult/ActionResultRepresentation/reasonMessage/parameters/%3Carray-primitive-parameter%3E",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "resultMessage",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/tools/ToolRepresentation/actionResult/ActionResultRepresentation/resultMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizationMessageId",
      usage: "output",
      type: "string",
      description:
        'Message localization key.\n\n This is the key in the localization file, and is the "return code" of the action. If there is a problem substituting the parameters in the message (because the message is formatted badly with the wrong parameters, etc), 1016 is returned, indicating an unexpected exception',
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/tools/ToolRepresentation/actionResult/ActionResultRepresentation/resultMessage/localizationMessageId",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizedMessage",
      usage: "output",
      type: "string",
      description:
        "The formatted localized message. If there is a problem substituting the parameters in the message, the message is returned unformatted.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/tools/ToolRepresentation/actionResult/ActionResultRepresentation/resultMessage/localizedMessage",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "parameters",
      usage: "output",
      type: "array",
      description:
        "Message parameters. These parameters have already been substituted into localizedMessage.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/tools/ToolRepresentation/actionResult/ActionResultRepresentation/resultMessage/parameters",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<array-primitive-parameter>",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ActionMessageRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/tools/ToolRepresentation/actionResult/ActionResultRepresentation/resultMessage/parameters/%3Carray-primitive-parameter%3E",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "deprecationStatus",
      usage: "output",
      type: "string",
      description:
        "Deprecation status. Describes whether the endpoint is deprecated or not. Will contain information about version which the deprecation started in and suggestion of the endpoint to use instead.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/ToolRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/tools/ToolRepresentation/deprecationStatus",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "id",
      usage: "output",
      type: "integer",
      description: "Representation ID",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ToolRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/tools/ToolRepresentation/id",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "toolType",
      usage: "output",
      type: "string",
      description:
        "Tool type. Currently, the tools available from Red Bend are the Update Generator and the DP signing tool.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/ToolRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/tools/ToolRepresentation/toolType",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "toolVersion",
      usage: "output",
      type: "integer",
      description: "Tool version",
      required: true,
      format: "int32",
      schemaReference: "#/components/schemas/ToolRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "int32",
      pathName:
        "AutomotiveSoftwareRepresentation/ecuModelRepresentations/ModelRepresentation/tools/ToolRepresentation/toolVersion",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "targetUsers",
      usage: "output",
      type: "integer",
      description: "Deprecated",
      required: false,
      format: "int64",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "int64",
      pathName: "AutomotiveSoftwareRepresentation/targetUsers",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "variant",
      usage: "output",
      type: "string",
      description: "The software variant",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareRepresentation/variant",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "id",
      usage: "output",
      type: "integer",
      description: "Representation ID",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareRepresentation/id",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "installerType",
      usage: "output",
      type: "integer",
      description: "The installer Type",
      required: true,
      format: "int32",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "int32",
      pathName: "AutomotiveSoftwareRepresentation/installerType",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "installerTypeRepresentation",
      usage: "output",
      type: "object",
      description: "",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/InstallerTypeRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareRepresentation/installerTypeRepresentation",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "isNative",
      usage: "output",
      type: "boolean",
      description:
        "Whether the installer type is native or not. Not included in the response if null.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/InstallerTypeRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "boolean",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/installerTypeRepresentation/isNative",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizationKey",
      usage: "output",
      type: "string",
      description: "Installer type localization key.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/InstallerTypeRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/installerTypeRepresentation/localizationKey",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "name",
      usage: "output",
      type: "string",
      description: "Display name of the installer type.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/InstallerTypeRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/installerTypeRepresentation/name",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "number",
      usage: "output",
      type: "integer",
      description: "Number identifying the installer type.",
      required: false,
      format: "int32",
      schemaReference: "#/components/schemas/InstallerTypeRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "int32",
      pathName:
        "AutomotiveSoftwareRepresentation/installerTypeRepresentation/number",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "isApproved",
      usage: "output",
      type: "boolean",
      description:
        'Whether the component has at least one version whose status is "ComponentVersionStatus.Approved". See ComponentVersionRepresentation.',
      required: false,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "boolean",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareRepresentation/isApproved",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "isConnectedToAllDomainTree",
      usage: "output",
      type: "boolean",
      description:
        'Whether the software is associated with all domains in the hierarchy. true=all, false=associated with the domains listed in "domains".',
      required: true,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "boolean",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareRepresentation/isConnectedToAllDomainTree",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "isNativeInstallerType",
      usage: "output",
      type: "boolean",
      description:
        "Whether the software installer type is native or non-native.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "boolean",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareRepresentation/isNativeInstallerType",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "isSoftwareWithVersionFile",
      usage: "output",
      type: "boolean",
      description:
        "Whether the software is a full version or a Delta. true indicates a full version, false indicates a Delta.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "boolean",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareRepresentation/isSoftwareWithVersionFile",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "isTesting",
      usage: "output",
      type: "boolean",
      description:
        'Whether the component has at least one version whose status is "ComponentVersionStatus.Testing". See ComponentVersionRepresentation.',
      required: false,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "boolean",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareRepresentation/isTesting",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "lastSoftwareVersionStatus",
      usage: "output",
      type: "string",
      description: "The latest software version's status",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareRepresentation/lastSoftwareVersionStatus",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "latestVersionId",
      usage: "output",
      type: "string",
      description: "The latest version ID.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareRepresentation/latestVersionId",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "latestVersionName",
      usage: "output",
      type: "string",
      description: "The latest version name.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareRepresentation/latestVersionName",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "latestVersionShortDescription",
      usage: "output",
      type: "string",
      description: "The latest version short description.",
      required: false,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName:
        "AutomotiveSoftwareRepresentation/latestVersionShortDescription",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "name",
      usage: "output",
      type: "string",
      description: "The software name",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareRepresentation/name",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "numberOfVersions",
      usage: "output",
      type: "integer",
      description: "The number of software versions.",
      required: true,
      format: "int64",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "int64",
      pathName: "AutomotiveSoftwareRepresentation/numberOfVersions",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "operatingSystems",
      usage: "output",
      type: "string",
      description: "The platform name.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareRepresentation/operatingSystems",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "scomoID",
      usage: "output",
      type: "string",
      description: "The SCOMO ID.",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareRepresentation/scomoID",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "status",
      usage: "output",
      type: "string",
      description: "The status of the software",
      required: true,
      format: "",
      schemaReference: "#/components/schemas/AutomotiveSoftwareRepresentation",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "",
      pathName: "AutomotiveSoftwareRepresentation/status",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<root-param>",
      usage: "output",
      type: "object",
      description: "",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "%3Croot-param%3E",
      statusCode: "400",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "key",
      usage: "output",
      type: "string",
      description: "",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "%3Croot-param%3E/key",
      statusCode: "400",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizedMessage",
      usage: "output",
      type: "string",
      description: "",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "%3Croot-param%3E/localizedMessage",
      statusCode: "400",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "httpError",
      usage: "output",
      type: "string",
      description: "",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "%3Croot-param%3E/httpError",
      statusCode: "400",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "<root-param>",
      usage: "output",
      type: "object",
      description: "",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "%3Croot-param%3E",
      statusCode: "405",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "key",
      usage: "output",
      type: "string",
      description: "",
      required: false,
      format: "other",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "%3Croot-param%3E/key",
      statusCode: "405",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "localizedMessage",
      usage: "output",
      type: "string",
      description: "",
      required: false,
      format: "other",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "%3Croot-param%3E/localizedMessage",
      statusCode: "405",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "httpError",
      usage: "output",
      type: "string",
      description: "",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "%3Croot-param%3E/httpError",
      statusCode: "405",
      evaluation: "uncertain",
    },
  ],
  "16789": [
    {
      in: "path",
      name: "postId",
      usage: "input",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "postId",
      statusCode: "",
      evaluation: "interpreted",
    },
    {
      in: "body",
      name: "\u003croot-param\u003e",
      usage: "input",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "%3Croot-param%3E",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "content",
      usage: "input",
      type: "string",
      description: "",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "%3Croot-param%3E/content",
      statusCode: "",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "\u003croot-param\u003e",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "%3Croot-param%3E",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "author",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "%3Croot-param%3E/author",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "created_at",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "datetime",
      pathName: "%3Croot-param%3E/author/created_at",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "email",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "email",
      pathName: "%3Croot-param%3E/author/email",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "nickname",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "%3Croot-param%3E/author/nickname",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "profile_pic_url",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "url",
      pathName: "%3Croot-param%3E/author/profile_pic_url",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "vehicleid",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "uuid",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "id",
      pathName: "%3Croot-param%3E/author/vehicleid",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "authorid",
      usage: "output",
      type: "integer",
      description: "",
      required: true,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "integer",
      oracleFormat: "id",
      pathName: "%3Croot-param%3E/authorid",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "comments",
      usage: "output",
      type: "array",
      description: "",
      required: true,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "%3Croot-param%3E/comments",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "\u003croot-param\u003e",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "%3Croot-param%3E/comments/%3Croot-param%3E",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "CreatedAt",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "datetime",
      pathName: "%3Croot-param%3E/comments/%3Croot-param%3E/CreatedAt",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "author",
      usage: "output",
      type: "object",
      description: "",
      required: true,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "%3Croot-param%3E/comments/%3Croot-param%3E/author",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "created_at",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "datetime",
      pathName: "%3Croot-param%3E/comments/%3Croot-param%3E/author/created_at",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "email",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "email",
      pathName: "%3Croot-param%3E/comments/%3Croot-param%3E/author/email",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "nickname",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "%3Croot-param%3E/comments/%3Croot-param%3E/author/nickname",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "profile_pic_url",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "url",
      pathName:
        "%3Croot-param%3E/comments/%3Croot-param%3E/author/profile_pic_url",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "vehicleid",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "uuid",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "id",
      pathName: "%3Croot-param%3E/comments/%3Croot-param%3E/author/vehicleid",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "content",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "%3Croot-param%3E/comments/%3Croot-param%3E/content",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "id",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "id",
      pathName: "%3Croot-param%3E/comments/%3Croot-param%3E/id",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "content",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "%3Croot-param%3E/content",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "id",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "id",
      pathName: "%3Croot-param%3E/id",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "title",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "%3Croot-param%3E/title",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "CreatedAt",
      usage: "output",
      type: "string",
      description: "",
      required: true,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "datetime",
      pathName: "%3Croot-param%3E/CreatedAt",
      statusCode: "200",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "\u003croot-param\u003e",
      usage: "output",
      type: "object",
      description: "",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "%3Croot-param%3E",
      statusCode: "401",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "error",
      usage: "output",
      type: "string",
      description: "",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "%3Croot-param%3E/error",
      statusCode: "401",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "\u003croot-param\u003e",
      usage: "output",
      type: "object",
      description: "",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "",
      oracleFormat: "",
      pathName: "%3Croot-param%3E",
      statusCode: "500",
      evaluation: "uncertain",
    },
    {
      in: "body",
      name: "error",
      usage: "output",
      type: "string",
      description: "",
      required: false,
      format: "",
      schemaReference: "",
      schemaComposition: "",
      not: "",
      oracleType: "string",
      oracleFormat: "other",
      pathName: "%3Croot-param%3E/error",
      statusCode: "500",
      evaluation: "uncertain",
    },
  ],
};
