import type { ChangeEvent } from "react";
import { useCallback, useMemo, useState } from "react";
import type { Alert, DangerZoneProps } from "./DangerZone.i";
import type { ButtonProps } from "@Components";
import { t } from "i18next";
import { useManageApiResponse } from "@Hooks";

const dangerZoneTranslationPref = "components.danger-zone";

export const useDangerZone = ({
  action = "delete",
  target,
  confirmationText,
  subject,
  promise,
  toastErrorMessage,
  toastSuccessMessage,
  onSuccess,
  additionalAlerts,
}: DangerZoneProps) => {
  const [canvasOpen, setCanvasOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const manageResponse = useManageApiResponse(setLoading);

  const openCanvas = useCallback(() => {
    setInputValue("");
    setCanvasOpen(true);
  }, []);

  const closeCanvas = useCallback(() => {
    setCanvasOpen(false);
  }, []);

  const deleteText = useMemo(() => {
    return confirmationText.trim().replaceAll(/\s+/g, " ");
  }, [confirmationText]);

  const labels = useMemo(() => {
    const translationKey = `${dangerZoneTranslationPref}.${action}-${target}`;
    const title = t(translationKey);
    const info = t(`${translationKey}-info`);
    const warning = t(`${translationKey}-warning`, { subject });
    const deleteMsg = t(`${dangerZoneTranslationPref}.delete-input-message`, {
      text: deleteText,
    });
    return { title, info, warning, deleteMsg, subject };
  }, [action, deleteText, subject, target]);

  const handleInputValueChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
    },
    []
  );

  const handleDelete = useCallback(() => {
    manageResponse({
      promise,
      errorMessage: toastErrorMessage,
      successMessage: toastSuccessMessage,
      onSuccess: () => {
        closeCanvas();
        onSuccess && onSuccess();
      },
    });
  }, [
    manageResponse,
    onSuccess,
    promise,
    closeCanvas,
    toastErrorMessage,
    toastSuccessMessage,
  ]);

  const deletable = deleteText === inputValue;

  const deleteButtonProps: ButtonProps[] = useMemo(() => {
    return [
      {
        color: "danger",
        children: `common.${action}`,
        disabled: !deletable,
        loading,
        onClick: handleDelete,
        id: `confirm-${action}-btn`,
        iconClass: "bi bi-exclamation-square-fill",
      },
    ];
  }, [action, deletable, handleDelete, loading]);

  const alerts = useMemo(() => {
    const result: Array<Alert> = [
      {
        level: "warning",
        children: t(`${dangerZoneTranslationPref}.delete-warning`),
      },
    ];

    additionalAlerts?.forEach(e => {
      result.push({
        level: e.level,
        children: e.children,
      });
    });

    return result.sort((a1, a2) => {
      return a1.level.localeCompare(a2.level); // errors on top, warning on bottom
    });
  }, [additionalAlerts]);

  return {
    labels,
    actions: {
      openCanvas,
      closeCanvas,
      handleInputValueChange,
    },
    alerts,
    confirmation: inputValue,
    deleteButtonProps,
    isCanvasOpen: canvasOpen,
    isCanvasLoading: loading,
    deletable,
  };
};
