import { joinClasses } from "@Utils";
import { t } from "i18next";
import type { FC } from "react";

export const AuthDisplaySection: FC<{ auth: boolean }> = ({ auth }) => {
  return (
    <div>
      <i
        className={joinClasses(
          "me-2",
          auth ? "color-success" : "color-gray",
          "bi",
          auth ? "bi-check-circle" : "bi-plus-circle"
        )}
      />
      <span>{t(auth ? "common.enabled" : "common.not-enabled")}</span>
    </div>
  );
};
