import { usePreference, useResetCachedData } from "@Hooks";
import { BEM, joinClasses, pureComponent } from "@Utils";
import { memo, useCallback, useContext, useState } from "react";
import {
  DateSettingsCard,
  FeedSettingsCard,
  StyleCard,
} from "../../../Components";
import { AuthContext } from "@Contexts";
import { config } from "@Services";
import { ConfirmDialog, SingleOptionSection } from "@Components";
import { DEFAULT_CODE_THEME } from "@Interfaces";

const ResetSettingsButton = memo(() => {
  const { settings, setSettings } = useContext(AuthContext);
  const [, setAppTheme] = usePreference("theme");
  const [, setAppZoom] = usePreference("appZoom");
  const [, setCodeTheme] = usePreference("codeTheme");

  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const handleResetSettingsToDefaults = useCallback(() => {
    setAppTheme("system");
    setAppZoom("100");
    setCodeTheme(DEFAULT_CODE_THEME);

    if (settings) {
      setSettings(
        {
          ...settings,
          notification: false,
          timeZone: config.defaultTimeZone,
        },
        true
      );
    }

    window.location.reload();
  }, [setAppTheme, setAppZoom, setCodeTheme, setSettings, settings]);

  return (
    <>
      <SingleOptionSection
        className="mt-3"
        iconClass="bi bi-gear-wide-connected"
        title="profile.reset-settings.title"
        description="profile.reset-settings.description"
        buttonProps={[
          {
            iconClass: "bi bi-x-square-fill",
            color: "danger",
            onMouseDown: () => setShowConfirmDialog(true),
            data: { cy: "reset-to-defaults-btn" },
            children: "common.restore",
          },
        ]}
      />
      {showConfirmDialog && (
        <ConfirmDialog
          title="profile.confirmation-dialog.title.reset-settings"
          description="profile.confirmation-dialog.description.reset-settings"
          cancelLabel="profile.confirmation-dialog.cancel.reset-settings"
          confirmLabel="profile.confirmation-dialog.confirm.reset-settings"
          onCancel={() => setShowConfirmDialog(false)}
          onConfirm={handleResetSettingsToDefaults}
        />
      )}
    </>
  );
}, pureComponent);

const ClearCacheButton = memo(() => {
  const { resetCachedData } = useResetCachedData();
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  return (
    <>
      <SingleOptionSection
        className="mt-3"
        iconClass="bi bi-gear-wide-connected"
        title="profile.clear-cached-data.title"
        description="profile.clear-cached-data.description"
        buttonProps={[
          {
            iconClass: "bi bi-trash-fill",
            color: "danger",
            onMouseDown: () => setShowConfirmDialog(true),
            data: { cy: "clear-cached-data-btn" },
            children: "common.clear",
          },
        ]}
      />
      {showConfirmDialog && (
        <ConfirmDialog
          title="profile.confirmation-dialog.title.clear-cache"
          description="profile.confirmation-dialog.description.clear-cache"
          cancelLabel="profile.confirmation-dialog.cancel.clear-cache"
          confirmLabel="profile.confirmation-dialog.confirm.clear-cache"
          onCancel={() => setShowConfirmDialog(false)}
          onConfirm={resetCachedData}
        />
      )}
    </>
  );
}, pureComponent);

export const ProfileDetailsPageSettingsTab = memo(() => {
  return (
    <>
      <div
        className={joinClasses(BEM("profile-details", "container"), "card")}
        id="profile-details-settings-tab"
      >
        <DateSettingsCard />
        <FeedSettingsCard />
        <StyleCard />

        <ResetSettingsButton />
        <ClearCacheButton />
      </div>
    </>
  );
}, pureComponent);
