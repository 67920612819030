import { LiveLogsViewer, MainOutlet } from "@Components";
import { memo } from "react";
import { Container } from "reactstrap";
import "./style.scss";

export const ProjectPage = memo(() => {
  return (
    <Container fluid id="project">
      <MainOutlet />
      <LiveLogsViewer />
    </Container>
  );
});
