import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

type PropsWithKey = { key: string };

const defaultProps: PropsWithKey = { key: "id" };

export const useValidSearchParams = ({ key } = defaultProps) => {
  const [searchParams] = useSearchParams();

  const value = searchParams.get(key) || "";
  const validSearchParams = !!value;

  const navigate = useNavigate();

  useEffect(() => {
    if (!validSearchParams) navigate("/login");
  }, [validSearchParams, navigate]);

  return { showEmptyPage: !validSearchParams, id: value };
};
