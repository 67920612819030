import type { Options } from "@Interfaces";
import type { UserAuthToken } from "@Services";
import { capitalizeString, isFilledString } from "@Services";
import { useAuthenticationSettingFormStore } from "@Stores";
import { set } from "lodash";
import type { ChangeEvent } from "react";
import { useCallback, useMemo, useState } from "react";
import { locations } from "../../../../constants";

const EMPTY_TOKEN: UserAuthToken = {
  authenticationSchemeType: "http-bearer",
  key: "",
  location: "header",
  value: "",
};

export const useUserTokensForm = () => {
  const [formValue, setFormValue] = useState<UserAuthToken>({ ...EMPTY_TOKEN });
  const { authenticationSettings, loading, setAuthenticationSettings } =
    useAuthenticationSettingFormStore();

  const handleSave = useCallback(() => {
    setAuthenticationSettings(prev => ({
      ...prev,
      userAuthTokens: [...(prev.userAuthTokens ?? []), { ...formValue }],
    }));
    setFormValue({ ...EMPTY_TOKEN });
  }, [formValue, setAuthenticationSettings]);

  const handleDelete = useCallback(
    (index: number) => {
      const nextTokens = [...(authenticationSettings.userAuthTokens ?? [])];
      if (nextTokens.length <= 0) {
        return;
      }

      nextTokens.splice(index, 1);
      setAuthenticationSettings(prev => ({
        ...prev,
        userAuthTokens: nextTokens,
      }));
    },
    [authenticationSettings.userAuthTokens, setAuthenticationSettings]
  );

  const handleChange = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      setFormValue(prev => {
        const nextValue = set(prev, name, value);
        return { ...nextValue };
      });
    },
    []
  );

  // Location Options Generation
  const locationOptions = useMemo(() => {
    const options: Options = locations.map(location => ({
      label: capitalizeString(location),
      value: location,
    }));

    switch (formValue.authenticationSchemeType) {
      case "api-key":
        return options;
      case "http-basic":
      case "http-bearer":
        return options.filter(({ value }) => value === "header");
      default:
        throw new Error("Unknown authentication scheme type");
    }
  }, [formValue.authenticationSchemeType]);

  const { validities, errors } = useMemo(() => {
    const dupKey = !!authenticationSettings.userAuthTokens?.find(
      t => t.key === formValue.key && t.location === formValue.location
    );
    return {
      errors: {
        dupKey,
      },
      validities: {
        authenticationSchemeType: !!formValue.authenticationSchemeType,
        location: isFilledString(formValue.location),
        key: !dupKey && isFilledString(formValue.key),
        value: isFilledString(formValue.value),
      },
    };
  }, [
    authenticationSettings.userAuthTokens,
    formValue.authenticationSchemeType,
    formValue.key,
    formValue.location,
    formValue.value,
  ]);

  return {
    formValue,
    locationOptions,
    authenticationSettings,
    loading,
    validities,
    errors,
    formValid: !Object.values(validities).some(v => !v),
    handleChange,
    handleDelete,
    handleSave,
  };
};
