import { FormInput, RefreshListButton } from "@Components";
import { getFilterAllowedOptions } from "@Services";
import type { PageFiltersProps } from "../PageFilters.i";
import { PageFiltersWrapper } from "../PageFiltersWrapper";
import { usePageFilters } from "../usePageFilters";

const verbOptions = getFilterAllowedOptions("auth_matrix_verb");

export const AuthMatrixFilters = ({
  updateFilters,
  refresh,
}: PageFiltersProps) => {
  const { getValueOf, handleOnChange } = usePageFilters(updateFilters);
  return (
    <PageFiltersWrapper>
      <FormInput
        id="select-verb"
        label="components.date-filter.verb-label"
        type="select"
        options={verbOptions}
        name="auth_matrix_verb"
        value={getValueOf("auth_matrix_verb")}
        onChange={handleOnChange}
      />
      <FormInput
        type="text"
        id="input-auth-matrix-path"
        label="common.path"
        name="auth_matrix_path"
        value={getValueOf("auth_matrix_path")}
        onChange={handleOnChange}
      />
      <RefreshListButton onClick={refresh} />
    </PageFiltersWrapper>
  );
};
