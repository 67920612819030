import type { CreateAppendFunctionArgs } from "./types.i";

export const createAppendFunction = ({
  start,
  length,
  lowerBound,
  upperBound,
  output,
}: CreateAppendFunctionArgs) => {
  const unshift = (previous: number) => {
    if (output.length < length && previous > lowerBound) {
      output.unshift(previous);
    }
  };

  const push = (next: number) => {
    if (output.length < length && next < upperBound) {
      output.push(next);
    }
  };

  let addNeighbors: (previous: number, next: number) => void;

  if (start - lowerBound < upperBound - start) {
    addNeighbors = (previous, next) => {
      unshift(previous);
      push(next);
    };
  } else {
    addNeighbors = (previous, next) => {
      push(next);
      unshift(previous);
    };
  }
  return addNeighbors;
};
