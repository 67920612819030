/* tslint:disable */
/* eslint-disable */
/**
 * Equixly API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * Contact: support@equixly.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { UserCreateProjectRequest } from '../model';
// @ts-ignore
import { UserExclusionMatchesRequest } from '../model';
// @ts-ignore
import { UserExclusionMatchesResponse } from '../model';
// @ts-ignore
import { UserGetProjectResponse } from '../model';
// @ts-ignore
import { UserListProjectsResponse } from '../model';
// @ts-ignore
import { UserUpdateProjectRequest } from '../model';
/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Archives the project with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectArchive: async (organizationId: string, projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('projectArchive', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectArchive', 'projectId', projectId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/archive`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new project in the organization with given id
         * @param {string} organizationId Organization ID
         * @param {UserCreateProjectRequest} requestBody UserCreateProjectRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCreate: async (organizationId: string, requestBody: UserCreateProjectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('projectCreate', 'organizationId', organizationId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('projectCreate', 'requestBody', requestBody)
            const localVarPath = `/v1/organizations/{organization_id}/projects`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the project with the given project id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDelete: async (organizationId: string, projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('projectDelete', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectDelete', 'projectId', projectId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Checks if the path and methods match in the project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {UserExclusionMatchesRequest} requestBody UserExclusionMatchesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectExclusionMatches: async (organizationId: string, projectId: string, requestBody: UserExclusionMatchesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('projectExclusionMatches', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectExclusionMatches', 'projectId', projectId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('projectExclusionMatches', 'requestBody', requestBody)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}/exclusion-matches`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the project with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGet: async (organizationId: string, projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('projectGet', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectGet', 'projectId', projectId)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the projects in the organization
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'project_created_at' | 'project_name'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'project_name' | 'project_status' | 'project_archived' | 'project_space_link_id'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectList: async (organizationId: string, page?: number, size?: number, sortBy?: 'project_created_at' | 'project_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'project_name' | 'project_status' | 'project_archived' | 'project_space_link_id'>, filterOperators?: Array<'LIKE' | 'EQ'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('projectList', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organization_id}/projects`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the project with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {UserUpdateProjectRequest} requestBody UserUpdateProjectRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdate: async (organizationId: string, projectId: string, requestBody: UserUpdateProjectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('projectUpdate', 'organizationId', organizationId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectUpdate', 'projectId', projectId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('projectUpdate', 'requestBody', requestBody)
            const localVarPath = `/v1/organizations/{organization_id}/projects/{project_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * Archives the project with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectArchive(organizationId: string, projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectArchive(organizationId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new project in the organization with given id
         * @param {string} organizationId Organization ID
         * @param {UserCreateProjectRequest} requestBody UserCreateProjectRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectCreate(organizationId: string, requestBody: UserCreateProjectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectCreate(organizationId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes the project with the given project id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDelete(organizationId: string, projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDelete(organizationId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Checks if the path and methods match in the project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {UserExclusionMatchesRequest} requestBody UserExclusionMatchesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectExclusionMatches(organizationId: string, projectId: string, requestBody: UserExclusionMatchesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserExclusionMatchesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectExclusionMatches(organizationId, projectId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the project with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGet(organizationId: string, projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGetProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGet(organizationId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the projects in the organization
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'project_created_at' | 'project_name'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'project_name' | 'project_status' | 'project_archived' | 'project_space_link_id'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectList(organizationId: string, page?: number, size?: number, sortBy?: 'project_created_at' | 'project_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'project_name' | 'project_status' | 'project_archived' | 'project_space_link_id'>, filterOperators?: Array<'LIKE' | 'EQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListProjectsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectList(organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the project with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {UserUpdateProjectRequest} requestBody UserUpdateProjectRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdate(organizationId: string, projectId: string, requestBody: UserUpdateProjectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdate(organizationId, projectId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApiFp(configuration)
    return {
        /**
         * Archives the project with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectArchive(organizationId: string, projectId: string, options?: any): AxiosPromise<void> {
            return localVarFp.projectArchive(organizationId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new project in the organization with given id
         * @param {string} organizationId Organization ID
         * @param {UserCreateProjectRequest} requestBody UserCreateProjectRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCreate(organizationId: string, requestBody: UserCreateProjectRequest, options?: any): AxiosPromise<void> {
            return localVarFp.projectCreate(organizationId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the project with the given project id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDelete(organizationId: string, projectId: string, options?: any): AxiosPromise<void> {
            return localVarFp.projectDelete(organizationId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Checks if the path and methods match in the project
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {UserExclusionMatchesRequest} requestBody UserExclusionMatchesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectExclusionMatches(organizationId: string, projectId: string, requestBody: UserExclusionMatchesRequest, options?: any): AxiosPromise<UserExclusionMatchesResponse> {
            return localVarFp.projectExclusionMatches(organizationId, projectId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the project with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGet(organizationId: string, projectId: string, options?: any): AxiosPromise<UserGetProjectResponse> {
            return localVarFp.projectGet(organizationId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the projects in the organization
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'project_created_at' | 'project_name'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'project_name' | 'project_status' | 'project_archived' | 'project_space_link_id'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectList(organizationId: string, page?: number, size?: number, sortBy?: 'project_created_at' | 'project_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'project_name' | 'project_status' | 'project_archived' | 'project_space_link_id'>, filterOperators?: Array<'LIKE' | 'EQ'>, filterValues?: Array<string>, options?: any): AxiosPromise<UserListProjectsResponse> {
            return localVarFp.projectList(organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the project with the given id
         * @param {string} organizationId Organization ID
         * @param {string} projectId Project ID
         * @param {UserUpdateProjectRequest} requestBody UserUpdateProjectRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdate(organizationId: string, projectId: string, requestBody: UserUpdateProjectRequest, options?: any): AxiosPromise<void> {
            return localVarFp.projectUpdate(organizationId, projectId, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - interface
 * @export
 * @interface ProjectApi
 */
export interface ProjectApiInterface {
    /**
     * Archives the project with the given id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectArchive(organizationId: string, projectId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Creates a new project in the organization with given id
     * @param {string} organizationId Organization ID
     * @param {UserCreateProjectRequest} requestBody UserCreateProjectRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectCreate(organizationId: string, requestBody: UserCreateProjectRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Deletes the project with the given project id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectDelete(organizationId: string, projectId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Checks if the path and methods match in the project
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {UserExclusionMatchesRequest} requestBody UserExclusionMatchesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectExclusionMatches(organizationId: string, projectId: string, requestBody: UserExclusionMatchesRequest, options?: AxiosRequestConfig): AxiosPromise<UserExclusionMatchesResponse>;

    /**
     * Returns the project with the given id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectGet(organizationId: string, projectId: string, options?: AxiosRequestConfig): AxiosPromise<UserGetProjectResponse>;

    /**
     * Returns the projects in the organization
     * @param {string} organizationId Organization ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'project_created_at' | 'project_name'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'project_name' | 'project_status' | 'project_archived' | 'project_space_link_id'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectList(organizationId: string, page?: number, size?: number, sortBy?: 'project_created_at' | 'project_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'project_name' | 'project_status' | 'project_archived' | 'project_space_link_id'>, filterOperators?: Array<'LIKE' | 'EQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<UserListProjectsResponse>;

    /**
     * Updates the project with the given id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {UserUpdateProjectRequest} requestBody UserUpdateProjectRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectUpdate(organizationId: string, projectId: string, requestBody: UserUpdateProjectRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI implements ProjectApiInterface {
    /**
     * Archives the project with the given id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectArchive(organizationId: string, projectId: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectArchive(organizationId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new project in the organization with given id
     * @param {string} organizationId Organization ID
     * @param {UserCreateProjectRequest} requestBody UserCreateProjectRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectCreate(organizationId: string, requestBody: UserCreateProjectRequest, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectCreate(organizationId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the project with the given project id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectDelete(organizationId: string, projectId: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectDelete(organizationId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Checks if the path and methods match in the project
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {UserExclusionMatchesRequest} requestBody UserExclusionMatchesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectExclusionMatches(organizationId: string, projectId: string, requestBody: UserExclusionMatchesRequest, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectExclusionMatches(organizationId, projectId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the project with the given id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGet(organizationId: string, projectId: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGet(organizationId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the projects in the organization
     * @param {string} organizationId Organization ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'project_created_at' | 'project_name'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'project_name' | 'project_status' | 'project_archived' | 'project_space_link_id'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectList(organizationId: string, page?: number, size?: number, sortBy?: 'project_created_at' | 'project_name', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'project_name' | 'project_status' | 'project_archived' | 'project_space_link_id'>, filterOperators?: Array<'LIKE' | 'EQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectList(organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the project with the given id
     * @param {string} organizationId Organization ID
     * @param {string} projectId Project ID
     * @param {UserUpdateProjectRequest} requestBody UserUpdateProjectRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdate(organizationId: string, projectId: string, requestBody: UserUpdateProjectRequest, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdate(organizationId, projectId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}
