import { AuthContext } from "@Contexts";
import { usePreference, useUrlParams } from "@Hooks";
import type { Nullable } from "@Interfaces";
import { API, type Role } from "@Services";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const usePopulateAuthContext = (validToken: boolean) => {
  const [loading, setLoading] = useState(true);
  const [latestOrgId, setLatestOrgId] = usePreference("organization-id");
  const { organizationId } = useUrlParams();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const fetchUser = async () => {
    const { data } = await API.user().userGetInfo();
    return data.user;
  };

  const fetchSetting = async () => {
    const response = await API.userSettings().userGetUserSettings();
    return response.data;
  };

  const fetchRole = async (organizationID: string) => {
    const response = await API.role().userGetRole(organizationID);
    return response.data.role;
  };

  const fetchOrganizations = async () => {
    const response =
      await API.organization().userGetOrganizationsRelatedToRequester();
    return response.data;
  };

  useEffect(() => {
    if (!validToken) return;

    setLoading(true);

    const handleErrors = () => {
      navigate("/login?service-unavailable=1");
    };

    const tryGetRole = async (
      organizationIdFromUrl: Nullable<string> | undefined,
      organizationIdFromStorage: Nullable<string> | undefined,
      defaultOrganizationId: Nullable<string> | undefined
    ) => {
      const setRole = (role: Role) => {
        authContext.setRole(role);
        setLatestOrgId(role.organizationID);
      };

      const fetchAndSet = async (organizationId: string): Promise<boolean> => {
        try {
          const role = await fetchRole(organizationId);

          if (role) {
            setRole(role);
          }

          return !!role;
        } catch (exception) {
          return false;
        }
      };

      // we need to check if an id exists but also if the current user can view that organization
      if (organizationIdFromUrl && (await fetchAndSet(organizationIdFromUrl))) {
        return true;
      }
      if (
        organizationIdFromStorage &&
        (await fetchAndSet(organizationIdFromStorage))
      ) {
        return true;
      }
      if (defaultOrganizationId && (await fetchAndSet(defaultOrganizationId))) {
        return true;
      }

      return false;
    };

    const loadData = async () => {
      try {
        const [user, settings, organizations] = await Promise.all([
          fetchUser(),
          fetchSetting(),
          fetchOrganizations(),
        ]);

        if (
          await tryGetRole(
            organizationId,
            latestOrgId,
            organizations?.data[0]?.id
          )
        ) {
          authContext.setUser(user);
          authContext.setSettings(settings);
          authContext.setOrganizations(organizations);
        } else {
          handleErrors();
        }
      } catch (error) {
        handleErrors();
      } finally {
        setLoading(false);
      }
    };

    loadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validToken]);

  return loading;
};
