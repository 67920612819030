import { FullLogo, FullLogoWhite } from "@Assets";
import { ThemeContext } from "@Contexts";
import { useOrganization } from "@Hooks";
import { t } from "i18next";
import { useCallback, useContext } from "react";
import { CurtesyPage } from "../CurtesyPage";
import { Button } from "@Components";
import { useNavigate } from "react-router-dom";
import { PROJECTS_PATH } from "@Constants";
import { generateUrl } from "@Utils";

export const OrganizationNoScans = () => {
  const organization = useOrganization();
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const navigateToProjects = useCallback(() => {
    navigate(generateUrl(PROJECTS_PATH, [organization?.id ?? ""], true));
  }, [navigate, organization?.id]);

  return (
    <CurtesyPage>
      <h1>
        {`${t("project.welcome")} `}
        <img
          src={theme === "dark" ? FullLogoWhite : FullLogo}
          alt="Logo"
          className="logo"
          width={155}
        />
        {"!"}
      </h1>
      <p className="mb-3">
        {t("common.no-scans", {
          organizationName: organization?.name,
        })}
      </p>
      <Button
        data={{ cy: "navigate-to-projects" }}
        children={t("common.projects")}
        color="primary"
        iconClass="bi bi-box-arrow-up-right"
        onClick={navigateToProjects}
      />
    </CurtesyPage>
  );
};
