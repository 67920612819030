import { ProjectOrganization, projects } from "src/Mock/data";
import type { OrganizationId, NewProjectData } from "@Interfaces";
import moment from "moment-timezone";

export const createProject = (
  organizationId: OrganizationId,
  data: NewProjectData
) => {
  // finding the max project-id value from stored projects
  const maxProjectId = projects
    .map(p => Number(p.id))
    .reduce((projId, max) => Math.max(projId, max), 0);

  const projectId = `${maxProjectId + 1}`;

  projects.push({
    description: data.description,
    id: projectId,
    latestScan: "",
    latestScanSeverityCount: {
      high: 0,
      information: 0,
      low: 0,
      medium: 0,
    },
    name: data.name,
    nextRun: "",
    status: "pending",
    endpoints: 0,
    archivedAt: "",
    createdAt: moment().format("YYYY-MM-DD"),
    baseURL: data.baseURL,
    lastError: "",
  });

  ProjectOrganization.push({
    organizationId,
    projectId,
  });

  return true;
};
