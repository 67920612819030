import type { EditorView, ViewUpdate } from "@uiw/react-codemirror";
import { RangeSetBuilder, Decoration, ViewPlugin } from "@uiw/react-codemirror";

// Helper function to create line decorations
export const applyLogLineDecorations = (view: EditorView) => {
  const builder = new RangeSetBuilder();

  // Loop through each line in the document
  for (let lineNo = 1; lineNo <= view.state.doc.lines; lineNo++) {
    const line = view.state.doc.line(lineNo);
    const text = line.text.trim(); // Get the line content

    let classToApply = "";

    if (text.startsWith("[ERROR]")) {
      classToApply = "log-error";
    } else if (text.startsWith("[FATAL]")) {
      classToApply = "log-fatal";
    } else if (text.startsWith("[WARNING]")) {
      classToApply = "log-error";
    } else if (text.startsWith("[TRACE]")) {
      classToApply = "log-trace";
    } else if (text.startsWith("[DEBUG]")) {
      classToApply = "log-debug";
    } else {
      classToApply = "log-info";
    }

    if (classToApply) {
      builder.add(
        line.from,
        line.from,
        Decoration.line({ class: `${classToApply}-line` })
      );
    }
  }

  return builder.finish();
};
// Plugin to update the decorations based on the log content
export const logLineDecorationPlugin = ViewPlugin.fromClass(
  class {
    decorations;

    constructor(view: EditorView) {
      this.decorations = applyLogLineDecorations(view);
    }

    update(update: ViewUpdate) {
      if (update.docChanged || update.viewportChanged) {
        this.decorations = applyLogLineDecorations(update.view);
      }
    }
  },
  {
    decorations: v => v.decorations as any,
  }
);
