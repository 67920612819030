import { useCallback, useMemo, useState } from "react";
import type { ComponentButtonProps } from "@Components";
import { t } from "i18next";
import { useAPIDefinitionDetailsStore } from "../Stores";
import { usePathExclusion } from "./usePathExclusion";
import { useProject } from "@Hooks";

export const useAPIDefinitionDetailsCanvasButtons = () => {
  const { details } = useAPIDefinitionDetailsStore();
  const { canExclude, loading, exclude } = usePathExclusion();
  const { isLatestAPIDefinition } = useAPIDefinitionDetailsStore();
  const { projectStatus } = useProject();
  const [openAddParameterLink, setOpenAddParameterLink] = useState(false);

  const canAddParameterLinks =
    isLatestAPIDefinition && projectStatus === "ready";
  const canAddParameters = details && details.parameters.length > 0;
  const addParametersTooltip = !isLatestAPIDefinition
    ? "inventory.add-parameter-link-to-api-tooltip-outdated-api-definition"
    : canAddParameters
      ? "inventory.add-parameter-link-to-api-tooltip"
      : "inventory.add-parameter-link-to-api-tooltip-no-parameters";

  const handleOnAddParameterLink = useCallback(() => {
    if (!canAddParameterLinks) {
      return;
    }
    setOpenAddParameterLink(true);
  }, [canAddParameterLinks]);

  const handleAddParametersLinkCanvasClose = useCallback(() => {
    if (projectStatus !== "ready") {
      return;
    }
    setOpenAddParameterLink(false);
  }, [projectStatus]);
  const canvasButtons = useMemo<Array<ComponentButtonProps>>(
    () => [
      {
        hiddenForReader: true,
        disabled: !canExclude || loading,
        color: "danger",
        iconClass: "slash-circle",
        children: "inventory.details.exclude-btn-label",
        "data-tooltip-id": "tooltip",
        "data-tooltip-content": t("inventory.exclude-inventory-tooltip"),
        "data-tooltip-place": "left",
        "data-cy": "button-inventory-exclude",
        loading: loading,
        onClick: exclude,
      },
      {
        hiddenForReader: true,
        color: "primary",
        iconClass: "bi bi-link-45deg",
        children: "common.parameter-link",
        disabled: !canAddParameters || !isLatestAPIDefinition,
        "data-tooltip-id": "tooltip",
        "data-tooltip-content": t(addParametersTooltip),
        "data-tooltip-place": "left",
        "data-cy": "button-inventory-add-parameter-link",
        onClick: handleOnAddParameterLink,
        style: { pointerEvents: "all" },
      },
    ],
    [
      addParametersTooltip,
      canAddParameters,
      canExclude,
      exclude,
      handleOnAddParameterLink,
      isLatestAPIDefinition,
      loading,
    ]
  );

  return {
    canvasButtons,
    openAddParameterLink,
    handleAddParametersLinkCanvasClose,
  };
};
