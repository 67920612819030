import { useMemo } from "react";
import { useUrlParams } from "./useUrlParams";

const matchingRegExp =
  /:userId|:projectId|:organizationId|:apiDefinitionsId|:scanId|:inventoryId/g;

export const useUrlParamsBindedString = (source: string): string => {
  const urlParams = useUrlParams() as Record<string, string | undefined>;

  return useMemo(() => {
    if (!source) {
      return "";
    }

    return source.replace(matchingRegExp, match => {
      const plainString = match.slice(1); // remove : from start
      return urlParams[plainString] || match;
    });
  }, [source, urlParams]);
};
