import type { MenuHorizontalElementProps } from "@Components";
import { createStore } from "@Hooks";
import type { CodeTheme } from "@Interfaces";
import { DEFAULT_CODE_THEME } from "@Interfaces";
import type { Dispatch } from "react";

export let APP_ZOOM = 100;
export let APP_ZOOM_PERCENTAGE = 1;

export type UIStoreProperties = {
  buttons: Array<MenuHorizontalElementProps>;
  appZoom: number;
  appZoomPercentage: number;
  codeTheme: CodeTheme;
};

export type UIStore = UIStoreProperties & {
  setButtons: (buttons: Array<MenuHorizontalElementProps>) => void;
  setAppZoom: Dispatch<number>;
  setCodeTheme: Dispatch<CodeTheme>;
};

const initialState: UIStoreProperties = {
  buttons: [],
  appZoom: 100,
  appZoomPercentage: 1,
  codeTheme: DEFAULT_CODE_THEME,
};

export const useUIStore = createStore<UIStore>((get, set) => ({
  ...initialState,
  setButtons: buttons => set(state => ({ ...state, buttons }), true),
  setAppZoom: appZoom =>
    set(state => {
      APP_ZOOM = appZoom;
      APP_ZOOM_PERCENTAGE = appZoom / 100;
      return { ...state, appZoom, appZoomPercentage: appZoom / 100 };
    }),
  setCodeTheme: codeTheme => set(state => ({ ...state, codeTheme })),
}));
