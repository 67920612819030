import type { ButtonProps } from "@Components";
import { useProject, useToast } from "@Hooks";
import type {
  ParameterLinkOperationVerb,
  ParameterLinksEntry,
} from "@Services";
import {
  type ParameterLinkConsumingParameterLocation as ConsumingLocation,
  type ParameterLinkProducingParameterLocation as ProducingLocation,
  projectDataToUpdateProjectData,
} from "@Services";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAPIDefinitionDetailsStore } from "../../Stores/APIDefinitionDetails.store";
import { useOffCanvasAddParameterLinkStore } from "./OffCanvasAddParameterLinkStore";
import isEqual from "react-fast-compare";

export const useOffCanvasAddParameterLink = (onClose: VoidFunction) => {
  const { details } = useAPIDefinitionDetailsStore();
  const {
    producingInventory,
    producingParameterLocation,
    producingParameterPath,
    consumingInventory,
    consumingParameterLocation,
    consumingParameterPath,
    reset,
    setProducingInventory,
    activeStep,
  } = useOffCanvasAddParameterLinkStore();
  const [loading, setLoading] = useState<boolean>(false);
  const { project, update } = useProject();
  const toast = useToast();

  const handleSave = useCallback(async () => {
    if (!project || !producingInventory || !consumingInventory) return false;

    const parameterLinks = [...project.parameterLinks];

    const parameterLink: ParameterLinksEntry = {
      producingOperationURI: producingInventory.path,
      producingOperationVerb:
        producingInventory.verb as ParameterLinkOperationVerb,
      producingParameterLocation:
        producingParameterLocation as ProducingLocation,
      producingParameterPath: producingParameterPath ?? "",
      consumingOperationURI: consumingInventory.path,
      consumingOperationVerb:
        consumingInventory.verb as ParameterLinkOperationVerb,
      consumingParameterLocation:
        consumingParameterLocation as ConsumingLocation,
      consumingParameterPath: consumingParameterPath ?? "",
    };

    const alreadyExists = parameterLinks.find(p => isEqual(p, parameterLink));

    if (alreadyExists) {
      toast({
        message: "inventory.parameter-link-already-exists",
        type: "error",
      });
      return;
    }

    setLoading(true);

    parameterLinks.push(parameterLink);

    const requestBody = projectDataToUpdateProjectData(project);
    requestBody.parameterLinks = parameterLinks;
    const success = await update(requestBody);

    if (success) {
      toast({
        message: "inventory.parameter-link-save-success",
        type: "success",
      });
      onClose();
    }

    setLoading(false);
  }, [
    consumingInventory,
    consumingParameterLocation,
    consumingParameterPath,
    onClose,
    producingInventory,
    producingParameterLocation,
    producingParameterPath,
    project,
    toast,
    update,
  ]);

  const saveButton: ButtonProps = useMemo(() => {
    return {
      color: "pink",
      iconClass: "bi bi-floppy-fill",
      children: `common.save`,
      disabled: activeStep < 5,
      loading,
      onClick: handleSave,
      id: `save-btn`,
      data: { cy: "save-btn" },
    };
  }, [activeStep, handleSave, loading]);

  useEffect(() => {
    if (!details) {
      return;
    }

    setProducingInventory(details);
    return () => {
      reset();
    };
  }, [details, reset, setProducingInventory]);

  return { saveButton };
};
