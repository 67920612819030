import { PROJECT_MAX_END_DATE } from "@Constants";
import { type Role } from "@Services";
import moment from "moment";

export const roles: Role[] = [
  // Leonardo Rossi
  {
    id: "11",
    role: "organization_admin",
    organizationID: "1",
  },
  {
    id: "11",
    role: "user",
    organizationID: "2",
  },
  {
    id: "11",
    role: "organization_admin",
    organizationID: "3",
  },
  {
    id: "11",
    role: "organization_admin",
    organizationID: "4",
  },
  {
    id: "11",
    role: "organization_admin",
    organizationID: "5",
  },
  {
    id: "11",
    role: "organization_admin",
    organizationID: "6",
  },
  {
    id: "11",
    role: "organization_admin",
    organizationID: "7",
    active: false,
    expiresAt: moment()
      .utc()
      .subtract(10, "days")
      .format("YYYY-MM-DD hh:mm:ss"),
  },
  {
    id: "11",
    role: "organization_admin",
    organizationID: "8",
  },
  {
    id: "11",
    role: "organization_admin",
    organizationID: "9",
  },
  {
    id: "11",
    role: "organization_admin",
    organizationID: "10",
  },
  {
    id: "11",
    role: "security_reader",
    organizationID: "11",
  },
  {
    id: "11",
    role: "organization_admin",
    organizationID: "12",
  },
  {
    id: "11",
    role: "user",
    organizationID: "13",
  },
  // Sofia Russo
  {
    id: "12",
    role: "user",
    organizationID: "1",
  },
  // Alessandro Ferrari
  {
    id: "13",
    role: "organization_admin",
    organizationID: "2",
  },
  // Aurora Bianchi
  {
    id: "14",
    role: "user",
    organizationID: "2",
  },
  {
    id: "14",
    role: "security_reader",
    organizationID: "1",
    expiresAt: moment().add(45, "days").format("YYYY-MM-DD"),
  },
  // Stefano Orfano
  {
    id: "16",
    role: "user",
    organizationID: "2",
    active: false,
  },
  // Laura Rossi
  {
    id: "17",
    role: "user",
    organizationID: "47",
  },
  // Giulio Bianchi
  {
    id: "18",
    role: "organization_admin",
    organizationID: "50",
  },
].map(r => ({
  expiresAt: PROJECT_MAX_END_DATE,
  active: true,
  ...r,
}));
