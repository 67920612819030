import type { InventoryItemParameter } from "@Interfaces";
import type { Edge, Node, NodeProps } from "@xyflow/react";
import type { NodeParametersByLocation } from "@Components";

export const NEW_PARAMETER_LINK_NODE_WIDTH = 320; // 20rem
export const NEW_PARAMETER_LINK_NODE_TITLE_HEIGHT = 40;
export const NEW_PARAMETER_LINK_NODE_ITEM_HEIGHT = 34;

export interface ParameterProps {
  parameter?: Omit<InventoryItemParameter, "in">;
  className?: string;
  style?: React.CSSProperties;
}

export type NewParameterLinkNodeData = {
  path: string;
  verb: string;
  name: string;
  parameters: NodeParametersByLocation;
  isConnected: boolean;
};

export type NewLinkWizardNodeData = {
  //
};

export type NewParameterLinkNodeProps = {
  data: NewParameterLinkNodeData;
  id: string;
};

export type NewLinkWizardNodeProps = {
  data: NewLinkWizardNodeData;
  id: string;
};

export type CustomNodeProps =
  | NewParameterLinkNodeData
  | NewLinkWizardNodeData
  | NodeProps;

export type NewParameterLinkNodes = Array<Node<CustomNodeProps>>;
export type NewParameterLinkEdges = Array<Edge>;
