import { memo, useMemo } from "react";
import { AddPathExclusion } from "./AddPathExclusion";
import { PathExclusionCard, ProjectSettingsCardContainer } from "@Components";
import { usePathExclusion } from "./usePathExclusion";
import { Card } from "reactstrap";

export const NewProjectPathExclusionSettings = memo(() => {
  const { addEntry, pathExclusion, paths, removeEntry } = usePathExclusion();

  const cards = useMemo(() => {
    return pathExclusion.map((p, i) => (
      <PathExclusionCard
        key={i + p.path}
        disabled={false}
        loading={false}
        methods={p.methods}
        path={p.path}
        deleteEntry={() => removeEntry(p)}
      />
    ));
  }, [pathExclusion, removeEntry]);

  return (
    <section id="path-exclusion">
      <Card className="p-4 border-tl-0">
        <AddPathExclusion addPathExclusion={addEntry} paths={paths} />
        <ProjectSettingsCardContainer
          cardType="path-exclusion"
          emptyLabel="project.settings.no-path-exclusion-settings"
        >
          {cards}
        </ProjectSettingsCardContainer>
      </Card>
    </section>
  );
});
