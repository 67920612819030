import { DisplayRowSpaceLink, Section, SpaceLinkEditCanvas } from "@Components";
import { memo } from "react";
import { EMPTY_PROJECT, type ApiDefinitionProps } from "./ApiDefinition.i";
import { BoxFile, FileDetails, Upload } from "./Components";
import { useApiDefinition } from "./Hooks";
import { handleFileSelected } from "./functions";
import { equals } from "@Services";
import "./ApiDefinition.style.scss";

export const ApiDefinition = memo((props: ApiDefinitionProps) => {
  const {
    fileOAS,
    project,
    projectExtras,
    spaceLinkCanvasOpen,
    setProject,
    setProjectExtras,
    getPathsLimit,
    handleOpenSpaceLinkCanvas,
    handleCloseSpaceLinkCanvas,
    handleSetSpaceLink,
  } = useApiDefinition(props);

  const showSpaceLinkSection = !fileOAS?.error && props.newProject;

  return (
    <div id="api-definition">
      <Section>
        {fileOAS ? (
          <>
            <BoxFile
              fileOAS={fileOAS}
              setProject={setProject}
              setProjectExtras={setProjectExtras}
            />
            <FileDetails
              fileOAS={fileOAS}
              project={project}
              setProject={setProject}
              projextExtras={projectExtras}
            />
            {showSpaceLinkSection && (
              <>
                <div className="card p-4 mt-3" data-cy="new-project-space-link">
                  <DisplayRowSpaceLink
                    onClick={handleOpenSpaceLinkCanvas}
                    spaceLinkID={project.spaceLinkID ?? ""}
                  />
                </div>
                {/* todo we need to create a fake project in order to accomodate target interface for the modal*/}
                <SpaceLinkEditCanvas
                  isOpen={spaceLinkCanvasOpen}
                  project={{
                    ...EMPTY_PROJECT,
                    ...project,
                    pathExclusions: project.pathExclusions.map(e => ({
                      ...e,
                      isMatch: false,
                    })),
                  }}
                  setClosed={handleCloseSpaceLinkCanvas}
                  setProject={handleSetSpaceLink}
                />
              </>
            )}
          </>
        ) : (
          <Upload
            handleFileSelected={file =>
              handleFileSelected(
                file,
                getPathsLimit,
                setProject,
                setProjectExtras
              )
            }
          />
        )}
      </Section>
    </div>
  );
}, equals);
