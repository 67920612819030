import { useManageApiResponse } from "@Hooks";
import { API } from "@Services";
import { useCallback, useState } from "react";
import { useValidSearchParams } from "../../Hooks";
import { resetPasswordErrorHandling } from "../../Functions";

export const useResetPasswordPage = () => {
  const { id, showEmptyPage } = useValidSearchParams();

  const manageResponse = useManageApiResponse();
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");

  const submitFunction = useCallback(
    async (password: string) => {
      if (!id || !password) return;
      const { status } = await manageResponse({
        onSuccess: () => setSubmitted(true),
        promise: API.user().userConfirmPasswordReset({
          passwordNew: password,
          passwordConfirm: password,
          id,
        }),
      });

      resetPasswordErrorHandling(status, setError);
    },

    [id, manageResponse]
  );

  return {
    error,
    showEmptyPage,
    submitFunction,
    submitted,
  };
};
