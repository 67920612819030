import { getFilterAllowedOptions } from "@Services";
import { RefreshListButton } from "../../Buttons";
import { FormInput } from "../../Form";
import type { PageFiltersProps } from "../PageFilters.i";
import { PageFiltersWrapper } from "../PageFiltersWrapper";
import { usePageFilters } from "../usePageFilters";

const rolesOptions = getFilterAllowedOptions("user_role");

export const ProfileRolesFilters = ({
  updateFilters,
  refresh,
}: PageFiltersProps) => {
  const { getValueOf, handleOnChange } = usePageFilters(updateFilters);

  return (
    <PageFiltersWrapper>
      <FormInput
        id="select-profile-role-value"
        label="common.role"
        type="select"
        options={rolesOptions}
        name="role_value"
        value={getValueOf("role_value")}
        onChange={handleOnChange}
      />
      <RefreshListButton onClick={refresh} />
    </PageFiltersWrapper>
  );
};
