import type { Nullable } from "@Interfaces";
import { useUIStore } from "@Stores";
import { langs } from "@uiw/codemirror-extensions-langs";
import { EditorView } from "@uiw/react-codemirror";
import { useCallback, useEffect, useMemo, useState } from "react";
import stripAnsi from "strip-ansi";
import { logLineDecorationPlugin } from "../utils";
import { useKeyboardEvent } from "@Hooks";
import { KA_UTILITY_LIVE_LOGS_ACTION } from "@Constants";
import { useLiveLogs } from "./useLiveLogs";

export const useLiveLogsViewer = () => {
  const { codeTheme } = useUIStore();
  const [view, setView] = useState<Nullable<EditorView>>(null);
  const [open, setOpen] = useState(false);
  const [doPolling, setDoPooling] = useState(false);
  const { logs, liveLogLevelFilter, refresh, setLiveLogLevelFilter } =
    useLiveLogs(doPolling);

  const reset = useCallback(() => {
    if (!view || view.state.doc.length === 0) return; // Avoid clearing if the document is already empty
    const transaction = view.state.update({
      changes: {
        from: 0, // Start from the beginning of the document
        to: view.state.doc.length, // Go to the end of the document
        insert: "", // Replace with an empty string (clears the document)
      },
    });

    view.dispatch(transaction); // Apply the transaction to the editor
  }, [view]);

  const updateLogs = useCallback(() => {
    if (!view) {
      return;
    }

    // Build the new logs to append
    const newLogs = logs
      .map(l =>
        `[${l.level.toUpperCase()}] ${l.timestamp ? "[" + l.timestamp + "]" : ""} ${stripAnsi(l.message)}`.trim()
      )
      .join("\n");

    const scrollDOM = view.scrollDOM;
    const actualScrollTop = scrollDOM.scrollTop;
    const isAtBottom =
      actualScrollTop + scrollDOM.clientHeight >= scrollDOM.scrollHeight - 50;

    const transaction = view.state.update({
      changes: {
        from: 0,
        to: view.state.doc.length,
        insert: newLogs,
      },
    });

    view.dispatch(transaction);

    // After the transaction, the scrollHeight might change, so calculate the difference
    const newScrollHeight = scrollDOM.scrollHeight;

    // Scroll to bottom if the user was near the bottom, considering the updated scrollHeight
    if (isAtBottom) {
      scrollDOM.scrollTop = newScrollHeight;
    } else {
      // Adjust the scrollTop to maintain the same relative scroll position
      const scrollDifference = newScrollHeight - scrollDOM.scrollHeight;
      requestAnimationFrame(() => {
        scrollDOM.scrollTop = actualScrollTop + scrollDifference;
      });
    }
  }, [logs, view]);

  useEffect(() => {
    if (!open) {
      return;
    }

    const timeout = setTimeout(
      updateLogs,
      500
    ); /* keep this timeout greater than offcanvas animation duration, else weird rendering issues will happen */
    return () => clearTimeout(timeout);
  }, [view, updateLogs, open]);

  useKeyboardEvent({
    action: KA_UTILITY_LIVE_LOGS_ACTION,
    onKeyDown: () => handleSetOpen(!open),
  });

  const handleEditorCreated = useCallback((view: EditorView) => {
    setView(view);
  }, []);

  const extensions = useMemo(
    () => [langs.shell(), logLineDecorationPlugin, EditorView.lineWrapping],
    []
  );

  const handleSetOpen = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        reset();
      }

      setOpen(isOpen);
    },
    [reset]
  );

  const handleUpdateFilters = useCallback(
    (filters: Record<string, string>) => {
      reset();
      setLiveLogLevelFilter(filters["live_log_level"]);
    },
    [reset, setLiveLogLevelFilter]
  );

  const handleToggleAutoRefresh = useCallback(() => {
    setDoPooling(doPolling => !doPolling);
  }, []);

  return {
    open,
    codeTheme,
    extensions,
    liveLogLevelFilter,
    doPolling,
    refresh,
    setOpen: handleSetOpen,
    handleEditorCreated,
    handleUpdateFilters,
    handleToggleAutoRefresh,
  };
};
