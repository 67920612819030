import { usePreference, useUpdateCacheFilters, useUrlParams } from "@Hooks";
import { useLocation, useNavigate } from "react-router-dom";
import type { ApiKeyId } from "@Interfaces";
import { useCallback, useMemo } from "react";
import { getDefaultFilters, mapHeaderItemsWithSorts } from "@Services";
import { getConfig, getHeaders } from "./functions";
import { useApiKeyListStore } from "../../Stores";
import { generateUrl } from "@Utils";
import { ORGANIZATION_PATH_API_KEY } from "@Constants";

export type ApiKeysShowCaseProps = {
  refresh: VoidFunction;
  openCanvas: VoidFunction;
};

export const useApiKeyShowCase = ({ ...rest }: ApiKeysShowCaseProps) => {
  const { apiKeys, loading, totalItems } = useApiKeyListStore();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [cardVersion, setCardVersion] = usePreference(
    "organization-api-keys-card-version",
    "true"
  );

  const { organizationId } = useUrlParams();

  const defaultFilters = useMemo(() => getDefaultFilters("apikeys"), []);
  const updateFilters = useUpdateCacheFilters("organization.api-keys");

  const goToDetail = useCallback(
    (id: ApiKeyId) => {
      navigate(
        generateUrl(ORGANIZATION_PATH_API_KEY, [organizationId ?? "", id], true)
      );
    },
    [navigate, organizationId]
  );

  const headers = useMemo(() => {
    return mapHeaderItemsWithSorts({
      items: getHeaders(),
      updateFunction: updateFilters,
      defaults: defaultFilters,
      search,
    });
  }, [defaultFilters, search, updateFilters]);

  const config = useMemo(
    () => getConfig(apiKeys || [], goToDetail),
    [goToDetail, apiKeys]
  );

  return {
    cardVersion: cardVersion === "true",
    setCardVersion,
    apiKeys,
    totalItems,
    updateFilters,
    headers,
    defaultFilters,
    config,
    loading,
    ...rest,
  };
};
