import type { UpdateProjectData } from "@Interfaces";
import { type Project } from "../client";
import { pathExclusionsToPathExclusionsEntries } from "./pathExclusionsToPathExclusionsEntries";

/**
 * Since ProjectData and UpdteProject data diverges, this function will
 * map a ProjectData instance into an UpdatedProjectData instance
 * @param project ProjectData
 * @returns UpdateProjectData
 */
export const projectDataToUpdateProjectData = function (
  project: Project
): UpdateProjectData {
  const result: UpdateProjectData = {
    baseURL: project.baseURL,
    description: project.description,
    isProductionURL: project.isProductionURL,
    name: project.name,
    authenticationSettings: project.authenticationSettings,
    dictionary: project.dictionary,
    pathExclusions: pathExclusionsToPathExclusionsEntries(
      project.pathExclusions
    ),
    runPolicy: project.runPolicy,
    injectables: project.injectables,
    mTLSCertificates: project.mTLSCertificates,
    spaceLinkID: project.spaceLinkID,
  };

  // advanced settings must be added ONLY is consistent
  // setting undefined means to set the values to the default
  if (project.advancedSettings) {
    result.advancedSettings = project.advancedSettings;
  }

  if (project.parameterLinks) {
    result.parameterLinks = project.parameterLinks;
  }

  return result;
};
