import { Button, DisplaySection } from "@Components";
import { pureComponent } from "@Utils";
import { memo } from "react";
import { useProfileCard } from "./useProfileCard";
import { EditUser } from "../EditUser";
import "./ProfileCard.style.scss";

export const ProfileCard = memo(() => {
  const { user, canvas } = useProfileCard();

  return user.model ? (
    <>
      <div className="user-details">
        <DisplaySection label={"common.name"} value={user.model.name || "-"} />

        <DisplaySection
          label={"common.surname"}
          value={user.model.surname || "-"}
        />

        <DisplaySection
          label={"common.email"}
          value={user.model.email || "-"}
        />
        <Button
          id="edit-user-details"
          color="primary"
          onClick={canvas.actions.open}
          data={{ cy: "edit-btn" }}
          children="common.edit"
          iconClass="bi bi-pencil-fill"
        />
      </div>

      <EditUser
        isOpen={canvas.isOpen}
        close={canvas.actions.close}
        user={user.model}
        updateUser={user.setValue}
      />
    </>
  ) : (
    <></>
  );
}, pureComponent);
