import React, { useMemo } from "react";
import type { Props } from "./NavigationBar.i";
import { PaginatorButton } from "../PaginatorButton";
import { evalButtonsList } from "./functions";

export const NavigationBar = React.memo(
  ({ page, setPage, totalPages, maxPageButtons = 9 }: Props) => {
    const displayArrows = totalPages > 3;
    const hasPrevious = page > 1;
    const hasNext = page < totalPages;

    const buttons = useMemo(() => {
      const pages = evalButtonsList({
        length: Math.max(maxPageButtons, 5),
        page,
        totalPages,
      });

      return pages.map((value, index) => {
        const isSpread = value === 0;
        return (
          <PaginatorButton
            active={page === value}
            label={isSpread ? "..." : `${value}`}
            onClick={() => !isSpread && setPage(value)}
            key={isSpread ? `gap-${index}-${value}` : `page-${index}-${value}`}
            className={`${isSpread ? "gap" : "paginator-button"}`}
            disabled={isSpread}
          />
        );
      });
    }, [maxPageButtons, page, setPage, totalPages]);

    return (
      <>
        {displayArrows && (
          <PaginatorButton
            label={<i className="bi bi-chevron-left" />}
            onClick={() => hasPrevious && setPage(page - 1)}
            key={"prev"}
            disabled={!hasPrevious}
          />
        )}
        {buttons}
        {displayArrows && (
          <PaginatorButton
            label={<i className="bi bi-chevron-right" />}
            onClick={() => hasNext && setPage(page + 1)}
            key={"next"}
            disabled={!hasNext}
          />
        )}
      </>
    );
  }
);

export * from "./functions";
