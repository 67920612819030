import { IssuesPageLayout } from "../IssuesPageLayout";
import useAllIssues from "./useAllIssues";

export const AllIssues = () => {
  const { fetch, loading, response, setFilters } = useAllIssues();
  return (
    <IssuesPageLayout
      response={response}
      issueListingType="all"
      fetch={fetch}
      loading={loading}
      setFilters={setFilters}
    />
  );
};
