import { usePaginatedResult, useUpdateCacheFilters } from "@Hooks";
import type { Inventory } from "@Interfaces";
import { API, getDefaultFilters, mapHeaderItemsWithSorts } from "@Services";
import { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { SCAN_INVENTORY_FILTERS_KEY } from "src/Pages/Scans/constants";
import { useScanDetailsStore } from "../../../Store/ScanDetails.store";
import { getHeaders } from "../functions";
import { useScanDetailsInventoryStore } from "../Inventory.store";
import { useTableConfig } from "./useTableConfig";

export const useScanDetailsInventory = () => {
  const { details } = useScanDetailsStore();
  const { search } = useLocation();
  const { items, setItems, setLoading } = useScanDetailsInventoryStore();
  const updateFilters = useUpdateCacheFilters(SCAN_INVENTORY_FILTERS_KEY);
  const defaultFilters = getDefaultFilters("apiDefinitions");

  const headers = useMemo(() => {
    return mapHeaderItemsWithSorts({
      defaults: defaultFilters,
      items: getHeaders(),
      search,
      updateFunction: updateFilters,
    });
  }, [defaultFilters, search, updateFilters]);

  const config = useTableConfig(
    items as Inventory[],
    details?.scan.baseURL ?? ""
  );

  const { response, fetch, loading } = usePaginatedResult({
    defaultFilters,
    getResponse: useCallback(
      args => {
        return API.inventory().inventoryListInProject(
          args.organizationId,
          args.projectId,
          details?.scan.apiDefinitionID ?? "",
          args.page,
          args.pageSize,
          args.sortBy as "inventory_item_path" | undefined,
          args.sortMode,
          args.criteria as
            | (
                | "inventory_item_path"
                | "inventory_item_verb"
                | "inventory_item_category"
                | "inventory_item_authentication_expected"
              )[]
            | undefined,
          args.operators as ("LIKE" | "EQ")[],
          args.values
        );
      },
      [details?.scan.apiDefinitionID]
    ),
    debounceDelay: 500,
    skipFirstFetch: !!items,
  });

  useEffect(() => {
    if (!response) {
      return;
    }

    setItems(response.data ?? [], response.totalItems);
  }, [setItems, response]);

  useEffect(() => setLoading(loading), [loading, setLoading]);

  return {
    defaultFilters,
    fetch,
    config,
    headers,
    updateFilters,
  };
};
