import { SkeletonBox } from "./SkeletonBox";

const Placeholder = ({
  y,
  x,
  width,
  height,
}: {
  y: string;
  x: string;
  width: string;
  height: string;
}) => <rect x={x} y={y} rx="3" ry="3" width={width} height={height} />;

export const SkeletonImg = ({
  width,
  height,
}: {
  width: string;
  height: string;
}) => {
  return (
    <SkeletonBox width="100%" height={height}>
      <Placeholder y={"0"} x={"0"} width={width} height={height} />
    </SkeletonBox>
  );
};
