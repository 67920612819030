import { FormInput, RefreshListButton } from "@Components";
import type { PageFiltersProps } from "../PageFilters.i";
import { usePageFilters } from "../usePageFilters";
import { PageFiltersWrapper } from "../PageFiltersWrapper";

export const OrganizationProjectFilters = ({
  refresh,
  updateFilters,
}: PageFiltersProps) => {
  const { getValueOf, handleOnChange } = usePageFilters(updateFilters);
  return (
    <PageFiltersWrapper>
      <FormInput
        id="project-name-input"
        type="text"
        label="common.name"
        name="project_name"
        value={getValueOf("project_name")}
        onChange={handleOnChange}
      />
      <RefreshListButton onClick={refresh} />
    </PageFiltersWrapper>
  );
};
