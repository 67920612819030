import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { usePreference, useUrlParams } from "@Hooks";
import { mapHeaderItemsWithSorts } from "@Services";
import { defaultSorts } from "../../../../../constants";
import { headerItems } from "../tableHeaders";
import type { SpaceLinkTableProps } from "../SpaceLinkTable.i";
import type { ListItemConfigElement } from "@Components";
import { getSpaceLinkTableConfig } from "../tableConfig";
import { useInlineStatusToggle } from "./useInlineStatusToggle";
import { ORGANIZATION_PATH_SPACE_LINK } from "@Constants";
import { generateUrl } from "@Utils";

export const useSpaceLinkTable = ({
  updateFiltersFunction,
  loading,
  spaceLinks,
}: SpaceLinkTableProps) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { organizationId } = useUrlParams();
  const [cardVersion, setCardVersion] = usePreference(
    "organization-space-link-card-version"
  );

  const handleSetCardVersion = useCallback(
    (value: boolean) => {
      setCardVersion(value ? "true" : null);
    },
    [setCardVersion]
  );

  const headers = useMemo(() => {
    return mapHeaderItemsWithSorts({
      defaults: defaultSorts,
      items: headerItems,
      search,
      updateFunction: updateFiltersFunction,
    });
  }, [search, updateFiltersFunction]);

  const navigateToId = useCallback(
    (id: string) => {
      navigate(
        generateUrl(
          ORGANIZATION_PATH_SPACE_LINK,
          [organizationId ?? "", id],
          true
        ),
        { replace: false }
      );
    },
    [navigate, organizationId]
  );

  const { updateInlineStatus, updatingInlineStatus } = useInlineStatusToggle();
  const config = useMemo<ListItemConfigElement[]>(() => {
    if (!spaceLinks) return [];
    return getSpaceLinkTableConfig({
      spaceLinks,
      navigateToId,
      updateInlineStatus,
      updatingInlineStatus,
    });
  }, [spaceLinks, navigateToId, updateInlineStatus, updatingInlineStatus]);

  return {
    cardVersion: cardVersion === "true",
    setCardVersion: handleSetCardVersion,
    headers,
    config,
    updateFiltersFunction,
    loading,
  };
};
