import type { FC } from "react";
import { memo } from "react";
import { DangerZone } from "@Components";
import { equals } from "@Services";
import { useDeleteUser } from "../../Hooks";

export type DeleteUserProps = {
  subject: string;
};

export const DeleteUser: FC<DeleteUserProps> = memo(({ subject }) => {
  const { handleDelete, handleOnSuccess } = useDeleteUser();
  return (
    <DangerZone
      className="mt-4"
      confirmationText={subject}
      promise={handleDelete}
      subject={subject}
      target="user"
      toastErrorMessage="delete-user"
      toastSuccessMessage="delete-user"
      onSuccess={handleOnSuccess}
    />
  );
}, equals);
