import { equals } from "@Services";
import { joinClasses } from "@Utils";
import { t } from "i18next";
import { memo, type FC } from "react";

interface FormMessageProps {
  message: string;
  color: "danger" | "warning";
  innerClassName?: string;
  noMargin?: boolean;
}

export const FormMessage: FC<FormMessageProps> = memo(
  ({ innerClassName, color, message, noMargin }) => {
    const className = joinClasses(
      "form-message",
      !noMargin && "mt-2",
      `text-${color}`
    );
    return (
      <div data-cy="form-message" className={className}>
        <i className="bi-info-circle me-2" />
        <small className={innerClassName}>{t(message)}</small>
      </div>
    );
  },
  equals
);
