import type {
  AuditLogsCriteria,
  AuditLogsOperators,
  AuditLogsSortBy,
} from "@Components";
import { usePaginatedResult } from "@Hooks";
import { getDefaultFilters, getDefaultFilterValue } from "@Services";
import type { Dispatch, SetStateAction } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";

const getFilterArgs = (filterValues: Record<string, string | number>) => {
  const criterias: AuditLogsCriteria = [];
  const operators: AuditLogsOperators = [];
  const values: string[] = [];

  const addFilter = (
    field: string,
    operator: "LIKE" | "EQ",
    filterValues: Record<string, string | number>
  ) => {
    if (filterValues[field]) {
      criterias.push(field as keyof AuditLogsCriteria);
      operators.push(operator);
      values.push(filterValues[field] as string);
    }
  };

  addFilter("audit_log_path", "LIKE", filterValues);
  addFilter("audit_log_status", "EQ", filterValues);
  addFilter("audit_log_method", "EQ", filterValues);
  addFilter("period", "EQ", filterValues);

  return {
    criterias,
    operators,
    values,
    sortBy: filterValues.sortBy as AuditLogsSortBy,
    sortMode: filterValues.sortMode,
    page: +(filterValues.page ?? "1"),
    pageSize: +(filterValues.pageSize ?? "12"),
  };
};

const getLiveLogsStartFilters = (defaultFilters: Record<string, string>) => ({
  audit_log_path: getDefaultFilterValue("audit_log_path"),
  audit_log_status: getDefaultFilterValue("audit_log_status"),
  audit_log_method: getDefaultFilterValue("audit_log_method"),
  period: getDefaultFilterValue("period"),
  sortBy: defaultFilters.sortBy,
  sortMode: defaultFilters.sortMode,
});

type AuditLogFetcher = (filterArgs: any) => Promise<any>;

export const useOrganizationAuditLogs = (
  logType: "organization-user-audit-logs" | "organization-apikeys-audit-logs",
  fetchLogs: AuditLogFetcher,
  urlParams: Record<string, string | undefined>
) => {
  const { organizationId, ...restParams } = urlParams;
  const defaultFilters = useMemo(() => getDefaultFilters(logType), [logType]);

  const [filterValues, setFilterValues] = useState<Record<string, string>>(
    getLiveLogsStartFilters(defaultFilters)
  );

  const { response, fetch, loading } = usePaginatedResult({
    defaultFilters,
    getResponse: useCallback(() => {
      const filterArgs = getFilterArgs(filterValues);
      return fetchLogs({
        ...restParams,
        organizationId,
        page: filterArgs.page as number,
        pageSize: filterArgs.pageSize as number,
        sortBy: filterArgs.sortBy as AuditLogsSortBy,
        sortMode: filterArgs.sortMode as "asc" | "desc",
        criterias: filterArgs.criterias,
        operators: filterArgs.operators,
        values: filterArgs.values,
      });
    }, [filterValues, restParams, organizationId, fetchLogs]),
  });

  const handleSetFilters = useCallback(
    (value: SetStateAction<Record<string, string>>) => {
      const nextValue =
        typeof value === "function" ? value(filterValues) : value;
      setFilterValues(prevFilters => ({ ...prevFilters, ...nextValue }));
    },
    [filterValues]
  );

  useEffect(() => {
    const timeout = setTimeout(fetch, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  const setFilters: Dispatch<SetStateAction<Record<string, string>>> =
    useCallback(handleSetFilters, [handleSetFilters]);

  return {
    logs: response?.data,
    totalItems: response?.totalItems,
    loading,
    filterValues,
    refresh: fetch,
    setFilters,
  };
};
