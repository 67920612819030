import { pureComponent } from "@Utils";
import { memo, useEffect } from "react";
import { CreateSpaceLink, SpaceLinkListingContent } from "./Components";
import { useSpaceLinkListing } from "@Hooks";
import { useSpaceLinkListingStore } from "./Stores";
import { Outlet } from "react-router-dom";

export const SpaceLinkListing = memo(() => {
  const {
    fetch,
    hasAnyItem,
    hasError,
    loading,
    spaceLinks,
    totalItems,
    updateSearchFilter,
  } = useSpaceLinkListing();
  const { refreshData, setNeedRefresh } = useSpaceLinkListingStore();

  useEffect(() => {
    if (refreshData) {
      fetch();
      setNeedRefresh(false);
    }
  }, [fetch, refreshData, setNeedRefresh]);

  return (
    <div id="organization-space-links">
      <SpaceLinkListingContent
        fetch={fetch}
        hasAnyItem={hasAnyItem}
        hasError={hasError}
        loading={loading}
        spaceLinks={spaceLinks}
        totalItems={totalItems}
        updateSearchFilter={updateSearchFilter}
      />
      <CreateSpaceLink fetch={fetch} />
      <Outlet />
    </div>
  );
}, pureComponent);
