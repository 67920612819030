import { FormInput } from "../../Form";
import type { PageFiltersProps } from "../PageFilters.i";
import { Button, RefreshListButton } from "../../Buttons";
import { memo } from "react";
import { equals, getFilterAllowedOptions } from "@Services";
import { PageFiltersWrapper } from "../PageFiltersWrapper";
import { joinClasses } from "@Utils";
import { usePageFilters } from "../usePageFilters";

const logLevelOptions = getFilterAllowedOptions("live_log_level");

type LiveLogFiltersProps = PageFiltersProps & {
  autoRefreshing: boolean;
  liveLogLevelFilter: string;
  toggleAutoRefresh: VoidFunction;
};

export const LiveLogsFilters = memo(
  ({
    autoRefreshing,
    liveLogLevelFilter,
    updateFilters,
    refresh,
    toggleAutoRefresh,
  }: LiveLogFiltersProps) => {
    const { handleOnChange } = usePageFilters(updateFilters);
    return (
      <PageFiltersWrapper className="p-3">
        <FormInput
          id="live_log_level"
          label="common.level"
          type="select"
          name="live_log_level"
          value={liveLogLevelFilter}
          options={logLevelOptions}
          onChange={handleOnChange}
        />
        <RefreshListButton onClick={refresh} />
        <Button
          children="Auto refresh"
          color="pink"
          iconClass="bi bi-infinity"
          className={joinClasses("icon-button", autoRefreshing && "active")}
          outline
          onClick={toggleAutoRefresh}
        />
      </PageFiltersWrapper>
    );
  },
  equals
);
