import {
  AuthWrapper,
  Breadcrumb,
  OrganizationApiKeysListBreadcrumb,
  OrganizationBreadcrumb,
  OrganizationSpaceLinkBreadcrumb,
  OrganizationUserListBreadcrumbBreadcrumb,
  type MenuHorizontalElementProps,
} from "@Components";
import {
  ApiKeyDetailsCanvas,
  ApiKeysListing,
  NewProject,
  Organization,
  OrganizationGeneralPage,
  OrganizationUserDetailsCanvas,
  OrganizationUsersList,
  SpaceLinkDetailsCanvas,
  SpaceLinkListing,
} from "@Pages";

import {
  organizationLastVisitedPageRegexp as GetOrganizationLastVisitedPageRegExp,
  ORGANIZATION_PATH,
  ORGANIZATION_PATH_API_KEYS,
  ORGANIZATION_PATH_GENERAL,
  ORGANIZATION_PATH_NEW_PROJECT,
  ORGANIZATION_PATH_SPACE_LINKS,
  ORGANIZATION_PATH_USERS,
} from "@Constants";
import { t } from "i18next";

const enterpriseLicense = `licence is Enterprise`;
const adminRole = `role in ["organization_admin", "super_admin"]`;

type Config = Omit<MenuHorizontalElementProps, "type"> & {
  roles?: string[];
};

const ORGANIZATION_BUTTONS: Array<Config> = [
  {
    name: "general",
    path: ORGANIZATION_PATH_GENERAL,
    icon: "bi bi-info-square-fill",
  },
  {
    name: "users",
    path: ORGANIZATION_PATH_USERS,
    partial: true,
    visibleControl: adminRole,
    recentPageSearch: GetOrganizationLastVisitedPageRegExp("users"),
    icon: "bi bi-people-fill",
  },
  {
    name: "api-keys",
    path: ORGANIZATION_PATH_API_KEYS,
    accessControl: enterpriseLicense,
    visibleControl: adminRole,
    partial: true,
    recentPageSearch: GetOrganizationLastVisitedPageRegExp("apikeys"),
    disabledTooltip: {
      content: t("common.enterprise-only"),
      place: "bottom",
    },
    icon: "bi bi-boxes",
  },
  {
    name: "space-links",
    path: ORGANIZATION_PATH_SPACE_LINKS,
    accessControl: enterpriseLicense,
    visibleControl: adminRole,
    partial: true,
    recentPageSearch: GetOrganizationLastVisitedPageRegExp("spacelinks"),
    disabledTooltip: {
      content: t("common.enterprise-only"),
      place: "bottom",
    },
    icon: "bi bi-broadcast-pin",
  },
];

export const OrganizationRoutes = [
  {
    path: ORGANIZATION_PATH,
    element: <AuthWrapper element={<Organization />} pageName="Organization" />,
    handle: {
      section: "organization",
      buttons: ORGANIZATION_BUTTONS,
      crumb: <OrganizationBreadcrumb />,
    },
    children: [
      {
        path: "general",
        element: <OrganizationGeneralPage />,
        handle: {
          crumb: (
            <Breadcrumb
              name="organization-general"
              to={ORGANIZATION_PATH_GENERAL}
            >
              {t("common.general")}
            </Breadcrumb>
          ),
        },
      },
      {
        path: "users",
        element: <OrganizationUsersList />,
        handle: {
          accessControl: adminRole,
          crumb: <OrganizationUserListBreadcrumbBreadcrumb />,
        },
        children: [
          {
            path: ":userId",
            element: <OrganizationUserDetailsCanvas />,
          },
        ],
      },
      {
        path: "apikeys",
        handle: {
          pageName: "Organization API Keys",
          accessControl: `${adminRole} and (${enterpriseLicense})`,
          crumb: <OrganizationApiKeysListBreadcrumb />,
        },
        element: <ApiKeysListing />,
        children: [
          {
            path: ":apiKeyId",
            element: <ApiKeyDetailsCanvas />,
          },
        ],
      },
      {
        path: "spacelinks",
        element: <SpaceLinkListing />,
        handle: {
          pageName: "Organization Space Link",
          accessControl: `${adminRole} and (${enterpriseLicense})`,
          crumb: <OrganizationSpaceLinkBreadcrumb />,
        },
        children: [
          {
            path: ":spaceLinkId",
            element: <SpaceLinkDetailsCanvas />,
          },
        ],
      },
    ],
  },
  {
    path: ORGANIZATION_PATH_NEW_PROJECT,
    element: <AuthWrapper element={<NewProject />} pageName="New project" />,
    handle: {
      accessControl: `not (role in ["security_reader"])`,
    },
  },
];
