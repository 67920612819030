import {
  Offcanvas,
  type ButtonProps,
  SpaceLinkForm,
  FormInput,
  Callout,
} from "@Components";
import type { FC } from "react";
import { memo, useMemo } from "react";
import type { CreateSpaceLinkProps } from "./CreateSpaceLink.i";
import { useCreateSpacelink } from "./useCreateSpaceLink";
import { Form } from "reactstrap";
import { t } from "i18next";
import isEqual from "react-fast-compare";

const submitButtonProps: ButtonProps = {
  color: "primary",
  children: "common.add",
  iconClass: "plus-square-fill",
  data: { cy: "submit-space-link-button" },
};

export const CreateSpaceLink: FC<CreateSpaceLinkProps> = memo(props => {
  const {
    canvasLoading,
    canvasOpen,
    spaceLink,
    spaceLinkApiKey,
    formDisabled,
    formValid,
    closeCanvas,
    handleSubmit,
    setFormValid,
    setSpaceLink,
  } = useCreateSpacelink(props);

  const exitLabel = spaceLinkApiKey ? "common.close" : undefined;

  const canvasButtons = useMemo<ButtonProps[]>(() => {
    if (spaceLinkApiKey) return [];
    return [
      {
        ...submitButtonProps,
        disabled: !formValid || formDisabled,
        loading: canvasLoading,
        onClick: handleSubmit,
      },
    ];
  }, [canvasLoading, formDisabled, formValid, handleSubmit, spaceLinkApiKey]);

  return (
    <>
      <Offcanvas
        isOpen={canvasOpen}
        toggle={closeCanvas}
        title="common.create-space-link"
        buttons={canvasButtons}
        id="add-space-link-organization-canvas"
        exitLabel={exitLabel}
      >
        <>
          <SpaceLinkForm
            spaceLink={spaceLink}
            setSpaceLink={setSpaceLink}
            setFormValid={setFormValid}
            disabled={formDisabled}
          />
          {spaceLinkApiKey && (
            <Form onSubmit={undefined}>
              <Callout level="warning" className="my-3">
                {t("organization.create-space-link-warning")}
              </Callout>
              <FormInput
                type="password"
                label={t(`common.token`)}
                value={spaceLinkApiKey}
                id="input-space-link-api-key-value"
                data-cy="input-space-link-api-key-value"
                readOnly
                canBeCopy
                canBeSeen
              />
            </Form>
          )}
        </>
      </Offcanvas>
    </>
  );
}, isEqual);
