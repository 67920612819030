import { DictionaryForm, Offcanvas } from "@Components";
import { useDictionaryFormCanvas } from "./useDictionaryFormCanvas";
import type { Props } from "./DictionaryFormCanvas.i";
import { memo } from "react";

export const DictionaryFormCanvas = memo((props: Props) => {
  const { canvas, entry, setEntry, setFormValid } =
    useDictionaryFormCanvas(props);
  return (
    <Offcanvas
      id="dictionary-form-canvas"
      isOpen={canvas.isOpen}
      toggle={canvas.close}
      title="common.dictionary"
      buttons={canvas.buttons}
      children={
        <DictionaryForm
          entry={entry}
          setEntry={setEntry}
          setFormValid={setFormValid}
          disabled={canvas.loading}
        />
      }
    />
  );
});
