import { DisplayRowProjectPathExclusion, SettingsContainer } from "@Components";
import { useSettingsStore } from "../Settings/Store/SettingsStore";

export const PathExclusion = () => {
  const { loading, pathExclusionSettings } = useSettingsStore();
  return (
    <div id="scan-path-exclusion" className="p-4">
      <SettingsContainer loading={loading} section="path-exclusion">
        <DisplayRowProjectPathExclusion
          pathExclusions={pathExclusionSettings.paths ?? []}
        />
      </SettingsContainer>
    </div>
  );
};
