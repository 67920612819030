import { memo } from "react";
import { ScansPage } from "@Pages";
import { useScans } from "./useScans";
import { LiveLogsViewer } from "@Components";

export const ProjectScans = memo(() => {
  const { fetch, loading, response } = useScans();
  return (
    <>
      <ScansPage
        forProject={true}
        loading={loading}
        refreshScans={fetch}
        response={response}
      />
      <LiveLogsViewer />
    </>
  );
});
