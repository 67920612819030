import type { FC, FunctionComponent } from "react";
import React, { memo, useMemo } from "react";
import { Meta, NoAuthPage } from "@Components";
import type { LoginPageProps, LoginSubPage } from "./Login.i";
import {
  ResetPassword,
  ForgotPassword,
  LoginCard,
  SetupOtp,
  SendOtp,
  NewPassword,
} from "./Subpages";
import { useLogOff } from "./useLogOff";
import { useErrors } from "./useErrors";

const contentComponents: Record<LoginSubPage, FunctionComponent> = {
  login: LoginCard,
  "setup-2fa": SetupOtp,
  "send-otp": SendOtp,
  "forgot-password": ForgotPassword,
  "reset-password": ResetPassword,
  "new-invite": NewPassword,
};

export const LoginPage: FC<LoginPageProps> = memo(({ subPage = "login" }) => {
  useErrors();
  useLogOff();

  const PageContent = useMemo(() => contentComponents[subPage], [subPage]);

  return (
    <>
      <Meta pageName="Login" />
      <NoAuthPage children={<PageContent />} />
    </>
  );
});
