import { projectInfos } from "src/Mock/data";
import { findProjectsByOrganizationId } from "./findProjectsByOrganizationId";
import type { OrganizationId } from "@Interfaces";

export const findProjectsInfoByOrganizationId = (
  organizationId: OrganizationId
) => {
  const ids = findProjectsByOrganizationId(organizationId).map(p => p.id);
  return projectInfos.filter(p => ids.includes(p.id));
};
