import { GridContainer, JSONViewer } from "@Components";
import { memo } from "react";
import { useSectionDetails } from "../../Hooks";
import { equals } from "@Services";
import { BEM, limitString } from "@Utils";
import { ApiKeyData } from "../ApiKeyData/ApiKeyData";
import { t } from "i18next";
import { DeleteApiKey } from "../DeleteApiKey/DeleteApiKey";

export const SectionDetails = memo(() => {
  const { apiKey, isExpired, remainingDays, status } = useSectionDetails();

  if (!apiKey) {
    return null;
  }

  return (
    <>
      <GridContainer
        data-cy="api-key-data"
        className="section-grid-container"
        id={BEM("api-key-details__content", "main-info-container")}
      >
        <div>
          <div
            id={BEM("api-key-details__content__main-info-container", "title")}
          >
            <h1 className="api-key-name">{limitString(apiKey.name, 80)}</h1>
            <h6 className="color-gray" data-cy="api-key-role">
              {t(`roles.${apiKey.role}`)}
            </h6>
          </div>
        </div>
        <ApiKeyData
          apiKey={apiKey}
          isExpired={isExpired}
          remainingDays={remainingDays}
          status={status}
        />
        <DeleteApiKey apiKey={apiKey} />
      </GridContainer>
      <JSONViewer data={apiKey} devMode />
    </>
  );
}, equals);
