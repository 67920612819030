import type { ChangeEvent } from "react";

export const getParsedInputValue = (
  e: ChangeEvent<HTMLInputElement>
): string => {
  switch (e.target.type) {
    case "number":
      return handleNumberInput(e);
    default:
      return e.target.value;
  }
};

const handleNumberInput = ({ target }: ChangeEvent<HTMLInputElement>) => {
  const inputValue = parseInt(target.value || "0");

  if (isNaN(inputValue)) throw new Error("Invalid input value");

  const inputMinValue = parseInt(target.min);
  if (!isNaN(inputMinValue) && inputMinValue > inputValue) {
    return Math.max(inputMinValue, inputValue).toString();
  }

  const inputMaxValue = parseInt(target.max);
  if (!isNaN(inputMaxValue) && inputMaxValue < inputValue) {
    return Math.min(inputMaxValue, inputValue).toString();
  }

  return inputValue.toString();
};
