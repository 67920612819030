import { Outlet } from "react-router-dom";
import { ApiKeyCanvas, ApiKeysShowCase, NoApiKeys } from "./Components";
import { useApiKeyCanvas, useApiKeyListing } from "./Hooks";

export const ApiKeysListing = () => {
  const { canvasOpen, openCanvas, closeCanvas } = useApiKeyCanvas();
  const { response, fetch, loading, showCurtesyPage } = useApiKeyListing();

  if (loading || (response && !showCurtesyPage)) {
    return (
      <>
        <ApiKeysShowCase openCanvas={openCanvas} refresh={fetch} />
        <ApiKeyCanvas
          open={canvasOpen}
          closeCanvas={closeCanvas}
          onSuccess={fetch}
        />
        <Outlet />
      </>
    );
  }

  if (response && showCurtesyPage) {
    return (
      <>
        <NoApiKeys openCanvas={openCanvas} />
        <ApiKeyCanvas
          open={canvasOpen}
          closeCanvas={closeCanvas}
          onSuccess={fetch}
        />
      </>
    );
  }

  return null;
};
