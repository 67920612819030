import { useGlobalInjectables } from "@Hooks";
import { capitalizeString, type Injectable } from "@Services";
import { t } from "i18next";
import { memo, useMemo } from "react";
import { OverrideIcon } from "../Components";
import { DisplayTable } from "../DisplayTable";

export const InjectableDisplayTable = memo(
  (props: { injectable: Injectable }) => {
    const { name, location, value } = props.injectable;
    const globalInjectables = useGlobalInjectables();
    const isOverriding = useMemo(() => {
      return globalInjectables.some(i => {
        return i.name === name && i.location === location;
      });
    }, [globalInjectables, location, name]);
    return (
      <DisplayTable>
        <tbody>
          <tr>
            <td>
              <span>{t("common.name")}</span>
            </td>
            <td>
              {isOverriding && <OverrideIcon className="pe-2" />}
              <span>{name}</span>
            </td>
          </tr>
          <tr>
            <td>{t("common.location")}</td>
            <td>{capitalizeString(location)}</td>
          </tr>
          <tr>
            <td>{t("common.value")}</td>
            <td>{value}</td>
          </tr>
        </tbody>
      </DisplayTable>
    );
  }
);
