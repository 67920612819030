import type { Dispatch } from "react";
import { createStore } from "@Hooks";

export type SpaceLinkDetailsCanvasStoreProperties = {
  open: boolean;
  editCanvasOpen: boolean;
};

export type SpaceLinkDetailsCanvasStore =
  SpaceLinkDetailsCanvasStoreProperties & {
    setOpen: Dispatch<boolean>;
    setEditCanvasOpen: Dispatch<boolean>;
  };

const initialState: SpaceLinkDetailsCanvasStoreProperties = {
  open: false,
  editCanvasOpen: false,
};

export const useSpaceLinkDetailsCanvasStore =
  createStore<SpaceLinkDetailsCanvasStore>(
    (get, set) => ({
      setOpen: open => set(store => ({ ...store, open })),
      setEditCanvasOpen: editCanvasOpen =>
        set(store => ({ ...store, editCanvasOpen })),
    }),
    initialState
  );
