import { FormInput } from "../../Form";
import { getFilterAllowedOptions } from "@Services";
import type { PageFiltersProps } from "../PageFilters.i";
import { RefreshListButton } from "../../Buttons";
import { usePageFilters } from "../usePageFilters";
import { PageFiltersWrapper } from "../PageFiltersWrapper";

const periodOptions = getFilterAllowedOptions("period");
const scanStatusOptions = getFilterAllowedOptions("scan_status");

export const ScansFilters = ({ updateFilters, refresh }: PageFiltersProps) => {
  const { getValueOf, handleOnChange } = usePageFilters(updateFilters);
  return (
    <PageFiltersWrapper>
      <FormInput
        id="select-scan-period"
        label="components.date-filter.period-label"
        type="select"
        options={periodOptions}
        name="period"
        value={getValueOf("period")}
        onChange={handleOnChange}
      />
      <FormInput
        id="select-scan-status"
        label="common.status"
        type="select"
        options={scanStatusOptions}
        name="scan_status"
        value={getValueOf("scan_status")}
        onChange={handleOnChange}
      />
      <RefreshListButton onClick={refresh} />
    </PageFiltersWrapper>
  );
};
