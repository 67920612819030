import { MethodBadge } from "@Components";
import type { Method } from "@Interfaces";
import { BEM, joinClasses, limitString } from "@Utils";
import { Handle, Position, useUpdateNodeInternals } from "@xyflow/react";
import type { FC } from "react";
import { memo, useEffect } from "react";
import type { InventoryNodeProps } from "../../AdditionalInfo.i";
import { equals } from "@Services";
import "./InventoryItemNode.styles.scss";
// CustomNode component with types
export const InventoryItemNode: FC<InventoryNodeProps> = memo(
  ({ id: nodeId, data }) => {
    const updateNodeInternals = useUpdateNodeInternals();
    const { details } = data;
    useEffect(() => {
      updateNodeInternals(nodeId);
    }, [data, nodeId, updateNodeInternals]);

    return (
      <div id={nodeId} className={joinClasses("inventory-node")}>
        <Handle
          type="target"
          position={Position.Left}
          id={`handle-target-${nodeId}`}
          isConnectable={false}
        >
          <i className="bi bi-arrow-right-square-fill" />
        </Handle>
        <div className={BEM("inventory-node", "title")}>
          <span title={details.path}>{limitString(details.path, 22)}</span>
          <div className="flex-center">
            {details.verb && <MethodBadge type={details.verb as Method} />}
          </div>
        </div>
        <Handle
          type="source"
          position={Position.Right}
          id={`handle-source-${nodeId}`}
          isConnectable={false}
        >
          <i className="bi bi-arrow-right-square-fill" />
        </Handle>
      </div>
    );
  },
  equals
);
