import { CYPRESS_RUNNING } from "@Constants";
import type {
  DashboardSummary,
  Predicate,
  ProjectDashboardSummary,
} from "@Interfaces";
import type { ListedProject, ListedScan, SeverityCount } from "@Services";
import { getStartOf } from "@Services";
import moment from "moment-timezone";
import { getAndCreateChartData, listIssues, scans } from "./data";
import { findProjectsByOrganizationId, getFiltersFromUrlParams } from "./utils";

// --------------------------
// - CHART DATA
// --------------------------
// K = org id, V = array of data
export const getRandomBetweenMinMax = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min) + min);

export function getChartData(
  organizationId: string,
  period: string,
  projectId?: string
) {
  const start = getStartOf(period);
  const data = getAndCreateChartData(organizationId, projectId);
  return data.filter(item => item.date.localeCompare(start) >= 0);
}

export const getRandomIssues = (min: number, max: number) => {
  const appIssues: {
    [key: string]: {
      name: string;
      type: string;
    };
  } = {};
  const len = listIssues.length;
  new Array(getRandomBetweenMinMax(min, max)).fill(1).forEach(() => {
    const newElem = listIssues[getRandomBetweenMinMax(0, len)];
    appIssues[newElem.name] = newElem;
  });

  return Object.values(appIssues);
};
// --------------------------
// - SUMMARY DATA
// --------------------------
// K = org id, V = summary
const cachedSummary = new Map();

function getAndCreateSummary(
  organizationId: string,
  projectId = ""
): DashboardSummary["summary"] {
  if (organizationId === "3") {
    return {
      riskLevel: "information",
      completedScansCount: 0,
      totalScansCount: 0,
      endpoints: 12,
      severityCount: {
        high: 0,
        medium: 0,
        low: 0,
        information: 0,
      },
      mostFrequentIssuesSeverityCount: [],
    };
  }
  if (!cachedSummary.has(organizationId + projectId)) {
    let scans = getAndCreateScans(organizationId);

    if (projectId) {
      scans = scans.filter(s => s.projectID === projectId);
    }

    const completedScans = scans.filter(s => s.status === "completed");

    const risks = ["high", "medium", "low"];
    const issues = getRandomIssues(4, 10);
    const summary: DashboardSummary["summary"] = {
      endpoints: getRandomBetweenMinMax(50, 120),
      severityCount: getRandomSeverityCount(),
      riskLevel: risks[getRandomBetweenMinMax(0, risks.length)],
      completedScansCount: completedScans.length,
      totalScansCount: scans.length,
      mostFrequentIssuesSeverityCount: issues.map(i => ({
        name: i.name,
        severity: i.type,
        count: getRandomBetweenMinMax(1, 15),
      })),
    };

    cachedSummary.set(organizationId + projectId, summary);
  }

  return cachedSummary.get(organizationId + projectId);
}

export const randomOwaspTopTen = () => {
  const year = "2023"; // year of the owasp version
  const number = Math.floor(Math.random() * 10) + 1; // Get a random number between 1 and 10
  return `API${number.toString().padStart(2, "0")}:${year}`; // Format it like API01:2023, API02:2023, ...
};

export function getSummary(
  organizationId: string,
  projectId?: string
): DashboardSummary | ProjectDashboardSummary {
  const mostFrequentOWASPTopTenCount = new Array(getRandomBetweenMinMax(4, 8))
    .fill(0)
    .map(() => ({
      name: randomOwaspTopTen(),
      count: getRandomBetweenMinMax(1, 15),
    }));

  const projects = findProjectsByOrganizationId(organizationId);

  const mostVulnerableProjects = projects
    .filter(p => !["pending", "error"].includes(p.status))
    .map(p => ({
      name: p.name,
      projectID: p.id,
      riskLevel: ["high", "medium", "low"][getRandomBetweenMinMax(0, 3)],
    }));

  if (!projectId) {
    const organizationResponse: DashboardSummary = {
      mostFrequentOWASPTopTenCount,
      mostVulnerableProjects,
      summary: getAndCreateSummary(organizationId),
      totalProjectsCount: projects.length,
    };
    return organizationResponse;
  } else {
    const projectReponse: ProjectDashboardSummary = {
      summary: getAndCreateSummary(organizationId, projectId),
      dataClassification: {
        inputsCount: [
          {
            count: getRandomBetweenMinMax(1, 15),
            name: "credit_card_number",
          },
          {
            count: getRandomBetweenMinMax(1, 15),
            name: "phone_number",
          },
          {
            count: getRandomBetweenMinMax(1, 15),
            name: "first_name",
          },
          {
            count: getRandomBetweenMinMax(1, 15),
            name: "user_agent",
          },
          {
            count: getRandomBetweenMinMax(1, 15),
            name: "token",
          },
        ],
        outputsCount: [
          {
            count: getRandomBetweenMinMax(1, 15),
            name: "token",
          },
          {
            count: getRandomBetweenMinMax(1, 15),
            name: "credit_card_number",
          },
          {
            count: getRandomBetweenMinMax(1, 15),
            name: "first_name",
          },
          {
            count: getRandomBetweenMinMax(1, 15),
            name: "ip",
          },
        ],
      },
    };
    return projectReponse;
  }
}

export function getQueryFilters(searchParams: URLSearchParams) {
  const filters = {
    criteria: searchParams.get("filter_criteria")?.split(",") || [],
    values: searchParams.get("filter_values")?.split(",") || [],
    operators: searchParams.get("filter_operators")?.split(",") || [],
  };
  return filters;
}

export function generateRandomToken() {
  const fakeExp = btoa(
    JSON.stringify({
      exp: Math.floor(
        moment()
          .add(24 * 60, "minutes")
          .valueOf() / 1000
      ),
    })
  );

  return `eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.${fakeExp}.VOmbQlxw4rw89F99gruo8wgciHtKwW2jTEysnb01KfAXLFfI26gxcg48FC3U7mkFuHALYr-v5Stzdwl7fp5whQ`;
}

const fixedStatuses = [
  "completed",
  "failed",
  "pending",
  "run_exploration_phase",
  "run_exploitation_phase",
];

const getScanRandomStatus = (scanIndex: number) => {
  const statusIndex = getRandomBetweenMinMax(0, 6);
  if (scanIndex < 9) {
    if (statusIndex === 3) {
      return "run_exploration_phase";
    } else if (statusIndex === 4) {
      return "run_exploitation_phase";
    } else if (statusIndex === 5) {
      return "pending";
    }
  }
  if (statusIndex === 0) {
    return "stopped";
  } else if (statusIndex === 2) {
    return "failed";
  }
  return "completed";
};

const getMockScanStatus = (scanIndex: number) => {
  if (CYPRESS_RUNNING) {
    // while testing we need some fixed statuses to perform specific tests
    return fixedStatuses[scanIndex] || getScanRandomStatus(scanIndex);
  }

  return getScanRandomStatus(scanIndex);
};

export const generateScanId = (
  organizationId: string,
  projectId: string,
  scanIndex: number
) => {
  const scanId = 100000 * Number(organizationId) + scanIndex;
  return `${projectId}-${scanId.toString()}`;
};

const generateRandomScan = (
  scanIndex: number,
  organizationID: string,
  project: ListedProject
) => {
  // mocking utc datetimes
  const time = moment().add(-scanIndex, "day").utc().startOf("day");
  const start = time.startOf("day").toISOString();
  const duration = getRandomBetweenMinMax(3, 6);
  const end = time.add(duration, "hours").toISOString();
  const status = getMockScanStatus(scanIndex);
  const total = getRandomBetweenMinMax(10, 15);
  const high = getRandomBetweenMinMax(0, 5);
  const medium = getRandomBetweenMinMax(0, 10);
  const triggeredBy = Math.random() >= 0.5 ? "Scheduler" : "API";
  const scanId = generateScanId(organizationID, project.id, scanIndex);
  const scan: ListedScan = {
    completedAt: status.startsWith("run") ? "" : end,
    id: scanId,
    severityCount: {
      high,
      medium,
      low: Math.max(0, total - high - medium),
      information: 0,
    },
    triggeredBy,
    projectID: project.id,
    projectName: project.name,
    startedAt: start,
    status,
    exploitation: Math.random() > 0.5,
    lastError: "",
  };
  return scan;
};

export function getAndCreateScans(
  organizationID: string,
  urlSearchParams: URLSearchParams | undefined = undefined
): ListedScan[] {
  let result: ListedScan[] = [];
  if (!scans.has(organizationID)) {
    const projects = findProjectsByOrganizationId(organizationID);
    projects
      .filter(project => project.status === "ready" && !project.archivedAt)
      .forEach(function (project) {
        const count = getRandomBetweenMinMax(15, 365);
        for (let scanIndex = 0; scanIndex <= count; scanIndex++) {
          result.push(generateRandomScan(scanIndex, organizationID, project));
        }
      });

    scans.set(organizationID, result);
  } else {
    result = scans.get(organizationID) ?? [];
  }

  if (urlSearchParams) {
    const {
      criteria,
      values,
      sortBy: filterSortBy,
      sortMode: filterSortMode,
    } = getFiltersFromUrlParams(urlSearchParams);

    const orPredicates: Predicate<ListedScan>[] = [];
    const andPredicates: Predicate<ListedScan>[] = [];

    criteria.forEach((c, i) => {
      const v = values[i];
      switch (c) {
        case "scan_status":
          orPredicates.push(scan => scan.status === v);
          break;
        case "period":
          andPredicates.push(scan => {
            const from = getStartOf(v);
            return scan.startedAt.localeCompare(from) > 0;
          });
          break;
        case "scan_triggered_by":
        case "scan_exploitation":
        default:
        // exclude
      }
    });

    result = result.filter(s => {
      let valid = andPredicates.every(p => p(s));
      if (valid && orPredicates.length) {
        valid = orPredicates.some(p => p(s));
      }
      return valid;
    });

    const sortBy = filterSortBy || "scan_started_at";
    const sortMode = filterSortMode || "desc";

    result.sort((scanA, scanB) => {
      const mult = sortMode === "asc" ? 1 : -1;
      if (sortBy === "scan_started_at") {
        return mult * scanA.startedAt.localeCompare(scanB.startedAt);
      }
      if (sortBy === "scan_completed_at") {
        if (scanA.completedAt && scanB.completedAt) {
          return mult * scanA.completedAt.localeCompare(scanB.completedAt);
        } else if (!scanA.completedAt) {
          return 1 * mult; // swapped based on sortMode (asc/desc)
        } else if (!scanB.completedAt) {
          return -1 * mult; // swapped based on sortMode (asc/desc)
        }
        return 0;
      }
      return 0;
    });
  }

  return result;
}

export function getRandomSeverityCount(): SeverityCount {
  return {
    high: getRandomBetweenMinMax(0, 5),
    medium: getRandomBetweenMinMax(5, 20),
    low: getRandomBetweenMinMax(5, 20),
    information: getRandomBetweenMinMax(5, 20),
  };
}
