import { useCallback, useState } from "react";
import type { ApiDefinitionProps } from "../ApiDefinition.i";
import type { UserUpdateProjectRequest } from "@Services";
import { useDefaultBaseUrl } from "./useBaseDefaultUrl";

export const useApiDefinition = ({
  project,
  projectExtras,
  setProject,
  setProjectExtras,
  availableEndpoints,
  updatingProjectBaseURL = "",
}: ApiDefinitionProps) => {
  const [spaceLinkCanvasOpen, setSpaceLinkCanvasOpen] = useState(false);

  // change the baseURL after uploading the oas file in page section
  useDefaultBaseUrl({
    project,
    baseUrlSuggestions: projectExtras?.oasFile?.baseUrls ?? [],
    updateBaseURL: (baseURL: string) => setProject({ ...project, baseURL }),
    originalBaseURL: updatingProjectBaseURL,
  });

  const getPathsLimit = useCallback(() => {
    return availableEndpoints;
  }, [availableEndpoints]);

  const handleSpaceLinkUpdate = useCallback(
    async (spaceLinkID: string): Promise<boolean> => {
      project.spaceLinkID = spaceLinkID;
      setProject({ ...project });
      return true;
    },
    [project, setProject]
  );

  const handleOpenSpaceLinkCanvas = useCallback(
    () => setSpaceLinkCanvasOpen(true),
    []
  );

  const handleCloseSpaceLinkCanvas = useCallback(
    () => setSpaceLinkCanvasOpen(false),
    []
  );

  const handleSetSpaceLink = useCallback(
    ({ spaceLinkID }: UserUpdateProjectRequest) =>
      handleSpaceLinkUpdate(spaceLinkID ?? ""),
    [handleSpaceLinkUpdate]
  );

  return {
    fileOAS: projectExtras.oasFile,
    project,
    projectExtras,
    spaceLinkCanvasOpen,
    setProject,
    setProjectExtras,
    getPathsLimit,
    setSpaceLinkCanvasOpen,
    handleSpaceLinkUpdate,
    handleOpenSpaceLinkCanvas,
    handleCloseSpaceLinkCanvas,
    handleSetSpaceLink,
  };
};
