import { BEM, pureComponent } from "@Utils";
import { t } from "i18next";
import { memo, useMemo, type FC } from "react";
import { ProjectListItem } from "./Components";
import { useSpaceLinkDetailsStore } from "../../../../../../Stores";

export const SpaceLinkAssociatedProject: FC = memo(() => {
  const { associatedProjects } = useSpaceLinkDetailsStore();

  const content = useMemo(() => {
    if (!associatedProjects?.length) return [];
    return associatedProjects.map(p => (
      <ProjectListItem key={p.id} listedProject={p} />
    ));
  }, [associatedProjects]);

  if (!content.length) {
    return (
      <h5 className="text-center p-4">{t("common.no-projects-space-link")}</h5>
    );
  }

  return (
    <div
      className="project-list"
      id={BEM("space-link-details__content", "projects")}
    >
      {content}
    </div>
  );
}, pureComponent);
