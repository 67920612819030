import type { InspectorTableConfig } from "@Components";
import {
  ActivityView,
  Callout,
  HTTPViewer,
  InfoBadge,
  InspectorTable,
  MethodBadge,
  StatusCodeBadge,
} from "@Components";
import type { Method as MethodI, Nullable } from "@Interfaces";
import type { ListedAttempt, UserGetScanResponse } from "@Services";
import { dateTimeFormat } from "@Services";
import { BEM, prettyFormatDiff } from "@Utils";
import { t } from "i18next";
import { memo, useMemo } from "react";
import { useScanDetailsStore } from "src/Pages/Scans/Details/Store/ScanDetails.store";
import { defaultFormatWithSeconds } from "src/Services/date/dateTimeFormat";
import { useGetAttempt } from "../../../../Hooks/useGetAttempt";
import { useHistoryStore } from "../../../../Store";
import { useKeyboardEvent } from "@Hooks";
import {
  KA_SCAN_DETAILS_HTTP_HISTORY_NEXT_ITEM_ACTION,
  KA_SCAN_DETAILS_HTTP_HISTORY_PREVIOUS_ITEM_ACTION,
  KA_SCAN_DETAILS_HTTP_HISTORY_SHORTCUT_ACTION,
} from "@Constants";

const getTableConfig: (
  attempt: ListedAttempt,
  details: Nullable<UserGetScanResponse>
) => InspectorTableConfig = (attempt, details) => ({
  items: [
    {
      label: t("common.method"),
      jsx: <MethodBadge type={attempt.method as MethodI} className="me-3" />,
    },
    {
      label: t("common.path"),
      jsx: (
        <span
          className="text-truncate cursor-pointer"
          title={`${details?.scan.baseURL}${attempt.path}`}
        >
          {attempt.path}
        </span>
      ),
      detailsConfig: {
        jsx: <span>{attempt.path}</span>,
      },
    },
    {
      label: t("common.status-code"),
      jsx: <StatusCodeBadge code={attempt.statusCode} />,
    },
    {
      label: t("common.resolved"),
      jsx: attempt.responseResolved ? (
        <i className="bi bi-check-circle-fill color-success"></i>
      ) : (
        <i className="bi bi-x-circle-fill color-failure"></i>
      ),
    },
    {
      label: t("common.content-length"),
      jsx: attempt.contentLength,
    },
    {
      label: t("common.content-type"),
      jsx: (
        <span
          className="text-truncate cursor-pointer"
          title={attempt.contentType}
        >
          {attempt.contentType}
        </span>
      ),
    },
    {
      label: t("common.started-at"),
      jsx: (
        <span className="font-size-caption">
          {dateTimeFormat(
            attempt.startedAt,
            undefined,
            defaultFormatWithSeconds
          )}
        </span>
      ),
      detailsConfig: {
        jsx: dateTimeFormat(
          attempt.startedAt,
          undefined,
          defaultFormatWithSeconds
        ),
      },
    },
    {
      label: t("common.duration"),
      jsx: (
        <InfoBadge className="text-truncate">
          {prettyFormatDiff(attempt.startedAt, attempt.endedAt)}
        </InfoBadge>
      ),
    },
    {
      label: t("common.auth-profile"),
      jsx: attempt.authProfileName,
    },
    {
      label: t("common.scope"),
      jsx: attempt.scope,
    },
    {
      label: t("common.caller"),
      jsx: attempt.caller,
    },
  ],
});

export const HistoryAttemptDetailsInspectorContent = () => {
  const { selectedListedAttempt: attempt } = useHistoryStore();
  const { details } = useScanDetailsStore();

  const config = useMemo(() => {
    if (!attempt) {
      return null;
    }

    return getTableConfig(attempt, details);
  }, [attempt, details]);

  if (!attempt) {
    return null;
  }

  return <InspectorTable configuration={config} />;
};

const HistoryAttemptDetailsInspector = memo(() => {
  return (
    <ActivityView
      showTitleBar={true}
      bodyContent={<HistoryAttemptDetailsInspectorContent />}
      titleContent={t("common.inspector")}
    />
  );
});

export const HistoryAttemptDetails = memo(() => {
  const { attempt } = useGetAttempt();
  const {
    setSelectedListedAttempt,
    selectNextListedAttempt,
    selectPreviousListedAttempt,
  } = useHistoryStore();

  useKeyboardEvent({
    action: KA_SCAN_DETAILS_HTTP_HISTORY_SHORTCUT_ACTION,
    onKeyDown: () => setSelectedListedAttempt(null),
  });

  useKeyboardEvent({
    action: KA_SCAN_DETAILS_HTTP_HISTORY_NEXT_ITEM_ACTION,
    onKeyDown: () => selectNextListedAttempt(),
  });

  useKeyboardEvent({
    action: KA_SCAN_DETAILS_HTTP_HISTORY_PREVIOUS_ITEM_ACTION,
    onKeyDown: () => selectPreviousListedAttempt(),
  });

  return (
    <div className="history-attempt-details">
      <div className={BEM("history-attempt-details", "section-left")}>
        <HTTPViewer
          value={attempt?.request ?? t("common.loading")}
          title={t("common.request")}
        />
        <HTTPViewer
          value={attempt?.response ?? t("common.loading")}
          title={t("common.response")}
        />
        <Callout>
          <span
            className="font-size-caption color-gray"
            dangerouslySetInnerHTML={{
              __html: t("scans.history.close-attempt-details-hint"),
            }}
          />
        </Callout>
      </div>
      <div className={BEM("history-attempt-details", "section-right")}>
        <HistoryAttemptDetailsInspector />
      </div>
    </div>
  );
});
