import * as React from "react";
import ContentLoader from "react-content-loader";
import type { Props, SkeletonTableProps } from "./Skeleton.i";
import { ThemeContext } from "@Contexts";

export const SkeletonBox = ({ width, height, children }: Props) => {
  const { theme } = React.useContext(ThemeContext);
  const isDark = theme === "dark";

  const viewBox =
    typeof width === "string" ? undefined : `0 0 ${width} ${height}`;

  return (
    <ContentLoader
      speed={2}
      width={width}
      height={height}
      viewBox={viewBox}
      backgroundColor={isDark ? "#252537" : "#F7F7FD"}
      foregroundColor={isDark ? "#9a9afa" : "#b8b8ff"}
      className="skeleton animation-fade-in"
      title=""
      uniqueKey={(Math.random() + 1).toString(36).substring(7)}
      data-cy="skeleton-box"
    >
      {children}
    </ContentLoader>
  );
};

export { type SkeletonTableProps };
