import { getCodeMirrorTheme } from "@Interfaces";
import { equals } from "@Services";
import { joinClasses } from "@Utils";
import { memo, useMemo } from "react";
import { useLiveLogsViewer } from "./Hooks";
import { LiveLogsFilters, Offcanvas, Section, withRbac } from "@Components";
import ReactCodeMirror from "@uiw/react-codemirror";
import "./LiveLogsViewer.style.scss";

type LiveLogsViewerProps = {
  className?: string;
};

export const LiveLogsViewer = withRbac(
  `role in ["organization_admin"] and (advancedSettings is enabled)`,
  memo(({ className }: LiveLogsViewerProps) => {
    const {
      codeTheme,
      extensions,
      open,
      liveLogLevelFilter,
      doPolling,
      setOpen,
      handleEditorCreated,
      refresh,
      handleUpdateFilters,
      handleToggleAutoRefresh,
    } = useLiveLogsViewer();

    const codeMirror = useMemo(
      () => (
        <ReactCodeMirror
          value=""
          height="100%"
          extensions={extensions}
          theme={getCodeMirrorTheme(codeTheme)}
          onCreateEditor={handleEditorCreated}
          readOnly
        />
      ),
      [codeTheme, extensions, handleEditorCreated]
    );

    return (
      <Offcanvas
        id="danger-zone-canvas"
        title={"Live Logs"}
        isOpen={open}
        toggle={() => setOpen(false)}
        size="md"
        exitLabel="common.close"
        children={
          <div
            className={joinClasses("live-logs-viewer h-100", className)}
            data-cy="live-logs-viewer"
          >
            <Section removeLowerRadius>
              <div className="d-flex justify-content-between align-items-end">
                <LiveLogsFilters
                  autoRefreshing={doPolling}
                  refresh={refresh}
                  updateFilters={handleUpdateFilters}
                  liveLogLevelFilter={liveLogLevelFilter}
                  toggleAutoRefresh={handleToggleAutoRefresh}
                />
              </div>
            </Section>
            {codeMirror}
          </div>
        }
      />
    );
  }, equals)
);
