import {
  ApiKeyFilters,
  Button,
  ListItems,
  Paginator,
  Section,
} from "@Components";
import { Container } from "reactstrap";
import type { ApiKeysShowCaseProps } from "./useApiKeyShowCase";
import { useApiKeyShowCase } from "./useApiKeyShowCase";

import "./ApiKeysShowCase.style.scss";
import { equals } from "@Services";
import { memo } from "react";

export const ApiKeysShowCase = memo((props: ApiKeysShowCaseProps) => {
  const {
    config,
    headers,
    loading,
    totalItems,
    cardVersion,
    defaultFilters,
    refresh,
    openCanvas,
    updateFilters,
    setCardVersion,
  } = useApiKeyShowCase(props);

  return (
    <Container fluid id="api-keys-showcase">
      <Section title="organization.api-keys">
        <div className="d-flex align-items-end justify-content-between">
          <ApiKeyFilters refresh={refresh} updateFilters={updateFilters} />
          <Button
            id="api-key-open-create-canvas"
            color="primary"
            children="common.create"
            onClick={openCanvas}
            data-cy="button-add-api-key"
            iconClass="bi bi-plus-square-fill"
          />
        </div>
      </Section>
      <ListItems
        cardVersion={cardVersion}
        setCardVersion={cardVersion => setCardVersion(`${cardVersion}`)}
        config={config}
        showSkeleton={loading}
        headers={headers}
        updateCardItemOrder={[0, 1, 5, 2, 3, 4]}
        fullRowIndexes={[0]}
        clickable={true}
        setSorts={updateFilters}
        defaultSorts={defaultFilters}
      />
      <Paginator totalItems={totalItems} setFilters={updateFilters} />
    </Container>
  );
}, equals);
