import { useUpdatedOrganizationLicense } from "@Hooks";
import { useMemo } from "react";
import type { OrganizationContextType } from "./OrganizationContext.i";

type Output = Pick<OrganizationContextType, "license">;

export const useLicenseContext = (): Output => {
  const { license, loading } = useUpdatedOrganizationLicense();

  const result = useMemo(
    () => ({
      license: { loading, name: license?.name },
    }),
    [license?.name, loading]
  );

  return result;
};
