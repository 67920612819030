import { FormInput } from "../../Form";
import { usePageFilters } from "../usePageFilters";
import type { FC } from "react";
import { getFilterAllowedOptions } from "@Services";
import type { PageFiltersProps } from "../PageFilters.i";
import { RefreshListButton } from "../../Buttons";
import { PageFiltersWrapper } from "../PageFiltersWrapper";

const connectedOptions = getFilterAllowedOptions("connection");
const statusOptions = getFilterAllowedOptions("enabled");

export const SpaceLinkFilters: FC<
  PageFiltersProps & { className?: string }
> = ({ refresh, updateFilters, className }) => {
  const { getValueOf, handleOnChange } = usePageFilters(updateFilters);
  return (
    <PageFiltersWrapper className={className}>
      <FormInput
        type="text"
        label="common.name"
        id="filter-space-link-name"
        name="space_link_name"
        value={getValueOf("space_link_name")}
        onChange={handleOnChange}
      />
      <FormInput
        type="select"
        options={connectedOptions}
        label="common.connected"
        id="filter-space-link-connected"
        name="space_link_connected"
        value={getValueOf("space_link_connected")}
        onChange={handleOnChange}
      />
      <FormInput
        type="select"
        options={statusOptions}
        label="common.status"
        id="filter-space-link-status"
        name="space_link_status"
        value={getValueOf("space_link_status")}
        onChange={handleOnChange}
      />
      <RefreshListButton onClick={refresh} />
    </PageFiltersWrapper>
  );
};
