import { isStatusCodeSuccess } from "@Utils";
import { t } from "i18next";
import type { Dispatch } from "react";

export const resetPasswordErrorHandling = (
  status: number | undefined,
  setError: Dispatch<string>
) => {
  switch (status) {
    case 400:
    case 404:
      setError(t("login.setup-password.token-invalid"));
      break;
    case 422:
      setError(t("login.setup-password.token-expired"));
      break;
    default:
      if (isStatusCodeSuccess(status)) break;
      setError(t("login.setup-password.api-error"));
  }
};
