import { pureComponent } from "@Utils";
import { memo, useMemo } from "react";
import { useSpaceLinkDetails } from "./Hooks";
import { SpaceLinkDetailsContent } from "./Components";
import { ItemNotFound, LoadingScreen } from "@Components";
import "./SpaceLinkDetails.style.scss";

export const SpaceLinkDetails = memo(
  ({ onDelete }: { onDelete: VoidFunction }) => {
    const { notFound, refreshSpaceLink, spaceLink } = useSpaceLinkDetails();

    const content = useMemo(() => {
      if (notFound) {
        return <ItemNotFound message="errors.get-space-link" />;
      }
      if (!spaceLink) {
        return <LoadingScreen />;
      }
      return (
        <SpaceLinkDetailsContent
          refresh={refreshSpaceLink}
          onDelete={onDelete}
        />
      );
    }, [notFound, onDelete, refreshSpaceLink, spaceLink]);

    return <div id="organization-space-link-details">{content}</div>;
  },
  pureComponent
);
