import { ThemeProvider, WorkerProvider } from "@Contexts";
import axios from "axios";
import { Chart } from "chart.js";
import { setupServer } from "msw/node";
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { I18nextProvider } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { handlers } from "./Mock";
import { isMockEnable } from "./Services/env";
import i18n from "./i18n";
import reportWebVitals from "./reportWebVitals";
import { initSentry } from "./sentry";
import { setCustomHeaders } from "@Services";
import { usePreference } from "@Hooks";
import { useUIStore } from "@Stores";
import { AppRouter } from "./Components/Router";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.min.css";
import "@xyflow/react/dist/base.css";
import "./index.scss";

Chart.defaults.font = {
  size: 12,
  family: "Sora",
};

// set withCredetinal to true in axios defaults
// to enable the usage of cloudfare against demo
axios.defaults.withCredentials = true;
axios.defaults.timeout = 60_000;

initSentry();

// set custom headers from local storage
setCustomHeaders();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const App = () => {
  const [appZoom] = usePreference("appZoom", "100");
  const { setAppZoom } = useUIStore();

  useEffect(() => {
    document.documentElement.style.setProperty("--app-zoom", `${appZoom}%`);
    setAppZoom(appZoom ? +appZoom : 100);
  }, [appZoom, setAppZoom]);

  return (
    <I18nextProvider i18n={i18n}>
      <HelmetProvider>
        <ThemeProvider>
          <WorkerProvider>
            <Tooltip id="tooltip" />
            <ToastContainer newestOnTop />
            <AppRouter />
          </WorkerProvider>
        </ThemeProvider>
      </HelmetProvider>
    </I18nextProvider>
  );
};

if (isMockEnable()) {
  // render with strict mode
  root.render(<React.StrictMode children={<App />} />);
  // enable "msw" mocking server
  setupServer(...handlers).listen();
} else {
  // render application (no strict mode)
  root.render(<App />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
