import type { OrganizationAdminGetLiveLogsInOrganizationResponse } from "@Services";
import { isFilledString } from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay, getRandomBetweenMinMax } from "src/Mock";
import { ORGANIZATION_LIVE_LOGS } from "src/Mock/data/live-logs";

export const getOrganizationLiveLogs = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const { searchParams: sP } = req.url;

  ORGANIZATION_LIVE_LOGS.push(
    ORGANIZATION_LIVE_LOGS[
      getRandomBetweenMinMax(0, ORGANIZATION_LIVE_LOGS.length)
    ]
  );

  const criterias = sP.getAll("filter_criteria");
  const values = sP.getAll("filter_values");

  const filters: {
    [key: string]: string;
  } = {};

  for (const i in criterias) {
    filters[criterias[i]] = values[i];
  }

  const logs = ORGANIZATION_LIVE_LOGS.filter(log => {
    let bConditionMet = true;

    if (filters?.live_log_level && isFilledString(filters?.live_log_level)) {
      bConditionMet &&= log.level === filters?.live_log_level;
    }

    return bConditionMet;
  });

  const response: OrganizationAdminGetLiveLogsInOrganizationResponse = {
    data: logs,
  };

  return res(ctx.json(response), ctx.status(200), ctx.status(delay));
};
