import type { TabsProps } from "@Components";
import type { ScanDetail } from "@Interfaces";
import { useMemo, useState } from "react";
import { useScanDetailsStore } from "../../Store/ScanDetails.store";
import { useOrganizationContext } from "@Hooks";

export type Counters = Partial<ScanDetail["settingsCount"]>;

export const useTabs = () => {
  const { details } = useScanDetailsStore();
  const { advancedSettings } = useOrganizationContext();
  const counters = details?.settingsCount;

  const tabs = useMemo<TabsProps["tabs"]>(() => {
    const createTab = (label: string, counter?: number) => ({
      label,
      counter,
      disabled: !counter,
    });

    const tabsArray: TabsProps["tabs"] = [
      createTab("common.authentication", counters?.authenticationSettingsCount),
      createTab("common.dictionary", counters?.dictionaryCount),
      createTab("common.path-exclusion", counters?.pathExclusionsCount),
      createTab("common.issue-exclusion", counters?.issueExclusionsCount),
      createTab("common.injectable-parameters", counters?.userInjectablesCount),
      createTab("common.mtls-certificate", counters?.mtlsCertificatesCount),
      createTab(
        "common.security-checks",
        counters?.extensionSettingsEnabledCategoriesCount
      ),
    ];

    if (advancedSettings.enabled) {
      tabsArray.push({ label: "common.advanced-settings", disabled: false });
    }

    return tabsArray;
  }, [counters, advancedSettings.enabled]);

  const [activeTab, setActiveTab] = useState(() => {
    return tabs.findIndex(tab => !tab.disabled);
  });

  return {
    activeTab,
    setActiveTab,
    tabs,
  };
};
