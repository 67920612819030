import moment from "moment-timezone";
import type { DashboardChart } from "@Interfaces";
import type { SeverityCount } from "@Services";
import { getRandomBetweenMinMax } from "@Utils";

const cachedData = new Map();

function getRandomSeverityCount(): SeverityCount {
  return {
    high: getRandomBetweenMinMax(0, 5),
    medium: getRandomBetweenMinMax(5, 20),
    low: getRandomBetweenMinMax(5, 20),
    information: getRandomBetweenMinMax(5, 20),
  };
}
export const getAndCreateChartData = (
  organizationID: string,
  projectId = ""
) => {
  if (!cachedData.has(organizationID + projectId)) {
    const data: DashboardChart = [];
    for (let i = 365, days = 1; i >= days; i--) {
      const time = moment().add(-i, "day");
      const date = time.startOf("day").format("YYYY-MM-DD");
      data.push({
        date,
        severityCount: getRandomSeverityCount(),
      });
      cachedData.set(organizationID + projectId, data);
    }
  }
  return cachedData.get(organizationID + projectId) as DashboardChart;
};
