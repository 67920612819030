import type { UserExclusionMatchesRequest } from "@Services";
import { API, clamp } from "@Services";
import { useCallback, useEffect, useRef, useState } from "react";
import { useUrlParams } from "../other";
import { useManageApiResponse } from "../http";
import { debounce } from "lodash";

export const useExclusionMatches = (path: string, methods: string[]) => {
  const [isMatch, setIsMatch] = useState<boolean | null>(null);
  const [loadingCount, setLoadingCount] = useState(0);

  const lastTestedPath = useRef(path);
  const { organizationId, projectId } = useUrlParams();

  const manageRequest = useManageApiResponse();

  const validPayload = !!path.length && !!methods.length;
  const fetchEnable = !!organizationId && !!projectId && validPayload;

  const fetchExclusionMatch = useCallback(
    async (payload: UserExclusionMatchesRequest) => {
      setLoadingCount(c => c + 1);
      await manageRequest({
        promise: API.project().projectExclusionMatches(
          organizationId as string,
          projectId as string,
          payload
        ),
        onSuccess: r => {
          setIsMatch(r.data.isMatch);
          lastTestedPath.current = payload.path;
        },
        onError: () => setIsMatch(null),
      });
      setLoadingCount(c => clamp(c - 1, 0, Number.MAX_SAFE_INTEGER));
    },
    [manageRequest, organizationId, projectId]
  );

  useEffect(() => {
    if (!fetchEnable) setIsMatch(null);
  }, [fetchEnable]);

  useEffect(() => {
    if (!fetchEnable) return;
    const fetchFn = debounce(() => fetchExclusionMatch({ path, methods }), 500);
    fetchFn();
    return () => fetchFn.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path.length]);

  useEffect(() => {
    if (!fetchEnable) return;
    const fetchFn = debounce(() => fetchExclusionMatch({ path, methods }), 10);
    fetchFn();
    return () => fetchFn.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.length]);

  return {
    loading: loadingCount > 0,
    isMatch,
    lastTestedPath: lastTestedPath.current,
  };
};
