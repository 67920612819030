import { DangerZone } from "@Components";
import type { ApiKey } from "@Interfaces";
import { type FC } from "react";
import { useDeleteApiKey } from "../../Hooks";

type DeleteApiKeyProps = {
  apiKey: ApiKey;
};

export const DeleteApiKey: FC<DeleteApiKeyProps> = ({ apiKey }) => {
  const { deletePromise, handleOnSuccess } = useDeleteApiKey(apiKey);

  return (
    <DangerZone
      className="mt-4"
      confirmationText={apiKey.name}
      target="api-key"
      promise={deletePromise}
      subject={apiKey.name}
      toastErrorMessage="delete-api-key"
      toastSuccessMessage="delete-api-key"
      onSuccess={handleOnSuccess}
    />
  );
};
