import { useManageApiResponse, useUrlParams } from "@Hooks";
import { useCallback, useEffect } from "react";
import { useSpaceLinkDetailsStore } from "../../../../../Stores";
import {
  fetchSpaceLinkAssociatedProjects,
  fetchSpaceLinkDetails,
} from "@Services";

export const useSpaceLinkDetails = () => {
  const {
    loading,
    notFound,
    spaceLinkDetails,
    reset,
    setLoading,
    setNotFound,
    setSpaceLinkDetails,
    setAssociatedProjects,
  } = useSpaceLinkDetailsStore();

  const { organizationId, spaceLinkId } = useUrlParams();
  const manage = useManageApiResponse();

  const fetchSpaceLinks = useCallback(async () => {
    if (!organizationId || !spaceLinkId) {
      return;
    }
    setLoading(true);
    setNotFound(false);
    const { status } = await manage({
      promise: fetchSpaceLinkDetails(organizationId, spaceLinkId),
      onSuccess: response => {
        setSpaceLinkDetails(response.data.spaceLink);
      },
      onError: () => {
        setNotFound(true);
        setSpaceLinkDetails(null);
      },
    });
    if (status === 200) {
      await manage({
        promise: fetchSpaceLinkAssociatedProjects(organizationId, spaceLinkId),
        onSuccess: response => {
          setAssociatedProjects(response.data.data);
        },
        onError: () => {
          setNotFound(true);
          setSpaceLinkDetails(null);
          setAssociatedProjects(null);
        },
      });
    }
    setLoading(false);
  }, [
    organizationId,
    spaceLinkId,
    setLoading,
    setNotFound,
    manage,
    setSpaceLinkDetails,
    setAssociatedProjects,
  ]);

  const refreshSpaceLink = useCallback(() => {
    if (loading) return;
    fetchSpaceLinks();
  }, [fetchSpaceLinks, loading]);

  useEffect(() => {
    fetchSpaceLinks();
  }, [fetchSpaceLinks]);

  useEffect(() => reset, [reset]);

  return {
    notFound,
    spaceLink: spaceLinkDetails,
    refreshSpaceLink,
    loading,
  };
};
