import { getDefaultFilterValue } from "@Services";
import type { ChangeEvent, Dispatch } from "react";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export const usePageFilters = (
  updateFunction: Dispatch<Record<string, string>>
) => {
  const [searchParams] = useSearchParams();

  const getValueOf = useCallback(
    (k: string) => {
      return searchParams.get(k) || getDefaultFilterValue(k) || "";
    },
    [searchParams]
  );

  const handleOnChange = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      updateFunction({ [name]: value });
    },
    [updateFunction]
  );

  return { getValueOf, handleOnChange };
};
