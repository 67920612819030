import { memo } from "react";
import "./style.scss";
import { t } from "i18next";
import type { LoadingScreenProps } from "./LoadingScreen.i";
import { Spinner } from "reactstrap";

export const LoadingScreen = memo(
  (props: LoadingScreenProps) => {
    return (
      <section
        id="loading-screen"
        data-cy="loading-screen"
        className="w-100 h-100"
      >
        <Spinner />
        <h1 className="loading-message">
          {t(props.message || "common.loading")}
        </h1>
      </section>
    );
  },
  (prev, next) => prev.message === next.message
);
