import { useCallback, useMemo, useState } from "react";
import { getAddButtonProps } from "../../../Functions";
import type { Props } from "./DictionaryFormCanvas.i";
import type { DictionaryEntry } from "@Services";
import type { ButtonProps } from "@Components";

const defaultEntry: DictionaryEntry = {
  key: "",
  value: "",
} as const;

export const useDictionaryFormCanvas = ({ isOpen, close, addEntry }: Props) => {
  const [formValid, setFormValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [entry, setEntry] = useState(defaultEntry);

  const closeCanvas = useCallback(() => {
    close();
    setEntry(defaultEntry);
  }, [close]);

  const handleSubmit = useCallback(() => {
    setLoading(true);
    addEntry(entry)
      .then(ok => ok && closeCanvas())
      .finally(() => setLoading(false));
  }, [addEntry, closeCanvas, entry]);

  const canvasButtons = useMemo<ButtonProps[]>(() => {
    return [
      getAddButtonProps({
        disabled: !formValid,
        loading,
        onClick: handleSubmit,
      }),
    ];
  }, [formValid, handleSubmit, loading]);

  return {
    canvas: {
      isOpen,
      close,
      loading,
      buttons: canvasButtons,
    },
    entry,
    setEntry,
    setFormValid,
  };
};
