import "./OrganizationUserDetails.style.scss";
import { UserSectionDetails, UserLogs } from "./Components";
import { pureComponent } from "@Utils";
import { EditUserCanvas } from "./Components/EditUserCanvas";
import { ItemNotFound, LoadingScreen, Tabs } from "@Components";
import { memo, useMemo } from "react";
import {
  useOrganizationUserDetails,
  useOrganizationUserDetailsTab,
} from "../../../../Hooks";

export const OrganizationUserDetails = memo(() => {
  const { details, notFound, editingItself, refresh } =
    useOrganizationUserDetails();
  const { tabs, activeTab, setActiveTab } = useOrganizationUserDetailsTab();

  const content = useMemo(() => {
    switch (activeTab) {
      case 1:
        return <UserLogs />;
      default:
        return <UserSectionDetails />;
    }
  }, [activeTab]);

  if (notFound) {
    return <ItemNotFound message="organization.user-not-found" />;
  }

  if (!details) {
    return <LoadingScreen />;
  }

  return (
    <div id="organization-user-details">
      <Tabs
        activeTab={activeTab}
        tabs={tabs}
        setActiveTab={setActiveTab}
        updateUrl={true}
      />
      <div id="organization-user-details__content" className="card">
        {content}
      </div>
      <EditUserCanvas selfEditing={editingItself} onSubmit={refresh} />
    </div>
  );
}, pureComponent);
