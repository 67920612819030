import { useProject } from "@Hooks";
import type { PathExclusion, PathExclusionEntry } from "@Services";
import { useCallback, useEffect, useState } from "react";
import updateEntries from "../updateEntries";

export const usePathExclusion = () => {
  const { project, loading, update } = useProject();
  const [pathExclusions, setPathExclusions] = useState<PathExclusion[]>([]);
  const [canvasOpen, setCanvasOpen] = useState(false);

  useEffect(() => {
    const pathExclusions = project?.pathExclusions ?? [];
    setPathExclusions(structuredClone(pathExclusions));
  }, [project]);

  const addEntry = useCallback(
    async (entry: PathExclusionEntry) => {
      if (!project) return false;
      const action = "add";
      return updateEntries({ project, update, action, entry });
    },
    [project, update]
  );

  const deleteEntry = useCallback(
    async (index: number) => {
      if (!pathExclusions || !project) return false;
      const action = "remove";
      return updateEntries({ project, update, action, index });
    },
    [pathExclusions, project, update]
  );

  return {
    pathExclusions,
    noData: !loading && !project,
    loading: {
      any: loading !== false,
      get: loading === "get",
      put: loading === "put",
    },
    actions: {
      addEntry,
      deleteEntry,
    },
    canvas: {
      isOpen: canvasOpen,
      open: () => setCanvasOpen(true),
      close: () => setCanvasOpen(false),
    },
  };
};
