import { createStore } from "@Hooks";
import type { Nullable, NullableArray } from "@Interfaces";
import type { SpaceLink } from "@Services";
import type { Dispatch } from "react";

type OrganizationSpaceLinkStoreData = {
  canvasOpen: boolean;
  hasAnyItem: Nullable<boolean>;
  contentLoaded: boolean;
  spaceLinkList: NullableArray<SpaceLink>;
};

type OrganizationSpaceLinkStore = OrganizationSpaceLinkStoreData & {
  setCanvasOpen: Dispatch<boolean>;
  setHasAnyItem: Dispatch<boolean>;
  setContentLoaded: Dispatch<boolean>;
  setSpaceLinkList: Dispatch<NullableArray<SpaceLink>>;
  reset: VoidFunction;
};

const INITIAL_STATE: OrganizationSpaceLinkStoreData = {
  canvasOpen: false,
  hasAnyItem: null,
  contentLoaded: false,
  spaceLinkList: null,
};

export const useOrganizationSpaceLinkStore =
  createStore<OrganizationSpaceLinkStore>((get, set) => ({
    ...INITIAL_STATE,
    setCanvasOpen: canvasOpen => {
      set(state => ({ ...state, canvasOpen }));
    },
    setHasAnyItem: hasAnyItem => {
      set(state => ({ ...state, hasAnyItem }));
    },
    setContentLoaded: contentLoaded => {
      set(state => ({ ...state, contentLoaded }));
    },
    setSpaceLinkList: spaceLinkList => {
      set(state => ({ ...state, spaceLinkList }));
    },
    reset: () => {
      set(state => ({ ...state, ...INITIAL_STATE }));
    },
  }));
