import { NewProjectContext } from "@Contexts";
import type { DictionaryEntry } from "@Services";
import type { Dispatch } from "react";
import { useCallback, useContext, useState } from "react";

const defaultEntry: DictionaryEntry = {
  key: "",
  value: "",
};

export const useAddDictionaryEntry = (consumeOption: Dispatch<string>) => {
  const { project, setProject } = useContext(NewProjectContext);

  const [entry, setEntry] = useState(defaultEntry);
  const [formValid, setFormValid] = useState(false);

  const addEntryToProject = useCallback(() => {
    const dictionary = structuredClone(project.dictionary);
    dictionary.unshift(entry);
    consumeOption(entry.key);
    setEntry(defaultEntry);
    setProject({ ...project, dictionary });
  }, [consumeOption, entry, project, setProject]);

  return { entry, setEntry, formValid, setFormValid, addEntryToProject };
};
