import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import type { ComponentButtonProps } from "@Components";
import { useApiKeyDetailsCanvasStore } from "../Stores";
import { getApiKeyExtraData } from "@Services";
import { generateUrl } from "@Utils";
import { ORGANIZATION_PATH_API_KEYS } from "@Constants";
import { useUrlParams } from "@Hooks";

export const useApiKeyDetailsCanvas = () => {
  const { apiKey, setEditCanvasOpen } = useApiKeyDetailsCanvasStore();
  const { organizationId } = useUrlParams();
  const { isExpired } = getApiKeyExtraData(apiKey);
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    navigate(
      generateUrl(ORGANIZATION_PATH_API_KEYS, [organizationId ?? ""], true),
      { replace: false }
    );
  }, [navigate, organizationId]);

  const canvasButtons = useMemo(
    (): Array<ComponentButtonProps> => [
      {
        color: "primary",
        children: "common.edit",
        data: {
          cy: "edit-apikey-button",
        },
        onClick: () => setEditCanvasOpen(true),
        iconClass: "bi bi-pencil-fill",
        disabled: isExpired,
      },
    ],
    [isExpired, setEditCanvasOpen]
  );
  return {
    canvasButtons,
    handleClose,
  };
};
