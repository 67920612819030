import type { Edge, Node, NodeProps } from "@xyflow/react";
import type { InventoryInput } from "@Interfaces";
import type { Parameter } from "@Services";

export const PARAMETER_LINKS_GRAPH_NODE_WIDTH = 400; // 20rem
export const PARAMETER_LINKS_GRAPH_NODE_TITLE_HEIGHT = 40;
export const PARAMETER_LINKS_GRAPH_NODE_ITEM_HEIGHT = 34;

export type ParameterLocation =
  | "body"
  | "header"
  | "query"
  | "path"
  | "cookie"
  | "unknown";

export type ParameterOrigin = "engine" | "user";

export type NodeParameterData = {
  name: string;
  type: InventoryInput;
  output?: boolean;
  input?: boolean;
  location?: ParameterLocation;
  data?: Parameter;
};

export type NodeParameters = Array<NodeParameterData>;
export type NodeParametersByLocation = Partial<
  Record<ParameterLocation, NodeParameters>
>;

// Define the type for the node data
export type ParameterLinksGraphNodeData = {
  path: string;
  verb: string;
  name: string;
  parameters: NodeParameters;
  origin: ParameterOrigin;
  consumer?: boolean;
  selected?: boolean;
};

// Define the type for the custom node component props
export type ParameterLinksGraphNodeProps = {
  data: ParameterLinksGraphNodeData;
  id: string;
};

export type ParameterLinksGraphEdgeData = {
  sourceNode: Node<ParameterLinksGraphNodeData | NodeProps>;
  targetNode: Node<ParameterLinksGraphNodeData | NodeProps>;
};

export type ParameterLinksGraphNodes = Array<
  Node<ParameterLinksGraphNodeData | NodeProps>
>;
export type ParameterLinksGraphEdges = Array<Edge>;
