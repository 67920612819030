import type { PlainObject, ProjectExtras } from "@Interfaces";
import type {
  AdvancedSettings,
  Project,
  RunPolicy,
  Upload,
  UserCreateProjectRequest,
} from "@Services";
import type { Dispatch, SetStateAction } from "react";

export interface Parameter {
  name?: string;
  schema?: {
    properties: PlainObject<string>;
  };
}

export type ApiDefinitionProps = {
  project: UserCreateProjectRequest;
  setProject: Dispatch<SetStateAction<UserCreateProjectRequest>>;
  projectExtras: ProjectExtras;
  setProjectExtras: Dispatch<SetStateAction<ProjectExtras>>;
  availableEndpoints: number;
  updatingProjectBaseURL?: string;
  newProject?: boolean;
};

export const EMPTY_PROJECT: Project = {
  advancedSettings: {} as AdvancedSettings,
  archivedAt: "",
  authenticationSettings: [],
  baseURL: "",
  description: "",
  dictionary: [],
  endpoints: 0,
  id: "",
  injectables: [],
  isProductionURL: false,
  lastError: "",
  mTLSCertificates: [],
  name: "",
  parameterLinks: [],
  pathExclusions: [],
  runPolicy: {} as RunPolicy,
  spaceLinkID: "",
  status: "",
  upload: {} as Upload,
};
