import type { ParameterProps } from "@Components";
import { GridContainer, DisplaySection } from "@Components";
import { joinClasses } from "@Utils";
import { memo } from "react";
import { Card } from "reactstrap";
import { equals } from "@Services";

export const ParameterDetails = memo(
  ({ parameter, className, style }: ParameterProps) => {
    if (!parameter) {
      return null;
    }

    const { name, description, ...others } = parameter;
    return (
      <Card
        className={joinClasses("parameter-details", className)}
        style={style}
      >
        <GridContainer columns={2}>
          <DisplaySection label="common.name" value={name || "-"} />
          <DisplaySection
            label="common.description"
            value={description || "-"}
          />
          <>
            {Object.entries(others)
              .filter(([, value]) => !!value)
              .map(([key, value]) => (
                <DisplaySection
                  key={key}
                  label={`parameter.${key}`}
                  value={value.toString()}
                />
              ))}
          </>
        </GridContainer>
      </Card>
    );
  },
  equals
);
