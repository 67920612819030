import { memo, type Dispatch, type FC } from "react";
import type { DisplaySectionProps } from "../DisplaySection.i";
import { LongText } from "@Components";
import { Spinner } from "reactstrap";

type DisplaySectionContentProps = {
  loading: DisplaySectionProps["loading"];
  value: DisplaySectionProps["value"];
  truncateEnabled: boolean;
  setShowLongTextModal: Dispatch<string | false>;
  className?: string;
};

export const DisplaySectionContent: FC<DisplaySectionContentProps> = memo(
  ({ value, loading, truncateEnabled, className, setShowLongTextModal }) => {
    if (loading) {
      return <Spinner size="sm" />;
    }

    if (truncateEnabled && typeof value === "string") {
      return (
        <LongText
          text={value} // truncateEnabled => typeof value = string
          type="value"
          maxLength={100}
          setShowLongTextModal={setShowLongTextModal}
        />
      );
    }

    return <span className={className}>{value}</span>;
  }
);
