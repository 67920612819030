import { NewProjectExtrasContext } from "@Contexts";
import { usePool } from "@Hooks";
import { useContext, useMemo } from "react";

export const useDictionary = () => {
  const { projectExtras } = useContext(NewProjectExtrasContext);
  const parameters = useMemo(
    () => projectExtras.oasFile?.parameters ?? [],
    [projectExtras.oasFile?.parameters]
  );
  const { pool, consume, restore } = usePool(parameters);

  return {
    comboBoxOptions: pool,
    consumeOption: consume,
    restoreOption: restore,
  };
};
