import { BEM, joinClasses, limitString } from "@Utils";
import { Handle, Position, useUpdateNodeInternals } from "@xyflow/react";
import type { FC } from "react";
import { memo, useEffect } from "react";
import type { ParameterNodeProps } from "../../AdditionalInfo.i";
import { equals, type Parameter } from "@Services";
import "./ParameterNode.styles.scss";
import { StatusCodeBadge, withMouseCursorTooltip } from "@Components";
import { ParameterDetails } from "./ParameterDetails";
import { t } from "i18next";

type AdditionalDetailsProps = {
  parameter: Parameter;
};

const AdditionalDetails = ({
  parameter,
  ...otherProps
}: AdditionalDetailsProps) => {
  return (
    <div
      className={BEM("parameter-node", "additional-details")}
      {...otherProps}
    >
      <div className={BEM("parameter-node", "title")}>
        <span>{parameter.name}</span>
      </div>
      <ParameterDetails parameter={parameter} />
    </div>
  );
};

// CustomNode component with types
const ParameterNodeContent: FC<ParameterNodeProps> = ({ id: nodeId, data }) => {
  const updateNodeInternals = useUpdateNodeInternals();
  const { parameter, input, subNodes } = data;

  useEffect(() => {
    updateNodeInternals(nodeId);
  }, [data, nodeId, updateNodeInternals]);

  const subNodesVisible = subNodes && subNodes.some(s => s.data.visible);
  const leftHandleVisible = subNodesVisible || !input;
  const rightHandleVisible = subNodesVisible || input;

  return (
    <div
      id={nodeId}
      className={joinClasses(
        "parameter-node",
        subNodes && "with-subnodes",
        subNodesVisible && "selected",
        input ? "input" : "output"
      )}
    >
      {leftHandleVisible && (
        <Handle
          type="target"
          position={Position.Left}
          id={`handle-target-${nodeId}`}
          isConnectable={false}
        >
          <i className="bi bi-arrow-right-square-fill" />
        </Handle>
      )}
      <div
        className={joinClasses(
          BEM("parameter-node", "title"),
          parameter.statusCode && "with-status-code"
        )}
      >
        <span>
          {limitString(parameter.name, parameter.statusCode ? 20 : 30)}
        </span>
        {parameter.statusCode && (
          <StatusCodeBadge code={+parameter.statusCode} />
        )}
      </div>
      <div className={BEM("parameter-node", "property")}>
        <b>{t("common.type")}</b>
        <span>{parameter.type || parameter.oracleType || "?"}</span>
      </div>
      {rightHandleVisible && (
        <Handle
          type="source"
          position={Position.Right}
          id={`handle-source-${nodeId}`}
          isConnectable={false}
        >
          <i className="bi bi-arrow-right-square-fill" />
        </Handle>
      )}
    </div>
  );
};

export const ParameterNode: FC<ParameterNodeProps> = memo(props => {
  const { parameter } = props.data;

  const ParameterNodeWithTooltip = withMouseCursorTooltip<
    ParameterNodeProps,
    AdditionalDetailsProps
  >(ParameterNodeContent, AdditionalDetails, { parameter });

  return <ParameterNodeWithTooltip {...props} />;
}, equals);
