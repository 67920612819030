import type { ScanStatus } from "@Interfaces";
type MapperResult = { color: string; label: string };

const mapper: Record<ScanStatus, MapperResult> = {
  completed: { color: "bg-success", label: "scans.completed" },
  failed: { color: "bg-severity-high", label: "scans.failed" },
  stopped: { color: "bg-secondary", label: "scans.stopped" },
  stopping: { color: "bg-cream", label: "scans.stopping" },
  queued: { color: "bg-gray", label: "scans.queued" },
  purged: { color: "bg-secondary", label: "scans.purged" },
  pending: { color: "bg-severity-medium", label: "scans.pending" },
  run_exploration_phase: {
    color: "bg-light-blue",
    label: "scans.exploration",
  },
  run_exploitation_phase: {
    color: "bg-severity-low",
    label: "scans.exploitation",
  },
};

export const scanStatusMapper = mapper as Record<string, MapperResult>;
