import { PROJECT_MAX_END_DATE } from "@Constants";
import type { Recurrence } from "@Interfaces";
import { type RunPolicy } from "@Services";

const defaultRecurrenct: Recurrence = "monthly";

const model: RunPolicy = {
  businessHours: false,
  endDate: PROJECT_MAX_END_DATE,
  recurrence: `${defaultRecurrenct}`,
  startDate: "",
};

export const getDefaultProjectRunPolicy = (
  base: Partial<RunPolicy> = {}
): RunPolicy => Object.assign({}, { ...model, ...base });
