import type { Edge, Node } from "@xyflow/react";
import dagre from "@dagrejs/dagre";

/*
 * Dagre is an algorithm to sort complex graph according to nodes connections
 * @see https://github.com/dagrejs/dagre/wiki
 */
export function getDagreLayoutedElements<
  NodeProps extends Record<string, unknown>,
  EdgeProps extends Record<string, unknown>,
>(
  nodes: Array<Node<NodeProps>>,
  edges: Array<Edge<EdgeProps>>,
  otherGraphOptions?: { [key: string]: any }
) {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: "LR", ...otherGraphOptions });

  nodes.forEach(node => {
    dagreGraph.setNode(node.id, {
      width: node.width ?? 500,
      height: node.height ?? 100,
    });
  });

  edges.forEach(edge => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  return {
    nodes: nodes.map(node => ({
      ...node,
      position: dagreGraph.node(node.id),
    })),
    edges,
  };
}
