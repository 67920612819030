import { useManageApiResponse, useUrlParams } from "@Hooks";
import type { OrganizationAdminCreateSpaceLinkResponse } from "@Services";
import { API, type SpaceLink } from "@Services";
import { useCallback, useState } from "react";
import type { CreateSpaceLinkProps } from "./CreateSpaceLink.i";
import type { AxiosResponse } from "axios";
import { useOrganizationSpaceLinkStore } from "@Stores";
import { PROJECT_MAX_END_DATE } from "@Constants";

const defaultSpaceLink: SpaceLink = {
  active: true,
  connected: true,
  createdAt: "",
  expiresAt: PROJECT_MAX_END_DATE,
  id: "",
  name: "",
};

export const useCreateSpacelink = ({ fetch }: CreateSpaceLinkProps) => {
  const { canvasOpen, setCanvasOpen, setHasAnyItem } =
    useOrganizationSpaceLinkStore();
  const [spaceLink, setSpaceLink] = useState({ ...defaultSpaceLink });
  const [canvasLoading, setCanvasLoading] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const { organizationId } = useUrlParams();

  const [spaceLinkApiKey, setSpaceLinkApiKey] = useState("");

  const manageResponse = useManageApiResponse(setCanvasLoading);

  const openCanvas = useCallback(() => {
    setCanvasOpen(true);
  }, [setCanvasOpen]);

  const closeCanvas = useCallback(() => {
    setCanvasOpen(false);
    setSpaceLinkApiKey("");
    setSpaceLink({ ...defaultSpaceLink });
  }, [setCanvasOpen]);

  const onSubmit = useCallback(
    (response: AxiosResponse<OrganizationAdminCreateSpaceLinkResponse>) => {
      fetch();
      setHasAnyItem(true);
      setSpaceLinkApiKey(response.data.token);
    },
    [fetch, setHasAnyItem]
  );

  const handleSubmit = useCallback(() => {
    if (!organizationId) throw new Error("invalid-url-params");
    manageResponse({
      promise: API.spaceLink().spaceLinkCreate(organizationId, {
        active: true,
        expiresAt: spaceLink.expiresAt,
        name: spaceLink.name,
      }),
      onSuccess: onSubmit,
      errorMessage: "post-spaceLink",
    });
  }, [
    manageResponse,
    onSubmit,
    organizationId,
    spaceLink.expiresAt,
    spaceLink.name,
  ]);

  return {
    openCanvas,
    closeCanvas,
    handleSubmit,
    spaceLink,
    setSpaceLink,
    canvasOpen,
    canvasLoading,
    formValid,
    setFormValid,
    spaceLinkApiKey,
    formDisabled: canvasLoading || !!spaceLinkApiKey,
  };
};
