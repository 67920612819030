import { DictionaryCard, ProjectSettingsCardContainer } from "@Components";
import type { DictionaryEntry } from "@Services";
import { joinClasses } from "@Utils";
import { memo, useCallback, useMemo, useState } from "react";
import { DisplayRow } from "../DisplayRow";
import type { DisplayRowProps as MainProps } from "../DisplayRow.i";

type DisplayRowProjectDictionaryProps = {
  dictionary: Array<DictionaryEntry>;
  deleteEntry?: (i: number) => Promise<boolean>;
} & Pick<MainProps, "buttonProps">;

export const DisplayRowProjectDictionary = memo(
  ({
    dictionary,
    deleteEntry,
    buttonProps,
  }: DisplayRowProjectDictionaryProps) => {
    const [deletingIndex, setDeletingIndex] = useState<number>();

    const getOnDeleteFunction: (i: number) => VoidFunction | undefined =
      useCallback(
        (i: number) => {
          if (!deleteEntry) return undefined;
          return async () => {
            setDeletingIndex(i);
            await deleteEntry(i);
            setDeletingIndex(undefined);
          };
        },
        [deleteEntry]
      );

    const cardsContainer = useMemo(
      () => (
        <ProjectSettingsCardContainer
          cardType="dictionary"
          emptyLabel="common.no-dictionary-settings"
        >
          {dictionary.map((d, i) => {
            const handleOnDelete = getOnDeleteFunction(i);
            return (
              <DictionaryCard
                entry={d}
                key={`${i}-${d.key}`}
                deleteEntry={handleOnDelete}
                loading={deletingIndex === i}
                disabled={deletingIndex !== undefined}
              />
            );
          })}
        </ProjectSettingsCardContainer>
      ),
      [deletingIndex, dictionary, getOnDeleteFunction]
    );

    return (
      <DisplayRow
        title="common.dictionary"
        buttonProps={buttonProps}
        className={joinClasses("h-100", dictionary.length <= 0 && "no-data")}
      >
        {cardsContainer}
      </DisplayRow>
    );
  }
);
