import { SkeletonBox } from "./SkeletonBox";

interface Props {
  height?: number;
  unit?: "px" | "vh" | "%";
}

const evalHeight = ({ height, unit }: Props) => {
  return `${height ?? 100}${unit ?? "%"}`;
};

export const SkeletonSection = ({ height, unit }: Props) => (
  <div className="h-100 border-radius overflow-hidden">
    <SkeletonBox width="100%" height={evalHeight({ height, unit })}>
      <rect x="0" y="52" rx="3" ry="3" width="100%" height="10000" />
    </SkeletonBox>
  </div>
);
