import { FormInput, Section } from "@Components";
import { APP_ZOOM_VALUES, useAppThemeValues } from "@Constants";
import { ThemeContext, type Theme } from "@Contexts";
import { usePreference } from "@Hooks";
import { useUIStore } from "@Stores";
import { useCallback, useContext, type ChangeEvent } from "react";
import type { CodeTheme } from "@Interfaces";
import {
  CodeThemeOptions,
  DEFAULT_CODE_THEME,
  getCodeMirrorTheme,
} from "@Interfaces";
import { langs } from "@uiw/codemirror-extensions-langs";
import CodeMirror from "@uiw/react-codemirror";

const EXAMPLE_CODE = `// this is an example code
const foo = "boo";

void myFunction(a, b) {
  return a * b;
}`;

export const StyleCard = () => {
  const themeOptions = useAppThemeValues();

  const { setTheme } = useContext(ThemeContext);
  const { setAppZoom, setCodeTheme } = useUIStore();
  const [appZoom, setPreferenceAppZoom] = usePreference("appZoom", "100");
  const [prefTheme] = usePreference("theme", "system");
  const [prefCodeTheme, setPreferenceCodeTheme] = usePreference(
    "codeTheme",
    DEFAULT_CODE_THEME
  );

  const handleSelectAppTheme = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setTheme(e.target.value as Theme);
    },
    [setTheme]
  );

  const handleSelectAppZoom = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setAppZoom(+e.target.value);
      setPreferenceAppZoom(e.target.value);
      document.documentElement.style.setProperty(
        "--app-zoom",
        `${e.target.value}%`
      );
    },
    [setAppZoom, setPreferenceAppZoom]
  );

  const handleSelectCodeTheme = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCodeTheme(e.target.value as CodeTheme);
      setPreferenceCodeTheme(e.target.value as CodeTheme);
    },
    [setCodeTheme, setPreferenceCodeTheme]
  );

  return (
    <Section title="profile.theme-title" id="profile-style-card">
      <FormInput
        type="select"
        id="app-theme"
        data-cy="app-theme-select"
        defaultValue={prefTheme ?? "system"}
        label="profile.theme"
        options={themeOptions}
        onChange={handleSelectAppTheme}
      ></FormInput>

      <FormInput
        type="select"
        id="app-zoom"
        data-cy="app-zoom-select"
        defaultValue={appZoom ? +appZoom : 100}
        label="profile.app-zoom"
        className="mt-3"
        options={APP_ZOOM_VALUES}
        onChange={handleSelectAppZoom}
      />
      <FormInput
        type="select"
        id="code-theme"
        data-cy="code-theme-select"
        className="mt-3"
        defaultValue={prefCodeTheme ?? DEFAULT_CODE_THEME}
        label="profile.code-theme"
        options={CodeThemeOptions}
        onChange={handleSelectCodeTheme}
      ></FormInput>

      <CodeMirror
        value={EXAMPLE_CODE}
        className="mt-2"
        height="200px"
        extensions={[langs.javascript()]}
        theme={getCodeMirrorTheme(prefCodeTheme as CodeTheme)}
      />
    </Section>
  );
};
