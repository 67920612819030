import { SingleOptionSection } from "@Components";
import { memo, type FC } from "react";
import { equals } from "@Services";
import { useReinviteButton } from "../../Hooks";

export const ReinviteBox: FC<{ hasLastLogin?: boolean; className?: string }> =
  memo(({ hasLastLogin, className }) => {
    const { buttonProps } = useReinviteButton();
    if (hasLastLogin) return null;
    return (
      <SingleOptionSection
        className={className}
        title="organization.re-invite"
        iconClass="bi bi-send-plus-fill"
        description="organization.re-invite-user-info"
        buttonProps={[buttonProps]}
      />
    );
  }, equals);
