import { useCallback } from "react";
import { useOrganizationUserDetailsCanvasStore } from "../../../../../Stores";
import { useEditingItself } from "../../../../../Hooks/useEditingItself";

export const useUserSectionDetails = () => {
  const editingItself = useEditingItself();
  const { userDetails, open, setOpen } =
    useOrganizationUserDetailsCanvasStore();

  const closeCanvas = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return { user: userDetails, editingItself, canvasOpen: open, closeCanvas };
};
