import { isFilledString, type ConfirmPasswordResetRequest } from "@Services";
import moment from "moment-timezone";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import { delay } from "src/Mock";

export const confirmPasswordReset = async (
  req: RestRequest<never, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const requestBody = (await req.json()) as ConfirmPasswordResetRequest;

  const { id, passwordConfirm, passwordNew } = requestBody;

  const validPassword = isFilledString(passwordNew);

  if (!id || !validPassword || passwordNew !== passwordConfirm) {
    return res(ctx.status(400), ctx.delay(delay));
  }

  // to test outdated token behaviour
  if (moment(id, true).isValid() && moment().isAfter(id)) {
    return res(ctx.delay(delay), ctx.status(422));
  }

  // force back end response
  if (Number.parseInt(id)) {
    return res(ctx.delay(delay), ctx.status(+id));
  }

  return res(ctx.delay(delay), ctx.status(200));
};
