import { useOrganizationAuditLogs, useUrlParams } from "@Hooks";
import { API } from "@Services";

export const useUserLogs = () => {
  const { organizationId, userId } = useUrlParams();

  return useOrganizationAuditLogs(
    "organization-user-audit-logs",
    ({ page, pageSize, sortBy, sortMode, criterias, operators, values }) =>
      API.auditLog().organizationAdminListUserAuditLogs(
        userId ?? "",
        organizationId ?? "",
        page,
        pageSize,
        sortBy,
        sortMode,
        criterias,
        operators,
        values
      ),
    { organizationId, userId }
  );
};
