import { Accordion, FormInput, GridContainer } from "@Components";
import type { Options } from "@Interfaces";
import { t } from "i18next";
import { useMemo } from "react";
import ReactDOMServer from "react-dom/server";
import { FormGroup } from "reactstrap";
import { schemes } from "../../../constants";
import { AdditionalDataTab } from "./Components";
import { useOAuthCredentialsForm } from "./Hooks";

const additionalDataHeader = t(
  "project.authentication-and-dictionary.additional-data"
);

const GRANT_TYPE = [
  {
    label:
      "project.authentication-and-dictionary.grant-type.client-credentials",
    value: "client_credentials",
  },
  {
    label: "project.authentication-and-dictionary.grant-type.password",
    value: "password",
  },
];

const schemeOptions: Options = schemes.map(scheme => ({
  label: scheme.toUpperCase(),
  value: scheme,
}));

export const OAuthCredentialsForm = () => {
  const {
    authenticationSettings,
    validities,
    locationOptions,
    enabledDomains,
    loading,
    handleUpdate,
  } = useOAuthCredentialsForm();

  const accordion = useMemo(() => {
    const additionalDataLength = Object.keys(
      authenticationSettings.oAuthData?.additionalData ?? {}
    ).length;

    return (
      <Accordion
        data={[
          {
            children: <AdditionalDataTab />,
            header: `${additionalDataHeader} (${additionalDataLength})`,
            key: "additional-data",
          },
        ]}
      />
    );
  }, [authenticationSettings.oAuthData?.additionalData]);

  const enabledDomainsTooltip = useMemo(
    () => (
      <p className="out-of-scope-message">
        <span
          className="color-severity-medium font-size-caption"
          data-tooltip-id="tooltip"
          data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
            <>
              <b className="pb-1">{t("common.enabled-domains")}</b>
              {enabledDomains?.map(d => <div key={d}>{d}</div>)}
            </>
          )}
        >
          {t("common.domain-not-enabled")}
          <i className="ms-2 bi bi-info-circle"></i>
        </span>
      </p>
    ),
    [enabledDomains]
  );

  return (
    <>
      <FormGroup>
        <FormInput
          id={"authentication-settings-grant-type"}
          type="select"
          label="common.grant-type"
          name="oAuthData.grantType"
          options={GRANT_TYPE}
          value={authenticationSettings.oAuthData?.grantType ?? ""}
          data-cy={"authentication-settings-grant-type"}
          onChange={handleUpdate}
          disabled={loading}
          bsSize="sm"
        />

        <FormInput
          id={"authentication-settings-token-endpoint"}
          type="text"
          label="common.token-endpoint"
          name="oAuthData.tokenEndpoint"
          className="mt-3"
          value={authenticationSettings.oAuthData?.tokenEndpoint ?? ""}
          data-cy={"authentication-settings-token-endpoint"}
          onChange={handleUpdate}
          bsSize="sm"
          required
          disabled={loading}
          valid={validities.tokenEndpoint}
          invalid={!validities.tokenEndpoint}
        />

        {!!authenticationSettings.oAuthData?.tokenEndpoint &&
          !validities.tokenEndpoint &&
          enabledDomainsTooltip}

        <GridContainer columns={2} className="mt-3">
          <FormInput
            id={"authentication-settings-client-id"}
            type="text"
            label="common.client-id"
            name="oAuthData.clientID"
            value={authenticationSettings.oAuthData?.clientID ?? ""}
            data-cy={"authentication-settings-client-id"}
            onChange={handleUpdate}
            bsSize="sm"
            required
            disabled={loading}
            valid={validities.clientID}
            invalid={!validities.clientID}
          />

          <FormInput
            id={"authentication-settings-client-secret"}
            type="text"
            label="common.client-secret"
            name="oAuthData.clientSecret"
            value={authenticationSettings.oAuthData?.clientSecret ?? ""}
            data-cy={"authentication-settings-client-secret"}
            onChange={handleUpdate}
            bsSize="sm"
            required
            disabled={loading}
            valid={validities.clientSecret}
            invalid={!validities.clientSecret}
          />
        </GridContainer>

        {authenticationSettings.oAuthData?.grantType === "password" && (
          <GridContainer columns={2} className="mt-3">
            <FormInput
              id={"authentication-settings-username"}
              type="text"
              label="common.username"
              name="oAuthData.username"
              value={authenticationSettings.oAuthData?.username ?? ""}
              data-cy={"authentication-settings-username"}
              onChange={handleUpdate}
              bsSize="sm"
              required
              disabled={loading}
              valid={validities.username}
              invalid={!validities.username}
            />

            <FormInput
              id={"authentication-settings-password"}
              type="text"
              label="common.password"
              name="oAuthData.password"
              value={authenticationSettings.oAuthData?.password ?? ""}
              data-cy={"authentication-settings-password"}
              onChange={handleUpdate}
              bsSize="sm"
              required
              disabled={loading}
              valid={validities.password}
              invalid={!validities.password}
            />
          </GridContainer>
        )}

        <GridContainer columns={3} className="mt-3">
          <FormInput
            type="select"
            label="common.scheme"
            name="userAuthTokens[0].authenticationSchemeType"
            id="authentication-setting-schema-type"
            value={
              authenticationSettings.userAuthTokens?.[0]
                ?.authenticationSchemeType ?? ""
            }
            onChange={handleUpdate}
            options={schemeOptions}
            bsSize="sm"
            disabled={loading}
            invalid={!validities.authenticationSchemeType}
            data-cy="authentication-settings-input-scheme-type"
          />
          <FormInput
            type="select"
            label="common.location"
            name="userAuthTokens[0].location"
            id="authentication-setting-token-location"
            value={authenticationSettings.userAuthTokens?.[0]?.location ?? ""}
            onChange={handleUpdate}
            options={locationOptions}
            bsSize="sm"
            disabled={loading}
            invalid={!validities.tokenLocation}
            data-cy="authentication-settings-input-token-location"
          />
          <FormInput
            type="text"
            label="common.key"
            name="userAuthTokens[0].key"
            id="authentication-setting-token-output-key"
            value={authenticationSettings.userAuthTokens?.[0]?.key}
            onChange={handleUpdate}
            bsSize="sm"
            disabled={loading}
            valid={validities.tokenOutputKey}
            invalid={!validities.tokenOutputKey}
            data-cy="authentication-settings-input-token-output-key"
          />
        </GridContainer>
        <FormInput
          className="mt-3"
          id={"authentication-settings-scope"}
          type="text"
          label="common.scope"
          name="oAuthData.scope"
          value={authenticationSettings.oAuthData?.scope ?? ""}
          data-cy={"authentication-settings-scope"}
          onChange={handleUpdate}
          disabled={loading}
          placeholder={t("common.scope-placeholder")}
          bsSize="sm"
        />
        <FormInput
          className="mt-3"
          id="authentication-settings-revocation-endpoint"
          type="text"
          label="common.revocation-endpoint"
          name="oAuthData.revocationEndpoint"
          value={authenticationSettings.oAuthData?.revocationEndpoint ?? ""}
          data-cy={"authentication-settings-revocation-endpoint"}
          onChange={handleUpdate}
          disabled={loading}
          bsSize="sm"
          placeholder="https://www.example.com/page"
          invalid={!validities.revocationEndpoint}
        />

        {!!authenticationSettings.oAuthData?.revocationEndpoint &&
          !validities.revocationEndpoint &&
          enabledDomainsTooltip}
      </FormGroup>
      {accordion}
    </>
  );
};
