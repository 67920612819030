import type { Dispatch } from "react";
import { createStore } from "@Hooks";
import type { ApiKey, Nullable } from "@Interfaces";

export type ApiKeyDetailsCanvasStoreProperties = {
  open: boolean;
  editCanvasOpen: boolean;
  apiKey: Nullable<ApiKey>;
};

export type ApiKeyDetailsCanvasStore = ApiKeyDetailsCanvasStoreProperties & {
  setOpen: Dispatch<boolean>;
  setEditCanvasOpen: Dispatch<boolean>;
  setApiKey: Dispatch<Nullable<ApiKey>>;
};

const initialState: ApiKeyDetailsCanvasStoreProperties = {
  open: false,
  editCanvasOpen: false,
  apiKey: null,
};

export const useApiKeyDetailsCanvasStore =
  createStore<ApiKeyDetailsCanvasStore>(
    (get, set) => ({
      setOpen: open => set(store => ({ ...store, open })),
      setEditCanvasOpen: editCanvasOpen =>
        set(store => ({ ...store, editCanvasOpen })),
      setApiKey: apiKey => set(state => ({ ...state, apiKey })),
    }),
    initialState
  );
