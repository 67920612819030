import { useCallback } from "react";
import { API } from "@Services";
import { useUrlParams } from "@Hooks";
import { useSpaceLinkDetailsStore } from "../../../../../Stores";

export type SpaceLinkDetailsContentProps = {
  refresh: VoidFunction;
  onDelete: VoidFunction;
};

export const useSpaceLinkDetailsContent = (
  props: SpaceLinkDetailsContentProps
) => {
  const { spaceLinkDetails: spaceLink, associatedProjects } =
    useSpaceLinkDetailsStore();

  const { organizationId } = useUrlParams();

  // at this point, store's spaceLink should always be populated
  if (!spaceLink) throw new Error("invalid-store-status");

  const spaceLinkDelete = useCallback(() => {
    if (!organizationId) throw new Error("invilid-url-params");
    if (!spaceLink?.id) throw new Error("missing-space-link-id");
    return API.spaceLink().spaceLinkDelete(organizationId, spaceLink.id);
  }, [spaceLink?.id, organizationId]);

  return {
    ...props,
    editEnabled: !spaceLink?.expired,
    spaceLink,
    spaceLinkDelete,
    onSpaceLinkDelete: props.onDelete,
    hasAssociatedProjects: !!associatedProjects?.length,
  };
};
