import type { Project, UserGetProjectResponse } from "@Services";
import type {
  DefaultBodyType,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
} from "msw";
import {
  ProjectSpaceLink,
  delay,
  findProjectsInfoByOrganizationId,
} from "src/Mock";

export const projectGet = async (
  req: RestRequest<DefaultBodyType, PathParams<string>>,
  res: ResponseComposition<DefaultBodyType>,
  ctx: RestContext
) => {
  const organizationId = req.params.organizationId as string;
  const projectId = req.params.projectId as string;

  const projects = findProjectsInfoByOrganizationId(organizationId);
  const project = projects.find(p => p.id === projectId);

  if (!project) {
    return res(ctx.delay(delay), ctx.status(404));
  }

  const projectResponse = structuredClone(project);
  projectResponse.spaceLinkID = ProjectSpaceLink.get(project.id) ?? "";

  const p = projectResponse;

  const response: UserGetProjectResponse = {
    project: projectResponse,
    settingsCount: {
      authenticationSettingsCount: getCounterOf(p, "authenticationSettings"),
      dictionaryCount: getCounterOf(p, "dictionary"),
      extensionSettingsEnabledCategoriesCount: 0,
      issueExclusionsCount: 0,
      mtlsCertificatesCount: getCounterOf(p, "mTLSCertificates"),
      parameterLinksCount: getCounterOf(p, "parameterLinks"),
      pathExclusionsCount: getCounterOf(p, "pathExclusions"),
      userInjectablesCount: getCounterOf(p, "injectables"),
    },
  };
  return res(ctx.delay(delay), ctx.json(response), ctx.status(200));
};

const getCounterOf = (project: Project, key: keyof Project) => {
  if (Array.isArray(project[key])) {
    return (project[key] as unknown[]).length;
  }
  return 0;
};
