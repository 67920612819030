/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from "react";
import type { SkeletonTableProps } from "./Skeleton.i";
import { SkeletonBox } from "./SkeletonBox";

export const SkeletonTable: React.FC<SkeletonTableProps> = ({
  rows = 10,
  height = 30,
  rowDistance = 5,
}) => {
  // force a re-render after first component mount
  const [refreshCount, setRefreshCount] = useState(0);
  useEffect(() => {
    const animationFrame = requestAnimationFrame(() => {
      setRefreshCount(i => i + 1);
    });
    return () => {
      cancelAnimationFrame(animationFrame);
    };
  }, []);
  const loaderHeight = height * rows + Math.max(0, rows - 1) * rowDistance;
  return (
    <SkeletonBox width="100%" height={loaderHeight} key={refreshCount}>
      {Array.from({ length: rows }).map((_, idx) => (
        <rect
          key={idx}
          x="0"
          y={`${idx * (rowDistance + height)}`}
          rx="3"
          ry="3"
          width="100%"
          height={`${height}`}
        />
      ))}
    </SkeletonBox>
  );
};
