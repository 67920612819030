import { KA_SHIFT_Q } from "@Constants";
import { useKeyboardEvent } from "@Hooks";
import { useCallback, useState } from "react";

export const useDebuggerMode = () => {
  const [devMode, setDevMode] = useState(false);

  const updateDevMode = useCallback(() => {
    setDevMode(enabled => !enabled);
  }, []);

  useKeyboardEvent({
    action: KA_SHIFT_Q,
    onKeyDown: updateDevMode,
  });

  return devMode;
};
