import { FormInput } from "../../Form";
import { getFilterAllowedOptions } from "@Services";
import type { PageFiltersProps } from "../PageFilters.i";
import { RefreshListButton } from "../../Buttons";
import { usePageFilters } from "../usePageFilters";
import type { IssueListingType } from "@Interfaces";
import { PageFiltersWrapper } from "../PageFiltersWrapper";

interface Props extends PageFiltersProps {
  issueListingType: IssueListingType;
}

const severityOptions = getFilterAllowedOptions("severity");
const periodOptions = getFilterAllowedOptions("period");

export const IssuesFilters = ({
  updateFilters,
  refresh,
  issueListingType,
}: Props) => {
  const { getValueOf, handleOnChange } = usePageFilters(updateFilters);
  return (
    <PageFiltersWrapper>
      <FormInput
        id="select-issue-severity"
        label="common.severity"
        type="select"
        options={severityOptions}
        name="issue_severity"
        value={getValueOf("issue_severity")}
        onChange={handleOnChange}
      />
      <>
        {issueListingType === "all" && (
          <FormInput
            id="select-issue-period"
            label="components.date-filter.period-label"
            type="select"
            options={periodOptions}
            name="period"
            value={getValueOf("period")}
            onChange={handleOnChange}
          />
        )}
      </>
      <RefreshListButton onClick={refresh} />
    </PageFiltersWrapper>
  );
};
