import configJson from "./config.json";
// file created before compiling
import generatedCommitInfoJson from "./generatedCommitInfo.json";

export * from "./client";
export * from "./api";
export * from "./models";
export * from "./workers";
export * from "./date";
export * from "./utils";
export * from "./objects";
export * from "./test";
export * from "./auth";
export * from "./filters";
export * from "./project";
export * from "./env";
export * from "./apikeys";
export * from "./scans";
export * from "./charts";
export * from "./spacelinks";

export const config = configJson;
export const generatedCommitInfo = generatedCommitInfoJson;
