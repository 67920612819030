/* tslint:disable */
/* eslint-disable */
/**
 * Equixly API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * Contact: support@equixly.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AskPasswordResetRequest } from '../model';
// @ts-ignore
import { ConfirmInviteRequest } from '../model';
// @ts-ignore
import { ConfirmPasswordResetRequest } from '../model';
// @ts-ignore
import { OrganizationAdminGetUserResponse } from '../model';
// @ts-ignore
import { OrganizationAdminInviteRequest } from '../model';
// @ts-ignore
import { OrganizationAdminListUsersResponse } from '../model';
// @ts-ignore
import { OrganizationAdminUpdateUserRequest } from '../model';
// @ts-ignore
import { UserAskEnableTwoFactorAuthenticationResponse } from '../model';
// @ts-ignore
import { UserConfirmEnableTwoFactorAuthenticationRequest } from '../model';
// @ts-ignore
import { UserDisableTwoFactorAuthenticationRequest } from '../model';
// @ts-ignore
import { UserGetUserResponse } from '../model';
// @ts-ignore
import { UserRefreshTokenResponse } from '../model';
// @ts-ignore
import { UserUpdateRequest } from '../model';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the user with the given id in the organization
         * @param {string} userId User ID
         * @param {string} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminGetUser: async (userId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('organizationAdminGetUser', 'userId', userId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationAdminGetUser', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organization_id}/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Invites a user specifying a specific role for an organization. If the user does not exist, creates the new user sending an email that contains the invite ID with validity of 48 hours. Otherwise, creates the new role
         * @param {string} organizationId Organization ID
         * @param {OrganizationAdminInviteRequest} requestBody OrganizationAdminInviteRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminInvite: async (organizationId: string, requestBody: OrganizationAdminInviteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationAdminInvite', 'organizationId', organizationId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('organizationAdminInvite', 'requestBody', requestBody)
            const localVarPath = `/v1/organizations/{organization_id}/users/invite`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the users related to the organization with the given id.
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'user_created_at' | 'user_name' | 'user_surname' | 'user_surname_name' | 'user_email' | 'user_role_active' | 'user_role_value'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'user_name' | 'user_surname' | 'user_surname_name' | 'user_email' | 'user_role_active' | 'user_role_value'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminListUsersByOrganizationId: async (organizationId: string, page?: number, size?: number, sortBy?: 'user_created_at' | 'user_name' | 'user_surname' | 'user_surname_name' | 'user_email' | 'user_role_active' | 'user_role_value', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'user_name' | 'user_surname' | 'user_surname_name' | 'user_email' | 'user_role_active' | 'user_role_value'>, filterOperators?: Array<'LIKE' | 'EQ'>, filterValues?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationAdminListUsersByOrganizationId', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organization_id}/users`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (sortMode !== undefined) {
                localVarQueryParameter['sort_mode'] = sortMode;
            }

            if (filterCriteria) {
                localVarQueryParameter['filter_criteria'] = filterCriteria;
            }

            if (filterOperators) {
                localVarQueryParameter['filter_operators'] = filterOperators;
            }

            if (filterValues) {
                localVarQueryParameter['filter_values'] = filterValues;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Re-invites the user with the given id in the organization, sending an invitation email
         * @param {string} organizationId Organization ID
         * @param {string} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminReInviteUser: async (organizationId: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationAdminReInviteUser', 'organizationId', organizationId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('organizationAdminReInviteUser', 'userId', userId)
            const localVarPath = `/v1/organizations/{organization_id}/users/{user_id}/re-invite`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the user with the given id
         * @param {string} organizationId Organization ID
         * @param {string} userId User ID
         * @param {OrganizationAdminUpdateUserRequest} requestBody OrganizationAdminUpdateUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminUpdateUser: async (organizationId: string, userId: string, requestBody: OrganizationAdminUpdateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationAdminUpdateUser', 'organizationId', organizationId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('organizationAdminUpdateUser', 'userId', userId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('organizationAdminUpdateUser', 'requestBody', requestBody)
            const localVarPath = `/v1/organizations/{organization_id}/users/{user_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lets a user ask to enable two-factor authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAskEnable2fa: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/ask-enable-2fa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Requests a password reset for a user with validity of 24 hours
         * @param {AskPasswordResetRequest} requestBody AskPasswordResetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAskPasswordReset: async (requestBody: AskPasswordResetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('userAskPasswordReset', 'requestBody', requestBody)
            const localVarPath = `/v1/users/ask-password-reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lets a user confirm to enable two-factor authentication
         * @param {UserConfirmEnableTwoFactorAuthenticationRequest} requestBody UserConfirmEnableTwoFactorAuthenticationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userConfirmEnable2fa: async (requestBody: UserConfirmEnableTwoFactorAuthenticationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('userConfirmEnable2fa', 'requestBody', requestBody)
            const localVarPath = `/v1/users/confirm-enable-2fa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Confirms the invite, setting a new password for the user
         * @param {ConfirmInviteRequest} requestBody ConfirmInviteRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userConfirmInvite: async (requestBody: ConfirmInviteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('userConfirmInvite', 'requestBody', requestBody)
            const localVarPath = `/v1/users/confirm-invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Confirms a password reset and sets a new password for a user
         * @param {ConfirmPasswordResetRequest} requestBody ConfirmPasswordResetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userConfirmPasswordReset: async (requestBody: ConfirmPasswordResetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('userConfirmPasswordReset', 'requestBody', requestBody)
            const localVarPath = `/v1/users/confirm-password-reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lets a user disable two-factor authentication
         * @param {UserDisableTwoFactorAuthenticationRequest} requestBody UserDisableTwoFactorAuthenticationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDisable2fa: async (requestBody: UserDisableTwoFactorAuthenticationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('userDisable2fa', 'requestBody', requestBody)
            const localVarPath = `/v1/users/disable-2fa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns user information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Logs out a user by blacklisting their JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLogout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a refreshed token with a new expiration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRefreshToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/refresh-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the user with the given id
         * @param {UserUpdateRequest} requestBody UserUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdate: async (requestBody: UserUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('userUpdate', 'requestBody', requestBody)
            const localVarPath = `/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AuthBearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the user with the given id in the organization
         * @param {string} userId User ID
         * @param {string} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationAdminGetUser(userId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationAdminGetUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationAdminGetUser(userId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Invites a user specifying a specific role for an organization. If the user does not exist, creates the new user sending an email that contains the invite ID with validity of 48 hours. Otherwise, creates the new role
         * @param {string} organizationId Organization ID
         * @param {OrganizationAdminInviteRequest} requestBody OrganizationAdminInviteRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationAdminInvite(organizationId: string, requestBody: OrganizationAdminInviteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationAdminInvite(organizationId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the users related to the organization with the given id.
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'user_created_at' | 'user_name' | 'user_surname' | 'user_surname_name' | 'user_email' | 'user_role_active' | 'user_role_value'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'user_name' | 'user_surname' | 'user_surname_name' | 'user_email' | 'user_role_active' | 'user_role_value'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationAdminListUsersByOrganizationId(organizationId: string, page?: number, size?: number, sortBy?: 'user_created_at' | 'user_name' | 'user_surname' | 'user_surname_name' | 'user_email' | 'user_role_active' | 'user_role_value', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'user_name' | 'user_surname' | 'user_surname_name' | 'user_email' | 'user_role_active' | 'user_role_value'>, filterOperators?: Array<'LIKE' | 'EQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationAdminListUsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationAdminListUsersByOrganizationId(organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Re-invites the user with the given id in the organization, sending an invitation email
         * @param {string} organizationId Organization ID
         * @param {string} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationAdminReInviteUser(organizationId: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationAdminReInviteUser(organizationId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the user with the given id
         * @param {string} organizationId Organization ID
         * @param {string} userId User ID
         * @param {OrganizationAdminUpdateUserRequest} requestBody OrganizationAdminUpdateUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationAdminUpdateUser(organizationId: string, userId: string, requestBody: OrganizationAdminUpdateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationAdminUpdateUser(organizationId, userId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lets a user ask to enable two-factor authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAskEnable2fa(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAskEnableTwoFactorAuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAskEnable2fa(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Requests a password reset for a user with validity of 24 hours
         * @param {AskPasswordResetRequest} requestBody AskPasswordResetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAskPasswordReset(requestBody: AskPasswordResetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAskPasswordReset(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lets a user confirm to enable two-factor authentication
         * @param {UserConfirmEnableTwoFactorAuthenticationRequest} requestBody UserConfirmEnableTwoFactorAuthenticationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userConfirmEnable2fa(requestBody: UserConfirmEnableTwoFactorAuthenticationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userConfirmEnable2fa(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Confirms the invite, setting a new password for the user
         * @param {ConfirmInviteRequest} requestBody ConfirmInviteRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userConfirmInvite(requestBody: ConfirmInviteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userConfirmInvite(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Confirms a password reset and sets a new password for a user
         * @param {ConfirmPasswordResetRequest} requestBody ConfirmPasswordResetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userConfirmPasswordReset(requestBody: ConfirmPasswordResetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userConfirmPasswordReset(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lets a user disable two-factor authentication
         * @param {UserDisableTwoFactorAuthenticationRequest} requestBody UserDisableTwoFactorAuthenticationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDisable2fa(requestBody: UserDisableTwoFactorAuthenticationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userDisable2fa(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns user information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGetUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Logs out a user by blacklisting their JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userLogout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userLogout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a refreshed token with a new expiration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRefreshToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRefreshTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRefreshToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the user with the given id
         * @param {UserUpdateRequest} requestBody UserUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdate(requestBody: UserUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdate(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * Get the user with the given id in the organization
         * @param {string} userId User ID
         * @param {string} organizationId Organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminGetUser(userId: string, organizationId: string, options?: any): AxiosPromise<OrganizationAdminGetUserResponse> {
            return localVarFp.organizationAdminGetUser(userId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Invites a user specifying a specific role for an organization. If the user does not exist, creates the new user sending an email that contains the invite ID with validity of 48 hours. Otherwise, creates the new role
         * @param {string} organizationId Organization ID
         * @param {OrganizationAdminInviteRequest} requestBody OrganizationAdminInviteRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminInvite(organizationId: string, requestBody: OrganizationAdminInviteRequest, options?: any): AxiosPromise<void> {
            return localVarFp.organizationAdminInvite(organizationId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the users related to the organization with the given id.
         * @param {string} organizationId Organization ID
         * @param {number} [page] The page number of the paginated result
         * @param {number} [size] The size of the paginated result
         * @param {'user_created_at' | 'user_name' | 'user_surname' | 'user_surname_name' | 'user_email' | 'user_role_active' | 'user_role_value'} [sortBy] Field used for sorting the paginated result
         * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
         * @param {Array<'user_name' | 'user_surname' | 'user_surname_name' | 'user_email' | 'user_role_active' | 'user_role_value'>} [filterCriteria] The criteria that should be used to filter
         * @param {Array<'LIKE' | 'EQ'>} [filterOperators] The operators that should be used to filter
         * @param {Array<string>} [filterValues] The values that should be used to filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminListUsersByOrganizationId(organizationId: string, page?: number, size?: number, sortBy?: 'user_created_at' | 'user_name' | 'user_surname' | 'user_surname_name' | 'user_email' | 'user_role_active' | 'user_role_value', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'user_name' | 'user_surname' | 'user_surname_name' | 'user_email' | 'user_role_active' | 'user_role_value'>, filterOperators?: Array<'LIKE' | 'EQ'>, filterValues?: Array<string>, options?: any): AxiosPromise<OrganizationAdminListUsersResponse> {
            return localVarFp.organizationAdminListUsersByOrganizationId(organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(axios, basePath));
        },
        /**
         * Re-invites the user with the given id in the organization, sending an invitation email
         * @param {string} organizationId Organization ID
         * @param {string} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminReInviteUser(organizationId: string, userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.organizationAdminReInviteUser(organizationId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the user with the given id
         * @param {string} organizationId Organization ID
         * @param {string} userId User ID
         * @param {OrganizationAdminUpdateUserRequest} requestBody OrganizationAdminUpdateUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationAdminUpdateUser(organizationId: string, userId: string, requestBody: OrganizationAdminUpdateUserRequest, options?: any): AxiosPromise<void> {
            return localVarFp.organizationAdminUpdateUser(organizationId, userId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Lets a user ask to enable two-factor authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAskEnable2fa(options?: any): AxiosPromise<UserAskEnableTwoFactorAuthenticationResponse> {
            return localVarFp.userAskEnable2fa(options).then((request) => request(axios, basePath));
        },
        /**
         * Requests a password reset for a user with validity of 24 hours
         * @param {AskPasswordResetRequest} requestBody AskPasswordResetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAskPasswordReset(requestBody: AskPasswordResetRequest, options?: any): AxiosPromise<void> {
            return localVarFp.userAskPasswordReset(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Lets a user confirm to enable two-factor authentication
         * @param {UserConfirmEnableTwoFactorAuthenticationRequest} requestBody UserConfirmEnableTwoFactorAuthenticationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userConfirmEnable2fa(requestBody: UserConfirmEnableTwoFactorAuthenticationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.userConfirmEnable2fa(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Confirms the invite, setting a new password for the user
         * @param {ConfirmInviteRequest} requestBody ConfirmInviteRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userConfirmInvite(requestBody: ConfirmInviteRequest, options?: any): AxiosPromise<void> {
            return localVarFp.userConfirmInvite(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Confirms a password reset and sets a new password for a user
         * @param {ConfirmPasswordResetRequest} requestBody ConfirmPasswordResetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userConfirmPasswordReset(requestBody: ConfirmPasswordResetRequest, options?: any): AxiosPromise<void> {
            return localVarFp.userConfirmPasswordReset(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Lets a user disable two-factor authentication
         * @param {UserDisableTwoFactorAuthenticationRequest} requestBody UserDisableTwoFactorAuthenticationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDisable2fa(requestBody: UserDisableTwoFactorAuthenticationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.userDisable2fa(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns user information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetInfo(options?: any): AxiosPromise<UserGetUserResponse> {
            return localVarFp.userGetInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * Logs out a user by blacklisting their JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLogout(options?: any): AxiosPromise<void> {
            return localVarFp.userLogout(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a refreshed token with a new expiration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRefreshToken(options?: any): AxiosPromise<UserRefreshTokenResponse> {
            return localVarFp.userRefreshToken(options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the user with the given id
         * @param {UserUpdateRequest} requestBody UserUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdate(requestBody: UserUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.userUpdate(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - interface
 * @export
 * @interface UserApi
 */
export interface UserApiInterface {
    /**
     * Get the user with the given id in the organization
     * @param {string} userId User ID
     * @param {string} organizationId Organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    organizationAdminGetUser(userId: string, organizationId: string, options?: AxiosRequestConfig): AxiosPromise<OrganizationAdminGetUserResponse>;

    /**
     * Invites a user specifying a specific role for an organization. If the user does not exist, creates the new user sending an email that contains the invite ID with validity of 48 hours. Otherwise, creates the new role
     * @param {string} organizationId Organization ID
     * @param {OrganizationAdminInviteRequest} requestBody OrganizationAdminInviteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    organizationAdminInvite(organizationId: string, requestBody: OrganizationAdminInviteRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Returns the users related to the organization with the given id.
     * @param {string} organizationId Organization ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'user_created_at' | 'user_name' | 'user_surname' | 'user_surname_name' | 'user_email' | 'user_role_active' | 'user_role_value'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'user_name' | 'user_surname' | 'user_surname_name' | 'user_email' | 'user_role_active' | 'user_role_value'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    organizationAdminListUsersByOrganizationId(organizationId: string, page?: number, size?: number, sortBy?: 'user_created_at' | 'user_name' | 'user_surname' | 'user_surname_name' | 'user_email' | 'user_role_active' | 'user_role_value', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'user_name' | 'user_surname' | 'user_surname_name' | 'user_email' | 'user_role_active' | 'user_role_value'>, filterOperators?: Array<'LIKE' | 'EQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<OrganizationAdminListUsersResponse>;

    /**
     * Re-invites the user with the given id in the organization, sending an invitation email
     * @param {string} organizationId Organization ID
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    organizationAdminReInviteUser(organizationId: string, userId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Updates the user with the given id
     * @param {string} organizationId Organization ID
     * @param {string} userId User ID
     * @param {OrganizationAdminUpdateUserRequest} requestBody OrganizationAdminUpdateUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    organizationAdminUpdateUser(organizationId: string, userId: string, requestBody: OrganizationAdminUpdateUserRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Lets a user ask to enable two-factor authentication
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userAskEnable2fa(options?: AxiosRequestConfig): AxiosPromise<UserAskEnableTwoFactorAuthenticationResponse>;

    /**
     * Requests a password reset for a user with validity of 24 hours
     * @param {AskPasswordResetRequest} requestBody AskPasswordResetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userAskPasswordReset(requestBody: AskPasswordResetRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Lets a user confirm to enable two-factor authentication
     * @param {UserConfirmEnableTwoFactorAuthenticationRequest} requestBody UserConfirmEnableTwoFactorAuthenticationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userConfirmEnable2fa(requestBody: UserConfirmEnableTwoFactorAuthenticationRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Confirms the invite, setting a new password for the user
     * @param {ConfirmInviteRequest} requestBody ConfirmInviteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userConfirmInvite(requestBody: ConfirmInviteRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Confirms a password reset and sets a new password for a user
     * @param {ConfirmPasswordResetRequest} requestBody ConfirmPasswordResetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userConfirmPasswordReset(requestBody: ConfirmPasswordResetRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Lets a user disable two-factor authentication
     * @param {UserDisableTwoFactorAuthenticationRequest} requestBody UserDisableTwoFactorAuthenticationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userDisable2fa(requestBody: UserDisableTwoFactorAuthenticationRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Returns user information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userGetInfo(options?: AxiosRequestConfig): AxiosPromise<UserGetUserResponse>;

    /**
     * Logs out a user by blacklisting their JWT token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userLogout(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Returns a refreshed token with a new expiration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userRefreshToken(options?: AxiosRequestConfig): AxiosPromise<UserRefreshTokenResponse>;

    /**
     * Updates the user with the given id
     * @param {UserUpdateRequest} requestBody UserUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userUpdate(requestBody: UserUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI implements UserApiInterface {
    /**
     * Get the user with the given id in the organization
     * @param {string} userId User ID
     * @param {string} organizationId Organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public organizationAdminGetUser(userId: string, organizationId: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).organizationAdminGetUser(userId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Invites a user specifying a specific role for an organization. If the user does not exist, creates the new user sending an email that contains the invite ID with validity of 48 hours. Otherwise, creates the new role
     * @param {string} organizationId Organization ID
     * @param {OrganizationAdminInviteRequest} requestBody OrganizationAdminInviteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public organizationAdminInvite(organizationId: string, requestBody: OrganizationAdminInviteRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).organizationAdminInvite(organizationId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the users related to the organization with the given id.
     * @param {string} organizationId Organization ID
     * @param {number} [page] The page number of the paginated result
     * @param {number} [size] The size of the paginated result
     * @param {'user_created_at' | 'user_name' | 'user_surname' | 'user_surname_name' | 'user_email' | 'user_role_active' | 'user_role_value'} [sortBy] Field used for sorting the paginated result
     * @param {'asc' | 'desc'} [sortMode] The way to order the paginated result
     * @param {Array<'user_name' | 'user_surname' | 'user_surname_name' | 'user_email' | 'user_role_active' | 'user_role_value'>} [filterCriteria] The criteria that should be used to filter
     * @param {Array<'LIKE' | 'EQ'>} [filterOperators] The operators that should be used to filter
     * @param {Array<string>} [filterValues] The values that should be used to filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public organizationAdminListUsersByOrganizationId(organizationId: string, page?: number, size?: number, sortBy?: 'user_created_at' | 'user_name' | 'user_surname' | 'user_surname_name' | 'user_email' | 'user_role_active' | 'user_role_value', sortMode?: 'asc' | 'desc', filterCriteria?: Array<'user_name' | 'user_surname' | 'user_surname_name' | 'user_email' | 'user_role_active' | 'user_role_value'>, filterOperators?: Array<'LIKE' | 'EQ'>, filterValues?: Array<string>, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).organizationAdminListUsersByOrganizationId(organizationId, page, size, sortBy, sortMode, filterCriteria, filterOperators, filterValues, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Re-invites the user with the given id in the organization, sending an invitation email
     * @param {string} organizationId Organization ID
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public organizationAdminReInviteUser(organizationId: string, userId: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).organizationAdminReInviteUser(organizationId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the user with the given id
     * @param {string} organizationId Organization ID
     * @param {string} userId User ID
     * @param {OrganizationAdminUpdateUserRequest} requestBody OrganizationAdminUpdateUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public organizationAdminUpdateUser(organizationId: string, userId: string, requestBody: OrganizationAdminUpdateUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).organizationAdminUpdateUser(organizationId, userId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lets a user ask to enable two-factor authentication
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userAskEnable2fa(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userAskEnable2fa(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Requests a password reset for a user with validity of 24 hours
     * @param {AskPasswordResetRequest} requestBody AskPasswordResetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userAskPasswordReset(requestBody: AskPasswordResetRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userAskPasswordReset(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lets a user confirm to enable two-factor authentication
     * @param {UserConfirmEnableTwoFactorAuthenticationRequest} requestBody UserConfirmEnableTwoFactorAuthenticationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userConfirmEnable2fa(requestBody: UserConfirmEnableTwoFactorAuthenticationRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userConfirmEnable2fa(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Confirms the invite, setting a new password for the user
     * @param {ConfirmInviteRequest} requestBody ConfirmInviteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userConfirmInvite(requestBody: ConfirmInviteRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userConfirmInvite(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Confirms a password reset and sets a new password for a user
     * @param {ConfirmPasswordResetRequest} requestBody ConfirmPasswordResetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userConfirmPasswordReset(requestBody: ConfirmPasswordResetRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userConfirmPasswordReset(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lets a user disable two-factor authentication
     * @param {UserDisableTwoFactorAuthenticationRequest} requestBody UserDisableTwoFactorAuthenticationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userDisable2fa(requestBody: UserDisableTwoFactorAuthenticationRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userDisable2fa(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns user information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetInfo(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Logs out a user by blacklisting their JWT token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userLogout(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userLogout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a refreshed token with a new expiration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userRefreshToken(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userRefreshToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the user with the given id
     * @param {UserUpdateRequest} requestBody UserUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUpdate(requestBody: UserUpdateRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userUpdate(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}
