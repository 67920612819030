import { memo, useCallback, useContext, useEffect } from "react";
import {
  ReactFlow,
  SelectionMode,
  MiniMap,
  Controls,
  Background,
  BackgroundVariant,
  useReactFlow,
  ReactFlowProvider,
} from "@xyflow/react";
import { ThemeContext } from "@Contexts";
import { useAdditionalInfo } from "./Hooks";
import { InventoryItemNode, LocationNode, ParameterNode } from "./Components";
import { equals } from "@Services";
import { LoadingScreen, NoParameters } from "@Components";
import { APIDefinitionDetailsNotFound } from "./Components/APIDefinitionDetailsNotFound";
import { useAPIDefinitionDetailsStore } from "./Stores";
import "./AdditionalInfo.styles.scss";

const AdditionalInfoGraph = memo(() => {
  const { theme } = useContext(ThemeContext);
  const { details, nodes, edges, selectedNodeId, handleNodeClick } =
    useAdditionalInfo();
  const { setCenter } = useReactFlow();

  const centerNode = useCallback(() => {
    if (!nodes) {
      return;
    }

    const node = selectedNodeId
      ? nodes.find(n => n.id === selectedNodeId)
      : nodes[0];
    if (node) {
      // Get the position of the node
      const { position } = node;
      // Use setCenter to focus on the node's coordinates
      setCenter(position.x, position.y, { zoom: 1, duration: 800 }); // Optional zoom and duration
    }
  }, [nodes, selectedNodeId, setCenter]);

  useEffect(() => {
    centerNode();
  }, [centerNode, nodes]);

  if (!details) {
    return null;
  }

  if (nodes && nodes.length <= 1) {
    return <NoParameters />;
  }

  return (
    nodes &&
    edges && (
      <ReactFlow
        nodes={nodes}
        edges={edges}
        selectionMode={SelectionMode.Partial}
        proOptions={{ hideAttribution: true }}
        colorMode={theme}
        onNodeClick={handleNodeClick}
        nodeTypes={{
          default: InventoryItemNode,
          location: LocationNode,
          parameter: ParameterNode,
        }}
        panOnDrag={[0]}
        fitView
        fitViewOptions={{
          maxZoom: 1,
        }}
        snapToGrid
      >
        <MiniMap pannable zoomable zoomStep={1} />
        <Controls orientation="horizontal" />
        <Background variant={BackgroundVariant.Cross} />
      </ReactFlow>
    )
  );
}, equals);

export const AdditionalInfo = memo(() => {
  const { notFound, loading } = useAPIDefinitionDetailsStore();

  if (notFound) {
    return <APIDefinitionDetailsNotFound />;
  }

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div id="additional-info">
      <ReactFlowProvider>
        <AdditionalInfoGraph />
      </ReactFlowProvider>
    </div>
  );
}, equals);
