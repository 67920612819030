import { useMemo, type Dispatch, type FC } from "react";
import { useViewDictionary } from "./useViewDictionary";
import { DictionaryCard, ProjectSettingsCardContainer } from "@Components";

type ViewDictionaryProps = {
  restoreOption: Dispatch<string>;
};

export const ViewDictionary: FC<ViewDictionaryProps> = ({ restoreOption }) => {
  const { dictionary, removeEntry } = useViewDictionary(restoreOption);

  const cards = useMemo(() => {
    return dictionary.map((entry, i) => (
      <DictionaryCard
        key={entry.key + i}
        entry={entry}
        deleteEntry={() => removeEntry(i)}
      />
    ));
  }, [dictionary, removeEntry]);

  return (
    <ProjectSettingsCardContainer
      cardType="dictionary"
      emptyLabel="project.settings.no-dictionary-settings"
      children={cards}
    />
  );
};
