import { FormGroup, Label } from "reactstrap";
import { Callout, ComboBox, GridContainer, MethodsComboBox } from "@Components";
import { t } from "i18next";
import type { PathExclusionFormProps } from "./PathExclusionForm.i";
import type { FC } from "react";
import { memo } from "react";
import type { Method } from "@Interfaces";
import { usePathExclusionForm } from "./usePathExclusionForm";

const allowedMethods: Method[] = ["GET", "POST", "PATCH", "PUT", "DELETE"];
const pathLabel = t("common.path");

export const PathExclusionForm: FC<PathExclusionFormProps> = memo(props => {
  const {
    activeMethods,
    comboBoxData,
    gridColumns,
    path,
    setActiveMethods,
    setPath,
    validities,
    disabled,
    calloutMessage,
    pathLoading,
  } = usePathExclusionForm(props);

  return (
    <GridContainer id="path-exclusion-form" columns={gridColumns}>
      <FormGroup noMargin>
        <Label for="path-exclusion-input" children={pathLabel} />
        <ComboBox
          id="path-exclusion-input"
          data={comboBoxData}
          value={path}
          onChange={setPath}
          bsSize="sm"
          valid={validities.path}
          invalid={!validities.path}
          loading={pathLoading}
        />
      </FormGroup>
      <FormGroup noMargin className="align-self-end">
        <span className="form-label">{t(`common.methods`)}</span>
        <MethodsComboBox
          methods={allowedMethods}
          activeMethods={activeMethods}
          setActiveMethods={setActiveMethods}
          disabled={disabled}
          addAll
        />
        {!!calloutMessage && (
          <div className="mt-3">
            <Callout {...calloutMessage} />
          </div>
        )}
      </FormGroup>
    </GridContainer>
  );
});
