import type { ButtonProps } from "@Components";
import { useIssueListStore } from "@Stores";
import { useCallback, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useIssueDetailsCanvasExcludeButton } from "./useIssueDetailsCanvasExcludeButton";
import { useIssueDetailsCanvasIssueIds } from "./useIssueDetailsCanvasIssueIds";
import { useIssueDetailsCanvasShareButton } from "./useIssueDetailsCanvasShareButton";
import { useIssueDetailsCanvasStore } from "../IssueDetailsCanvas.store";

export const useIssueDetailsCanvas = () => {
  const { organizationId, projectId, scanId } = useIssueDetailsCanvasIssueIds();
  const { excludeButton } = useIssueDetailsCanvasExcludeButton();
  const { openIssueId, issues, setOpenIssueId, clearOpenIssueId } =
    useIssueListStore();
  const { shareButton } = useIssueDetailsCanvasShareButton();
  const { reset } = useIssueDetailsCanvasStore();

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const handleOpenIssueIdChange = useCallback(
    (openIssueId: string) => {
      searchParams.set("isp_issueId", openIssueId);
      navigate({ search: searchParams.toString(), hash: location.hash });
    },
    [location.hash, navigate, searchParams]
  );

  const clearIssueId = useCallback(() => {
    searchParams.delete("isp_issueId");
    clearOpenIssueId();
    navigate({ search: searchParams.toString(), hash: location.hash });
  }, [clearOpenIssueId, location.hash, navigate, searchParams]);

  useEffect(() => {
    if (openIssueId) handleOpenIssueIdChange(openIssueId);
  }, [handleOpenIssueIdChange, openIssueId]);

  useEffect(() => {
    // open canvas at page load
    const urlIssueId = searchParams.get("isp_issueId");
    if (!urlIssueId) return clearIssueId();
    if (!organizationId || !projectId) return;

    if (scanId) {
      return setOpenIssueId(urlIssueId, scanId, projectId, organizationId);
    }

    if (issues) {
      const issueScanId = issues?.find(i => i.id === urlIssueId)?.scanID;
      if (!issueScanId) return clearIssueId();
      return setOpenIssueId(urlIssueId, issueScanId, projectId, organizationId);
    }
  }, [
    clearIssueId,
    issues,
    organizationId,
    projectId,
    scanId,
    searchParams,
    setOpenIssueId,
  ]);

  useEffect(() => reset, [reset]);

  const closeCanvas = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("isp_issueId");
    navigate(`${location.pathname}?${searchParams}${location.hash}`, {
      replace: false,
    });
    clearOpenIssueId();
  }, [
    clearOpenIssueId,
    location.hash,
    location.pathname,
    location.search,
    navigate,
  ]);

  return {
    canvasOpen: !!openIssueId,
    canvasButtons: [excludeButton, shareButton] as Array<ButtonProps>,
    closeCanvas,
  };
};
