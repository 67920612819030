import { useCallback, useMemo } from "react";
import type { PathExclusionFormProps } from "./PathExclusionForm.i";
import type { Options } from "@Interfaces";

export const usePathExclusionForm = ({
  gridColumns = 2,
  paths,
  setPath,
  ...props
}: PathExclusionFormProps) => {
  const comboBoxData = useMemo<Options>(() => {
    return (paths ?? []).map(entry => ({ label: entry, value: entry }));
  }, [paths]);

  const handleSetPath = useCallback(
    (path: string) => setPath(path.trim()),
    [setPath]
  );

  return {
    ...props,
    gridColumns,
    comboBoxData,
    setPath: handleSetPath,
  };
};
