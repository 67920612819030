import { memo } from "react";
import { PasswordCreated, ResetPasswordPage } from "../../Components";
import { useResetPasswordPage } from "./useResetPasswordPage";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { Callout } from "@Components";

export const ResetPassword = memo(() => {
  const { showEmptyPage, submitFunction, submitted, error } =
    useResetPasswordPage();

  if (showEmptyPage) {
    return null;
  }

  if (submitted) {
    return <PasswordCreated />;
  }

  return (
    <>
      <h4 className="mb-3">{t("login.setup-password.reset-password")}</h4>
      <ResetPasswordPage submitFunction={submitFunction} />
      {error && (
        <div className="mt-3">
          <Callout
            level="error"
            className="white-space-prewrap"
            children={error}
          />
          <Link to={`/forgot-password`} className="flex-center mt-2 w-100">
            {t("login.request-new-password-link")}
          </Link>
        </div>
      )}
    </>
  );
});
