import { DownloadJsonButton, JSONViewer, SettingsContainer } from "@Components";
import type { UserUpdateProjectRequest } from "@Services";
import { equals } from "@Services";
import { memo, useMemo } from "react";
import { FullAccessMode, ReadOnlyMode } from "./Components";
import { useProjectSettings } from "./useProjectSettings";

export const ProjectGeneral = memo(() => {
  const {
    canvasController,
    dangerZoneController,
    license,
    loadingStates,
    project,
    projectUpdate,
    isRoleReader,
    noData,
  } = useProjectSettings();

  const children = useMemo(() => {
    if (!project || !license) {
      return null;
    }
    if (isRoleReader) {
      return <ReadOnlyMode project={project} />;
    }
    return (
      <FullAccessMode
        project={project}
        setProject={projectUpdate}
        license={license}
        canvasController={canvasController}
        dangerZoneController={dangerZoneController}
      />
    );
  }, [
    canvasController,
    dangerZoneController,
    license,
    project,
    projectUpdate,
    isRoleReader,
  ]);

  return (
    <>
      <div id="project-generals">
        <SettingsContainer
          loading={loadingStates.get}
          section="settings"
          hasError={noData}
        >
          {children}
        </SettingsContainer>
      </div>
      <DownloadJsonButton data={project} />
      <JSONViewer
        data={project}
        devMode
        onChange={data => projectUpdate(data as UserUpdateProjectRequest, true)}
      />
    </>
  );
}, equals);
