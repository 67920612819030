import { useMemo } from "react";
import type { Props } from "./SortInput.i";
import type { InputProps } from "reactstrap";
import { t } from "i18next";
import { useSearchParams } from "react-router-dom";

export const useSortInput = ({
  headerItems,
  updateFunction,
  className = "",
  defaultSorts,
}: Props) => {
  const [searchParams] = useSearchParams();

  const [options, length] = useMemo(() => {
    const options = headerItems.reduce(
      (result, item) => {
        if (typeof item === "object" && item.sortByKey) {
          const { text, sortByKey } = item;
          result.push({ text, sortByKey });
        }
        return result;
      },
      [] as { text: string; sortByKey: string }[]
    );

    return [options, options.length];
  }, [headerItems]);

  const inputProps = useMemo(() => {
    const type = length > 1 ? "select" : "text";
    const result: InputProps = { type };
    if (type === "text") {
      result.children = length ? t(options[0].text) : "";
    } else {
      result.value = searchParams.get("sortBy") || defaultSorts.sortBy;
      result.children = options.map(({ sortByKey, text }, i) => {
        return <option value={sortByKey} children={t(text)} key={i} />;
      });
      result.onChange = e => {
        const sortBy = e.target.value;
        updateFunction({ sortBy, sortMode: "desc" });
      };
    }
    return result;
  }, [defaultSorts.sortBy, length, options, searchParams, updateFunction]);

  const iconProps = useMemo(() => {
    const sortMode = searchParams.get("sortMode") || defaultSorts.sortMode;
    const result = {
      asc: "caret-up",
      desc: "caret-down",
      onClick: () => {
        updateFunction({ sortMode: sortMode === "desc" ? "asc" : "desc" });
      },
    };
    if (sortMode === "asc") {
      result.asc = "caret-up-fill";
    }
    if (sortMode === "desc") {
      result.desc = "caret-down-fill";
    }
    return result;
  }, [defaultSorts.sortMode, searchParams, updateFunction]);

  return { className, length, iconProps, inputProps };
};
