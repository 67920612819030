import type { InjectableContent } from "@Interfaces";
import { BEM, joinClasses } from "@Utils";
import "./style.scss";
import type { CSSProperties } from "react";

export type CalloutProps = {
  children: InjectableContent;
  level?: "info" | "warning" | "success" | "error";
  className?: string;
  id?: string;
  style?: CSSProperties;
  iconClass?: string;
};

export const Callout = ({
  children,
  level = "info",
  className,
  iconClass,
  ...otherProps
}: CalloutProps) => {
  return (
    <div
      className={joinClasses(BEM("callout", "", level), className)}
      data-cy={`callout${level && `-${level}`}`}
      {...otherProps}
    >
      <i className={iconClass || "bi bi-info-square-fill"}></i>
      {children}
    </div>
  );
};
