import type { FC } from "react";
import type { PropsText } from "../../FormInput.i";
import { Input, Spinner } from "reactstrap";
import { joinClasses } from "@Utils";
import { handleAutoTrim } from "../../function";

export const TextFormInput: FC<PropsText> = ({
  className,
  inputClassName,
  maxLength = 255,
  autoTrim,
  loading,
  ...props
}) => {
  return (
    <>
      <Input
        {...props}
        className={joinClasses(inputClassName, !!props.value && "dirty")}
        maxLength={maxLength}
        onBlur={e => handleAutoTrim(e, { ...props, autoTrim })}
      />
      {loading && <Spinner size="sm" />}
    </>
  );
};
