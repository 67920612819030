import { useExclusionMatches, useManageApiResponse, useProject } from "@Hooks";
import type { Nullable } from "@Interfaces";
import { API, type IssueExclusion } from "@Services";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIssueDetailsCanvasIssueIds } from "../../../../../Hooks/useIssueDetailsCanvasIssueIds";
import { useIssueDetailsCanvasStore } from "../../../../../IssueDetailsCanvas.store";
import { isStatusCodeSuccess } from "@Utils";
import type { CalloutProps } from "@Components";
import { type ButtonProps } from "@Components";
import { t } from "i18next";

const pathNotFound = t("project.settings.unrelated-project-path");

export const useIssueExclusionCanvas = () => {
  const {
    issueDetails,
    issueExlusionCanvasOpen,
    setIssueExclusionCanvasOpen,
    excludeIssueDetails,
  } = useIssueDetailsCanvasStore();
  const { organizationId, projectId } = useIssueDetailsCanvasIssueIds();
  const [loading, setLoading] = useState(false);
  const [issueExclusionForm, setIssueExclusionForm] =
    useState<Nullable<IssueExclusion>>(null);
  const manageResponse = useManageApiResponse(setLoading);
  const baseUrl = useProject().project?.baseURL;

  useEffect(() => {
    if (!issueDetails) {
      setIssueExclusionForm(null);
      return;
    }

    setIssueExclusionForm({
      extensionName: issueDetails.extensionName,
      falsePositive: false,
      id: issueDetails.id,
      methods: [issueDetails.verb],
      path: issueDetails.endpoint,
      isMatch: false,
    });
  }, [issueDetails]);

  const handleExcludeIssue = useCallback(async () => {
    if (!organizationId || !projectId || !issueExclusionForm) {
      return false;
    }

    const { status } = await manageResponse({
      errorMessage: "issue-exclusion-creation",
      promise: API.scanSettings().issueExclusionCreate(
        organizationId,
        projectId,
        issueExclusionForm
      ),
      successMessage: "issue-exclusion-creation",
    });

    if (isStatusCodeSuccess(status)) {
      setIssueExclusionCanvasOpen(false);
      excludeIssueDetails();
    }
  }, [
    excludeIssueDetails,
    issueExclusionForm,
    manageResponse,
    organizationId,
    projectId,
    setIssueExclusionCanvasOpen,
  ]);

  const saveButton = useMemo<Array<ButtonProps>>(
    () => [
      {
        id: "submit-authentication",
        color: "primary",
        iconClass: "bi bi-floppy",
        children: "common.save",
        disabled: !issueExclusionForm || !issueDetails,
        loading,
        onClick: handleExcludeIssue,
      },
    ],
    [issueExclusionForm, issueDetails, loading, handleExcludeIssue]
  );

  const {
    isMatch,
    lastTestedPath,
    loading: exclusionLoading,
  } = useExclusionMatches(
    issueExclusionForm?.path ?? "",
    issueExclusionForm?.methods ?? []
  );

  const calloutMessage = useMemo<CalloutProps | undefined>(() => {
    if (baseUrl && isMatch === false) {
      return {
        level: "info",
        children: (
          <div>
            {pathNotFound}:
            <b className="d-block">
              {`${baseUrl}/${lastTestedPath}`.replaceAll(/\/+/g, "/")}
            </b>
          </div>
        ),
      };
    }
  }, [baseUrl, isMatch, lastTestedPath]);

  return {
    loading,
    issueExclusionForm,
    setIssueExclusionForm,
    saveButton,
    open: issueExlusionCanvasOpen,
    close: () => setIssueExclusionCanvasOpen(false),
    calloutMessage,
    exclusionLoading,
  };
};
