import { FormInput } from "../../Form";
import type { PageFiltersProps } from "../PageFilters.i";
import { RefreshListButton } from "../../Buttons";
import { usePageFilters } from "../usePageFilters";
import { getFilterAllowedOptions } from "@Services";
import { PageFiltersWrapper } from "../PageFiltersWrapper";
import { STATUS_CODE_OPTIONS } from "@Utils";
import { t } from "i18next";
import { Label } from "reactstrap";
import { ComboBox } from "src/Components/ComboBox";
import { useCallback } from "react";

const methodOptions = getFilterAllowedOptions("verb");
const periodOptions = getFilterAllowedOptions("period");

type AuditLogsFiltersProps = PageFiltersProps & {
  filterValues: Record<string, string>;
};

export const AuditLogsFilters = ({
  updateFilters,
  refresh,
  filterValues,
}: AuditLogsFiltersProps) => {
  const { handleOnChange } = usePageFilters(updateFilters);
  const handleStatusChange = useCallback(
    (statusCode: string) => {
      updateFilters({ audit_log_status: statusCode });
    },
    [updateFilters]
  );

  return (
    <PageFiltersWrapper>
      <FormInput
        id="audit-log-path"
        label="common.path"
        type="text"
        name="audit_log_path"
        value={filterValues.audit_log_path}
        onChange={handleOnChange}
      />

      <div>
        <Label for="audit-log-status-code">{t("common.status-code")}</Label>
        <ComboBox
          id="audit-log-status-code"
          bsSize="sm"
          value={filterValues.audit_log_status}
          data={STATUS_CODE_OPTIONS}
          role="form-control"
          onChange={handleStatusChange}
        />
      </div>

      <FormInput
        id="audit-log-method"
        label="common.method"
        type="select"
        name="audit_log_method"
        value={filterValues.audit_log_method}
        onChange={handleOnChange}
        options={methodOptions}
      />

      <FormInput
        id="audit-log-period"
        label="components.date-filter.period-label"
        type="select"
        options={periodOptions}
        name="period"
        value={filterValues.period}
        onChange={handleOnChange}
      />

      <RefreshListButton onClick={refresh} />
    </PageFiltersWrapper>
  );
};
