import { DisplayRow } from "@Components";
import type { ApiKey } from "@Interfaces";
import { dateTimeFormat, isMaxEndDate } from "@Services";
import { t } from "i18next";
import type { FC } from "react";

type Props = {
  apiKey: ApiKey;
  isExpired: boolean;
  remainingDays: number;
  status: string;
};

export const ApiKeyData: FC<Props> = ({
  apiKey,
  isExpired,
  remainingDays,
  status,
}) => {
  const hasExpiration = !isMaxEndDate(apiKey.expiresAt);

  let expiresAtValue = "";
  let remainingDaysValue = 0;

  if (hasExpiration) {
    expiresAtValue = dateTimeFormat(apiKey.expiresAt, {
      trimTime: true,
    });
    if (Number.isFinite(remainingDays)) {
      remainingDaysValue = remainingDays;
    }
  }

  return (
    <DisplayRow
      noHeader
      className="m-0 mt-4"
      columns={[
        {
          label: "common.name",
          value: apiKey.name,
          xs: 12,
          xl: 4,
          xxl: 3,
        },
        {
          label: "common.created-at",
          value: dateTimeFormat(apiKey.createdAt, { trimTime: true }),
          xs: 12,
          xl: 4,
          xxl: 3,
        },
        {
          label: "common.role",
          value: t(`roles.${apiKey.role}`) ?? "N/A",
          xs: 12,
          xl: 4,
          xxl: 3,
        },
        {
          label: "common.status",
          value: isExpired ? t("common.expired") : t(`common.${status}`),
          xs: 12,
          xl: 4,
          xxl: 3,
        },
        expiresAtValue && {
          label: "common.expires-at",
          value: expiresAtValue,
          xs: 12,
          xl: 4,
          xxl: 3,
        },
        remainingDaysValue && {
          label: "common.remaining-days",
          value: remainingDaysValue,
          xs: 12,
          xl: 4,
          xxl: 3,
        },
      ].filter(e => !!e)}
    />
  );
};
