import type { Injectable } from "@Services";
import { joinClasses } from "@Utils";
import { memo, useCallback, useMemo, useState } from "react";
import { InjectablesEntryCard } from "src/Components/Cards";
import { ProjectSettingsCardContainer } from "src/Components/GridContainer";
import { DisplayRow } from "../DisplayRow";
import type { DisplayRowProps as MainProps } from "../DisplayRow.i";

type DisplayRowProjectInjectablesProps = {
  injectableEntries: Array<Injectable>;
  deleteEntry?: (i: Injectable) => Promise<boolean>;
} & Pick<MainProps, "buttonProps">;

export const DisplayRowProjectInjectables = memo(
  ({
    injectableEntries,
    deleteEntry,
    buttonProps,
  }: DisplayRowProjectInjectablesProps) => {
    const [deletingIndex, setDeletingIndex] = useState<number>();

    const getOnDeleteFunction: (i: number) => VoidFunction | undefined =
      useCallback(
        (i: number) => {
          if (!deleteEntry) return undefined;
          return async () => {
            setDeletingIndex(i);
            await deleteEntry(injectableEntries[i]);
            setDeletingIndex(undefined);
          };
        },
        [deleteEntry, injectableEntries]
      );

    const cardsContainer = useMemo(
      () => (
        <ProjectSettingsCardContainer
          cardType="injectable"
          emptyLabel="common.no-injectables-data"
        >
          {injectableEntries.map((e, i) => {
            const handleOnDelete = getOnDeleteFunction(i);
            return (
              <InjectablesEntryCard
                injectableEntry={e}
                key={`${i}-${e.name}`}
                onDelete={handleOnDelete}
                loading={deletingIndex === i}
                disabled={deletingIndex !== undefined}
              />
            );
          })}
        </ProjectSettingsCardContainer>
      ),
      [deletingIndex, getOnDeleteFunction, injectableEntries]
    );

    return (
      <DisplayRow
        title="common.injectable-parameters"
        buttonProps={buttonProps}
        className={joinClasses(
          "h-100",
          injectableEntries.length <= 0 && "no-data"
        )}
      >
        {cardsContainer}
      </DisplayRow>
    );
  }
);
