import { useOrganizationAuditLogs, useUrlParams } from "@Hooks";
import { API } from "@Services";

export const useApiKeyLogs = () => {
  const { organizationId, apiKeyId } = useUrlParams();

  return useOrganizationAuditLogs(
    "organization-apikeys-audit-logs",
    ({ page, pageSize, sortBy, sortMode, criterias, operators, values }) =>
      API.auditLog().organizationAdminListApikeysAuditLogs(
        apiKeyId ?? "",
        organizationId ?? "",
        page,
        pageSize,
        sortBy,
        sortMode,
        criterias,
        operators,
        values
      ),
    { organizationId, apiKeyId }
  );
};
