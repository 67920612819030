import {
  Button,
  ProjectSettingsCardContainer,
  InjectablesEntryCard,
  GlobalInjectableParameterFormCanvas,
} from "@Components";
import { Card } from "reactstrap";
import { useInjectableParameters } from "./useInjectableParameters";
import { useMemo } from "react";

export const NewProjectInjectableParametersSettings = () => {
  const { actions, canvas, injectableEntries } = useInjectableParameters();

  const removeEntry = actions.removeEntry;

  const cards = useMemo(() => {
    return injectableEntries.map((e, i) => (
      <InjectablesEntryCard
        key={e.name + i}
        injectableEntry={e}
        onDelete={() => removeEntry(e)}
      />
    ));
  }, [removeEntry, injectableEntries]);

  return (
    <section id="injectable-parameters">
      <Card className="p-4 border-tl-0">
        <div className="d-flex w-100 justify-content-end">
          <Button
            color="primary"
            children="common.add"
            onClick={canvas.open}
            iconClass="bi bi-plus-square-fill"
            data-cy="show-injectable-canvas-button"
          />
        </div>

        <ProjectSettingsCardContainer
          cardType="injectable"
          emptyLabel="project.settings.no-injectable-parameters-settings"
        >
          {cards}
        </ProjectSettingsCardContainer>
      </Card>

      <GlobalInjectableParameterFormCanvas
        isOpen={canvas.isOpen}
        close={canvas.close}
        saveEntry={actions.addEntry}
      />
    </section>
  );
};
