import {
  usePaginatedResult,
  useUpdateCacheFilters,
  useUrlParams,
} from "@Hooks";
import type {
  ListSpaceLinkCriteria,
  ListSpaceLinkOperator,
  ListSpaceLinkSort,
  OrganizationId,
} from "@Interfaces";
import { API } from "@Services";
import { useCallback, useEffect, useState } from "react";
import { useOrganizationSpaceLinkStore } from "@Stores";

const fetchHasAnyItem = (organizationId: OrganizationId) => {
  return API.spaceLink().spaceLinkList(organizationId, 1, 1);
};

export const defaultFilters: Record<string, string> = {};

export const defaultSorts = {
  sortBy: `space_link_name`,
  sortMode: "desc",
};

export const useSpaceLinkListing = (readOnly?: boolean) => {
  const { organizationId } = useUrlParams();
  const [hasError, setHasError] = useState(false);
  const {
    spaceLinkList,
    setSpaceLinkList,
    hasAnyItem,
    setHasAnyItem,
    setContentLoaded,
    reset,
  } = useOrganizationSpaceLinkStore();

  const updateSearchFilter = useUpdateCacheFilters("organization.space-links");

  const { response, loading, fetch } = usePaginatedResult({
    defaultFilters,
    getResponse: useCallback(
      args => {
        const filter_criterias = (
          readOnly
            ? [readOnly ? "space_link_expired" : "", ...(args.criteria ?? [])]
            : args.criteria
        ) as ListSpaceLinkCriteria[];

        const filter_operators = (
          readOnly ? ["EQ", ...(args.operators ?? [])] : args.operators
        ) as ListSpaceLinkOperator[];

        const filter_values = readOnly
          ? ["false", ...(args.values ?? [])]
          : args.values;

        return API.spaceLink().spaceLinkList(
          args.organizationId,
          args.page,
          args.pageSize,
          args.sortBy as ListSpaceLinkSort,
          args.sortMode,
          filter_criterias,
          filter_operators,
          filter_values
        );
      },
      [readOnly]
    ),
  });

  const handleResponseChanges = useCallback(() => {
    if (!organizationId || loading) return;

    if (!response) {
      setHasError(true);
      setHasAnyItem(false);
      setContentLoaded(true);
      setSpaceLinkList([]);
      return;
    }

    setSpaceLinkList(response.data);

    if (response.totalItems) {
      setHasAnyItem(true);
      setContentLoaded(true);
      return;
    }

    // first page fetch have not returned any item. try to fetch any
    if (hasAnyItem === null) {
      fetchHasAnyItem(organizationId)
        .then(response => setHasAnyItem(!!response.data.totalItems))
        .catch(() => setHasError(true))
        .finally(() => setContentLoaded(true));
    }
  }, [
    hasAnyItem,
    loading,
    organizationId,
    response,
    setContentLoaded,
    setHasAnyItem,
    setSpaceLinkList,
  ]);

  useEffect(handleResponseChanges, [handleResponseChanges]);
  useEffect(() => reset, [reset]);

  return {
    spaceLinks: spaceLinkList ?? [],
    totalItems: response?.totalItems ?? 0,
    loading: loading || hasAnyItem === null,
    hasAnyItem,
    hasError,
    updateSearchFilter,
    fetch,
  };
};
