import useIssueTable from "./useIssueTable";
import { ListItems } from "@Components";
import { memo } from "react";
import { equals } from "@Services";
import type { IssuesTableProps } from "./IssueTable.i";

export const IssuesTable = memo((props: IssuesTableProps) => {
  const {
    headers,
    loading,
    config,
    cardItemOrder,
    fullRowIndexes,
    defaultFilters,
    setFilters,
  } = useIssueTable(props);

  return (
    <ListItems
      cardVersion={false}
      config={config}
      headers={headers}
      showSkeleton={loading}
      clickable={true}
      noDataMessage="common.no-issues-found"
      updateCardItemOrder={cardItemOrder}
      fullRowIndexes={fullRowIndexes}
      setSorts={setFilters}
      defaultSorts={defaultFilters}
    />
  );
}, equals);
