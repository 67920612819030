import type { UserWithRole } from "@Services";
import type { Dispatch } from "react";
import { createStore } from "src/Hooks/store/useStore";

export type OrganizationUserListStoreProperties = {
  users: Array<UserWithRole>;
  refreshData: boolean;
};

export type OrganizationUserListStore = OrganizationUserListStoreProperties & {
  setUsers: Dispatch<Array<UserWithRole>>;
  setNeedRefresh: Dispatch<boolean>;
};

const initialState: OrganizationUserListStoreProperties = {
  users: [],
  refreshData: false,
};

export const useOrganizationUserListStore =
  createStore<OrganizationUserListStore>(
    (get, set) => ({
      setUsers: users => set(state => ({ ...state, users })),
      setNeedRefresh: (refreshData = true) =>
        set(state => ({ ...state, refreshData })),
    }),
    initialState
  );
