import { pureComponent } from "@Utils";
import { memo } from "react";
import { Offcanvas } from "@Components";
import { useOrganizationUserDetailsCanvas } from "../../Hooks";
import { OrganizationUserDetails } from "./Components/OrganizationUserDetails";

export const OrganizationUserDetailsCanvas = memo(() => {
  const { canvasButtons, handleClose } = useOrganizationUserDetailsCanvas();
  return (
    <Offcanvas
      className="full-height unstiled"
      isOpen={true}
      title="common.user-details"
      toggle={handleClose}
      children={<OrganizationUserDetails />}
      size="md"
      exitLabel="common.close"
      buttons={canvasButtons}
    />
  );
}, pureComponent);
