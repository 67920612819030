import { getFilterAllowedOptions } from "@Services";
import { useCallback, useEffect, useMemo, useState } from "react";
import { constants } from "../../contants";
import moment from "moment-timezone";
import type { Props } from "./CreateForm.i";
import { PROJECT_MAX_END_DATE } from "@Constants";

export const useCreateForm = ({ formData, setFormData, ...rest }: Props) => {
  const roleOptions = useMemo(() => {
    return getFilterAllowedOptions("api_key_role").filter(({ value }) => value);
  }, []);

  const [endDateEnabled, setEndDateEnabled] = useState(true);
  const [previousValue, setPreviousValue] = useState("");

  const [minExpiration, maxExpiration] = constants.expireDateRangesInDays.map(
    increment => moment().add(increment, "day").toDate()
  );

  useEffect(() => {
    const momentExpiration = moment(formData.expiresAt);
    const momentMinExpiration = moment(minExpiration);
    const isValid = momentExpiration.isValid();
    if (!isValid || momentExpiration.isBefore(momentMinExpiration, "date")) {
      setFormData({
        ...formData,
        expiresAt: momentMinExpiration.format("YYYY-MM-DD"),
      });
    }
  }, [formData, minExpiration, roleOptions, setFormData]);

  const toggleEndDate = useCallback(
    (enabled: boolean) => {
      // enable end-date input
      if (enabled) {
        setEndDateEnabled(true);
        setFormData({
          ...formData,
          expiresAt:
            previousValue || moment(minExpiration).format("YYYY-MM-DD"),
        });
      }
      // disable end-date input
      if (!enabled) {
        setEndDateEnabled(false);
        if (previousValue !== PROJECT_MAX_END_DATE) {
          setPreviousValue(formData.expiresAt);
        }
        setFormData({ ...formData, expiresAt: PROJECT_MAX_END_DATE });
      }
    },
    [formData, minExpiration, previousValue, setFormData]
  );

  return {
    ...rest,
    roleOptions,
    minExpiration,
    maxExpiration,
    formData,
    setFormData,
    endDateEnabled,
    toggleEndDate,
  };
};
