import { useCallback, useEffect, useMemo, useState } from "react";
import type { OrganizationAdminGetUserResponse } from "@Services";
import { equals } from "@Services";
import { useToast, useUrlParams } from "@Hooks";
import { useSaveUserForm } from "./useSaveUserForm";
import {
  useOrganizationUserDetailsCanvasStore,
  useOrganizationUserListStore,
} from "../../../../../Stores";
import type { InviteOrEditUserModel } from "@Interfaces";

export type EditUserCanvasProps = {
  onSubmit: VoidFunction;
  selfEditing: boolean;
};

export const useEditUserCanvas = ({
  onSubmit,
  selfEditing,
}: EditUserCanvasProps) => {
  const { userDetails, open, setOpen, setUserDetails } =
    useOrganizationUserDetailsCanvasStore();
  const { setNeedRefresh } = useOrganizationUserListStore();
  const [user, setUser] = useState<InviteOrEditUserModel>({
    ...(userDetails as InviteOrEditUserModel),
  });

  const [canvasLoading, setCanvasLoading] = useState(false);
  const formDirty = useMemo(
    () => userDetails && !equals(userDetails, user),
    [userDetails, user]
  );
  const [formValid, setFormValid] = useState(false);
  const { organizationId, userId } = useUrlParams();

  const toast = useToast();

  const handleClose = useCallback(() => {
    setOpen(false);
    setUserDetails({ ...(user as OrganizationAdminGetUserResponse) });
  }, [setOpen, setUserDetails, user]);

  const getPromises = useSaveUserForm(
    userDetails as InviteOrEditUserModel,
    user as InviteOrEditUserModel
  );

  useEffect(() => {
    setUser(userDetails as InviteOrEditUserModel);
  }, [userDetails]);

  const onFullySuccessfullEdit = useCallback(() => {
    toast({ message: "successes.put-user" });
    onSubmit();
    setNeedRefresh(true);
    handleClose();
  }, [handleClose, onSubmit, setNeedRefresh, toast]);

  const onPartialSuccessEdit = useCallback(() => {
    onSubmit();
    setNeedRefresh(true);
    handleClose();
  }, [handleClose, onSubmit, setNeedRefresh]);

  const handleSubmit = useCallback(() => {
    if (!userId || !organizationId) throw new Error("missing-url-params");
    setCanvasLoading(true);
    getPromises()
      .then(responses => {
        if (!responses.length) return;
        const { length } = responses;
        const successReqCount = responses.reduce((total, response) => {
          return (total += +response);
        }, 0);
        if (successReqCount === 0) return; // everythings FAILED
        if (successReqCount === length) return onFullySuccessfullEdit();
        return onPartialSuccessEdit();
      })
      .finally(() => setCanvasLoading(false));
  }, [
    getPromises,
    onFullySuccessfullEdit,
    onPartialSuccessEdit,
    organizationId,
    userId,
  ]);

  return {
    isOpen: open,
    close: handleClose,
    selfEditing,
    user: user as InviteOrEditUserModel,
    setUser,
    formValid,
    setFormValid,
    formDirty,
    canvasLoading,
    handleSubmit,
  };
};
