import { ItemNotFound, Tabs } from "@Components";
import { ApiKeyLogs, LoadingPage, SectionDetails } from "./Components";
import { useApiKeyDetail } from "./Hooks/useApiKeyDetail";
import { memo, useMemo } from "react";
import { pureComponent } from "@Utils";
import { EditApiKey } from "./Components/EditApiKey/EditApiKey";
import "./ApiKeyDetails.style.scss";
import { useApiKeyDetailsTab } from "./Hooks";

export const ApiKeyDetail = memo(() => {
  const {
    apiKey,
    isExpired,
    notFound,
    editCanvasOpen,
    handleUpdate,
    setEditCanvasOpen,
  } = useApiKeyDetail();
  const { tabs, activeTab, setActiveTab } = useApiKeyDetailsTab();

  const content = useMemo(() => {
    switch (activeTab) {
      case 1:
        return <ApiKeyLogs />;
      default:
        return <SectionDetails />;
    }
  }, [activeTab]);

  if (notFound) {
    return <ItemNotFound message="organization.api-key-not-found" />;
  }

  if (!apiKey) {
    return <LoadingPage />;
  }

  return (
    <div id="api-key-details">
      <Tabs
        activeTab={activeTab}
        tabs={tabs}
        setActiveTab={setActiveTab}
        updateUrl={true}
      />
      <div id="api-key-details__content" className="card">
        {content}
      </div>
      {!isExpired && (
        <>
          <EditApiKey
            apiKey={apiKey}
            isOpen={editCanvasOpen}
            toggle={() => setEditCanvasOpen(false)}
            onSubmit={handleUpdate}
          />
        </>
      )}
    </div>
  );
}, pureComponent);
