import { CubesSVG } from "@Components";
import { pureComponent } from "@Utils";
import { memo } from "react";
import "./style.scss";
import { t } from "i18next";
import { Spinner } from "reactstrap";

export const LoadingApplication = memo(() => {
  return (
    <div
      id="loading-application"
      data-cy="loading-application"
      className="h-100 w-100 flex-center flex-column"
    >
      <div className="content">
        <Spinner size="sm" />
        <h6>{t("common.loading")}</h6>
      </div>
      <CubesSVG />
    </div>
  );
}, pureComponent);
